import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import LoadingOverlay from "./LoadingOverlay";

const PrivateRoute = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(null);

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/auth/verify`,
          {
            method: "GET",
            credentials: "include",
          }
        );

        if (response.ok) {
          setIsAuthenticated(true);
        } else {
          setIsAuthenticated(false);
        }
      } catch (error) {
        setIsAuthenticated(false);
      }
    };

    checkAuth();
  }, []);

  if (isAuthenticated === null) {
    return <LoadingOverlay />;
  }

  return isAuthenticated ? children : <Navigate to="/" />;
};

export default PrivateRoute;
