import styled from 'styled-components';
import { motion } from 'framer-motion';

export const SubscriptionOverlay = styled(motion.div)`
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(8px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
  padding: 20px;
`;

export const SubscriptionContent = styled(motion.div)`
  background: linear-gradient(
    165deg,
    rgba(28, 28, 35, 0.85),
    rgba(18, 18, 24, 0.95)
  );
  backdrop-filter: blur(40px);
  border-radius: 24px;
  padding: 32px;
  width: 100%;
  max-width: 80vw;
  border: 1px solid rgba(255, 255, 255, 0.07);
  box-shadow: 
    0 0 0 1px rgba(255, 255, 255, 0.05),
    0 20px 50px -12px rgba(0, 0, 0, 0.5),
    0 0 100px rgba(136, 83, 251, 0.1);
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    background: radial-gradient(
      circle at top right,
      rgba(136, 83, 251, 0.08),
      transparent 70%
    );
    border-radius: inherit;
    pointer-events: none;
  }
`;

export const SubscriptionTitle = styled.h2`
  text-align: center;
  margin-bottom: 2rem;
  font-size: 1.8rem;
  background: linear-gradient(45deg, #8853fb, #3498db);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  text-shadow: 0 2px 10px rgba(136, 83, 251, 0.3);
`;

export const SubscriptionGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  margin: 20px 0;
`;

export const PlanCard = styled(motion.div)`
  background: linear-gradient(
    165deg,
    rgba(38, 38, 45, 0.5),
    rgba(28, 28, 35, 0.5)
  );
  border: 1px solid rgba(136, 83, 251, 0.2);
  border-radius: 20px;
  padding: 24px;
  position: relative;
  overflow: hidden;
  transition: all 0.4s cubic-bezier(0.16, 1, 0.3, 1);
  cursor: pointer;

  ${props => props.$selected && `
    border-color: rgba(136, 83, 251, 0.4);
    box-shadow: 
      0 8px 32px rgba(136, 83, 251, 0.2),
      0 0 0 1px rgba(136, 83, 251, 0.2);
    transform: translateY(-2px);
  `}

  &:hover {
    transform: translateY(-2px);
    border-color: rgba(136, 83, 251, 0.3);
    box-shadow: 
      0 8px 32px rgba(136, 83, 251, 0.15),
      0 0 0 1px rgba(136, 83, 251, 0.15);
  }

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    background: linear-gradient(
      165deg,
      rgba(255, 255, 255, 0.03),
      transparent
    );
    border-radius: inherit;
  }
`;

export const PlanName = styled.h3`
  font-size: 1.4rem;
  margin-bottom: 12px;
  background: linear-gradient(45deg, #8853fb, #3498db);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
`;

export const PlanPrice = styled.div`
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 16px;
  color: white;

  span {
    font-size: 1rem;
    opacity: 0.7;
  }
`;

export const FeaturesList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  color: rgba(255, 255, 255, 0.8);

  li {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 8px;
    font-size: 14px;

    svg {
      color: #8853fb;
      flex-shrink: 0;
    }
  }
`;

export const ConfirmButton = styled(motion.button)`
  width: 100%;
  height: 48px;
  background: linear-gradient(
    165deg,
    rgba(136, 83, 251, 0.15),
    rgba(96, 57, 176, 0.15)
  );
  border: 1px solid rgba(136, 83, 251, 0.3);
  border-radius: 12px;
  color: white;
  cursor: pointer;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0.3px;
  backdrop-filter: blur(20px);
  margin-top: 24px;
  position: relative;
  overflow: hidden;
  transform: translateZ(0);
  transition: all 0.4s cubic-bezier(0.16, 1, 0.3, 1);

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    background: linear-gradient(
      165deg,
      rgba(255, 255, 255, 0.1),
      transparent
    );
    opacity: 0;
    transition: opacity 0.4s ease;
  }

  &::after {
    content: '';
    position: absolute;
    inset: -1px;
    background: linear-gradient(
      165deg,
      rgba(136, 83, 251, 0.1),
      transparent 50%
    );
    opacity: 0;
    transition: opacity 0.4s ease;
    pointer-events: none;
  }

  ${props => !props.disabled && `
    &:hover {
      transform: translateY(-2px) scale(1.02);
      background: linear-gradient(
        165deg,
        rgba(136, 83, 251, 0.2),
        rgba(96, 57, 176, 0.2)
      );
      border-color: rgba(136, 83, 251, 0.4);
      box-shadow: 
        0 8px 32px rgba(136, 83, 251, 0.2),
        0 0 0 1px rgba(136, 83, 251, 0.2);

      &::before {
        opacity: 1;
      }

      &::after {
        opacity: 0.4;
      }
    }

    &:active {
      transform: translateY(1px) scale(0.98);
    }
  `}

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
    transform: none;
  }

  /* Special styling for upgrade/subscribe buttons */
  &.upgrade-button, &.subscribe-button {
    background: linear-gradient(
      165deg,
      rgba(136, 83, 251, 0.25),
      rgba(96, 57, 176, 0.25)
    );
    border-color: rgba(136, 83, 251, 0.4);
    box-shadow: 
      0 0 0 1px rgba(136, 83, 251, 0.2),
      0 8px 20px rgba(136, 83, 251, 0.15);

    &:hover {
      background: linear-gradient(
        165deg,
        rgba(136, 83, 251, 0.3),
        rgba(96, 57, 176, 0.3)
      );
      border-color: rgba(136, 83, 251, 0.5);
      box-shadow: 
        0 12px 32px rgba(136, 83, 251, 0.25),
        0 0 0 1px rgba(136, 83, 251, 0.3);
    }
  }

  /* Special styling for reactivate button */
  &.reactivate-button {
    background: linear-gradient(
      165deg,
      rgba(52, 199, 89, 0.15),
      rgba(48, 209, 88, 0.15)
    );
    border-color: rgba(52, 199, 89, 0.4);

    &:hover {
      background: linear-gradient(
        165deg,
        rgba(52, 199, 89, 0.2),
        rgba(48, 209, 88, 0.2)
      );
      border-color: rgba(52, 199, 89, 0.5);
      box-shadow: 
        0 12px 32px rgba(52, 199, 89, 0.25),
        0 0 0 1px rgba(52, 199, 89, 0.3);
    }
  }
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 16px;
  right: 16px;
  width: 32px;
  height: 32px;
  background: linear-gradient(
    165deg,
    rgba(38, 38, 45, 0.5),
    rgba(28, 28, 35, 0.5)
  );
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  color: rgba(255, 255, 255, 0.8);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s cubic-bezier(0.16, 1, 0.3, 1);

  &:hover {
    background: linear-gradient(
      165deg,
      rgba(43, 43, 50, 0.6),
      rgba(33, 33, 40, 0.6)
    );
    border-color: rgba(136, 83, 251, 0.2);
    color: white;
    transform: translateY(-1px);
    box-shadow: 
      0 4px 12px rgba(0, 0, 0, 0.2),
      0 0 0 1px rgba(136, 83, 251, 0.1);
  }

  &:active {
    transform: translateY(1px);
  }
`;

export const IntervalSelection = styled.div`
  background: linear-gradient(
    165deg,
    rgba(38, 38, 45, 0.5),
    rgba(28, 28, 35, 0.5)
  );
  border: 1px solid rgba(136, 83, 251, 0.2);
  border-radius: 12px;
  padding: 4px;
  display: flex;
  position: relative;
  width: fit-content;
  margin: 0 auto 24px;
`;

export const IntervalButton = styled.button`
  background: none;
  border: none;
  padding: 8px 24px;
  color: ${props => props.$active ? '#fff' : 'rgba(255, 255, 255, 0.6)'};
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  position: relative;
  z-index: 1;
  transition: color 0.3s ease;

  &:hover {
    color: white;
  }
`;

export const IntervalSlider = styled.div`
  position: absolute;
  top: 4px;
  left: 4px;
  bottom: 4px;
  width: calc(50% - 4px);
  background: linear-gradient(
    165deg,
    rgba(136, 83, 251, 0.15),
    rgba(96, 57, 176, 0.15)
  );
  border: 1px solid rgba(136, 83, 251, 0.3);
  border-radius: 8px;
  transform: translateX(${props => props.$position === 'right' ? '100%' : '0'});
  transition: transform 0.3s cubic-bezier(0.16, 1, 0.3, 1);
`;

export const ConfirmationContent = styled.div`
  padding: 20px;
`;

export const ConfirmationTitle = styled.h3`
  font-size: 1.4rem;
  margin-bottom: 24px;
  text-align: center;
  background: linear-gradient(45deg, #8853fb, #3498db);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
`;

export const PlanChangeDetails = styled.div`
  background: linear-gradient(
    165deg,
    rgba(38, 38, 45, 0.5),
    rgba(28, 28, 35, 0.5)
  );
  border: 1px solid rgba(136, 83, 251, 0.2);
  border-radius: 16px;
  padding: 24px;
  margin-bottom: 24px;
`;

export const CurrentPlanInfo = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  color: rgba(255, 255, 255, 0.8);
`;

export const NewPlanInfo = styled(CurrentPlanInfo)`
  padding-top: 16px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
`;

export const PlanValue = styled.span`
  color: white;
  font-weight: 500;
`;

export const ChangeImpact = styled.div`
  margin-top: 20px;
  padding: 16px;
  border-radius: 12px;
  background: ${props => {
    switch (props.$type) {
      case 'upgrade':
        return 'linear-gradient(165deg, rgba(52, 199, 89, 0.1), rgba(52, 199, 89, 0.05))';
      case 'downgrade':
        return 'linear-gradient(165deg, rgba(255, 149, 0, 0.1), rgba(255, 149, 0, 0.05))';
      case 'reactivation':
        return 'linear-gradient(165deg, rgba(0, 122, 255, 0.1), rgba(0, 122, 255, 0.05))';
      default:
        return 'linear-gradient(165deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.05))';
    }
  }};
  border: 1px solid ${props => {
    switch (props.$type) {
      case 'upgrade':
        return 'rgba(52, 199, 89, 0.2)';
      case 'downgrade':
        return 'rgba(255, 149, 0, 0.2)';
      case 'reactivation':
        return 'rgba(0, 122, 255, 0.2)';
      default:
        return 'rgba(255, 255, 255, 0.1)';
    }
  }};
  color: ${props => {
    switch (props.$type) {
      case 'upgrade':
        return 'rgba(52, 199, 89, 1)';
      case 'downgrade':
        return 'rgba(255, 149, 0, 1)';
      case 'reactivation':
        return 'rgba(0, 122, 255, 1)';
      default:
        return 'rgba(255, 255, 255, 0.8)';
    }
  }};
`;

export const ConfirmationActions = styled.div`
  display: flex;
  gap: 16px;
  margin-top: 24px;
`;

export const BackButton = styled(motion.button)`
  flex: 1;
  height: 48px;
  background: linear-gradient(
    165deg,
    rgba(255, 255, 255, 0.1),
    rgba(255, 255, 255, 0.05)
  );
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  color: white;
  cursor: pointer;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0.3px;
  transition: all 0.3s cubic-bezier(0.16, 1, 0.3, 1);

  &:hover {
    background: linear-gradient(
      165deg,
      rgba(255, 255, 255, 0.15),
      rgba(255, 255, 255, 0.07)
    );
    transform: translateY(-2px);
  }
`; 