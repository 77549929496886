import React, { useState, useMemo, useEffect, useRef, useContext } from "react";
import styled from "styled-components";
import { motion, AnimatePresence } from "framer-motion";
import { X, Send, ChevronDown, Users, UserPlus } from "react-feather";
import { io } from "socket.io-client";
import { AuthContext } from "../../context/AuthContext";
import { useToast } from "../../context/ToastContext";
import { createChatApi } from "../chat/chatApi";

const MiniChatContainer = styled(motion.div)`
  position: absolute;
  bottom: calc(100% + 16px);
  right: 0;
  width: 300px;
  background: linear-gradient(
    165deg,
    rgba(28, 28, 35, 0.95),
    rgba(18, 18, 24, 0.95)
  );
  backdrop-filter: blur(40px);
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.07);
  box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.05),
    0 20px 50px -12px rgba(0, 0, 0, 0.5), 0 0 100px rgba(136, 83, 251, 0.1);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  max-height: 400px;
  z-index: 1000;
`;

const ChatHeader = styled.div`
  padding: 12px 16px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.07);
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(
    165deg,
    rgba(38, 38, 45, 0.5),
    rgba(28, 28, 35, 0.5)
  );

  .header-title {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  h3 {
    color: rgba(255, 255, 255, 0.9);
    font-size: 14px;
    margin: 0;
  }

  .unread-total {
    background: linear-gradient(165deg, #8853fb, #6039b0);
    color: white;
    font-size: 11px;
    font-weight: 500;
    padding: 2px 8px;
    border-radius: 10px;
    box-shadow: 0 2px 6px rgba(136, 83, 251, 0.3);
  }

  .header-actions {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  button {
    background: none;
    border: none;
    color: rgba(255, 255, 255, 0.6);
    cursor: pointer;
    padding: 4px;
    display: flex;
    align-items: center;
    transition: color 0.2s ease;

    &:hover {
      color: rgba(255, 255, 255, 0.9);
    }
  }
`;

const MessagesContainer = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 12px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  min-height: 200px;
  max-height: 300px;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.02);
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(136, 83, 251, 0.2);
    border-radius: 3px;
  }
`;

const MessageBubble = styled.div`
  position: relative;
  padding: 8px 12px;
  border-radius: 12px;
  max-width: 80%;
  align-self: ${(props) => (props.$sent ? "flex-end" : "flex-start")};
  background: ${(props) =>
    props.$sent
      ? "linear-gradient(165deg, rgba(136, 83, 251, 0.15), rgba(96, 57, 176, 0.15))"
      : "linear-gradient(165deg, rgba(38, 38, 45, 0.5), rgba(28, 28, 35, 0.5))"};
  border: 1px solid
    ${(props) =>
      props.$sent ? "rgba(136, 83, 251, 0.3)" : "rgba(255, 255, 255, 0.07)"};
  color: rgba(255, 255, 255, 1);
  font-size: 13px;
  word-break: break-word;
  cursor: pointer;

  &:hover {
    background: ${(props) =>
      props.$sent
        ? "linear-gradient(165deg, rgba(136, 83, 251, 0.2), rgba(96, 57, 176, 0.2))"
        : "linear-gradient(165deg, rgba(43, 43, 50, 0.6), rgba(33, 33, 40, 0.6))"};
  }
`;

const InputArea = styled.div`
  padding: 12px;
  border-top: 1px solid rgba(255, 255, 255, 0.07);
  display: flex;
  gap: 8px;
`;

const Input = styled.input`
  flex: 1;
  background: linear-gradient(
    165deg,
    rgba(38, 38, 45, 0.5),
    rgba(28, 28, 35, 0.5)
  );
  border: 1px solid rgba(136, 83, 251, 0.2);
  border-radius: 8px;
  padding: 8px 12px;
  color: rgba(255, 255, 255, 0.9);
  font-size: 13px;

  &:focus {
    outline: none;
    border-color: rgba(136, 83, 251, 0.4);
  }
`;

const SendButton = styled(motion.button)`
  background: linear-gradient(
    165deg,
    rgba(136, 83, 251, 0.15),
    rgba(96, 57, 176, 0.15)
  );
  border: 1px solid rgba(136, 83, 251, 0.3);
  border-radius: 8px;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(136, 83, 251, 0.9);
  cursor: pointer;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const ChatListContainer = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 8px;
  display: flex;
  flex-direction: column;
  gap: 6px;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.02);
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(136, 83, 251, 0.2);
    border-radius: 3px;
  }
`;

const ChatListItem = styled(motion.div)`
  padding: 10px 12px;
  border-radius: 12px;
  background: ${(props) =>
    props.$active
      ? "linear-gradient(165deg, rgba(136, 83, 251, 0.15), rgba(96, 57, 176, 0.15))"
      : "linear-gradient(165deg, rgba(38, 38, 45, 0.5), rgba(28, 28, 35, 0.5))"};
  border: 1px solid
    ${(props) =>
      props.$active ? "rgba(136, 83, 251, 0.3)" : "rgba(255, 255, 255, 0.07)"};
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.16, 1, 0.3, 1);
  position: relative;

  .chat-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    min-height: 46px;
  }

  .chat-info {
    flex: 1;
    min-width: 0;
    padding-right: ${(props) => (props.$hasUnread ? "50px" : "40px")};
  }

  .chat-name {
    font-size: 13px;
    font-weight: 500;
    color: rgba(255, 255, 255, 0.9);
    margin-bottom: 4px;
  }

  .last-message {
    font-size: 12px;
    color: rgba(255, 255, 255, 0.5);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 180px;
  }

  .time {
    position: absolute;
    top: 12px;
    right: 12px;
    font-size: 11px;
    color: rgba(255, 255, 255, 0.4);
  }

  .unread-badge {
    position: absolute;
    bottom: 12px;
    right: 12px;
    min-width: 20px;
    height: 20px;
    padding: 0 6px;
    background: linear-gradient(165deg, #8853fb, #6039b0);
    color: white;
    font-size: 11px;
    font-weight: 500;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 6px rgba(136, 83, 251, 0.3),
      0 0 0 1px rgba(136, 83, 251, 0.2);
    transform-origin: center right;
  }

  &:hover {
    transform: translateY(-1px);
    background: linear-gradient(
      165deg,
      rgba(136, 83, 251, 0.1),
      rgba(96, 57, 176, 0.1)
    );
    border-color: rgba(136, 83, 251, 0.2);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1), 0 0 0 1px rgba(136, 83, 251, 0.1);

    .unread-badge {
      transform: scale(1.05);
      box-shadow: 0 4px 8px rgba(136, 83, 251, 0.4),
        0 0 0 1px rgba(136, 83, 251, 0.3);
    }
  }
`;

const ParticipantsModal = styled(motion.div)`
  position: absolute;
  top: 70px;
  left: 20px;
  width: 260px;
  height: 250px;
  max-height: 300px;
  background: linear-gradient(
    165deg,
    rgba(28, 28, 35, 0.95),
    rgba(18, 18, 24, 0.95)
  );
  backdrop-filter: blur(40px);
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.07);
  box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.05),
    0 20px 50px -12px rgba(0, 0, 0, 0.5), 0 0 100px rgba(136, 83, 251, 0.1);
  z-index: 1000;
  overflow: hidden;
  transform-origin: top left;
`;

const ModalHeader = styled.div`
  padding: 12px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.07);
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: linear-gradient(
    165deg,
    rgba(38, 38, 45, 0.5),
    rgba(28, 28, 35, 0.5)
  );

  h3 {
    color: rgba(255, 255, 255, 0.9);
    font-size: 14px;
    font-weight: 500;
    margin: 0;
  }
`;

const CloseButton = styled(motion.button)`
  background: none;
  border: none;
  color: rgba(255, 255, 255, 0.6);
  cursor: pointer;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  transition: all 0.2s ease;

  &:hover {
    color: rgba(255, 255, 255, 0.9);
    background: rgba(255, 255, 255, 0.1);
  }

  svg {
    width: 16px;
    height: 16px;
  }
`;

const ParticipantList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 0 16px;
  overflow-y: auto;
  max-height: calc(100% - 60px);

  /* First 5 items special styling */
  & > *:nth-child(-n + 5) {
    padding: 12px;
    background: linear-gradient(
      165deg,
      rgba(43, 43, 50, 0.6),
      rgba(33, 33, 40, 0.6)
    );
    border: 1px solid rgba(136, 83, 251, 0.2);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.02);
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(136, 83, 251, 0.2);
    border-radius: 3px;
  }
`;

const ParticipantItem = styled.div`
  padding: 10px;
  background: linear-gradient(
    165deg,
    rgba(38, 38, 45, 0.5),
    rgba(28, 28, 35, 0.5)
  );
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.07);

  .name {
    color: rgba(255, 255, 255, 0.9);
    font-size: 14px;
    font-weight: 500;
  }

  .email {
    color: rgba(255, 255, 255, 0.5);
    font-size: 12px;
    margin-top: 2px;
  }
`;

const ParticipantsButton = styled(motion.button)`
  background: linear-gradient(
    165deg,
    rgba(136, 83, 251, 0.15),
    rgba(96, 57, 176, 0.15)
  );
  border: 1px solid rgba(136, 83, 251, 0.3);
  border-radius: 8px;
  padding: 4px 12px;
  color: rgba(255, 255, 255, 0.9);
  font-size: 13px;
  display: flex;
  align-items: center;
  gap: 6px;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.16, 1, 0.3, 1);
  backdrop-filter: blur(10px);

  &:hover {
    background: linear-gradient(
      165deg,
      rgba(136, 83, 251, 0.2),
      rgba(96, 57, 176, 0.2)
    );
    border-color: rgba(136, 83, 251, 0.4);
    transform: translateY(-1px);
    box-shadow: 0 4px 12px rgba(136, 83, 251, 0.1),
      0 0 0 1px rgba(136, 83, 251, 0.2);
  }

  &:active {
    transform: translateY(1px);
  }

  svg {
    width: 14px;
    height: 14px;
    color: rgba(136, 83, 251, 0.9);
  }
`;

const UnreadBadge = styled(motion.span)`
  background: linear-gradient(165deg, #8853fb, #6039b0);
  color: white;
  font-size: 11px;
  font-weight: 500;
  padding: 2px 8px;
  border-radius: 10px;
  box-shadow: 0 2px 6px rgba(136, 83, 251, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 20px;
  height: 20px;
`;

const LoadingWaveContainer = styled.div`
  position: relative;
  width: 100%;
  height: 40px;
  overflow: hidden;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const WaveEffect = styled.div`
  position: absolute;
  width: 200%;
  height: 100%;
  background: linear-gradient(
    90deg,
    transparent,
    rgba(136, 83, 251, 0.1),
    rgba(136, 83, 251, 0.2),
    rgba(136, 83, 251, 0.1),
    transparent
  );
  animation: waveAnimation 2s linear infinite;
  transform-origin: center;
  opacity: 0.5;

  @keyframes waveAnimation {
    0% {
      transform: translateX(-50%) perspective(500px) rotateX(60deg) scaleY(0.5);
    }
    100% {
      transform: translateX(0%) perspective(500px) rotateX(60deg) scaleY(0.5);
    }
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: inherit;
    filter: blur(8px);
  }
`;

const LoadingText = styled.div`
  color: rgba(255, 255, 255, 0.7);
  font-size: 13px;
  z-index: 1;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
`;

const ScrollToBottomButton = styled(motion.button)`
  position: fixed;
  bottom: 80px;
  left: 50%;
  transform: translateX(-50%);
  height: 32px;
  padding: 0 12px;
  border-radius: 16px;
  background: linear-gradient(
    165deg,
    rgba(136, 83, 251, 0.15),
    rgba(96, 57, 176, 0.15)
  );
  border: 1px solid rgba(136, 83, 251, 0.3);
  color: rgba(255, 255, 255, 0.9);
  display: flex;
  align-items: center;
  gap: 6px;
  cursor: pointer;
  z-index: 10;
  transition: all 0.3s cubic-bezier(0.16, 1, 0.3, 1);
  backdrop-filter: blur(10px);
  font-size: 13px;

  &:hover {
    background: linear-gradient(
      165deg,
      rgba(136, 83, 251, 0.2),
      rgba(96, 57, 176, 0.2)
    );
    transform: translateX(-50%) translateY(-2px);
  }

  .new-message-count {
    background: #8853fb;
    padding: 2px 6px;
    border-radius: 10px;
    font-size: 11px;
    font-weight: 500;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
`;

const MiniChat = ({ isOpen, onClose }) => {
  const [view, setView] = useState("list");
  const [chats, setChats] = useState([]);
  const [activeChat, setActiveChat] = useState(null);
  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState("");
  const [unreadCount, setUnreadCount] = useState(0);
  const messagesEndRef = useRef(null);
  const socket = useRef(null);
  const { user } = useContext(AuthContext);
  const { showToast } = useToast();
  const [showOptions, setShowOptions] = useState(false);
  const [activeMessage, setActiveMessage] = useState(null);
  const [showParticipants, setShowParticipants] = useState(false);
  const [selectedChat, setSelectedChat] = useState(null);
  const optionsRef = useRef(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const loadingTimeoutRef = useRef(null);
  const [searchResults, setSearchResults] = useState([]);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const initialLoadTimeoutRef = useRef(null);
  const [showScrollButton, setShowScrollButton] = useState(false);
  const [hasNewMessage, setHasNewMessage] = useState(false);
  const [isNearBottom, setIsNearBottom] = useState(true);
  const SCROLL_THRESHOLD = 300;
  const [newMessageCount, setNewMessageCount] = useState(0);
  const [justSent, setJustSent] = useState(false);

  const chatApi = useMemo(
    () =>
      createChatApi({
        setChats,
        setMessages,
        setMessage,
        socket,
        showToast,
        navigate: undefined,
        setSearchResults,
        setActiveChat,
        user,
      }),
    [user]
  );

  const { fetchChats, handleSend: sendMessage } = chatApi;

  useEffect(() => {
    let mounted = true;

    const loadChats = async () => {
      if (isOpen && mounted) {
        try {
          const chatsData = await fetchChats();
          if (mounted) {
            setChats(chatsData);
          }
        } catch (error) {
          if (mounted) {
            showToast("Failed to load chats", "error");
          }
        }
      }
    };

    loadChats();

    return () => {
      mounted = false;
    };
  }, [isOpen]);

  const loadMessages = async (chatId, page, isInitial = false) => {
    try {
      const messageContainer = messagesEndRef.current?.parentElement;
      const scrollPos = messageContainer?.scrollHeight;

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/chat/chats/${chatId}/messages?page=${page}&limit=20`,
        {
          credentials: "include",
        }
      );

      if (response.ok) {
        const data = await response.json();

        if (!data.messages) {
          console.error("No messages array in response");
          return false;
        }

        setMessages((prevMessages) => {
          const newMessages =
            page === 1 ? data.messages : [...data.messages, ...prevMessages];
          return newMessages;
        });

        if (page > 1 && messageContainer && scrollPos) {
          const newScrollPos = messageContainer.scrollHeight - scrollPos;
          messageContainer.scrollTop = newScrollPos;
        }

        if (isInitial && data.hasMore) {
          initialLoadTimeoutRef.current = setTimeout(() => {
            if (messageContainer && messageContainer.scrollTop < 100) {
              loadMessages(chatId, page + 1);
            }
            setIsInitialLoad(false);
          }, 1000);
        }

        return data.hasMore;
      } else {
        console.error("API response not OK:", response.status);
        return false;
      }
    } catch (error) {
      console.error("Error loading messages:", error);
      return false;
    }
  };

  const handleChatSelect = async (chatId) => {
    setActiveChat(chatId);
    setView("chat");
    setCurrentPage(1);
    setHasMore(true);
    setMessages([]);
    setIsInitialLoad(true);

    if (socket.current) {
      socket.current.emit("join chat", chatId);
    }

    try {
      const clearUnreadResponse = await fetch(
        `${process.env.REACT_APP_API_URL}/api/chat/chats/${chatId}/clear-unread`,
        {
          method: "POST",
          credentials: "include",
        }
      );

      if (clearUnreadResponse.ok) {
        const { totalUnread } = await clearUnreadResponse.json();

        setChats((prev) =>
          prev.map((chat) => {
            if (chat._id === chatId) {
              return {
                ...chat,
                unreadCounts: {
                  ...chat.unreadCounts,
                  [user._id]: 0,
                },
              };
            }
            return chat;
          })
        );

        setUnreadCount(totalUnread);

        if (socket.current) {
          socket.current.emit("update_unread_count", {
            chatId,
            totalUnread,
            userId: user._id,
          });
        }
      }

      await loadMessages(chatId, 1, true);
    } catch (error) {
      console.error("Error handling chat click:", error);
      setMessages([]);
      showToast("Failed to load messages", "error");
    }
  };

  useEffect(() => {
    if (isOpen && user?._id) {
      socket.current = io(process.env.REACT_APP_API_URL, {
        withCredentials: true,
        transports: ["websocket"],
        path: "/socket.io",
        reconnectionAttempts: 5,
        reconnectionDelay: 1000,
        auth: {
          token: document.cookie,
        },
        extraHeaders: {
          Cookie: document.cookie,
        },
      });

      socket.current.on("connect_error", (error) => {
        console.error("Socket connection error in MiniChat:", error);
      });

      socket.current.on("connect", () => {
        console.log("Socket connected successfully in MiniChat");
        socket.current.emit("user connected", user._id);

        // Join all chat rooms the user is part of
        chats.forEach((chat) => {
          socket.current.emit("join chat", chat._id);
        });
      });

      const handleMessage = (data) => {
        const { message, chat: chatId } = data;

        // Update messages if in active chat
        if (chatId === activeChat) {
          setMessages((prev) => {
            const newMessages = Array.isArray(prev)
              ? [...prev, message]
              : [message];

            // Maintain scroll position logic
            const container = messagesEndRef.current?.parentElement;
            if (container) {
              const { scrollTop, scrollHeight, clientHeight } = container;
              const scrollPosition = scrollHeight - scrollTop - clientHeight;

              if (scrollPosition < SCROLL_THRESHOLD) {
                setTimeout(() => scrollToBottom(), 0);
              } else {
                setHasNewMessage(true);
                setNewMessageCount((prev) => prev + 1);
              }
            }

            return newMessages;
          });

          // Don't increment unread count if we're in the active chat
          socket.current.emit("message_read", { chatId });
        }

        // Always update chat preview with latest message regardless of active status
        setChats((prev) =>
          prev.map((chat) => {
            if (chat._id === chatId) {
              return {
                ...chat,
                lastMessage: message,
                // Only update unread counts if not in active chat
                unreadCounts: chatId === activeChat 
                  ? chat.unreadCounts 
                  : {
                      ...chat.unreadCounts,
                      [user._id]: (chat.unreadCounts?.[user._id] || 0) + 1,
                    },
              };
            }
            return chat;
          })
        );

        // Update total unread count only if not in active chat
        if (chatId !== activeChat) {
          setUnreadCount((prev) => prev + 1);
        }
      };

      const handleMessageUnsent = ({ messageId, chatId }) => {
        console.log("MiniChat: Message unsent received:", {
          messageId,
          chatId,
        }); // Debug log

        // Always update chats list first
        setChats((prev) => {
          // Create a new array to ensure state update
          return prev.map((chat) => {
            if (chat._id === chatId) {
              const updatedChat = { ...chat };
              // Check if this message is the last message
              if (chat.lastMessage?._id === messageId) {
                updatedChat.lastMessage = {
                  ...chat.lastMessage,
                  _id: messageId,
                  isUnsent: true,
                  content: "This message was unsent",
                  sender: chat.lastMessage.sender,
                  updatedAt: new Date().toISOString(),
                };
                console.log("Updating last message for chat:", updatedChat); // Debug log
              }
              return updatedChat;
            }
            return chat;
          });
        });

        // Update messages if in active chat
        if (chatId === activeChat) {
          setMessages((prev) =>
            prev.map((msg) => {
              if (msg._id === messageId) {
                return {
                  ...msg,
                  isUnsent: true,
                  content: "This message was unsent",
                };
              }
              return msg;
            })
          );
        }
      };

      const handleUnreadUpdate = ({ chatId, totalUnread }) => {
        setUnreadCount(totalUnread);

        if (chatId) {
          setChats((prev) =>
            prev.map((chat) => {
              if (chat._id === chatId) {
                return {
                  ...chat,
                  unreadCounts: {
                    ...chat.unreadCounts,
                    [user._id]: 0,
                  },
                };
              }
              return chat;
            })
          );
        }
      };

      socket.current.on("message received", handleMessage);
      socket.current.on("update_unread_count", handleUnreadUpdate);
      socket.current.on("message_unsent", handleMessageUnsent);

      // Add socket event listener for message edits
      socket.current.on(
        "message_edited",
        ({ messageId, updatedMessage, chatId }) => {
          // Update messages if in active chat
          if (chatId === activeChat) {
            setMessages((prev) =>
              prev.map((msg) => (msg._id === messageId ? updatedMessage : msg))
            );
          }

          // Update chats list for message preview
          setChats((prev) =>
            prev.map((chat) => {
              if (chat._id === chatId && chat.lastMessage?._id === messageId) {
                return {
                  ...chat,
                  lastMessage: updatedMessage,
                };
              }
              return chat;
            })
          );
        }
      );

      return () => {
        if (socket.current) {
          if (activeChat) {
            socket.current.emit("leave chat", activeChat);
          }
          socket.current.off("message received", handleMessage);
          socket.current.off("update_unread_count", handleUnreadUpdate);
          socket.current.off("message_unsent", handleMessageUnsent);
          socket.current.off("message_edited");
          socket.current.disconnect();
        }
      };
    }
  }, [isOpen, user?._id, activeChat, chats]);

  useEffect(() => {
    if (currentPage === 1 || messages.length === 0) {
      messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages, currentPage]);

  const handleMessageSend = () => {
    if (!message.trim() || !activeChat) return;

    sendMessage(message, activeChat);
    setJustSent(true);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleMessageSend();
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (optionsRef.current && !optionsRef.current.contains(event.target)) {
        setShowOptions(false);
        setActiveMessage(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const handleMessageClick = (msg) => {
    setActiveMessage(msg);
    setShowOptions(true);
  };

  const handleShowParticipants = (chat) => {
    setSelectedChat(chat);
    setShowParticipants(true);
  };

  const handleCloseChat = () => {
    setView("list");
    setActiveChat(null);
    setMessages([]);
  };

  useEffect(() => {
    const messageContainer = messagesEndRef.current?.parentElement;
    if (!messageContainer) return;

    const handleScroll = async () => {
      const { scrollTop } = messageContainer;

      if (
        scrollTop < 100 &&
        hasMore &&
        !isLoadingMore &&
        !isInitialLoad &&
        activeChat
      ) {
        if (loadingTimeoutRef.current) {
          clearTimeout(loadingTimeoutRef.current);
        }

        loadingTimeoutRef.current = setTimeout(async () => {
          setIsLoadingMore(true);
          const nextPage = currentPage + 1;
          const hasMoreMessages = await loadMessages(activeChat, nextPage);

          setCurrentPage(nextPage);
          setHasMore(hasMoreMessages);
          setIsLoadingMore(false);
        }, 1000);
      }
    };

    messageContainer.addEventListener("scroll", handleScroll);
    return () => {
      messageContainer.removeEventListener("scroll", handleScroll);
      if (loadingTimeoutRef.current) {
        clearTimeout(loadingTimeoutRef.current);
      }
      if (initialLoadTimeoutRef.current) {
        clearTimeout(initialLoadTimeoutRef.current);
      }
    };
  }, [currentPage, hasMore, isLoadingMore, activeChat, isInitialLoad]);

  const scrollToBottom = () => {
    const container = messagesEndRef.current?.parentElement;
    if (container) {
      container.scrollTop = container.scrollHeight;
      setHasNewMessage(false);
      setShowScrollButton(false);
      setNewMessageCount(0);
    }
  };

  useEffect(() => {
    const messageContainer = messagesEndRef.current?.parentElement;
    if (!messageContainer) return;

    const handleScroll = () => {
      const { scrollTop, scrollHeight, clientHeight } = messageContainer;
      const scrollPosition = scrollHeight - scrollTop - clientHeight;
      const isNearBottom = scrollPosition < 100;

      setIsNearBottom(isNearBottom);
      setShowScrollButton(!isNearBottom);

      if (isNearBottom) {
        setHasNewMessage(false);
        setNewMessageCount(0);
      }
    };

    messageContainer.addEventListener("scroll", handleScroll);
    return () => messageContainer.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    if (justSent) {
      const timer = setTimeout(() => {
        const container = messagesEndRef.current?.parentElement;
        if (container) {
          container.scrollTop = container.scrollHeight;
        }
        setJustSent(false);
      }, 100);

      return () => clearTimeout(timer);
    }
  }, [justSent, messages]);

  return (
    <AnimatePresence>
      {isOpen && (
        <MiniChatContainer
          initial={{ opacity: 0, y: 20, scale: 0.95 }}
          animate={{ opacity: 1, y: 0, scale: 1 }}
          exit={{ opacity: 0, y: 20, scale: 0.95 }}
          transition={{ type: "spring", damping: 25, stiffness: 300 }}
        >
          <ChatHeader>
            <div className="header-title">
              <h3>
                {view === "list" ? (
                  "Messages"
                ) : (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "8px",
                    }}
                  >
                    <ParticipantsButton
                      onClick={() =>
                        handleShowParticipants(
                          chats.find((c) => c._id === activeChat)
                        )
                      }
                      whileHover={{ scale: 1.02 }}
                      whileTap={{ scale: 0.98 }}
                    >
                      <Users size={14} />
                      {chats.find((c) => c._id === activeChat)?.participants
                        ?.length || 0}
                    </ParticipantsButton>
                  </div>
                )}
              </h3>
              {view === "list" && unreadCount > 0 && (
                <UnreadBadge
                  initial={{ scale: 0 }}
                  animate={{ scale: 1 }}
                  transition={{ type: "spring", stiffness: 300, damping: 25 }}
                >
                  {unreadCount}
                </UnreadBadge>
              )}
            </div>
            <div className="header-actions">
              {view === "chat" && (
                <button onClick={handleCloseChat}>
                  <ChevronDown size={18} />
                </button>
              )}
              <button onClick={onClose}>
                <X size={18} />
              </button>
            </div>
          </ChatHeader>

          {view === "list" ? (
            <ChatListContainer>
              {chats.map((chat) => (
                <ChatListItem
                  key={chat._id}
                  $active={chat._id === activeChat}
                  $hasUnread={chat.unreadCounts?.[user?._id] > 0}
                  onClick={() => handleChatSelect(chat._id)}
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                >
                  <div className="chat-content">
                    <div className="chat-info">
                      <div className="chat-name">
                        {chat.participants
                          .filter((p) => p._id !== user?._id)
                          .map((p) => p.name)
                          .join(", ")}
                      </div>
                      <div className="last-message">
                        {chat.lastMessage?.isUnsent
                          ? "This message was unsent"
                          : chat.lastMessage?.content || "No messages yet"}
                      </div>
                    </div>
                  </div>
                  <div className="time">
                    {chat.lastMessage
                      ? new Date(chat.lastMessage.createdAt).toLocaleTimeString(
                          [],
                          {
                            hour: "2-digit",
                            minute: "2-digit",
                          }
                        )
                      : ""}
                  </div>
                  <AnimatePresence>
                    {chat.unreadCounts?.[user?._id] > 0 && (
                      <motion.div
                        className="unread-badge"
                        initial={{ scale: 0, opacity: 0 }}
                        animate={{ scale: 1, opacity: 1 }}
                        exit={{ scale: 0, opacity: 0 }}
                        transition={{
                          type: "spring",
                          stiffness: 400,
                          damping: 25,
                        }}
                      >
                        {chat.unreadCounts[user._id]}
                      </motion.div>
                    )}
                  </AnimatePresence>
                </ChatListItem>
              ))}
            </ChatListContainer>
          ) : (
            <>
              <MessagesContainer>
                {isLoadingMore && (
                  <LoadingWaveContainer>
                    <WaveEffect />
                    <LoadingText>Loading more messages...</LoadingText>
                  </LoadingWaveContainer>
                )}

                {!Array.isArray(messages) && <div>Loading...</div>}
                {Array.isArray(messages) && messages.length === 0 && (
                  <div
                    style={{
                      textAlign: "center",
                      color: "rgba(255,255,255,0.5)",
                    }}
                  >
                    No messages yet
                  </div>
                )}

                {Array.isArray(messages) &&
                  messages.length > 0 &&
                  messages.map((msg, index) => {
                    if (!msg) return null;

                    return (
                      <MessageBubble
                        key={`${msg._id}-${index}`}
                        $sent={msg.sender._id === user._id}
                        onClick={() => handleMessageClick(msg)}
                      >
                        {msg.isUnsent ? (
                          <span style={{ fontStyle: "italic", opacity: 0.7 }}>
                            Message was unsent
                          </span>
                        ) : (
                          msg.content
                        )}
                      </MessageBubble>
                    );
                  })}
                <AnimatePresence>
                  {(showScrollButton || hasNewMessage) && (
                    <ScrollToBottomButton
                      onClick={scrollToBottom}
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: 20 }}
                      whileHover={{ scale: 1.02 }}
                      whileTap={{ scale: 0.98 }}
                    >
                      <ChevronDown size={14} />
                      {newMessageCount > 0 ? (
                        <>New ({newMessageCount})</>
                      ) : (
                        "Bottom"
                      )}
                    </ScrollToBottomButton>
                  )}
                </AnimatePresence>

                <div ref={messagesEndRef} />
              </MessagesContainer>

              <InputArea>
                <Input
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  onKeyPress={handleKeyPress}
                  placeholder="Type a message..."
                />
                <SendButton
                  onClick={handleMessageSend}
                  disabled={!message.trim()}
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  <Send size={14} />
                </SendButton>
              </InputArea>
            </>
          )}

          <AnimatePresence>
            {showParticipants && selectedChat && (
              <ParticipantsModal
                initial={{ opacity: 0, scale: 0.95 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.95 }}
                transition={{ type: "spring", damping: 25, stiffness: 300 }}
              >
                <ModalHeader>
                  <h3>Chat Participants</h3>
                  <CloseButton onClick={() => setShowParticipants(false)}>
                    <X size={16} />
                  </CloseButton>
                </ModalHeader>
                <ParticipantList>
                  {selectedChat.participants.map((participant) => (
                    <ParticipantItem key={participant._id}>
                      <div className="name">{participant.name}</div>
                      <div className="email">{participant.email}</div>
                    </ParticipantItem>
                  ))}
                </ParticipantList>
              </ParticipantsModal>
            )}
          </AnimatePresence>
        </MiniChatContainer>
      )}
    </AnimatePresence>
  );
};

export default MiniChat;
