const API_URL = process.env.REACT_APP_API_URL;

export const searchUsers = async (searchTerm) => {
  try {
    const response = await fetch(
      `${API_URL}/api/friends/search?term=${encodeURIComponent(searchTerm)}`,
      {
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );
    
    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.error || 'Search failed');
    }
    
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error searching users:', error);
    throw error;
  }
};

export const sendFriendRequest = async (userId, message) => {
  try {
    const response = await fetch(`${API_URL}/api/friends/request`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ userId, message }),
    });
    
    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.error || 'Failed to send friend request');
    }
    
    return await response.json();
  } catch (error) {
    console.error('Error sending friend request:', error);
    throw error;
  }
};

export const getFriendsList = async () => {
  try {
    const response = await fetch(`${API_URL}/api/friends`, {
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      }
    });
    
    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.error || 'Failed to fetch friends');
    }
    
    return await response.json();
  } catch (error) {
    console.error('Error fetching friends:', error);
    throw error;
  }
};

export const respondToRequest = async (requestId, status) => {
  try {
    const response = await fetch(`${API_URL}/api/friends/request/${requestId}`, {
      method: 'PUT',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ status }),
    });
    
    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.error || 'Failed to respond to friend request');
    }
    
    return await response.json();
  } catch (error) {
    console.error('Error responding to friend request:', error);
    throw error;
  }
};

export const removeFriend = async (friendshipId) => {
  try {
    const response = await fetch(`${API_URL}/api/friends/${friendshipId}`, {
      method: 'DELETE',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      }
    });
    
    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.error || 'Failed to remove friend');
    }
    
    return await response.json();
  } catch (error) {
    console.error('Error removing friend:', error);
    throw error;
  }
};

export const getPendingRequests = async () => {
  try {
    const response = await fetch(`${API_URL}/api/friends/pending`, {
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      }
    });
    
    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.error || 'Failed to fetch pending requests');
    }
    
    return await response.json();
  } catch (error) {
    console.error('Error fetching pending requests:', error);
    throw error;
  }
};

export const cancelFriendRequest = async (requestId) => {
  try {
    const response = await fetch(`${API_URL}/api/friends/request/${requestId}/cancel`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      }
    });
    
    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.error || 'Failed to cancel friend request');
    }
    
    return await response.json();
  } catch (error) {
    console.error('Error canceling friend request:', error);
    throw error;
  }
};

export const blockUser = async (userId, reason) => {
  try {
    const response = await fetch(`${API_URL}/api/friends/block/${userId}`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ reason })
    });

    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.error || 'Failed to block user');
    }

    return await response.json();
  } catch (error) {
    console.error('Error blocking user:', error);
    throw error;
  }
};

export const unblockUser = async (userId) => {
  try {
    const response = await fetch(`${API_URL}/api/friends/unblock/${userId}`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      }
    });

    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.error || 'Failed to unblock user');
    }

    return await response.json();
  } catch (error) {
    console.error('Error unblocking user:', error);
    throw error;
  }
};

export const deleteUser = async (userId) => {
  try {
    const response = await fetch(`${API_URL}/api/friends/delete/${userId}`, {
      method: 'DELETE',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      }
    });

    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.error || 'Failed to delete user');
    }

    return await response.json();
  } catch (error) {
    console.error('Error deleting user:', error);
    throw error;
  }
}; 