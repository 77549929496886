// src/pages/LandingPage.js

import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { motion, AnimatePresence } from "framer-motion";
import { useNavigate, Link, useLocation } from "react-router-dom";
import styled, { createGlobalStyle } from "styled-components";
import SignInModal from "../modals/SignInModal";
import SignUpModal from "../modals/SignUpModal";
import PolicyModal from "../modals/PolicyModal";
import BackgroundAnimation from "../common/BackgroundAnimation";
import LoadingOverlay from "../common/LoadingOverlay";
import {
  privacyPolicyContent,
  termsOfServiceContent,
} from "../../utils/policyContent";
import {
  Globe,
  LogOut,
  LogIn,
  UserPlus,
  Search,
  Users,
  DollarSign,
  Briefcase,
  Menu,
  X,
} from "react-feather";
import SwarmLogo, {
  SwarmContainer,
  BubbleSkin,
  Highlight,
  EnergyRing,
} from "../common/SwarmLogo";
import FakeWindowsScreen from "../common/FakeWindowsScreen";
import GlobalStyle from "../common/GlobalStyle";

const LandingPageContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
  overflow: hidden;
  background: transparent;
  justify-content: space-between;
  z-index: 1;
`;

const StyledBackgroundAnimation = styled(BackgroundAnimation)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
`;

const Navbar = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  background: linear-gradient(
    165deg,
    rgba(28, 28, 35, 0.85),
    rgba(18, 18, 24, 0.95)
  );
  backdrop-filter: blur(40px);
  border-bottom: 1px solid rgba(255, 255, 255, 0.07);
  box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.05), 0 4px 20px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
`;

const Logo = styled(Link)`
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  font-weight: bold;
  text-decoration: none;
  cursor: pointer;

  ${SwarmContainer} {
    width: 48px;
    height: 48px;
    transform: scale(1);
    transition: transform 0.3s cubic-bezier(0.16, 1, 0.3, 1);
  }

  &:hover {
    ${SwarmContainer} {
      transform: scale(1.05);
    }

    ${BubbleSkin} {
      scale: 1.02;
      opacity: 0.9;
    }

    ${Highlight} {
      opacity: 0.5;
      scale: 1.1;
    }

    ${EnergyRing} {
      scale: 1.1;
      opacity: 1;
    }
  }
`;

const LogoText = styled.span`
  position: relative;
  background: linear-gradient(45deg, #3498db, #8853fb, #3498db, #8853fb);
  background-size: 300% 300%;
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  animation: waveAnimation 8s ease-in-out infinite;

  @keyframes waveAnimation {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
`;

const Nav = styled.nav`
  display: flex;
  gap: 1rem;
  align-items: center;

  @media (max-width: 768px) {
    width: 100%;
    flex-direction: column;
    gap: 0.5rem;
  }
`;

const IconButton = styled.button`
  width: 140px;
  height: 44px;
  background: linear-gradient(
    165deg,
    rgba(136, 83, 251, 0.12),
    rgba(96, 57, 176, 0.12)
  );
  border: 1px solid rgba(136, 83, 251, 0.2);
  border-radius: 12px;
  color: rgba(255, 255, 255, 0.9);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.3px;
  backdrop-filter: blur(10px);
  transition: all 0.4s cubic-bezier(0.16, 1, 0.3, 1);
  position: relative;
  overflow: hidden;

  &::before {
    content: "";
    position: absolute;
    inset: 0;
    background: linear-gradient(
      165deg,
      rgba(136, 83, 251, 0.1),
      rgba(96, 57, 176, 0.05)
    );
    opacity: 0;
    transition: opacity 0.4s ease;
  }

  &:hover {
    transform: translateY(-2px);
    background: linear-gradient(
      165deg,
      rgba(136, 83, 251, 0.15),
      rgba(96, 57, 176, 0.15)
    );
    border-color: rgba(136, 83, 251, 0.3);
    box-shadow: 0 8px 32px rgba(136, 83, 251, 0.15),
      0 0 0 1px rgba(136, 83, 251, 0.2);

    &::before {
      opacity: 1;
    }
  }

  &:active {
    transform: translateY(1px);
  }

  svg {
    width: 16px;
    height: 16px;
    filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.2));
  }

  @media (max-width: 768px) {
    width: 100%;
    max-width: 280px;
    margin: 4px 0;
  }
`;

const CTABUTTON = styled(IconButton)`
  background: linear-gradient(
    165deg,
    rgba(136, 83, 251, 0.2),
    rgba(96, 57, 176, 0.2)
  );
  border-color: rgba(136, 83, 251, 0.3);
  font-weight: 600;
  color: white;

  &:hover {
    background: linear-gradient(
      165deg,
      rgba(136, 83, 251, 0.25),
      rgba(96, 57, 176, 0.25)
    );
    border-color: rgba(136, 83, 251, 0.4);
    box-shadow: 0 12px 36px rgba(136, 83, 251, 0.25),
      0 0 0 1px rgba(136, 83, 251, 0.3);
  }
`;

const LandingMain = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 80px 2rem 4rem;
  position: relative;
  z-index: 2;
  width: 100%;

  @media (max-width: 768px) {
    padding: 80px 1rem 3rem;
  }
`;

const HeroContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
  position: relative;
  z-index: 2;
  flex: 0 0 auto;

  &::before {
    content: "";
    position: absolute;
    inset: -50px -100px;
    background: radial-gradient(
      circle at 50% 0%,
      rgba(136, 83, 251, 0.15),
      transparent 70%
    );
    z-index: -1;
    pointer-events: none;
  }
`;

const Hero = styled.div`
  text-align: center;
  max-width: 800px;
  padding: 0 20px;
`;

const HeroTitle = styled.h1`
  font-size: clamp(2.5rem, 5vw, 4rem);
  font-weight: 700;
  background: linear-gradient(45deg, #8853fb, #3498db);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  margin-bottom: 1.5rem;
  letter-spacing: -0.02em;
  text-shadow: 0 2px 10px rgba(136, 83, 251, 0.3);
  position: relative;
`;

const HeroSubtitle = styled.p`
  font-size: clamp(1.1rem, 2vw, 1.4rem);
  color: rgba(255, 255, 255, 0.7);
  line-height: 1.6;
  margin-bottom: 2.5rem;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
`;

const Features = styled.div`
  width: 100%;
  max-width: 1200px;
  padding-top: 2rem;
  flex: 0 0 auto;
`;

const FeaturesTitle = styled.h2`
  text-align: center;
  font-size: 2rem;
  margin-bottom: 2rem;

  @media (max-width: 768px) {
    font-size: 1.75rem;
  }
`;

const FeatureGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1.5rem;
  padding: 0 1rem;
`;

const Feature = styled.div`
  background: linear-gradient(
    165deg,
    rgba(28, 28, 35, 0.85),
    rgba(18, 18, 24, 0.95)
  );
  backdrop-filter: blur(40px);
  border-radius: 24px;
  padding: 1.5rem;
  border: 1px solid rgba(255, 255, 255, 0.07);
  box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.05),
    0 20px 50px -12px rgba(0, 0, 0, 0.5);
  position: relative;
  overflow: hidden;
  transition: all 0.4s cubic-bezier(0.16, 1, 0.3, 1);

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 30px 60px -12px rgba(0, 0, 0, 0.5),
      0 0 0 1px rgba(136, 83, 251, 0.2);
  }

  &::before {
    content: "";
    position: absolute;
    inset: 0;
    background: radial-gradient(
      circle at top right,
      rgba(136, 83, 251, 0.08),
      transparent 70%
    );
    border-radius: inherit;
    pointer-events: none;
  }
`;

const FeatureIcon = styled.div`
  width: 64px;
  height: 64px;
  background: linear-gradient(
    165deg,
    rgba(38, 38, 45, 0.5),
    rgba(28, 28, 35, 0.5)
  );
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 1rem;
  border: 1px solid rgba(255, 255, 255, 0.03);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1), 0 1px 1px rgba(255, 255, 255, 0.03);
  backdrop-filter: blur(20px);

  svg {
    width: 32px;
    height: 32px;
    color: #8853fb;
    filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.2));
  }
`;

const FeatureDescription = styled.p`
  color: rgba(255, 255, 255, 0.7);
  font-size: 15px;
  line-height: 1.6;
  text-align: center;
`;

const LandingFooter = styled.footer`
  flex-shrink: 0;
  background: linear-gradient(
    165deg,
    rgba(28, 28, 35, 0.95),
    rgba(18, 18, 24, 0.98)
  );
  backdrop-filter: blur(40px);
  border-top: 1px solid rgba(255, 255, 255, 0.07);
  padding: 1rem;
  text-align: center;
  position: relative;
  z-index: 2;
  box-shadow: 0 -4px 20px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 1rem 0.5rem;
    gap: 0.5rem;
  }
`;

const FooterLinks = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 24px;
  padding: 0 1rem;

  a {
    color: rgba(136, 83, 251, 0.8);
    text-decoration: none;
    font-size: 14px;
    transition: all 0.2s ease;

    &:hover {
      color: #8853fb;
    }
  }
`;

const MinimizedBarContainer = styled.div`
  height: 40px;
  margin: 1rem 0;
`;

const MinimizedBar = styled.div`
  width: 300px;
  height: 75px;
  background-color: rgba(98, 0, 234, 0.2);
  color: #fff;
  display: flex;
  align-items: center;
  padding: 0 15px;
  border-radius: 8px;
  cursor: pointer;
  margin: 0 auto;
  backdrop-filter: blur(10px);
  border: 1px solid rgba(98, 0, 234, 0.3);
  box-shadow: 0 4px 6px rgba(98, 0, 234, 0.1);
  transition: all 0.3s ease;
  position: relative;

  &:hover {
    background-color: rgba(98, 0, 234, 0.3);
    box-shadow: 0 6px 12px rgba(98, 0, 234, 0.2);
    transform: translateY(-2px);

    ${SwarmContainer} {
      transform: scale(1.05);
    }

    ${BubbleSkin} {
      scale: 1.02;
      opacity: 0.9;
    }

    ${Highlight} {
      opacity: 0.5;
      scale: 1.1;
    }

    ${EnergyRing} {
      scale: 1.1;
      opacity: 1;
    }
  }

  &:active {
    transform: translateY(1px);
    box-shadow: 0 2px 4px rgba(98, 0, 234, 0.2);
  }
`;

const MinimizedBarContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const MinimizedBarTitle = styled.p`
  font-size: 1rem;
  font-weight: 600;
  margin: 0;
  color: #b388ff;
`;

const MinimizedBarSubtitle = styled.p`
  font-size: 0.85rem;
  margin: 2px 0 0;
  color: rgba(255, 255, 255, 0.8);
`;

const WindowWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: ${(props) => (props.$isVisible ? "1000" : "-1")};
  pointer-events: ${(props) => (props.$isMinimized ? "none" : "auto")};
  opacity: ${(props) => (props.$isVisible ? "1" : "0")};
  transition: opacity 0.3s ease-out, z-index 0.3s step-end;
`;

const pageVariants = {
  initial: { opacity: 0, scale: 0.95 },
  animate: {
    opacity: 1,
    scale: 1,
    transition: {
      duration: 0.5,
      ease: [0.16, 1, 0.3, 1],
      staggerChildren: 0.1,
    },
  },
  exit: {
    opacity: 0,
    scale: 0.95,
    transition: {
      duration: 0.4,
      ease: [0.16, 1, 0.3, 1],
    },
  },
};

const MobileMenuButton = styled(motion.button)`
  display: none;
  background: linear-gradient(
    165deg,
    rgba(38, 38, 45, 0.5),
    rgba(28, 28, 35, 0.5)
  );
  border: 1px solid rgba(136, 83, 251, 0.2);
  border-radius: 12px;
  padding: 8px;
  cursor: pointer;
  width: 44px;
  height: 44px;
  position: relative;
  isolation: isolate;
  overflow: hidden;

  @media (max-width: 768px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &::before {
    content: "";
    position: absolute;
    inset: 0;
    background: radial-gradient(
      circle at center,
      rgba(136, 83, 251, 0.1),
      transparent 70%
    );
    opacity: 0;
    transition: opacity 0.3s cubic-bezier(0.16, 1, 0.3, 1);
  }

  &:hover {
    background: linear-gradient(
      165deg,
      rgba(43, 43, 50, 0.6),
      rgba(33, 33, 40, 0.6)
    );
    border-color: rgba(136, 83, 251, 0.3);
    transform: translateY(-1px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2), 0 0 0 1px rgba(136, 83, 251, 0.2),
      0 0 20px rgba(136, 83, 251, 0.15);

    &::before {
      opacity: 1;
    }

    svg {
      color: rgba(136, 83, 251, 1);
      filter: drop-shadow(0 0 8px rgba(136, 83, 251, 0.5));
    }
  }

  &:active {
    transform: translateY(1px);
  }

  svg {
    width: 24px;
    height: 24px;
    color: rgba(136, 83, 251, 0.8);
    filter: drop-shadow(0 0 4px rgba(136, 83, 251, 0.3));
    transition: all 0.4s cubic-bezier(0.16, 1, 0.3, 1);
  }
`;

const MobileMenuContent = styled(motion.div)`
  @media (max-width: 768px) {
    width: 100%;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
  }
`;

const NavContent = styled(motion.div)`
  display: flex;
  align-items: center;
  gap: 1rem;

  @media (max-width: 768px) {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    flex-direction: column;
    background: linear-gradient(
      165deg,
      rgba(28, 28, 35, 0.98),
      rgba(18, 18, 24, 0.98)
    );
    backdrop-filter: blur(40px);
    border-bottom: 1px solid rgba(255, 255, 255, 0.07);
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.2),
      0 0 0 1px rgba(136, 83, 251, 0.1), 0 0 100px rgba(136, 83, 251, 0.1);
    padding: 0;
    overflow: hidden;
    transform-origin: top;
  }
`;

const LanguageButtons = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

const LanguageButton = styled.button`
  background: none;
  border: none;
  color: rgba(255, 255, 255, 0.5);
  font-size: 13px;
  padding: 4px 8px;
  cursor: pointer;
  transition: all 0.2s ease;
  border-radius: 6px;
  display: flex;
  align-items: center;
  gap: 4px;

  &:hover,
  &.active {
    color: rgba(136, 83, 251, 0.8);
    background: rgba(136, 83, 251, 0.1);
  }

  svg {
    width: 12px;
    height: 12px;
  }
`;

const LandingPage = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [activeModal, setActiveModal] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isWindowMinimized, setIsWindowMinimized] = useState(true);
  const [showFakeWindowsScreen, setShowFakeWindowsScreen] = useState(false);
  const [isWindowVisible, setIsWindowVisible] = useState(true);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const checkAuthentication = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/auth/verify`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
        }
      );

      if (response.ok) {
        setIsAuthenticated(true);
        navigate("/home/buy");
      } else {
        setIsAuthenticated(false);
      }
    } catch (error) {
      console.error("Failed to verify authentication:", error);
      setIsAuthenticated(false);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    checkAuthentication();
  }, [navigate]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const modalParam = params.get("modal");
    const hash = location.hash.slice(1);

    if (modalParam === "privacy-policy" || hash === "privacy-policy") {
      setActiveModal("privacyPolicy");
    } else if (
      modalParam === "terms-of-service" ||
      hash === "terms-of-service"
    ) {
      setActiveModal("termsOfService");
    } else {
      setActiveModal(null);
    }
  }, [location]);

  if (isLoading) return <LoadingOverlay />;

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const handleSignOut = () => {
    fetch(`${process.env.REACT_APP_API_URL}/api/auth/logout`, {
      method: "POST",
      credentials: "include",
    })
      .then((response) => {
        if (response.ok) {
          setIsAuthenticated(false);
        } else {
          console.error("Logout failed");
        }
      })
      .catch((err) => console.error("Sign out error:", err));
  };

  const handleOpenModal = (modalType) => {
    if (modalType === "privacyPolicy" || modalType === "termsOfService") {
      const queryParam = modalType.replace(/([A-Z])/g, "-$1").toLowerCase();
      navigate(`?modal=${queryParam}`);
    }
    setActiveModal(modalType);
  };

  const handleCloseModal = () => {
    navigate("");
    setActiveModal(null);
  };

  const handleSignInSuccess = () => {
    setIsAuthenticated(true);
    handleCloseModal();
    navigate("/home/buy");
  };

  const handleFakeSignUp = () => {
    handleOpenModal("signUp");
    setShowFakeWindowsScreen(false);
    setIsWindowMinimized(false);
  };

  const handleFakeSignIn = () => {
    handleOpenModal("signIn");
    setShowFakeWindowsScreen(false);
    setIsWindowMinimized(false);
  };

  const handleFakeSeePlans = () => {
    navigate("/pricing");
    setShowFakeWindowsScreen(false);
    setIsWindowMinimized(false);
  };

  const handleMinimizeWindow = () => {
    setIsWindowMinimized(true);
  };

  const handleRestoreWindow = () => {
    setIsWindowMinimized(false);
    setShowFakeWindowsScreen(true);
    setIsWindowVisible(true);
  };

  const handleCloseWindow = () => {
    setShowFakeWindowsScreen(false);
    setTimeout(() => {
      setIsWindowVisible(false);
    }, 300);
  };

  const handleLogoClick = (e) => {
    e.preventDefault();
    if (showFakeWindowsScreen) {
      setIsWindowMinimized(true);
    }
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const handlePrivacyPolicyClick = (e) => {
    e.preventDefault();
    handleOpenModal("privacyPolicy");
  };

  const handleTermsOfServiceClick = (e) => {
    e.preventDefault();
    handleOpenModal("termsOfService");
  };

  return (
    <>
      <GlobalStyle />
      <LandingPageContainer
        variants={pageVariants}
        initial="initial"
        animate="animate"
        exit="exit"
      >
        <StyledBackgroundAnimation />
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2, duration: 0.5 }}
        >
          <Navbar>
            <Logo onClick={handleLogoClick} to="#">
              <SwarmLogo size="48px" />
              <LogoText>{t("companyName")}</LogoText>
            </Logo>

            <MobileMenuButton
              onClick={toggleMobileMenu}
              className={isMobileMenuOpen ? "active" : ""}
              whileTap={{ scale: 0.95 }}
            >
              <AnimatePresence mode="wait">
                {isMobileMenuOpen ? (
                  <motion.div
                    key="close"
                    initial={{ rotate: -45, opacity: 0, scale: 0.8 }}
                    animate={{ rotate: 0, opacity: 1, scale: 1 }}
                    exit={{ rotate: 45, opacity: 0, scale: 0.8 }}
                    transition={{
                      duration: 0.3,
                      ease: [0.16, 1, 0.3, 1],
                    }}
                  >
                    <X />
                  </motion.div>
                ) : (
                  <motion.div
                    key="menu"
                    initial={{ rotate: 45, opacity: 0, scale: 0.8 }}
                    animate={{ rotate: 0, opacity: 1, scale: 1 }}
                    exit={{ rotate: -45, opacity: 0, scale: 0.8 }}
                    transition={{
                      duration: 0.3,
                      ease: [0.16, 1, 0.3, 1],
                    }}
                  >
                    <Menu />
                  </motion.div>
                )}
              </AnimatePresence>
            </MobileMenuButton>

            <AnimatePresence>
              {(isMobileMenuOpen || window.innerWidth > 768) && (
                <NavContent
                  initial={{ height: 0, opacity: 0, scale: 0.98 }}
                  animate={{
                    height: "auto",
                    opacity: 1,
                    scale: 1,
                    transition: {
                      height: {
                        duration: 0.4,
                        ease: [0.16, 1, 0.3, 1],
                      },
                      opacity: {
                        duration: 0.3,
                        delay: 0.1,
                      },
                      scale: {
                        duration: 0.4,
                        ease: [0.16, 1, 0.3, 1],
                      },
                    },
                  }}
                  exit={{
                    height: 0,
                    opacity: 0,
                    scale: 0.98,
                    transition: {
                      height: {
                        duration: 0.4,
                        ease: [0.16, 1, 0.3, 1],
                      },
                      opacity: { duration: 0.2 },
                      scale: { duration: 0.3 },
                    },
                  }}
                >
                  <MobileMenuContent
                    initial={{ y: -20, opacity: 0 }}
                    animate={{
                      y: 0,
                      opacity: 1,
                      transition: {
                        delay: 0.2,
                        duration: 0.4,
                        ease: [0.16, 1, 0.3, 1],
                      },
                    }}
                    exit={{
                      y: -20,
                      opacity: 0,
                      transition: {
                        duration: 0.3,
                        ease: [0.16, 1, 0.3, 1],
                      },
                    }}
                  >
                    <Nav>
                      {isAuthenticated ? (
                        <IconButton onClick={handleSignOut}>
                          <LogOut size={18} /> {t("signout")}
                        </IconButton>
                      ) : (
                        <>
                          <IconButton onClick={() => handleOpenModal("signIn")}>
                            <LogIn size={18} /> {t("signin")}
                          </IconButton>
                          <CTABUTTON onClick={() => handleOpenModal("signUp")}>
                            <UserPlus size={18} /> {t("signup")}
                          </CTABUTTON>
                        </>
                      )}
                    </Nav>
                  </MobileMenuContent>
                </NavContent>
              )}
            </AnimatePresence>
          </Navbar>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.3, duration: 0.5 }}
        >
          <LandingMain>
            <HeroContainer>
              <Hero>
                <HeroTitle>Welcome to DeedFinders</HeroTitle>
                <HeroSubtitle>
                  Your one-stop platform for property purchase and services.
                </HeroSubtitle>
              </Hero>
              <MinimizedBarContainer>
                {isWindowMinimized && (
                  <MinimizedBar onClick={handleRestoreWindow}>
                    <SwarmLogo size="24px" enableHover={false} />
                    <MinimizedBarContent>
                      <MinimizedBarTitle>
                        Uncover Hidden Opportunities
                      </MinimizedBarTitle>
                      <MinimizedBarSubtitle>
                        Try our Dashboard
                      </MinimizedBarSubtitle>
                    </MinimizedBarContent>
                  </MinimizedBar>
                )}
              </MinimizedBarContainer>
            </HeroContainer>

            {/* Features Section */}
            <Features>
              <FeaturesTitle>{t("ourFeatures")}</FeaturesTitle>
              <FeatureGrid>
                <Feature>
                  <FeatureIcon>
                    <Search size={48} />
                  </FeatureIcon>
                  <FeatureDescription>{t("findProperties")}</FeatureDescription>
                </Feature>
                <Feature>
                  <FeatureIcon>
                    <Users size={48} />
                  </FeatureIcon>
                  <FeatureDescription>{t("connect")}</FeatureDescription>
                </Feature>
                <Feature>
                  <FeatureIcon>
                    <DollarSign size={48} />
                  </FeatureIcon>
                  <FeatureDescription>{t("invest")}</FeatureDescription>
                </Feature>
                <Feature>
                  <FeatureIcon>
                    <Briefcase size={48} />
                  </FeatureIcon>
                  <FeatureDescription>{t("accessAuctions")}</FeatureDescription>
                </Feature>
              </FeatureGrid>
            </Features>
          </LandingMain>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.4, duration: 0.5 }}
        >
          <LandingFooter>
            <p>&copy; {new Date().getFullYear()} DeedFinders</p>

            <FooterLinks>
              <Link to="/pricing">Pricing</Link>
              <a href="#" onClick={handlePrivacyPolicyClick}>
                Privacy Policy
              </a>
              <a href="#" onClick={handleTermsOfServiceClick}>
                Terms of Service
              </a>
            </FooterLinks>

            <LanguageButtons>
              <LanguageButton
                onClick={() => changeLanguage("en")}
                className={i18n.language === "en" ? "active" : ""}
              >
                <Globe size={12} />
                EN
              </LanguageButton>
              <LanguageButton
                onClick={() => changeLanguage("ptbr")}
                className={i18n.language === "ptbr" ? "active" : ""}
              >
                <Globe size={12} />
                PT
              </LanguageButton>
            </LanguageButtons>
          </LandingFooter>
        </motion.div>

        <WindowWrapper
          $isMinimized={isWindowMinimized}
          $isVisible={isWindowVisible && showFakeWindowsScreen}
        >
          {showFakeWindowsScreen && (
            <FakeWindowsScreen
              onSignUp={handleFakeSignUp}
              onSignIn={handleFakeSignIn}
              onSeePlans={handleFakeSeePlans}
              onClose={handleCloseWindow}
              onMinimize={handleMinimizeWindow}
              isMinimized={isWindowMinimized}
            />
          )}
        </WindowWrapper>

        {/* Modals */}
        <SignInModal
          open={activeModal === "signIn"}
          onClose={handleCloseModal}
          onSuccess={handleSignInSuccess}
          onSwitchToSignUp={() => handleOpenModal("signUp")}
          onOpenPrivacyPolicy={() => handleOpenModal("privacyPolicy")}
          onOpenTermsOfService={() => handleOpenModal("termsOfService")}
        />
        <SignUpModal
          open={activeModal === "signUp"}
          onClose={handleCloseModal}
          onSwitchToSignIn={() => handleOpenModal("signIn")}
          onOpenPrivacyPolicy={() => handleOpenModal("privacyPolicy")}
          onOpenTermsOfService={() => handleOpenModal("termsOfService")}
        />
        <PolicyModal
          open={
            activeModal === "privacyPolicy" || activeModal === "termsOfService"
          }
          onClose={handleCloseModal}
          title={
            activeModal === "privacyPolicy"
              ? "Privacy Policy"
              : "Terms of Service"
          }
          content={
            activeModal === "privacyPolicy"
              ? privacyPolicyContent
              : termsOfServiceContent
          }
        />
      </LandingPageContainer>
      <GlobalStyles />
    </>
  );
};

export default LandingPage;

const GlobalStyles = createGlobalStyle`
  @media (max-width: 768px) {
    .desktop-only {
      display: none !important;
    }
  }

  @media (min-width: 769px) {
    .mobile-only {
      display: none !important;
    }
  }
`;
