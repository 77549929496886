// NotesTab.jsx

import React, { useState, useContext, useEffect, useRef, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { NotesContext } from "../../../context/NotesContext";
import { TabDetails, ScrollableContent } from "./TabStyles";
import styled from "styled-components";
import { createPortal } from "react-dom";
import Tippy from "@tippyjs/react";
import { Lock, UserPlus, LogIn, Send, AlertCircle, Trash2 } from "react-feather";
import ConfirmationModal from "../../common/ConfirmationModal";
import Toast from "../../common/Toast";
import SignInModal from "../../modals/SignInModal";
import SignUpModal from "../../modals/SignUpModal";
import PolicyModal from "../../modals/PolicyModal";
import {
  privacyPolicyContent,
  termsOfServiceContent,
} from "../../../utils/policyContent";
import { AnimatePresence, motion } from "framer-motion";

// Styled Components
const NotesContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  position: relative;
  padding: 20px;
  padding-bottom: 100px;
  overflow: hidden;
`;

const NotesList = styled.div`
  flex: 1;
  overflow-y: auto;
  padding-right: 10px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  gap:12px;

  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const NotesCount = styled.div`
  margin-bottom: 15px;
  color: #888;
  font-size: 0.9rem;
`;

const InputSection = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px;
  background: linear-gradient(180deg, rgba(21, 21, 21, 0) 0%, #151515 100%);
  display: flex;
  align-items: center;
  gap: 12px;
  backdrop-filter: blur(10px);
  border-top: 1px solid rgba(136, 83, 251, 0.1);
  z-index: 10;
`;

const NotesTextarea = styled.textarea`
  flex: 1;
  height: 42px;
  padding: 11px 16px;
  background: linear-gradient(
    165deg,
    rgba(38, 38, 45, 0.5),
    rgba(28, 28, 35, 0.5)
  );
  border: 1px solid rgba(255, 255, 255, 0.03);
  border-radius: 12px;
  color: #fff;
  font-size: 14px;
  resize: none;
  backdrop-filter: blur(20px);
  transition: all 0.4s cubic-bezier(0.16, 1, 0.3, 1);

  &:focus {
    outline: none;
    border-color: rgba(136, 83, 251, 0.4);
    background: linear-gradient(
      165deg,
      rgba(43, 43, 50, 0.6),
      rgba(33, 33, 40, 0.6)
    );
    box-shadow: 
      0 4px 20px rgba(0, 0, 0, 0.2),
      0 0 20px rgba(136, 83, 251, 0.1),
      0 0 0 1px rgba(136, 83, 251, 0.2);
  }

  &::placeholder {
    color: rgba(255, 255, 255, 0.4);
  }
`;

const SaveButton = styled(motion.button)`
  height: 42px;
  min-width: 42px;
  background: linear-gradient(
    165deg,
    rgba(136, 83, 251, 0.15),
    rgba(96, 57, 176, 0.15)
  );
  border: 1px solid rgba(136, 83, 251, 0.3);
  border-radius: 12px;
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  backdrop-filter: blur(20px);
  transform: translateZ(0);
  will-change: transform;
  transition: all 0.4s cubic-bezier(0.16, 1, 0.3, 1);

  &:hover {
    transform: translateY(-2px) scale(1.02);
    background: linear-gradient(
      165deg,
      rgba(136, 83, 251, 0.2),
      rgba(96, 57, 176, 0.2)
    );
    border-color: rgba(136, 83, 251, 0.4);
    box-shadow: 
      0 8px 32px rgba(136, 83, 251, 0.2),
      0 0 0 1px rgba(136, 83, 251, 0.2);
  }

  &:active {
    transform: translateY(1px) scale(0.98);
  }

  svg {
    width: 20px;
    height: 20px;
    filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.2));
  }
`;

const NoteItem = styled(motion.div)`
  background: linear-gradient(
    165deg,
    rgba(38, 38, 45, 0.5),
    rgba(28, 28, 35, 0.5)
  );
  border: 1px solid rgba(255, 255, 255, 0.03);
  border-radius: 12px;
  padding: 16px;
  color: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(20px);
  transition: all 0.4s cubic-bezier(0.16, 1, 0.3, 1);
  position: relative;
  overflow: visible;
  z-index: 1;

  &:hover {
    background: linear-gradient(
      165deg,
      rgba(43, 43, 50, 0.6),
      rgba(33, 33, 40, 0.6)
    );
    border-color: rgba(136, 83, 251, 0.2);
    transform: translateY(-2px) scale(1.01);
    box-shadow: 
      0 20px 40px rgba(0, 0, 0, 0.2),
      0 0 20px rgba(136, 83, 251, 0.1),
      0 0 0 1px rgba(136, 83, 251, 0.2);
  }

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    background: linear-gradient(
      165deg,
      rgba(255, 255, 255, 0.03),
      transparent
    );
    border-radius: inherit;
  }

  p {
    color: #ffffff;
    margin: 0;
    font-size: 14px;
    line-height: 1.6;
    font-weight: 400;
    letter-spacing: 0.2px;
  }

  small {
    color: rgba(255, 255, 255, 0.4);
    font-size: 12px;
    display: block;
    margin-top: 12px;
    font-weight: 500;
  }

  &.new-note {
    animation: slideIn 0.5s cubic-bezier(0.2, 0.8, 0.2, 1);
  }

  @keyframes slideIn {
    0% {
      opacity: 0;
      transform: translateY(30px) scale(0.95);
    }
    100% {
      opacity: 1;
      transform: translateY(0) scale(1);
    }
  }

  position: relative;

  & > * {
    position: relative;
    z-index: 2;
  }
`;

const NoteItemHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 16px;
`;

const DeleteButtonWrapper = styled.div`
  position: relative;
  z-index: 3;
`;

const DeleteButton = styled(motion.button)`
  background: transparent;
  border: none;
  padding: 8px;
  cursor: pointer;
  color: rgba(255, 255, 255, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  transition: all 0.3s cubic-bezier(0.16, 1, 0.3, 1);
  opacity: 0;
  transform: translateX(10px);
  
  ${NoteItem}:hover & {
    opacity: 1;
    transform: translateX(0);
  }

  &:hover {
    background: rgba(255, 77, 77, 0.1);
    color: #ff4d4d;
    transform: scale(1.1);
  }

  svg {
    width: 16px;
    height: 16px;
    filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.2));
  }
`;

const PublicMessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 32px;
  text-align: center;
  background: linear-gradient(
    165deg,
    rgba(28, 28, 35, 0.85),
    rgba(18, 18, 24, 0.95)
  );
  backdrop-filter: blur(40px);
  border-radius: 24px;
  border: 1px solid rgba(255, 255, 255, 0.07);
  box-shadow: 
    0 0 0 1px rgba(255, 255, 255, 0.05),
    0 20px 50px -12px rgba(0, 0, 0, 0.5),
    0 0 100px rgba(136, 83, 251, 0.1);
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    background: radial-gradient(
      circle at top right,
      rgba(136, 83, 251, 0.08),
      transparent 70%
    );
    border-radius: inherit;
    pointer-events: none;
  }
`;

const MessageTitle = styled.h3`
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 16px;
  color: #fff;
  text-shadow: 0 2px 10px rgba(136, 83, 251, 0.3);
`;

const MessageText = styled.p`
  font-size: 15px;
  line-height: 1.6;
  color: rgba(255, 255, 255, 0.7);
  max-width: 480px;
  margin: 0 auto 32px;
`;

const ActionButtons = styled.div`
  display: flex;
  gap: 16px;
  margin-top: 8px;

  @media (max-width: 480px) {
    flex-direction: column;
    width: 100%;
    max-width: 280px;
  }
`;

const ActionButton = styled(motion.button)`
  background: linear-gradient(
    165deg,
    rgba(136, 83, 251, 0.15),
    rgba(96, 57, 176, 0.15)
  );
  border: 1px solid rgba(136, 83, 251, 0.3);
  border-radius: 28px;
  padding: 14px 28px;
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
  min-width: 180px;
  justify-content: center;
  position: relative;
  overflow: hidden;
  font-weight: 500;
  font-size: 15px;
  letter-spacing: 0.3px;
  backdrop-filter: blur(20px);
  transform: translateZ(0);
  will-change: transform;
  transition: all 0.4s cubic-bezier(0.16, 1, 0.3, 1);

  ${({ $primary }) => $primary && `
    background: linear-gradient(
      165deg,
      rgba(136, 83, 251, 0.2),
      rgba(96, 57, 176, 0.2)
    );
    border-color: rgba(136, 83, 251, 0.4);
  `}

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    background: linear-gradient(
      165deg,
      rgba(255, 255, 255, 0.1),
      transparent
    );
    opacity: 0;
    transition: opacity 0.4s ease;
  }

  &:hover {
    transform: translateY(-2px) scale(1.02);
    background: linear-gradient(
      165deg,
      rgba(136, 83, 251, 0.2),
      rgba(96, 57, 176, 0.2)
    );
    border-color: rgba(136, 83, 251, 0.4);
    box-shadow: 
      0 8px 32px rgba(136, 83, 251, 0.2),
      0 0 0 1px rgba(136, 83, 251, 0.2);

    &::before {
      opacity: 1;
    }
  }

  &:active {
    transform: translateY(1px) scale(0.98);
  }

  svg {
    filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.2));
  }
`;

const IconContainer = styled.div`
  margin-bottom: 32px;
  width: 64px;
  height: 64px;
  background: linear-gradient(
    165deg,
    rgba(38, 38, 45, 0.5),
    rgba(28, 28, 35, 0.5)
  );
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(255, 255, 255, 0.03);
  box-shadow: 
    0 1px 2px rgba(0, 0, 0, 0.1),
    0 1px 1px rgba(255, 255, 255, 0.03);
  backdrop-filter: blur(20px);

  svg {
    width: 32px;
    height: 32px;
    color: #8853fb;
    filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.2));
  }

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    background: linear-gradient(
      165deg,
      rgba(255, 255, 255, 0.03),
      transparent
    );
    border-radius: inherit;
  }
`;

// Create a portal container for modals
const ModalPortal = ({ children }) => {
  const modalRoot = document.getElementById("modal-root");
  if (!modalRoot) {
    console.error("Modal root element not found");
    return null;
  }
  return createPortal(children, modalRoot);
};

// Add this function at the top level of the component
const scrollToTop = (element, duration = 600) => {
  const start = element.scrollTop;
  const change = -start;
  let currentTime = 0;
  const increment = 16; // For 60fps

  // Custom easing function - similar to iOS/Apple animations
  const easeOutQuart = (t) => 1 - (--t) * t * t * t;

  const animateScroll = () => {
    currentTime += increment;
    const val = easeOutQuart(currentTime / duration);
    
    element.scrollTop = start + change * val;

    if (currentTime < duration) {
      requestAnimationFrame(animateScroll);
    }
  };

  animateScroll();
};

// Update the HighlightGlow styled component
const HighlightGlow = styled(motion.div)`
  position: absolute;
  inset: -1px;
  border-radius: 12px;
  pointer-events: none;
  background: linear-gradient(
    165deg,
    rgba(136, 83, 251, 0.2),
    rgba(96, 57, 176, 0.2)
  );
  opacity: 0;
  z-index: 1;
`;

const NotesTab = ({ expandedData, isPublic = false }) => {
  const { t } = useTranslation();
  const notesContext = useContext(NotesContext);
  const notesListRef = useRef(null);
  
  // State declarations
  const [activeModal, setActiveModal] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [newNote, setNewNote] = useState("");
  const [noteToDelete, setNoteToDelete] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [toast, setToast] = useState(null);
  const [newestNoteId, setNewestNoteId] = useState(null);

  // Effect to fetch notes when component mounts
  useEffect(() => {
    if (!isPublic && notesContext?.fetchNotes && expandedData?.uid) {
      notesContext.fetchNotes(expandedData.uid);
    }
  }, [isPublic, expandedData?.uid]);

  // Move useMemo before any conditional returns
  const sortedNotes = useMemo(() => {
    // Guard against undefined or null propertyNotes
    if (!notesContext?.propertyNotes || !Array.isArray(notesContext.propertyNotes)) {
      return [];
    }

    // Safely sort notes with proper type checking
    return [...notesContext.propertyNotes].sort((a, b) => {
      // Guard against missing createdAt values
      const dateA = a?.createdAt ? new Date(a.createdAt) : new Date(0);
      const dateB = b?.createdAt ? new Date(b.createdAt) : new Date(0);
      
      return dateB - dateA;
    });
  }, [notesContext?.propertyNotes]);

  // Modal handlers
  const handleOpenModal = (modalType) => {
    setActiveModal(modalType);
    setIsModalVisible(true);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
    setTimeout(() => {
      setActiveModal(null);
    }, 300);
  };

  // Public view
  if (isPublic) {
    return (
      <TabDetails>
        <PublicMessageContainer>
          <IconContainer>
            <Lock />
          </IconContainer>
          <MessageTitle>{t("Unlock Your Property Notes")}</MessageTitle>
          <MessageText>
            {t(
              "Create your personal workspace to track important details, thoughts, and observations about this property. Sign in or create an account to get started."
            )}
          </MessageText>
          <ActionButtons>
            <ActionButton $primary onClick={() => handleOpenModal("signUp")}>
              <UserPlus size={18} />
              {t("Create Account")}
            </ActionButton>
            <ActionButton onClick={() => handleOpenModal("signIn")}>
              <LogIn size={18} />
              {t("Sign In")}
            </ActionButton>
          </ActionButtons>
        </PublicMessageContainer>

        <ModalPortal>
          <AnimatePresence mode="wait">
            {isModalVisible && (
              <>
                <SignInModal
                  open={activeModal === "signIn"}
                  onClose={handleCloseModal}
                  onSwitchToSignUp={() => setActiveModal("signUp")}
                  onOpenPrivacyPolicy={() => setActiveModal("privacyPolicy")}
                  onOpenTermsOfService={() => setActiveModal("termsOfService")}
                />
                <SignUpModal
                  open={activeModal === "signUp"}
                  onClose={handleCloseModal}
                  onSwitchToSignIn={() => setActiveModal("signIn")}
                  onOpenPrivacyPolicy={() => setActiveModal("privacyPolicy")}
                  onOpenTermsOfService={() => setActiveModal("termsOfService")}
                />
                <PolicyModal
                  open={activeModal === "privacyPolicy" || activeModal === "termsOfService"}
                  onClose={handleCloseModal}
                  title={activeModal === "privacyPolicy" ? "Privacy Policy" : "Terms of Service"}
                  content={activeModal === "privacyPolicy" ? privacyPolicyContent : termsOfServiceContent}
                />
              </>
            )}
          </AnimatePresence>
        </ModalPortal>
      </TabDetails>
    );
  }

  // Guard clause for missing context
  if (!notesContext) {
    return (
      <TabDetails>
        <PublicMessageContainer>
          <MessageText>
            {t("Error: Notes functionality is currently unavailable.")}
          </MessageText>
        </PublicMessageContainer>
      </TabDetails>
    );
  }

  // Event handlers
  const handleNoteChange = (event) => setNewNote(event.target.value);

  const handleSaveNote = async () => {
    if (!newNote?.trim() || !expandedData?.uid) return;

    const note = {
      propertyId: expandedData.uid,
      text: newNote,
      propertyDetails: {
        parcelNumber: expandedData?.account_nbr || '',
        address: expandedData?.address_full || '',
      },
    };

    try {
      const savedNote = await notesContext?.saveNote(note);
      if (!savedNote) throw new Error('Failed to save note');
      
      setNewNote("");
      
      if (notesContext?.fetchNotes) {
        await notesContext.fetchNotes(expandedData.uid);
        
        // Set the newest note ID only if we have a valid ID
        if (savedNote._id) {
          setNewestNoteId(savedNote._id);
          
          // Clear the newest note ID after animation
          setTimeout(() => {
            setNewestNoteId(null);
          }, 2000);
        }

        // Scroll to top only if we have a valid ref
        if (notesListRef.current) {
          scrollToTop(notesListRef.current);
        }
      }

      setToast({
        message: t("noteSavedSuccessfully"),
        type: "success",
        duration: 3000,
      });
    } catch (error) {
      console.error("Failed to save note:", error);
      setToast({
        message: t("failedToSaveNote"),
        type: "error",
        duration: 3000,
      });
    }
  };

  const handleKeyDown = (event) => {
    if ((event.metaKey || event.ctrlKey) && event.key === "Enter") {
      event.preventDefault();
      handleSaveNote();
    }
  };

  const handleDeleteClick = (note) => {
    setNoteToDelete(note);
    setIsDeleteModalOpen(true);
  };

  const handleConfirmDelete = async () => {
    if (!noteToDelete?._id) return;

    try {
      await notesContext.deleteNote(noteToDelete._id);
      setIsDeleteModalOpen(false);
      setNoteToDelete(null);
      
      setToast({
        message: t("noteDeletedSuccessfully"),
        type: "success",
        duration: 3000,
      });
    } catch (error) {
      console.error("Failed to delete note:", error);
      setToast({
        message: t("failedToDeleteNote"),
        type: "error",
        duration: 3000,
      });
    }
  };

  // Private view render
  return (
    <TabDetails>
      <NotesContainer>
        <NotesCount>
          {sortedNotes.length} {sortedNotes.length === 1 ? t("note") : t("notes")}
        </NotesCount>
        <NotesList ref={notesListRef}>
          <AnimatePresence mode="popLayout">
            {sortedNotes.map((note, index) => (
              <NoteItem
                key={note._id}
                initial={{ opacity: 0, y: 30, scale: 0.95 }}
                animate={{ 
                  opacity: 1, 
                  y: 0, 
                  scale: 1,
                  transition: {
                    type: "spring",
                    stiffness: 200,
                    damping: 20,
                    mass: 0.8,
                    delay: index * 0.08,
                  }
                }}
                exit={{ 
                  opacity: 0, 
                  y: -20,
                  scale: 0.95,
                  transition: {
                    duration: 0.3,
                    ease: [0.32, 0.72, 0, 1]
                  }
                }}
                layout
              >
                <HighlightGlow
                  initial={{ opacity: 0, scale: 0.95 }}
                  animate={{ 
                    opacity: newestNoteId === note._id ? [0, 0.4, 0] : 0,
                    scale: newestNoteId === note._id ? [0.98, 1.01, 1] : 1,
                  }}
                  transition={{
                    duration: 1.2,
                    times: [0, 0.3, 1],
                    ease: "easeOut",
                  }}
                />
                <NoteItemHeader>
                  <p>{note.text}</p>
                  <DeleteButtonWrapper>
                    <Tippy
                      content={t("deleteNote")}
                      animation="shift-away"
                      placement="left"
                      arrow={true}
                      duration={[300, 250]}
                      delay={[400, 0]}
                      theme="modern"
                      zIndex={9999}
                    >
                      <DeleteButton
                        onClick={() => handleDeleteClick(note)}
                        aria-label={t("deleteNote")}
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.95 }}
                      >
                        <Trash2 />
                      </DeleteButton>
                    </Tippy>
                  </DeleteButtonWrapper>
                </NoteItemHeader>
                <small>
                  {new Date(note.createdAt).toLocaleString(undefined, {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                    hour: "2-digit",
                    minute: "2-digit",
                    second: "2-digit",
                  })}
                </small>
              </NoteItem>
            ))}
          </AnimatePresence>
        </NotesList>

        <InputSection>
          <NotesTextarea
            value={newNote}
            onChange={handleNoteChange}
            onKeyDown={handleKeyDown}
            placeholder={t("writeYourNotesHere")}
          />
          <Tippy
            content={t("pressCtrlEnterToSave")}
            animation="shift-away"
            placement="top"
            arrow={true}
            duration={[300, 250]}
            delay={[400, 0]}
            theme="modern"
          >
            <SaveButton onClick={handleSaveNote}>
              <Send size={18} />
            </SaveButton>
          </Tippy>
        </InputSection>
      </NotesContainer>

      <ConfirmationModal
        isOpen={isDeleteModalOpen}
        message={t("areYouSureDeleteNote")}
        onConfirm={handleConfirmDelete}
        onClose={() => {
          setIsDeleteModalOpen(false);
          setNoteToDelete(null);
        }}
      />

      {toast && (
        <Toast
          message={toast.message}
          type={toast.type}
          duration={toast.duration}
          onClose={() => setToast(null)}
        />
      )}
    </TabDetails>
  );
};

export default NotesTab;
