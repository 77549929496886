import React, { useState, useEffect, useContext, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { motion, AnimatePresence } from "framer-motion";
import { Eye, EyeOff, Check, X as CloseIcon, AlertTriangle } from "react-feather";
import styled from "styled-components";
import { AuthContext } from "../../context/AuthContext";
import { useToast } from "../../context/ToastContext";
import debounce from 'lodash/debounce';

const SignupModalOverlay = styled(motion.div)`
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(8px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
  padding: 20px;
`;

const SignupModalContent = styled(motion.div)`
  background: linear-gradient(
    165deg,
    rgba(28, 28, 35, 0.85),
    rgba(18, 18, 24, 0.95)
  );
  backdrop-filter: blur(40px);
  border-radius: 24px;
  padding: 24px;
  width: 90%;
  max-width: 440px;
  border: 1px solid rgba(255, 255, 255, 0.07);
  box-shadow: 
    0 0 0 1px rgba(255, 255, 255, 0.05),
    0 20px 50px -12px rgba(0, 0, 0, 0.5),
    0 0 100px rgba(136, 83, 251, 0.1);
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    background: radial-gradient(
      circle at top right,
      rgba(136, 83, 251, 0.08),
      transparent 70%
    );
    border-radius: inherit;
    pointer-events: none;
  }

  @media (max-width: 480px) {
    padding: 20px;
    width: 95%;
    margin: 10px;
  }
`;

const SignupModalTitle = styled.h2`
  text-align: center;
  margin-bottom: 1.5rem;
  font-size: 1.8rem;
  background: linear-gradient(45deg, #8853fb, #3498db);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);

  @media (max-width: 480px) {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
`;

const SignupErrorMessage = styled.p`
  color: #ef5350;
  text-align: center;
  margin-bottom: 1rem;
  font-size: 0.9rem;
`;

const SignupFormGroup = styled.div`
  margin-bottom: 1.5rem;
  width: 100%;
  position: relative;

  @media (max-width: 480px) {
    margin-bottom: 1rem;
  }
`;

const SignupInput = styled.input`
  width: 100%;
  height: 48px;
  background: linear-gradient(
    165deg,
    rgba(38, 38, 45, 0.5),
    rgba(28, 28, 35, 0.5)
  );
  border: 1px solid rgba(136, 83, 251, 0.2);
  border-radius: 12px;
  color: #fff;
  font-size: 14px;
  padding: 0 16px;
  transition: all 0.3s cubic-bezier(0.16, 1, 0.3, 1);
  backdrop-filter: blur(10px);
  box-sizing: border-box;
  max-width: 100%;

  &:focus {
    outline: none;
    border-color: rgba(136, 83, 251, 0.4);
    background: linear-gradient(
      165deg,
      rgba(43, 43, 50, 0.6),
      rgba(33, 33, 40, 0.6)
    );
    box-shadow: 
      0 4px 20px rgba(0, 0, 0, 0.2),
      0 0 20px rgba(136, 83, 251, 0.1),
      0 0 0 1px rgba(136, 83, 251, 0.2);
  }

  &::placeholder {
    color: rgba(255, 255, 255, 0.4);
  }

  @media (max-width: 480px) {
    height: 44px;
    font-size: 13px;
  }
`;

const SignupCheckboxGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  margin: 16px 0;

  input[type="checkbox"] {
    appearance: none;
    width: 20px;
    height: 20px;
    border: 1px solid rgba(136, 83, 251, 0.3);
    border-radius: 6px;
    background: linear-gradient(
      165deg,
      rgba(38, 38, 45, 0.5),
      rgba(28, 28, 35, 0.5)
    );
    cursor: pointer;
    position: relative;
    transition: all 0.3s cubic-bezier(0.16, 1, 0.3, 1);

    &:checked {
      background: linear-gradient(165deg, #8853fb, #6039b0);
      border-color: rgba(136, 83, 251, 0.4);
    }

    &:checked::after {
      content: "✓";
      position: absolute;
      color: white;
      font-size: 14px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &:hover {
      border-color: rgba(136, 83, 251, 0.5);
      background: linear-gradient(
        165deg,
        rgba(43, 43, 50, 0.6),
        rgba(33, 33, 40, 0.6)
      );
    }
  }

  label {
    color: rgba(255, 255, 255, 0.7);
    font-size: 14px;
    user-select: none;
    cursor: pointer;
  }
`;

const SignupSubmitButton = styled(motion.button)`
  width: 100%;
  height: 48px;
  background: linear-gradient(
    165deg,
    rgba(136, 83, 251, 0.15),
    rgba(96, 57, 176, 0.15)
  );
  border: 1px solid rgba(136, 83, 251, 0.3);
  border-radius: 12px;
  color: white;
  cursor: pointer;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0.3px;
  backdrop-filter: blur(20px);
  transition: all 0.4s cubic-bezier(0.16, 1, 0.3, 1);
  opacity: ${props => props.disabled ? 0.5 : 1};
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};

  ${props => !props.disabled && `
    &:hover {
      transform: translateY(-2px) scale(1.02);
      background: linear-gradient(
        165deg,
        rgba(136, 83, 251, 0.2),
        rgba(96, 57, 176, 0.2)
      );
      border-color: rgba(136, 83, 251, 0.4);
      box-shadow: 
        0 8px 32px rgba(136, 83, 251, 0.2),
        0 0 0 1px rgba(136, 83, 251, 0.2);
    }

    &:active {
      transform: translateY(1px) scale(0.98);
    }
  `}
`;

const SignupModalFooter = styled.div`
  margin-top: 24px;
  text-align: center;
  
  p {
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
    line-height: 1.6;
    margin: 8px 0;
  }

  a {
    color: #8853fb;
    text-decoration: none;
    transition: all 0.2s ease;
    
    &:hover {
      color: #6039b0;
      text-decoration: underline;
    }
  }
`;

const SignupPasswordInput = styled.div`
  position: relative;
  width: 100%;
  box-sizing: border-box;

  input {
    width: 100%;
    height: 48px;
    background: linear-gradient(
      165deg,
      rgba(38, 38, 45, 0.5),
      rgba(28, 28, 35, 0.5)
    );
    border: 1px solid rgba(136, 83, 251, 0.2);
    border-radius: 12px;
    color: #fff;
    font-size: 14px;
    padding: 0 48px 0 16px;
    transition: all 0.3s cubic-bezier(0.16, 1, 0.3, 1);
    backdrop-filter: blur(10px);
    box-sizing: border-box;
    max-width: 100%;

    &:focus {
      outline: none;
      border-color: rgba(136, 83, 251, 0.4);
      background: linear-gradient(
        165deg,
        rgba(43, 43, 50, 0.6),
        rgba(33, 33, 40, 0.6)
      );
      box-shadow: 
        0 4px 20px rgba(0, 0, 0, 0.2),
        0 0 20px rgba(136, 83, 251, 0.1),
        0 0 0 1px rgba(136, 83, 251, 0.2);
    }

    &::placeholder {
      color: rgba(255, 255, 255, 0.4);
    }
  }
`;

const SignupTogglePassword = styled.button`
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  color: rgba(136, 83, 251, 0.6);
  cursor: pointer;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
  border-radius: 8px;

  &:hover {
    color: rgba(136, 83, 251, 0.9);
    background: rgba(136, 83, 251, 0.1);
  }
`;

const SignupPasswordRequirements = styled.div`
  margin-top: 12px;
  padding: 16px;
  background: linear-gradient(
    165deg,
    rgba(38, 38, 45, 0.3),
    rgba(28, 28, 35, 0.3)
  );
  border: 1px solid rgba(136, 83, 251, 0.1);
  border-radius: 12px;
  backdrop-filter: blur(10px);

  p {
    color: rgba(255, 255, 255, 0.7);
    font-size: 13px;
    margin-bottom: 8px;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
`;

const SignupRequirement = styled.li`
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${({ $fieldsTouched, $met }) =>
    !$fieldsTouched
      ? 'rgba(255, 255, 255, 0.5)'
      : $met
      ? '#4caf50'
      : '#ff4d4d'};
  font-size: 13px;
  margin-bottom: 6px;
  transition: color 0.3s ease;
`;

const SignupRequirementIcon = styled.span`
  margin-right: 0.5rem;
  min-width: 14px;
  height: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SignupModalClose = styled.button`
  position: absolute;
  top: 16px;
  right: 16px;
  width: 32px;
  height: 32px;
  background: linear-gradient(
    165deg,
    rgba(38, 38, 45, 0.5),
    rgba(28, 28, 35, 0.5)
  );
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  color: rgba(255, 255, 255, 0.8);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s cubic-bezier(0.16, 1, 0.3, 1);

  &:hover {
    background: linear-gradient(
      165deg,
      rgba(43, 43, 50, 0.6),
      rgba(33, 33, 40, 0.6)
    );
    border-color: rgba(136, 83, 251, 0.2);
    color: white;
    transform: translateY(-1px);
    box-shadow: 
      0 4px 12px rgba(0, 0, 0, 0.2),
      0 0 0 1px rgba(136, 83, 251, 0.1);
  }

  &:active {
    transform: translateY(1px);
  }
`;

const ErrorMessage = styled(motion.div)`
  position: absolute;
  left: 0;
  bottom: -20px;
  font-size: 12px;
  color: rgba(255, 59, 48, 0.9);
  display: flex;
  align-items: center;
  gap: 6px;

  svg {
    width: 12px;
    height: 12px;
  }
`;

const SignupLink = styled.button`
  background: none;
  border: none;
  color: #8853fb;
  text-decoration: none;
  cursor: pointer;
  padding: 0;
  font: inherit;
  
  &:hover {
    color: #6039b0;
    text-decoration: underline;
  }
`;

const SignUpModal = ({
  open,
  onClose,
  onSwitchToSignIn,
  onOpenPrivacyPolicy,
  onOpenTermsOfService,
}) => {
  const { t } = useTranslation();
  const [error, setError] = useState(null);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [passwordRequirements, setPasswordRequirements] = useState({
    length: false,
    uppercaseLowercase: false,
    numberOrSpecial: false,
  });
  const [passwordsMatch, setPasswordsMatch] = useState(false);
  const [fieldsTouched, setFieldsTouched] = useState(false);
  const [showEmailError, setShowEmailError] = useState(false);
  const [isEmailTouched, setIsEmailTouched] = useState(false);

  const { register: registerUser } = useContext(AuthContext);
  const { showToast } = useToast();

  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const checkPasswordRequirements = (password) => {
    const hasUppercase = /[A-Z]/.test(password);
    const hasLowercase = /[a-z]/.test(password);
    const hasNumber = /[0-9]/.test(password);
    const hasSpecial = /[!@#$%^&*]/.test(password);

    setPasswordRequirements({
      length: password.length >= 8,
      uppercaseLowercase: hasUppercase && hasLowercase,
      numberOrSpecial: hasNumber || hasSpecial,
    });
  };

  const checkPasswordsMatch = (password, confirmPassword) => {
    setPasswordsMatch(password === confirmPassword);
  };

  useEffect(() => {
    if (password.length > 0 || confirmPassword.length > 0) {
      setFieldsTouched(true);
    } else {
      setFieldsTouched(false);
    }

    if (password.length > 0) {
      checkPasswordRequirements(password);
    } else {
      setPasswordRequirements({
        length: false,
        uppercaseLowercase: false,
        numberOrSpecial: false,
      });
    }

    checkPasswordsMatch(password, confirmPassword);
  }, [password, confirmPassword]);

  useEffect(() => {
    const isValid =
      name.trim() !== "" &&
      validateEmail(email) &&
      Object.values(passwordRequirements).every(Boolean) &&
      passwordsMatch &&
      termsAccepted;

    setIsFormValid(isValid);
  }, [name, email, passwordRequirements, passwordsMatch, termsAccepted]);

  const handleSignUp = async (event) => {
    event.preventDefault();
    setError(null);

    if (!isFormValid) {
      const errorMessage =
        "Please fill all fields correctly and accept the terms of service.";
      setError(errorMessage);
      showToast(errorMessage, "error");
      return;
    }

    const userData = {
      name,
      email: email.toLowerCase(),
      password,
    };

    try {
      const result = await registerUser(userData);

      if (result.success) {
        showToast(
          "Registration successful! Please check your email to verify your account.",
          "success"
        );
        onClose();
      } else {
        const errorMsg =
          result.error || "An unexpected error occurred during registration";
        setError(errorMsg);
        showToast(errorMsg, "error");
      }
    } catch (err) {
      const errorMsg =
        err.message || "An unexpected error occurred during registration";
      setError(errorMsg);
      showToast(errorMsg, "error");
    }
  };

  const handleSignInClick = (e) => {
    e.preventDefault();
    onSwitchToSignIn();
  };

  const handlePrivacyPolicyClick = (e) => {
    e.preventDefault();
    onOpenPrivacyPolicy();
  };

  const handleTermsOfServiceClick = (e) => {
    e.preventDefault();
    onOpenTermsOfService();
  };

  const renderIcon = (condition) => {
    if (!fieldsTouched) {
      return <SignupRequirementIcon>•</SignupRequirementIcon>;
    }
    return condition ? <Check size={14} /> : <CloseIcon size={14} />;
  };

  const renderRequirementItem = (key, value) => {
    return (
      <SignupRequirement key={key} $met={value} $fieldsTouched={fieldsTouched}>
        {renderIcon(value)}
        {t(`passwordRequirement.${key}`)}
      </SignupRequirement>
    );
  };

  const debouncedValidateEmail = useCallback(
    debounce((value) => {
      if (isEmailTouched && value && !validateEmail(value)) {
        setShowEmailError(true);
      } else {
        setShowEmailError(false);
      }
    }, 800),
    [isEmailTouched]
  );

  useEffect(() => {
    return () => {
      debouncedValidateEmail.cancel();
    };
  }, [debouncedValidateEmail]);

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);
    debouncedValidateEmail(value);
  };

  const handleEmailBlur = () => {
    setIsEmailTouched(true);
    if (email) {
      debouncedValidateEmail(email);
    }
  };

  if (!open) return null;

  return (
    <AnimatePresence>
      {open && (
        <SignupModalOverlay
          onClick={onClose}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <SignupModalContent
            onClick={(e) => e.stopPropagation()}
            initial={{ scale: 0.8, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.8, opacity: 0 }}
            transition={{ type: "spring", damping: 25, stiffness: 500 }}
          >
            <SignupModalClose onClick={onClose}>
              <CloseIcon size={24} />
            </SignupModalClose>
            <SignupModalTitle>{t("signup")}</SignupModalTitle>
            {error && <SignupErrorMessage>{error}</SignupErrorMessage>}
            <form onSubmit={handleSignUp}>
              <SignupFormGroup>
                <SignupInput
                  type="text"
                  id="name"
                  name="name"
                  placeholder={t("name")}
                  required
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </SignupFormGroup>
              <SignupFormGroup>
                <SignupInput
                  type="email"
                  id="email"
                  name="email"
                  placeholder={t("email")}
                  required
                  value={email}
                  onChange={handleEmailChange}
                  onBlur={handleEmailBlur}
                  $hasError={showEmailError}
                />
                <AnimatePresence>
                  {showEmailError && (
                    <ErrorMessage
                      initial={{ opacity: 0, y: -10 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: -10 }}
                      transition={{ duration: 0.2 }}
                    >
                      <AlertTriangle />
                      {t("pleaseEnterValidEmail")}
                    </ErrorMessage>
                  )}
                </AnimatePresence>
              </SignupFormGroup>
              {/* You can add a preferredLanguage selection here if needed */}
              <SignupFormGroup>
                <SignupPasswordInput>
                  <input
                    type={showPassword ? "text" : "password"}
                    id="password"
                    name="password"
                    placeholder={t("password")}
                    required
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                  />
                  <SignupTogglePassword
                    type="button"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? <EyeOff size={20} /> : <Eye size={20} />}
                  </SignupTogglePassword>
                </SignupPasswordInput>
              </SignupFormGroup>
              <SignupFormGroup>
                <SignupPasswordInput>
                  <input
                    type={showConfirmPassword ? "text" : "password"}
                    id="confirmPassword"
                    name="confirmPassword"
                    placeholder={t("confirmPassword")}
                    required
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                  <SignupTogglePassword
                    type="button"
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  >
                    {showConfirmPassword ? (
                      <EyeOff size={20} />
                    ) : (
                      <Eye size={20} />
                    )}
                  </SignupTogglePassword>
                </SignupPasswordInput>
              </SignupFormGroup>
              <SignupPasswordRequirements>
                <p>{t("passwordMust")}:</p>
                <ul>
                  {Object.entries(passwordRequirements).map(([key, value]) =>
                    renderRequirementItem(key, value)
                  )}
                  <SignupRequirement
                    $met={passwordsMatch}
                    $fieldsTouched={fieldsTouched}
                  >
                    {renderIcon(passwordsMatch)}
                    {t("passwordsMustMatch")}
                  </SignupRequirement>
                </ul>
              </SignupPasswordRequirements>
              <SignupCheckboxGroup>
                <input
                  type="checkbox"
                  id="terms"
                  checked={termsAccepted}
                  onChange={(e) => setTermsAccepted(e.target.checked)}
                />
                <label htmlFor="terms">{t("agreeToTerms")}</label>
              </SignupCheckboxGroup>
              <SignupCheckboxGroup>
                <input type="checkbox" id="updates" />
                <label htmlFor="updates">{t("receiveUpdates")}</label>
              </SignupCheckboxGroup>
              <SignupSubmitButton type="submit" disabled={!isFormValid}>
                {t("signup")}
              </SignupSubmitButton>
            </form>
            <SignupModalFooter>
              <p>
                {t("alreadyRegistered")}{" "}
                <SignupLink onClick={handleSignInClick}>
                  {t("signin")}
                </SignupLink>
              </p>
              <p>
                <a href="#" onClick={handlePrivacyPolicyClick}>
                  {t("privacyPolicy")}
                </a>{" "}
                {t("and")}{" "}
                <a href="#" onClick={handleTermsOfServiceClick}>
                  {t("termsOfService")}
                </a>{" "}
                {t("apply")}
              </p>
            </SignupModalFooter>
          </SignupModalContent>
        </SignupModalOverlay>
      )}
    </AnimatePresence>
  );
};

export default SignUpModal;
