import React, { useRef, useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import styled from 'styled-components';

export const SwarmContainer = styled(motion.div)`
  width: ${props => props.$size || "40px"};
  height: ${props => props.$size || "40px"};
  position: relative;
  perspective: 1000px;
  transform-style: preserve-3d;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 480px) {
    width: ${props => props.$size ? `calc(${props.$size} * 0.8)` : "32px"};
    height: ${props => props.$size ? `calc(${props.$size} * 0.8)` : "32px"};
  }
`;

const Canvas = styled.canvas`
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: transparent;
  left: 0;
  top: 0;

  @media (max-width: 480px) {
    width: 100%;
    height: 100%;
  }
`;

export const BubbleSkin = styled(motion.div)`
  position: absolute;
  inset: -1px;
  border-radius: 50%;
  background: radial-gradient(
    circle at 30% 30%,
    rgba(255, 255, 255, 0.12) 0%,
    rgba(255, 255, 255, 0.05) 20%,
    transparent 70%
  );
  backdrop-filter: blur(2px);
  border: 1px solid rgba(255, 255, 255, 0.12);
  box-shadow: 
    inset 0 0 20px rgba(136, 83, 251, 0.2),
    inset 0 0 5px rgba(255, 255, 255, 0.2);
  pointer-events: none;
  z-index: 2;

  &::before {
    content: '';
    position: absolute;
    inset: -1px;
    border-radius: inherit;
    background: linear-gradient(
      135deg,
      rgba(136, 83, 251, 0.15),
      transparent 60%,
      rgba(136, 83, 251, 0.1)
    );
    opacity: 0.5;
    animation: bubbleShimmer 4s linear infinite;
  }
`;

export const Highlight = styled.div`
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: radial-gradient(
    circle at center,
    rgba(255, 255, 255, 0.4),
    transparent 80%
  );
  top: 25%;
  left: 25%;
  filter: blur(1px);
  opacity: 0.3;
  animation: highlightFloat 4s ease-in-out infinite;

  @keyframes highlightFloat {
    0%, 100% {
      transform: translate(0, 0);
      opacity: 0.3;
    }
    50% {
      transform: translate(1px, 1px);
      opacity: 0.4;
    }
  }
`;

export const EnergyRing = styled(motion.div)`
  position: absolute;
  inset: -4px;
  border-radius: 50%;
  border: 1px solid rgba(136, 83, 251, 0.2);
  background: radial-gradient(
    circle at center,
    rgba(136, 83, 251, 0.1) 0%,
    transparent 70%
  );
  pointer-events: none;
  z-index: 1;
  box-shadow: 
    inset 0 0 15px rgba(136, 83, 251, 0.2),
    0 0 15px rgba(136, 83, 251, 0.2);
`;

class Particle {
  constructor(canvas, ctx, isActive) {
    this.canvas = canvas;
    this.ctx = ctx;
    
    this.radius = Math.min(canvas.width, canvas.height) * 0.8;
    
    const centerX = this.canvas.width / 2;
    const centerY = this.canvas.height / 2;
    
    this.phi = Math.random() * Math.PI * 2;
    this.theta = Math.random() * Math.PI * 2;
    this.phiSpeed = (Math.random() - 0.5) * 0.02;
    this.thetaSpeed = (Math.random() - 0.5) * 0.02;
    
    const radius = this.radius * (0.2 + Math.random() * 0.3);
    this.x = centerX + radius * Math.cos(this.phi);
    this.y = centerY + radius * Math.sin(this.phi);
    this.z = radius * Math.sin(this.theta);
    
    this.baseSpeed = 0.3 + Math.random() * 0.2;
    this.speedMultiplier = 1;
    this.acceleration = 0;
    this.maxSpeed = 2;
    
    this.baseSize = 0.5 + Math.random() * 0.4;
    this.glowIntensity = 0.7 + Math.random() * 0.3;
    this.baseHue = 210 + (Math.random() - 0.5) * 15;
    this.brightness = 75 + Math.random() * 10;
    
    this.trail = [];
    this.trailLength = isActive ? 10 + Math.floor(Math.random() * 4) : 8 + Math.floor(Math.random() * 3);
    this.trailOpacity = 0.4 + Math.random() * 0.2;
    
    this.orbitRadius = radius;
    this.orbitSpeed = (Math.random() - 0.5) * 0.015;
    this.verticalSpeed = (Math.random() - 0.5) * 0.01;
    this.phase = Math.random() * Math.PI * 2;
    
    this.energy = 1;
    this.targetEnergy = 1;
    this.energyEase = 0.1;
    this.wobblePhase = Math.random() * Math.PI * 2;
    this.wobbleSpeed = 0.015 + Math.random() * 0.015;
    
    this.baseRadius = this.radius;
    this.isActive = isActive;
    this.activeMultiplier = isActive ? 1.5 : 1;
    this.activeSpeed = isActive ? 1.5 : 1;
    this.activePulse = 0;
  }

  update(isHovered) {
    const centerX = this.canvas.width / 2;
    const centerY = this.canvas.height / 2;
    
    this.targetEnergy = isHovered ? 1.5 : (this.isActive ? 1.3 : 1);
    this.energy += (this.targetEnergy - this.energy) * this.energyEase;
    
    const time = Date.now() * 0.001;
    
    const baseSpeed = this.isActive ? this.activeSpeed : 1;
    this.phi += this.phiSpeed * baseSpeed;
    this.theta += this.thetaSpeed * baseSpeed;
    
    if (this.isActive) {
      this.activePulse = Math.sin(time * 2) * 0.2;
      this.radius = this.baseRadius * (1 + this.activePulse);
    }
    
    const wobble = Math.sin(time * this.wobbleSpeed + this.wobblePhase) * (this.isActive ? 4 : 3);
    const verticalOffset = Math.sin(time * 0.5 + this.phase) * (this.isActive ? 4 : 3);
    
    const dynamicRadius = this.orbitRadius * (1 + Math.sin(time + this.phase) * 0.15);
    
    const targetX = centerX + Math.cos(this.phi) * dynamicRadius + wobble;
    const targetY = centerY + Math.sin(this.phi) * dynamicRadius + verticalOffset;
    const targetZ = Math.sin(this.theta) * dynamicRadius * 0.6;
    
    this.x += (targetX - this.x) * 0.1;
    this.y += (targetY - this.y) * 0.1;
    this.z += (targetZ - this.z) * 0.1;
    
    const randomMovement = 0.3 * baseSpeed;
    this.x += (Math.random() - 0.5) * randomMovement;
    this.y += (Math.random() - 0.5) * randomMovement;
    
    this.trail.unshift({ 
      x: this.x, 
      y: this.y, 
      z: this.z,
      energy: this.energy 
    });
    
    if (this.trail.length > this.trailLength) {
      this.trail.pop();
    }
    
    const distanceFromCenter = Math.sqrt(
      Math.pow(this.x - centerX, 2) + 
      Math.pow(this.y - centerY, 2)
    );
    
    if (distanceFromCenter > this.radius * 0.4) {
      const angle = Math.atan2(this.y - centerY, this.x - centerX);
      const returnForce = (distanceFromCenter - this.radius * 0.4) * 0.04;
      
      this.x -= Math.cos(angle) * returnForce;
      this.y -= Math.sin(angle) * returnForce;
    }
    
    if (this.isActive) {
      const activeMovement = 0.4 * baseSpeed;
      this.x += Math.sin(time * 3 + this.phase) * activeMovement;
      this.y += Math.cos(time * 2 + this.phase) * activeMovement;
      this.z += Math.sin(time * 4 + this.phase) * activeMovement;
    }
  }

  draw() {
    if (this.trail.length > 1) {
      for (let i = 1; i < this.trail.length; i++) {
        const point = this.trail[i];
        const prevPoint = this.trail[i - 1];
        
        const alpha = (1 - i / this.trail.length) * 0.4 * this.trailOpacity;
        
        const gradient = this.ctx.createLinearGradient(
          prevPoint.x, prevPoint.y,
          point.x, point.y
        );
        
        gradient.addColorStop(0, `hsla(${this.baseHue}, 100%, ${this.brightness}%, ${alpha})`);
        gradient.addColorStop(1, `hsla(${this.baseHue}, 100%, ${this.brightness}%, 0)`);
        
        this.ctx.strokeStyle = gradient;
        this.ctx.lineWidth = this.baseSize * (1 - i / this.trail.length) * this.energy * 1.2;
        
        this.ctx.beginPath();
        this.ctx.moveTo(prevPoint.x, prevPoint.y);
        this.ctx.lineTo(point.x, point.y);
        this.ctx.stroke();
      }
    }
    
    const gradient = this.ctx.createRadialGradient(
      this.x, this.y, 0,
      this.x, this.y, this.baseSize * 10 * this.energy
    );
    
    gradient.addColorStop(0, `hsla(${this.baseHue}, 100%, ${this.brightness + 10}%, ${this.glowIntensity * 0.7})`);
    gradient.addColorStop(0.5, `hsla(${this.baseHue}, 100%, ${this.brightness}%, ${this.glowIntensity * 0.4})`);
    gradient.addColorStop(1, `hsla(${this.baseHue}, 100%, ${this.brightness - 10}%, 0)`);
    
    this.ctx.fillStyle = gradient;
    this.ctx.beginPath();
    this.ctx.arc(this.x, this.y, this.baseSize * 2.5 * this.energy, 0, Math.PI * 2);
    this.ctx.fill();
    
    if (this.isActive) {
      const activeGlow = this.ctx.createRadialGradient(
        this.x, this.y, 0,
        this.x, this.y, this.baseSize * 15 * this.energy
      );
      
      activeGlow.addColorStop(0, `hsla(${this.baseHue}, 100%, ${this.brightness + 20}%, ${this.glowIntensity * 0.4})`);
      activeGlow.addColorStop(1, 'transparent');
      
      this.ctx.fillStyle = activeGlow;
      this.ctx.beginPath();
      this.ctx.arc(this.x, this.y, this.baseSize * 3 * this.energy, 0, Math.PI * 2);
      this.ctx.fill();
    }
  }
}

const SwarmLogo = ({ size, variant, enableHover = true, active = false }) => {
  const canvasRef = useRef(null);
  const particlesRef = useRef([]);
  const animationFrameRef = useRef();
  const [isHovered, setIsHovered] = useState(false);

  const getPixelSize = () => {
    switch (variant) {
      case 'startButton':
        return 16;
      default:
        return parseInt(size || "40", 10);
    }
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const dpr = window.devicePixelRatio || 1;
    
    const updateCanvasSize = () => {
      const pixelSize = getPixelSize();
      canvas.width = pixelSize * dpr;
      canvas.height = pixelSize * dpr;
      ctx.scale(dpr, dpr);
    };
    
    updateCanvasSize();

    const isMobile = window.innerWidth <= 480;
    const particleCount = isMobile 
      ? (active ? 20 : 15) 
      : (active ? 40 : (variant === 'startButton' ? 20 : 30));
    
    particlesRef.current = Array(particleCount).fill().map(() => 
      new Particle(canvas, ctx, active)
    );

    const animate = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.globalCompositeOperation = 'screen';

      particlesRef.current.forEach(particle => {
        particle.update(isHovered);
        particle.draw();
      });

      animationFrameRef.current = requestAnimationFrame(animate);
    };

    animate();

    return () => {
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }
    };
  }, [isHovered, size, variant, active]);

  return (
    <SwarmContainer
      $size={variant === 'startButton' ? "16px" : size}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
      onHoverStart={() => enableHover && setIsHovered(true)}
      onHoverEnd={() => enableHover && setIsHovered(false)}
      whileHover={enableHover ? { scale: 1.05 } : undefined}
    >
      <Canvas ref={canvasRef} />
      <BubbleSkin 
        animate={{
          scale: isHovered || active ? 1.02 : 1,
          opacity: isHovered || active ? 0.9 : 0.6,
        }}
        transition={{
          type: "spring",
          stiffness: 400,
          damping: 20
        }}
      />
      <Highlight
        animate={{
          opacity: isHovered || active ? 0.5 : 0.3,
          scale: isHovered || active ? 1.1 : 1,
        }}
        transition={{
          duration: 0.3
        }}
      />
      <EnergyRing
        initial={false}
        animate={{
          scale: isHovered || active ? 1.1 : 0,
          opacity: isHovered || active ? 1 : 0,
        }}
        transition={{
          type: "spring",
          stiffness: 400,
          damping: 25
        }}
      />
    </SwarmContainer>
  );
};

export default SwarmLogo; 