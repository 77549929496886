import React, { useEffect, useState } from "react";
import "../../assets/styles/Toast.css";

const Toast = ({
  message,
  type = "info",
  duration = 3000,
  onClose,
  onUndo,
}) => {
  const [isVisible, setIsVisible] = useState(true);
  const [isHiding, setIsHiding] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsHiding(true);
      setTimeout(() => {
        setIsVisible(false);
        onClose();
      }, 300); // Duration of the slideOut animation
    }, duration);

    return () => clearTimeout(timer);
  }, [duration, onClose]);

  const getToastClass = () => {
    let classes = ["toast", getToastType()];
    if (isHiding) classes.push("hide");
    return classes.join(" ");
  };

  const getToastType = () => {
    switch (type) {
      case "success":
        return "toast-success";
      case "error":
        return "toast-error";
      case "warning":
        return "toast-warning";
      default:
        return "toast-info";
    }
  };

  const handleUndo = () => {
    if (onUndo) {
      onUndo();
      setIsHiding(true);
    }
  };

  if (!isVisible) return null;

  return (
    <div className={getToastClass()}>
      <div className="toast-content">
        <p>{message}</p>
        {onUndo && (
          <button className="toast-undo" onClick={handleUndo}>
            Undo
          </button>
        )}
      </div>
      <button className="toast-close" onClick={() => setIsHiding(true)}>
        &times;
      </button>
    </div>
  );
};

export default Toast;
