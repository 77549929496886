import React from "react";

const HireContent = () => {
  return (
    <div>
      <h2>Hire Section</h2>
      {/* Your Hire content here */}
    </div>
  );
};

export default HireContent;
