import React, { createContext, useContext } from "react";

const PropertyResourcesContext = createContext();
export const usePropertyResources = () => useContext(PropertyResourcesContext);

export const PropertyResourcesProvider = ({ children }) => {
  const value = {};

  return (
    <PropertyResourcesContext.Provider value={value}>
      {children}
    </PropertyResourcesContext.Provider>
  );
};
