import React, { useEffect, useState, useCallback, useMemo } from "react";
import { ChevronDown } from "react-feather";
import { AnimatePresence, motion } from "framer-motion";

const DataTable = ({
  headers,
  items,
  sortConfig,
  onSort,
  onRowClick,
  loading,
  isPublic = false
}) => {
  const ROW_HEIGHT = 35.5;
  const HEADER_HEIGHT = 56;
  const PAGINATION_HEIGHT = 80; // Height of pagination area
  const CONTAINER_HEIGHT = window.innerHeight - 220;

  // Calculate how many rows can fit based on available height
  const AVAILABLE_HEIGHT = CONTAINER_HEIGHT - HEADER_HEIGHT - PAGINATION_HEIGHT;
  const CALCULATED_MAX_ROWS = Math.floor(AVAILABLE_HEIGHT / ROW_HEIGHT);

  // Use the larger of calculated rows or minimum 18 rows
  const MAX_ROWS = Math.max(CALCULATED_MAX_ROWS, 18);

  const displayItems = items.slice(0, MAX_ROWS);
  const emptyRows = MAX_ROWS - displayItems.length;

  const [shouldAnimate, setShouldAnimate] = useState(false);

  useEffect(() => {
    setShouldAnimate(false);

    const timer = setTimeout(() => {
      setShouldAnimate(true);
    }, 50);

    return () => clearTimeout(timer);
  }, [items]);

  const getLinearDelay = (index) => {
    return index * 0.02;
  };

  const formatValue = (value, format) => {
    if (!value) return "-";

    switch (format) {
      case "USD":
        return new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        }).format(value);
      default:
        return value;
    }
  };

  const getColumnClassName = (key) => {
    if (key.includes("_nbr") || key === "zipCode") return "column-number";
    if (key.includes("bid") || key.includes("value")) return "column-money";
    return "";
  };

  const renderCell = (item, header) => {
    const value = item[header.key];
    let formattedValue;

    if (typeof header.format === "function") {
      formattedValue = header.format(value);
    } else {
      formattedValue = formatValue(value, header.format);
    }

    if (header.key === "status" || header.key === "sale_type") {
      return (
        <span
          className={header.key === "status" ? "status-badge" : "type-badge"}
        >
          {formattedValue}
        </span>
      );
    }

    return formattedValue;
  };

  // Calculate max width for each column based on content
  const getColumnWidths = useCallback(() => {
    const widths = {};

    // Initialize with header widths
    headers.forEach((header) => {
      widths[header.key] = header.label.length * 8; // Base character width
    });

    // Compare with actual data
    items.forEach((item) => {
      headers.forEach((header) => {
        const value = item[header.key];
        let displayValue = value;

        // Format value if needed
        if (header.format === "USD") {
          displayValue = new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          }).format(value);
        } else if (typeof header.format === "function") {
          displayValue = header.format(value);
        }

        const valueLength = String(displayValue || "-").length * 8;
        widths[header.key] = Math.max(widths[header.key], valueLength);
      });
    });

    // Add padding
    Object.keys(widths).forEach((key) => {
      widths[key] += 32; // 16px padding on each side
    });

    return widths;
  }, [headers, items]);

  const columnWidths = useMemo(() => getColumnWidths(), [getColumnWidths]);

  return (
    <div className="data-table-container">
      <table className="data-table">
        <thead>
          <AnimatePresence mode="wait">
            <motion.tr
              key={`header-${items.length}`}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.2 }}
            >
              {headers.map((header) => (
                <th
                  key={header.key}
                  onClick={() => !isPublic && onSort?.(header.key)}
                  className={`${getColumnClassName(header.key)} ${isPublic ? 'no-sort' : ''}`}
                  style={{ width: columnWidths[header.key] }}
                >
                  <motion.div
                    className="header-content"
                    whileHover={!isPublic ? {
                      color: "rgba(255, 255, 255, 0.95)",
                      transition: { duration: 0.2 },
                    } : {}}
                  >
                    <AnimatePresence mode="wait">
                      <motion.span
                        key={`header-text-${items.length}-${header.key}`}
                        initial={{ opacity: 0, y: -5 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: 5 }}
                        transition={{ duration: 0.15 }}
                      >
                        {header.label}
                      </motion.span>
                    </AnimatePresence>
                    {!isPublic && sortConfig?.key === header.key && (
                      <motion.div
                        initial={{ rotate: 0 }}
                        animate={{
                          rotate:
                            sortConfig.direction === "ascending" ? 180 : 0,
                        }}
                        transition={{
                          type: "spring",
                          stiffness: 200,
                          damping: 15,
                        }}
                      >
                        <ChevronDown className="sort-icon" size={16} />
                      </motion.div>
                    )}
                  </motion.div>
                </th>
              ))}
            </motion.tr>
          </AnimatePresence>
        </thead>
        <tbody>
          <AnimatePresence mode="sync">
            {shouldAnimate &&
              displayItems.map((item, index) => (
                <motion.tr
                  key={`${item.uid}-${index}`}
                  initial={{
                    opacity: 0,
                    y: -10,
                  }}
                  animate={{
                    opacity: 1,
                    y: 0,
                  }}
                  transition={{
                    delay: getLinearDelay(index),
                    duration: 0.2,
                    ease: [0.2, 0.65, 0.3, 0.9],
                  }}
                  whileHover={{
                    backgroundColor: "rgba(255, 255, 255, 0.03)",
                    transition: {
                      duration: 0.15,
                      ease: "easeOut",
                    },
                  }}
                  onClick={() => onRowClick?.(index)}
                  className="data-row"
                >
                  {headers.map((header) => (
                    <motion.td
                      key={`${item.uid}-${header.key}`}
                      className={getColumnClassName(header.key)}
                      style={{ width: columnWidths[header.key] }}
                      initial={{ opacity: 0 }}
                      animate={{
                        opacity: 1,
                        transition: {
                          delay: getLinearDelay(index) + 0.1,
                          duration: 0.2,
                        },
                      }}
                    >
                      {renderCell(item, header)}
                    </motion.td>
                  ))}
                </motion.tr>
              ))}
            {emptyRows > 0 &&
              Array.from({ length: emptyRows }).map((_, index) => (
                <tr key={`empty-${index}`} className="empty-row">
                  {headers.map((header, colIndex) => (
                    <td key={`empty-${index}-${colIndex}`}>&nbsp;</td>
                  ))}
                </tr>
              ))}
          </AnimatePresence>
        </tbody>
      </table>

      <AnimatePresence>
        {loading && items.length === 0 && (
          <motion.div
            className="data-table-loading"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <div className="data-table-empty">Loading...</div>
          </motion.div>
        )}
      </AnimatePresence>

      <AnimatePresence mode="wait">
        {!loading && items.length === 0 && (
          <motion.div
            className="data-table-empty"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
          >
            No items to display
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default React.memo(DataTable);
