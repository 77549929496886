// src/components/FakeWindowsScreen.js

import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { motion, AnimatePresence } from "framer-motion";
import { UserPlus, LogIn, Briefcase, Minus, Maximize, X } from "react-feather";
import { PublicBuyProvider } from "../../context/PublicBuyContext";
import PublicBuyContent from "./PublicBuyContent";
import { useTranslation } from "react-i18next";
import SwarmLogo from './SwarmLogo';

const WindowContainer = styled(motion.div)`
  width: ${(props) => (props.$isMaximized ? "100%" : "800px")};
  height: ${(props) => (props.$isMaximized ? "100%" : "600px")};
  background: linear-gradient(
    165deg,
    rgba(28, 28, 35, 0.85),
    rgba(18, 18, 24, 0.95)
  );
  backdrop-filter: blur(40px);
  border: 1px solid rgba(255, 255, 255, 0.07);
  border-radius: ${(props) => (props.$isMaximized ? "0" : "24px")};
  box-shadow: 
    0 0 0 1px rgba(255, 255, 255, 0.05),
    0 20px 50px -12px rgba(0, 0, 0, 0.5),
    0 0 100px rgba(136, 83, 251, 0.1);
  position: fixed;
  inset: ${(props) => (props.$isMaximized ? "0" : "auto")};
  margin: ${(props) => (props.$isMaximized ? "0" : "auto")};
  overflow: hidden;
  display: flex;
  flex-direction: column;
  z-index: 1000;
  transform-origin: center center;

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    background: radial-gradient(
      circle at top right,
      rgba(136, 83, 251, 0.08),
      transparent 70%
    );
    border-radius: inherit;
    pointer-events: none;
  }

  @media (max-width: 850px) {
    width: ${(props) => (props.$isMaximized ? "100%" : "90%")};
    height: ${(props) => (props.$isMaximized ? "100%" : "auto")};
    aspect-ratio: ${(props) => (props.$isMaximized ? "auto" : "4 / 3")};
    max-height: ${(props) => (props.$isMaximized ? "100%" : "90vh")};
  }
`;

const TitleBar = styled.div`
  height: 48px;
  background: linear-gradient(
    165deg,
    rgba(38, 38, 45, 0.5),
    rgba(28, 28, 35, 0.5)
  );
  display: flex;
  align-items: center;
  padding: 0 16px;
  justify-content: space-between;
  cursor: default;
  border-bottom: 1px solid rgba(255, 255, 255, 0.07);
  position: relative;

  .logo-container {
    display: flex;
    align-items: center;
    gap: 12px;
    
    > div {
      width: 24px;
      min-width: 24px;
      height: 24px;
      flex-shrink: 0;
      position: relative;
      overflow: visible;
    }
    
    span {
      font-size: 14px;
      font-weight: 500;
      color: rgba(255, 255, 255, 0.8);
      letter-spacing: 0.3px;
    }
  }

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    background: linear-gradient(
      to right,
      rgba(136, 83, 251, 0.05),
      transparent
    );
    pointer-events: none;
  }
`;

const WindowControls = styled.div`
  display: flex;
  gap: 8px;
`;

const ControlButton = styled.button`
  width: 28px;
  height: 28px;
  background: linear-gradient(
    165deg,
    rgba(38, 38, 45, 0.5),
    rgba(28, 28, 35, 0.5)
  );
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  color: rgba(255, 255, 255, 0.8);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s cubic-bezier(0.16, 1, 0.3, 1);
  backdrop-filter: blur(10px);

  &:hover {
    background: linear-gradient(
      165deg,
      rgba(43, 43, 50, 0.6),
      rgba(33, 33, 40, 0.6)
    );
    border-color: rgba(136, 83, 251, 0.2);
    color: white;
    transform: translateY(-1px);
    box-shadow: 
      0 4px 12px rgba(0, 0, 0, 0.2),
      0 0 0 1px rgba(136, 83, 251, 0.1);
  }

  &:active {
    transform: translateY(1px);
  }

  svg {
    width: 14px;
    height: 14px;
    filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.2));
  }
`;

const ContentArea = styled.div`
  flex-grow: 1;
  padding: 20px;
  color: var(--text-color);
  background-color: var(--background-color);
  display: flex;
  flex-direction: column;
  position: relative;
  height: calc(100% - 80px);
  overflow: hidden;
`;

const ScrollableContent = styled.div`
  flex: 1;
  overflow-y: auto;
  position: relative;
  padding: 20px;
  
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: rgba(18, 18, 24, 0.3);
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: linear-gradient(
      180deg,
      rgba(136, 83, 251, 0.3),
      rgba(96, 57, 176, 0.3)
    );
    border-radius: 4px;
    border: 2px solid rgba(18, 18, 24, 0.3);
  }
`;

const Taskbar = styled.div`
  height: 48px;
  background: linear-gradient(
    165deg,
    rgba(38, 38, 45, 0.5),
    rgba(28, 28, 35, 0.5)
  );
  backdrop-filter: blur(40px);
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 16px;
  border-top: 1px solid rgba(255, 255, 255, 0.07);
  position: relative;

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    background: linear-gradient(
      to right,
      rgba(136, 83, 251, 0.05),
      transparent
    );
    pointer-events: none;
  }
`;

const StartButton = styled.button`
  background: linear-gradient(
    165deg,
    rgba(136, 83, 251, 0.15),
    rgba(96, 57, 176, 0.15)
  );
  border: 1px solid rgba(136, 83, 251, 0.2);
  border-radius: 8px;
  color: white;
  padding: 0 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.3px;
  backdrop-filter: blur(10px);
  transition: all 0.3s cubic-bezier(0.16, 1, 0.3, 1);
  height: 32px;

  &:hover {
    transform: translateY(-1px);
    background: linear-gradient(
      165deg,
      rgba(136, 83, 251, 0.2),
      rgba(96, 57, 176, 0.2)
    );
    border-color: rgba(136, 83, 251, 0.4);
    box-shadow: 
      0 8px 32px rgba(136, 83, 251, 0.2),
      0 0 0 1px rgba(136, 83, 251, 0.2);
  }

  &:active {
    transform: translateY(1px);
  }
`;

const StartMenu = styled(motion.div)`
  position: absolute;
  bottom: 60px;
  left: 16px;
  width: 280px;
  background: linear-gradient(
    165deg,
    rgba(28, 28, 35, 0.95),
    rgba(18, 18, 24, 0.95)
  );
  backdrop-filter: blur(40px);
  border: 1px solid rgba(255, 255, 255, 0.07);
  border-radius: 16px;
  padding: 8px;
  z-index: 10;
  box-shadow: 
    0 0 0 1px rgba(255, 255, 255, 0.05),
    0 10px 30px -8px rgba(0, 0, 0, 0.3),
    0 0 40px rgba(136, 83, 251, 0.1);
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    background: radial-gradient(
      circle at top right,
      rgba(136, 83, 251, 0.08),
      transparent 70%
    );
    border-radius: inherit;
    pointer-events: none;
  }
`;

const MenuItem = styled.button`
  width: 100%;
  background: none;
  border: none;
  color: white;
  padding: 12px 16px;
  text-align: left;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 12px;
  border-radius: 10px;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.3px;
  transition: all 0.3s cubic-bezier(0.16, 1, 0.3, 1);

  &:hover {
    background: linear-gradient(
      165deg,
      rgba(136, 83, 251, 0.15),
      rgba(96, 57, 176, 0.15)
    );
    transform: translateX(4px);
  }

  svg {
    width: 18px;
    height: 18px;
    color: #8853fb;
    filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.2));
  }
`;

const ContentWrapper = styled(motion.div)`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    background: linear-gradient(
      165deg,
      rgba(136, 83, 251, 0.02),
      transparent
    );
    pointer-events: none;
  }
`;

const FakeWindowsScreen = ({
  onSignUp,
  onSignIn,
  onSeePlans,
  onClose,
  onMinimize,
  isMinimized,
}) => {
  const { t } = useTranslation();
  const [isStartMenuOpen, setIsStartMenuOpen] = useState(false);
  const [isMaximized, setIsMaximized] = useState(true);
  const [selectedPlan, setSelectedPlan] = useState("Free");

  useEffect(() => {
    if (!isMinimized) {
      setIsMaximized(true);
    }
  }, [isMinimized]);

  const toggleStartMenu = () => {
    setIsStartMenuOpen((prev) => !prev);
  };

  const closeStartMenu = () => {
    setIsStartMenuOpen(false);
  };

  const handleMinimize = () => {
    if (onMinimize) {
      onMinimize();
    }
  };

  const handleMaximize = () => {
    setIsMaximized((prev) => !prev);
  };

  const handleClose = () => {
    if (onMinimize) {
      onMinimize();
    }
  };

  const windowVariants = {
    hidden: { opacity: 0, scale: 0.9 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: { duration: 0.3, ease: "easeOut" },
    },
    maximized: {
      opacity: 1,
      scale: 1,
      transition: { duration: 0.3, ease: "easeInOut" },
    },
    minimized: {
      scale: 0.1,
      opacity: 0,
      transition: { duration: 0.3, ease: "easeInOut" },
    },
    exit: {
      opacity: 0,
      scale: 0.9,
      transition: { duration: 0.2, ease: "easeIn" },
    },
  };

  const contentVariants = {
    visible: { opacity: 1, transition: { duration: 0.2 } },
    minimized: { opacity: 0, transition: { duration: 0.2 } },
  };

  return (
    <WindowContainer
      variants={windowVariants}
      initial="hidden"
      animate={
        isMinimized ? "minimized" : isMaximized ? "maximized" : "visible"
      }
      exit="exit"
      $isMaximized={isMaximized}
    >
      <TitleBar>
        <div className="logo-container">
          <SwarmLogo size="24px" enableHover={false} />
          <span>DeedFinders Dashboard</span>
        </div>
        <WindowControls>
          <ControlButton onClick={handleMinimize} aria-label="Minimize Window">
            <Minus size={12} />
          </ControlButton>
          <ControlButton
            onClick={handleMaximize}
            aria-label="Maximize/Restore Window"
          >
            <Maximize size={12} />
          </ControlButton>
          <ControlButton onClick={handleClose} aria-label="Close Window">
            <X size={12} />
          </ControlButton>
        </WindowControls>
      </TitleBar>

      <ContentWrapper
        variants={contentVariants}
        animate={isMinimized ? "minimized" : "visible"}
        transition={{ duration: 0.2 }}
      >
        <ContentArea>
          <ScrollableContent>
            <PublicBuyProvider>
              <PublicBuyContent
                planType={selectedPlan}
                onSignUp={onSignUp}
                onSignIn={onSignIn}
              />
            </PublicBuyProvider>
          </ScrollableContent>
        </ContentArea>

        <Taskbar>
          <StartButton onClick={toggleStartMenu} aria-label="Start Menu">
            {t("start")}
          </StartButton>
        </Taskbar>

        <AnimatePresence>
          {isStartMenuOpen && (
            <StartMenu
              initial={{ opacity: 0, y: 10, scale: 0.98 }}
              animate={{ opacity: 1, y: 0, scale: 1 }}
              exit={{ opacity: 0, y: 10, scale: 0.98 }}
              transition={{ 
                duration: 0.2,
                ease: [0.16, 1, 0.3, 1]
              }}
            >
              <MenuItem
                onClick={() => {
                  onSignUp();
                  closeStartMenu();
                }}
              >
                <UserPlus size={16} />
                {t("signup")}
              </MenuItem>
              <MenuItem
                onClick={() => {
                  onSignIn();
                  closeStartMenu();
                }}
              >
                <LogIn size={16} />
                {t("signin")}
              </MenuItem>
              <MenuItem
                onClick={() => {
                  onSeePlans();
                  closeStartMenu();
                }}
              >
                <Briefcase size={16} />
                {t("seePlans")}
              </MenuItem>
            </StartMenu>
          )}
        </AnimatePresence>
      </ContentWrapper>
    </WindowContainer>
  );
};

export default FakeWindowsScreen;
