import React from 'react';
import { motion } from 'framer-motion';
import Friends from '../friends/Friends';

const FriendsPage = () => {
  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.95 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0, scale: 0.95 }}
      transition={{ duration: 0.5, ease: [0.16, 1, 0.3, 1] }}
      style={{ 
        height: '100%',
        width: '100%',
        padding: '24px',
        boxSizing: 'border-box',
        overflow: 'hidden'
      }}
    >
      <motion.div 
        className="friends-content"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.2, duration: 0.5 }}
        style={{
          width: '100%',
          height: '100%',
          background: 'linear-gradient(165deg, rgba(28, 28, 35, 0.85), rgba(18, 18, 24, 0.95))',
          backdropFilter: 'blur(40px)',
          borderRadius: '24px',
          border: '1px solid rgba(255, 255, 255, 0.07)',
          boxShadow: `
            0 0 0 1px rgba(255, 255, 255, 0.05),
            0 20px 50px -12px rgba(0, 0, 0, 0.5),
            0 0 100px rgba(136, 83, 251, 0.1)
          `,
          position: 'relative',
          overflow: 'hidden'
        }}
      >
        <Friends />
      </motion.div>
    </motion.div>
  );
};

export default FriendsPage; 