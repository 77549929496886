import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { AlertTriangle, X } from "react-feather";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import { motion } from "framer-motion";

const WarningContainer = styled(motion.div)`
  background: linear-gradient(
    165deg,
    rgba(28, 28, 35, 0.85),
    rgba(18, 18, 24, 0.95)
  );
  backdrop-filter: blur(40px);
  border-top: 1px solid rgba(255, 255, 255, 0.07);
  box-shadow: 
    0 -4px 20px rgba(0, 0, 0, 0.2),
    0 0 0 1px rgba(255, 255, 255, 0.05);
  color: rgba(255, 255, 255, 0.9);
  padding: 12px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  bottom: ${({ $isHomePage }) => ($isHomePage ? "0px" : "40px")};
  left: 0;
  right: 0;
  z-index: 9999;
  transform: translateZ(0);
  will-change: transform;
`;

const WarningContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  gap: 12px;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.3px;
`;

const WarningText = styled.span`
  background: linear-gradient(45deg, #8853fb, #3498db);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  text-shadow: 0 2px 10px rgba(136, 83, 251, 0.3);
`;

const StyledAlertTriangle = styled(AlertTriangle)`
  color: #8853fb;
  filter: drop-shadow(0 2px 4px rgba(136, 83, 251, 0.2));
`;

const CloseButton = styled.button`
  background: linear-gradient(
    165deg,
    rgba(38, 38, 45, 0.5),
    rgba(28, 28, 35, 0.5)
  );
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  color: rgba(255, 255, 255, 0.8);
  cursor: pointer;
  width: 28px;
  height: 28px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s cubic-bezier(0.16, 1, 0.3, 1);

  &:hover {
    background: linear-gradient(
      165deg,
      rgba(43, 43, 50, 0.6),
      rgba(33, 33, 40, 0.6)
    );
    border-color: rgba(136, 83, 251, 0.2);
    color: white;
    transform: translateY(-1px);
    box-shadow: 
      0 4px 12px rgba(0, 0, 0, 0.2),
      0 0 0 1px rgba(136, 83, 251, 0.1);
  }

  &:active {
    transform: translateY(1px);
  }

  svg {
    width: 16px;
    height: 16px;
  }
`;

const DevelopmentWarning = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const isHomePage = location.pathname.startsWith("/home");
  const [isVisible, setIsVisible] = useState(true);

  if (!isVisible) return null;

  return (
    <WarningContainer
      $isHomePage={isHomePage}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 20 }}
      transition={{ duration: 0.3, ease: [0.16, 1, 0.3, 1] }}
    >
      <WarningContent>
        <StyledAlertTriangle size={18} />
        <WarningText>{t("developmentWarning")}</WarningText>
      </WarningContent>
      <CloseButton onClick={() => setIsVisible(false)}>
        <X />
      </CloseButton>
    </WarningContainer>
  );
};

export default DevelopmentWarning;
