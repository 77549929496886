import React, { useContext, useState, useEffect, useRef, useMemo } from "react";
import { X, ExternalLink, Search, Filter, XCircle } from "react-feather";
import { useTranslation } from "react-i18next";
import { NotesContext } from "../../context/NotesContext";
import ConfirmationModal from "../common/ConfirmationModal";
import Toast from "../common/Toast";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import "tippy.js/animations/shift-away.css";
import "../../assets/styles/NotesModal.css";
import { motion, AnimatePresence } from "framer-motion";
import ReactDOM from "react-dom";
import styled from "styled-components";

const MODAL_PORTAL_CLASS = "notes-modal-portal";

const CustomSelect = ({ value, onChange, options, placeholder, className }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [triggerRect, setTriggerRect] = useState(null);
  const selectRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        const portalElements =
          document.getElementsByClassName(MODAL_PORTAL_CLASS);
        for (let element of portalElements) {
          if (element.contains(event.target)) {
            return;
          }
        }
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  const updateTriggerRect = () => {
    if (selectRef.current) {
      const rect = selectRef.current.getBoundingClientRect();
      setTriggerRect(rect);
    }
  };

  const handleTriggerClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    updateTriggerRect();
    setIsOpen(!isOpen);
  };

  return (
    <div className={`custom-select ${className}`} ref={selectRef}>
      <div
        className={`select-trigger ${isOpen ? "active" : ""}`}
        onClick={handleTriggerClick}
      >
        <span>
          {options.find((opt) => opt.value === value)?.label || placeholder}
        </span>
        <div className="select-arrow" />
      </div>

      {isOpen &&
        triggerRect &&
        ReactDOM.createPortal(
          <div
            className={`select-options ${MODAL_PORTAL_CLASS}`}
            style={{
              position: "fixed",
              top: `${triggerRect.bottom + 8}px`,
              left: `${triggerRect.left}px`,
              width: `${triggerRect.width}px`,
              zIndex: 9999,
            }}
          >
            {options.map((option) => (
              <div
                key={option.value}
                className={`select-option ${
                  value === option.value ? "selected" : ""
                }`}
                onClick={(e) => {
                  e.stopPropagation();
                  onChange(option.value);
                  setIsOpen(false);
                }}
              >
                {option.label}
              </div>
            ))}
          </div>,
          document.body
        )}
    </div>
  );
};

const containerVariants = {
  hidden: {
    opacity: 0,
    scale: 0.98,
  },
  show: {
    opacity: 1,
    scale: 1,
    transition: {
      staggerChildren: 0.06,
      delayChildren: 0.05,
      duration: 0.2,
    },
  },
  exit: {
    opacity: 0,
    scale: 0.98,
    transition: {
      duration: 0.15,
    },
  },
};

const noteVariants = {
  hidden: {
    opacity: 0,
    scale: 0.95,
    y: 15,
    rotateX: -5,
  },
  show: {
    opacity: 1,
    scale: 1,
    y: 0,
    rotateX: 0,
    transition: {
      type: "spring",
      stiffness: 300,
      damping: 25,
      mass: 0.5,
      velocity: 2,
    },
  },
  exit: {
    opacity: 0,
    scale: 0.95,
    y: -10,
    rotateX: 5,
    transition: {
      duration: 0.15,
      ease: "easeInOut",
    },
  },
};

const StyledStackedNotesContainer = styled(motion.div)`
  position: relative;
  height: 200px;
  perspective: 1000px;
  transform-style: preserve-3d;
  width: 100%;
  margin-bottom: 24px;
  padding: 0;
`;

const CloseOrb = styled(motion.button)`
  position: fixed;
  bottom: 30px;
  right: 30px;
  width: 56px;
  height: 56px;
  background: linear-gradient(
    145deg,
    rgba(136, 83, 251, 0.95),
    rgba(96, 57, 176, 0.95)
  );
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
  box-shadow: 0 2px 20px rgba(136, 83, 251, 0.3),
    0 0 0 1px rgba(255, 255, 255, 0.1) inset, 0 0 0 1px rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(20px);
  transition: all 0.4s cubic-bezier(0.16, 1, 0.3, 1);
  z-index: 9999;
  pointer-events: auto;
  color: white;

  &::before {
    content: "";
    position: absolute;
    inset: 1px;
    background: linear-gradient(145deg, rgba(255, 255, 255, 0.1), transparent);
    border-radius: inherit;
    opacity: 0.5;
  }

  &:hover {
    transform: scale(1.05) translateY(-2px);
    box-shadow: 0 8px 32px rgba(136, 83, 251, 0.4),
      0 0 0 1px rgba(255, 255, 255, 0.15) inset;
  }

  svg {
    filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.2));
    color: white;
    transition: transform 0.3s cubic-bezier(0.16, 1, 0.3, 1);
  }

  &:hover svg {
    transform: scale(1.1);
  }
`;

const NotesModal = ({ isOpen, onClose, onViewProperty }) => {
  const { t } = useTranslation();
  const { notes, deleteNote, fetchAllUserNotes } = useContext(NotesContext);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [noteToDelete, setNoteToDelete] = useState(null);
  const [toast, setToast] = useState(null);
  const [selectedNote, setSelectedNote] = useState(null);
  const modalRef = useRef(null);

  // Add loading state
  const [isLoading, setIsLoading] = useState(false);

  // Filter states
  const [searchTerm, setSearchTerm] = useState("");
  const [dateFilter, setDateFilter] = useState("all");
  const [selectedProperty, setSelectedProperty] = useState("");
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  // Get unique properties for the filter dropdown
  const uniqueProperties = useMemo(() => {
    const properties = new Set();

    // Check if notes exists and is an array
    if (!Array.isArray(notes)) {
      return [];
    }

    notes.forEach((note) => {
      if (note?.propertyDetails?.address) {
        properties.add(note.propertyDetails.address);
      }
    });

    return Array.from(properties);
  }, [notes]);

  // Filter notes based on all criteria
  const filteredNotes = useMemo(() => {
    // Check if notes exists and is an array
    if (!Array.isArray(notes)) {
      return [];
    }

    return notes.filter((note) => {
      const matchesSearch =
        note?.text?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        note?.propertyDetails?.address
          ?.toLowerCase()
          .includes(searchTerm.toLowerCase());

      const matchesProperty =
        !selectedProperty ||
        note?.propertyDetails?.address === selectedProperty;

      const noteDate = new Date(note.createdAt);
      const today = new Date();
      const weekAgo = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);
      const monthAgo = new Date(today.getTime() - 30 * 24 * 60 * 60 * 1000);

      let matchesDate = true;
      if (dateFilter === "today") {
        matchesDate = noteDate.toDateString() === today.toDateString();
      } else if (dateFilter === "week") {
        matchesDate = noteDate >= weekAgo;
      } else if (dateFilter === "month") {
        matchesDate = noteDate >= monthAgo;
      }

      return matchesSearch && matchesProperty && matchesDate;
    });
  }, [notes, searchTerm, selectedProperty, dateFilter]);

  const handleCancelDelete = () => {
    setIsConfirmOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      const confirmationModal = document.querySelector(".confirmation-modal");
      const confirmationOverlay = document.querySelector(
        ".confirmation-modal-overlay"
      );
      const portalElements =
        document.getElementsByClassName(MODAL_PORTAL_CLASS);

      // If confirmation is open, only handle clicks for confirmation
      if (isConfirmOpen) {
        if (
          confirmationModal &&
          !confirmationModal.contains(event.target) &&
          confirmationOverlay &&
          confirmationOverlay.contains(event.target)
        ) {
          handleCancelDelete();
        }
        return; // Stop here if confirmation is open
      }

      // Check if click is within any portal elements
      for (let element of portalElements) {
        if (element.contains(event.target)) {
          return;
        }
      }

      // Only handle modal clicks if confirmation is not open
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen, onClose, isConfirmOpen]);

  useEffect(() => {
    const loadNotes = async () => {
      if (isOpen) {
        try {
          setIsLoading(true); // Add loading state
          await fetchAllUserNotes();
        } catch (error) {
          console.error("Failed to fetch notes:", error);
          setToast({
            message: t("failedToFetchNotes"),
            type: "error",
            duration: 3000,
          });
        } finally {
          setIsLoading(false);
        }
      }
    };

    loadNotes();
  }, [isOpen, fetchAllUserNotes]);

  const handleViewProperty = async (propertyId, tabIndex = 0) => {
    try {
      await onViewProperty(propertyId, tabIndex);

      onClose();
    } catch (error) {
      console.error("Failed to view property:", error);
      setToast({
        message: t("failedToFetchDetails"),
        type: "error",
        duration: 3000,
      });
    }
  };

  const confirmDeleteNote = async () => {
    if (noteToDelete) {
      try {
        await deleteNote(noteToDelete._id);
        setToast({
          message: t("noteDeletedSuccessfully"),
          type: "success",
          duration: 3000,
        });
      } catch (error) {
        setToast({
          message: t("failedToDeleteNote"),
          type: "error",
          duration: 3000,
        });
      }
    }
    setIsConfirmOpen(false);
    setNoteToDelete(null);
  };

  const groupNotesByProperty = (notes) => {
    return notes.reduce((acc, note) => {
      const propertyId = note.propertyId;
      if (!acc[propertyId]) {
        acc[propertyId] = [];
      }
      acc[propertyId].push(note);
      return acc;
    }, {});
  };

  const limitNotesPerProperty = (groupedNotes, limit = 3) => {
    return Object.entries(groupedNotes).reduce((acc, [propertyId, notes]) => {
      acc[propertyId] = notes
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
        .slice(0, limit);
      return acc;
    }, {});
  };

  const truncateText = (text, maxLength = 30) => {
    if (!text) return "";
    if (text.length <= maxLength) return text;
    return text.substring(0, maxLength) + "...";
  };

  const handleOverlayClick = (event) => {
    if (event.target === event.currentTarget) {
      onClose();
    }
  };

  if (!isOpen) return null;

  return (
    <>
      <div className="notes-modal-overlay" onClick={handleOverlayClick}>
        <div
          className="notes-modal"
          ref={modalRef}
          onClick={(e) => e.stopPropagation()}
        >
          <div className="notes-modal-header">
            <div className="header-content">
              <div className="title-section">
                <h2>
                  <span className="title-gradient">Notes</span>
                  <span className="note-count">{filteredNotes.length}</span>
                </h2>
              </div>

              <div
                className={`header-controls ${isFilterOpen ? "expanded" : ""}`}
              >
                <motion.div
                  className={`search-input-wrapper ${
                    isFilterOpen ? "expanded" : ""
                  }`}
                  animate={{
                    flex: isFilterOpen ? 2 : 3,
                    marginRight: isFilterOpen ? "12px" : "0",
                  }}
                  transition={{
                    type: "spring",
                    stiffness: 300,
                    damping: 30,
                  }}
                >
                  <Search size={16} />
                  <input
                    type="text"
                    placeholder={t("searchNotes")}
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                  {searchTerm && (
                    <button
                      className="clear-search"
                      onClick={() => setSearchTerm("")}
                    >
                      <XCircle size={16} />
                    </button>
                  )}
                </motion.div>

                <AnimatePresence>
                  {isFilterOpen && (
                    <motion.div
                      className="filter-controls"
                      initial={{
                        width: 0,
                        opacity: 0,
                        x: 40,
                        scaleX: 0,
                      }}
                      animate={{
                        width: "auto",
                        opacity: 1,
                        x: 0,
                        scaleX: 1,
                        transition: {
                          width: {
                            duration: 0.3,
                            ease: [0.4, 0, 0.2, 1],
                          },
                          opacity: {
                            duration: 0.2,
                            delay: 0.1,
                          },
                          x: {
                            duration: 0.3,
                            ease: [0.4, 0, 0.2, 1],
                          },
                          scaleX: {
                            duration: 0.3,
                            ease: [0.4, 0, 0.2, 1],
                          },
                        },
                      }}
                      exit={{
                        width: 0,
                        opacity: 0,
                        x: 40,
                        scaleX: 0,
                        transition: {
                          duration: 0.2,
                          ease: [0.4, 0, 0.2, 1],
                          opacity: { duration: 0.1 },
                        },
                      }}
                      style={{
                        overflow: "hidden",
                        position: "relative",
                        zIndex: 1,
                      }}
                    >
                      <motion.div
                        initial={{ x: 40 }}
                        animate={{ x: 0 }}
                        exit={{ x: 40 }}
                        transition={{ duration: 0.2, ease: "easeOut" }}
                        style={{
                          display: "flex",
                          gap: "12px",
                          minWidth: "max-content",
                        }}
                      >
                        <CustomSelect
                          className="date-filter"
                          value={dateFilter}
                          onChange={setDateFilter}
                          options={[
                            { value: "all", label: t("allTime") },
                            { value: "today", label: t("today") },
                            { value: "week", label: t("pastWeek") },
                            { value: "month", label: t("pastMonth") },
                          ]}
                          placeholder={t("filterNotes")}
                        />

                        <CustomSelect
                          className="property-filter"
                          value={selectedProperty}
                          onChange={setSelectedProperty}
                          options={[
                            { value: "", label: t("allProperties") },
                            ...uniqueProperties.map((property) => ({
                              value: property,
                              label: truncateText(property, 40),
                            })),
                          ]}
                          placeholder={t("selectProperty")}
                        />
                      </motion.div>
                    </motion.div>
                  )}
                </AnimatePresence>

                <div className="header-actions">
                  <Tippy
                    content={t("toggleFilters")}
                    placement="bottom"
                    animation="shift-away"
                    arrow={true}
                    duration={[300, 250]}
                    delay={[400, 0]}
                    theme="modern"
                  >
                    <motion.button
                      className={`filter-toggle-button ${
                        isFilterOpen ? "active" : ""
                      }`}
                      onClick={() => setIsFilterOpen(!isFilterOpen)}
                      whileHover={{
                        y: -1,
                        transition: { duration: 0.2 },
                      }}
                      animate={{
                        scale: isFilterOpen ? 1 : 1,
                        backgroundColor: isFilterOpen
                          ? "rgba(136, 83, 251, 0.3)"
                          : "rgba(136, 83, 251, 0.15)",
                      }}
                      transition={{
                        type: "spring",
                        stiffness: 400,
                        damping: 25,
                      }}
                    >
                      <Filter size={20} />
                    </motion.button>
                  </Tippy>
                </div>
              </div>
            </div>
          </div>

          <div className="notes-modal-content">
            <AnimatePresence mode="wait">
              {isLoading ? (
                <motion.div
                  className="notes-loading"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.2 }}
                >
                  {t("loading")}
                </motion.div>
              ) : !Array.isArray(notes) || notes.length === 0 ? (
                <motion.div
                  className="no-notes-message"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -20 }}
                  transition={{ duration: 0.3 }}
                >
                  {searchTerm || dateFilter !== "all" || selectedProperty
                    ? t("noNotesMatchFilters")
                    : t("noNotesFound")}
                </motion.div>
              ) : (
                <motion.div
                  className="notes-grid"
                  key={`grid-${filteredNotes.length}-${searchTerm}-${dateFilter}-${selectedProperty}`}
                  variants={containerVariants}
                  initial="hidden"
                  animate="show"
                  exit="exit"
                  layout
                >
                  {Object.entries(
                    limitNotesPerProperty(groupNotesByProperty(filteredNotes))
                  ).map(([propertyId, propertyNotes]) => {
                    const totalNotes =
                      groupNotesByProperty(filteredNotes)[propertyId].length;

                    return (
                      <StyledStackedNotesContainer
                        key={propertyId}
                        className="stacked-notes-container"
                        variants={noteVariants}
                        layout
                      >
                        {propertyNotes.map((note, index) =>
                          index === 0 ? (
                            <motion.div
                              key={note._id}
                              className={`note-card ${
                                selectedNote === note._id ? "active" : ""
                              }`}
                              initial={false}
                              layout
                              animate={{
                                scale: selectedNote === note._id ? 1.02 : 1,
                                y: selectedNote === note._id ? -15 : 0,
                                zIndex: propertyNotes.length - index,
                              }}
                            >
                              <div className="note-property-info">
                                <div className="note-header">
                                  <h3 title={note.propertyDetails.address}>
                                    {truncateText(
                                      note.propertyDetails.address,
                                      30
                                    )}
                                  </h3>
                                </div>
                                <span className="parcel-number">
                                  {t("parcelNumber")}:{" "}
                                  {note.propertyDetails.parcelNumber}
                                </span>
                              </div>
                              <div className="note-message">
                                <div className="note-content">{note.text}</div>
                                <span className="note-timestamp">
                                  {new Date(note.createdAt).toLocaleString()}
                                </span>
                              </div>
                              <div className="note-actions">
                                {totalNotes > 1 && (
                                  <Tippy
                                    content={t("clickToViewAllNotes")}
                                    placement="top"
                                    animation="shift-away"
                                    arrow={true}
                                    duration={[300, 250]}
                                    delay={[400, 0]}
                                    theme="modern"
                                  >
                                    <button
                                      className="notes-count-badge"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleViewProperty(note.propertyId, 5);
                                      }}
                                    >
                                      {totalNotes}{" "}
                                      {t("notes").charAt(0).toUpperCase() +
                                        t("notes").slice(1)}
                                    </button>
                                  </Tippy>
                                )}
                                <button
                                  className="view-property-button"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleViewProperty(note.propertyId);
                                  }}
                                >
                                  <ExternalLink size={16} />
                                  {t("viewProperty")}
                                </button>
                              </div>
                            </motion.div>
                          ) : (
                            <motion.div
                              key={note._id}
                              className="note-card-stack-placeholder"
                              initial={false}
                              layout
                              animate={{
                                scale: 1,
                                y: -index * 4,
                                zIndex: propertyNotes.length - index,
                              }}
                            />
                          )
                        )}
                      </StyledStackedNotesContainer>
                    );
                  })}
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        </div>
        <ConfirmationModal
          isOpen={isConfirmOpen}
          message={t("areYouSureDeleteNote")}
          onConfirm={confirmDeleteNote}
          onClose={handleCancelDelete}
        />
        {toast && (
          <Toast
            message={toast.message}
            type={toast.type}
            duration={toast.duration}
            onClose={() => setToast(null)}
          />
        )}
      </div>

      <CloseOrb
        onClick={onClose}
        initial={{ scale: 0, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        exit={{ scale: 0, opacity: 0 }}
        whileHover={{ scale: 1.05, y: -2 }}
        whileTap={{ scale: 0.95 }}
      >
        <X size={24} />
      </CloseOrb>
    </>
  );
};

export default NotesModal;
