import React, { useState, useContext, useRef, useEffect } from "react";
import { Heart, Trash2 } from "react-feather";
import { FavoritesContext } from "../../../context/FavoritesContext";
import ConfirmationModal from "../../common/ConfirmationModal";
import Toast from "../../common/Toast";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import "tippy.js/animations/shift-away.css";
import "../../../assets/styles/Favorite.css";
import "../../../assets/styles/shared/Tooltip.css"; // Import shared tooltip styles

const FavoriteButton = ({ uid }) => {
  const {
    folders,
    addFavorite,
    removeFavorite,
    createFolder,
    deleteFolder,
    selectedFolder,
    setSelectedFolder,
  } = useContext(FavoritesContext);

  const [showDropdown, setShowDropdown] = useState(false);
  const [isCreatingFolder, setIsCreatingFolder] = useState(false);
  const [newFolderName, setNewFolderName] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [folderToDelete, setFolderToDelete] = useState(null);
  const [toast, setToast] = useState(null);

  const dropdownRef = useRef(null);
  const buttonRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        !buttonRef.current.contains(event.target)
      ) {
        setShowDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const hasFavoritedThisProperty = () => {
    return folders.some(({ properties }) => properties.includes(uid));
  };

  const isFavoritedInFolder = (folder) => {
    return folder.properties.includes(uid);
  };

  const handleFavoriteClick = () => {
    setShowDropdown(!showDropdown);
  };

  const handleFolderSelect = (folder) => {
    if (isFavoritedInFolder(folder)) {
      removeFavorite(uid, folder.id);
      setToast({
        message: `Property removed from "${folder.name}" folder`,
        type: "info",
      });
    } else {
      setSelectedFolder(folder.id);
      addFavorite(uid, folder.id);
      setToast({
        message: `Property added to "${folder.name}" folder`,
        type: "success",
      });
    }
    setShowDropdown(false);
  };

  const handleCreateFolder = () => {
    setIsCreatingFolder(true);
  };

  const handleSaveNewFolder = async () => {
    if (newFolderName.trim()) {
      const newFolder = { id: newFolderName, name: newFolderName };
      setSelectedFolder(newFolder.id);
      await createFolder(newFolderName);
      addFavorite(uid, newFolder.id);
      setIsCreatingFolder(false);
      setNewFolderName("");
      setToast({
        message: `New folder "${truncateMessage(newFolderName, 20)}" created`,
        type: "success",
      });
    }
  };

  const handleDeleteClick = (folder) => {
    setFolderToDelete(folder);
    setIsModalOpen(true);
  };

  const confirmDeleteFolder = () => {
    setIsModalOpen(false);
    if (folderToDelete) {
      if (selectedFolder === folderToDelete.id) {
        removeFavorite(uid, folderToDelete.id);
      }
      deleteFolder(folderToDelete.id);
      setSelectedFolder(null);
      setToast({
        message: `Folder "${truncateMessage(
          folderToDelete.name,
          20
        )}" and all favorites inside have been deleted`,
        type: "warning",
      });
      setFolderToDelete(null);
    }
  };

  const cancelDeleteFolder = () => {
    setIsModalOpen(false);
    setFolderToDelete(null);
  };

  const truncateFolderName = (name, maxLength = 15) => {
    return name.length > maxLength
      ? name.substring(0, maxLength) + "..."
      : name;
  };

  const truncateMessage = (message, maxLength = 30) => {
    return message.length > maxLength
      ? message.substring(0, maxLength) + "..."
      : message;
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSaveNewFolder();
    }
  };

  return (
    <div className="favorite-container">
      <Tippy
        content={hasFavoritedThisProperty() ? "Remove from favorites" : "Add to favorites"}
        animation="shift-away"
        placement="top"
        duration={[300, 250]}
        delay={[400, 0]}
        theme="modern"
        arrow={true}
      >
        <button
          onClick={handleFavoriteClick}
          className="favorite-button"
          ref={buttonRef}
        >
          <Heart
            size={20}
            color="#8853fb"
            fill={hasFavoritedThisProperty() ? "#8853fb" : "none"}
          />
        </button>
      </Tippy>

      {showDropdown && (
        <div className="dropdown-favorite" ref={dropdownRef}>
          {folders.map((folder) => (
            <div
              key={folder.id}
              className={`dropdown-item ${isFavoritedInFolder(folder) ? "selected" : ""}`}
              onClick={() => handleFolderSelect(folder)}
            >
              <Tippy
                content={truncateFolderName(folder.name).endsWith("...")
                  ? `${folder.name} (${folder.properties.length} items)`
                  : null}
                animation="shift-away"
                placement="right"
                duration={[300, 250]}
                delay={[400, 0]}
                theme="modern"
                arrow={true}
                disabled={!truncateFolderName(folder.name).endsWith("...")}
              >
                <span>
                  {truncateFolderName(folder.name)} ({folder.properties.length})
                </span>
              </Tippy>
              <Tippy
                content="Delete folder"
                animation="shift-away"
                placement="right"
                duration={[300, 250]}
                delay={[400, 0]}
                theme="modern"
                arrow={true}
              >
                <Trash2
                  size={16}
                  className="delete-icon"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDeleteClick(folder);
                  }}
                />
              </Tippy>
            </div>
          ))}
          {isCreatingFolder ? (
            <div className="dropdown-item create-folder">
              <input
                type="text"
                value={newFolderName}
                onChange={(e) => setNewFolderName(e.target.value)}
                onKeyDown={handleKeyDown}
                placeholder="New folder name"
                className="create-folder-input"
              />
              <button
                onClick={handleSaveNewFolder}
                className="save-folder-button"
              >
                Save
              </button>
            </div>
          ) : (
            <div
              className="dropdown-item create-one"
              onClick={handleCreateFolder}
            >
              + Create One
            </div>
          )}
        </div>
      )}
      <ConfirmationModal
        isOpen={isModalOpen}
        message={`Are you sure you want to delete the folder "${folderToDelete?.name}" and all favorites inside?`}
        onConfirm={confirmDeleteFolder}
        onClose={cancelDeleteFolder}
      />
      {toast && (
        <Toast
          message={toast.message}
          type={toast.type}
          duration={3000}
          onClose={() => setToast(null)}
        />
      )}
    </div>
  );
};

export default FavoriteButton;
