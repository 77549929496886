const fetchExpandedData = async (uid) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/properties/${uid}/details`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      }
    );

    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      console.error(`HTTP error! status: ${response.status}`);
      return null;
    }
  } catch (error) {
    console.error("Failed to fetch expanded data:", error);
    return null;
  }
};

export default fetchExpandedData;
