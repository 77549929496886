import React from 'react';
import PropTypes from 'prop-types';

const Button = ({ children, onClick, icon, className, ...props }) => {
  return (
    <button 
      className={`custom-button ${className || ''}`} 
      onClick={onClick} 
      {...props}
    >
      {icon && <span className="button-icon">{icon}</span>}
      {children}
    </button>
  );
};

Button.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  icon: PropTypes.node,
  className: PropTypes.string,
};

export default Button;