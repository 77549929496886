import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import Chat from "../chat/Chat";
import { 
  ChatHistoryModal, 
  ChatHistoryContent,
  ChatHistoryList,
  ChatHistoryItem 
} from "../chat/styles/Chat.styles";

const ChatPage = () => {
  const [showHistory, setShowHistory] = useState(false);
  const [editHistory, setEditHistory] = useState({
    currentVersion: null,
    history: []
  });

  const handleShowHistory = (history) => {
    setEditHistory(history);
    setShowHistory(true);
  };

  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.95 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0, scale: 0.95 }}
      transition={{ duration: 0.5, ease: [0.16, 1, 0.3, 1] }}
      style={{
        height: "100%",
        padding: "14px",
        width: "100%",
        boxSizing: "border-box",
        overflow: "hidden",
      }}
    >
      <motion.div
        className="chat-content"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.2, duration: 0.5 }}
        style={{
          width: "100%",
          height: "100%",
          background: "transparent",
          backdropFilter: "blur(40px)",
          borderRadius: "24px",
          padding: "3px",
          border: "transparent",
          position: "relative",
          overflow: "hidden",
        }}
      >
        <Chat onShowHistory={handleShowHistory} />
      </motion.div>

      <AnimatePresence>
        {showHistory && (
          <ChatHistoryModal
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={() => setShowHistory(false)}
          >
            <ChatHistoryContent
              onClick={(e) => e.stopPropagation()}
              initial={{ scale: 0.95, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0.95, opacity: 0 }}
              transition={{ type: "spring", damping: 25, stiffness: 500 }}
            >
              <h2 style={{ 
                textAlign: "center",
                background: "linear-gradient(45deg, #8853fb, #3498db)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
                marginBottom: "24px"
              }}>
                Message Edit History
              </h2>
              <ChatHistoryList>
                {editHistory.currentVersion && (
                  <ChatHistoryItem
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    $current
                  >
                    <div className="content">{editHistory.currentVersion.content}</div>
                    <div className="timestamp">
                      {new Date(editHistory.currentVersion.timestamp).toLocaleString()} (current)
                    </div>
                  </ChatHistoryItem>
                )}
                {editHistory.history.map((edit, index) => (
                  <ChatHistoryItem
                    key={index}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: index * 0.1 }}
                  >
                    <div className="content">{edit.content}</div>
                    <div className="timestamp">
                      {new Date(edit.editedAt).toLocaleString()}
                    </div>
                  </ChatHistoryItem>
                ))}
              </ChatHistoryList>
            </ChatHistoryContent>
          </ChatHistoryModal>
        )}
      </AnimatePresence>
    </motion.div>
  );
};

export default ChatPage;
