import React, { useState, useEffect } from "react";
import { ToastProvider } from "./context/ToastContext";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import LandingPage from "./components/pages/LandingPage";
import HomePage from "./components/pages/HomePage";
import { FavoritesProvider } from "./context/FavoritesContext";
import PrivateRoute from "./components/common/PrivateRoute";
import { AuthProvider } from "./context/AuthContext";
import { NotesProvider } from "./context/NotesContext";
import { WalletProvider } from "./context/WalletContext";
import ForgotPassword from "./components/ForgotPassword";
import ResetPassword from "./components/ResetPassword";
import "./assets/styles/App.css";
import EmailVerification from "./components/EmailVerification";
import { initI18n } from "./i18n";

import PricingPage from "./components/pages/PricingPage";
import DevelopmentWarning from "./components/common/DevelopmentWarning";
import { PublicBuyProvider } from "./context/PublicBuyContext";
import { LinkReportProvider } from './context/LinkReportContext';
import NotFoundPage from './components/pages/NotFoundPage';
import ChatPage from './components/pages/ChatPage';
import FriendsPage from './components/pages/FriendsPage';

function App() {
  const [i18nInitialized, setI18nInitialized] = useState(false);

  useEffect(() => {
    initI18n().then(() => {
      setI18nInitialized(true);
    });
  }, []);

  useEffect(() => {
    if (i18nInitialized) {
      const loadingScreen = document.getElementById("loading-screen");
      if (loadingScreen) {
        loadingScreen.style.opacity = "0";
        loadingScreen.style.transition = "opacity 0.5s ease-out";
        setTimeout(() => {
          loadingScreen.style.display = "none";
        }, 500); // Match this to the transition duration
      }
    }
  }, [i18nInitialized]);

  if (!i18nInitialized) {
    return null; // Return null to keep the HTML loading screen visible
  }

  return (
    <LinkReportProvider>
      <AuthProvider>
        <ToastProvider>
          <PublicBuyProvider>
            <Router>
              <DevelopmentWarning />
              <Routes>
                <Route path="/" element={<LandingPage />}>
                  <Route path="#privacy-policy" element={<LandingPage />} />
                  <Route path="#terms-of-service" element={<LandingPage />} />
                </Route>
                <Route path="/pricing" element={<PricingPage />}>
                  <Route path="#privacy-policy" element={<PricingPage />} />
                  <Route path="#terms-of-service" element={<PricingPage />} />
                </Route>
                <Route
                  path="/home/*"
                  element={
                    <PrivateRoute>
                      <AuthProvider>
                        <NotesProvider>
                          <FavoritesProvider>
                            <WalletProvider>
                              <HomePage />
                            </WalletProvider>
                          </FavoritesProvider>
                        </NotesProvider>
                      </AuthProvider>
                    </PrivateRoute>
                  }
                >
                  <Route path="chat" element={<ChatPage />} />
                  <Route path="friends" element={<FriendsPage />} />
                </Route>
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route
                  path="/reset-password/:token"
                  element={<ResetPassword />}
                />
                <Route
                  path="/verify-email/:token"
                  element={<EmailVerification />}
                />
                <Route path="*" element={<NotFoundPage />} />
              </Routes>
            </Router>
          </PublicBuyProvider>
        </ToastProvider>
      </AuthProvider>
    </LinkReportProvider>
  );
}

export default App;
