export const privacyPolicyContent = `
<h2>Updated Privacy Policy for U.S. Operations</h2>
<h3>Effective Date: Sep 06 2024</h3>

<h3>1. Introduction</h3>
<p>Welcome to Deed Finders. We respect your privacy and are committed to protecting your personal data. This privacy policy will inform you about how we handle your personal data when you visit our website, use our services, or interact with us.</p>

<h3>2. Information We Collect</h3>
<ul>
  <li><strong>Personal Information:</strong> When you register or use our services, we may collect personal information such as your name, email address, and billing information.</li>
  <li><strong>Technical Data:</strong> We collect technical data including your IP address, browser type, and operating system. This helps us optimize your experience on our platform.</li>
  <li><strong>Usage Data:</strong> We may collect information about how you use our services, such as the types of properties you search for and the features you use.</li>
</ul>

<h3>3. How We Use Your Information</h3>
<ul>
  <li>To provide and improve our services.</li>
  <li>To communicate with you about your account or transactions.</li>
  <li>To send you updates and promotional materials (you can opt out at any time).</li>
  <li>To enhance the security of our website and services.</li>
  <li>To comply with legal obligations.</li>
</ul>

<h3>4. Sharing Your Information</h3>
<p>We do not sell, trade, or otherwise transfer your personal information to third parties without your consent, except as required by law or as necessary to provide our services (e.g., with payment processors).</p>

<h3>5. Cookies</h3>
<p>Our website uses cookies to enhance user experience. Cookies are small data files stored on your device. You can choose to accept or decline cookies. Most browsers automatically accept cookies, but you can modify your browser settings to decline them if you prefer.</p>

<h3>6. Data Security</h3>
<p>We take reasonable measures to protect your personal information from unauthorized access, use, or disclosure. However, no internet-based site can be 100% secure, and we cannot be held responsible for unauthorized or unintended access beyond our control.</p>

<h3>7. Your Rights</h3>
<p>You have the right to access, update, and delete your personal information. You may also request a copy of the personal data we hold about you. If you wish to exercise these rights, please contact us at privacy@deedfinders.com.</p>

<h3>8. Changes to This Policy</h3>
<p>We may update this privacy policy from time to time. We will notify you of any changes by posting the new policy on our website. You are advised to review this policy periodically for any changes.</p>

<h3>9. Contact Us</h3>
<p>If you have any questions or concerns about this privacy policy, please contact us at:</p>
<p>Email: privacy@deedfinders.com</p>
`;

export const termsOfServiceContent = `
<h2>Terms of Service</h2>
<h3>Effective Date: Sep 06 2024</h3>

<h3>1. Acceptance of Terms</h3>
<p>By accessing and using Deed Finders, you accept and agree to be bound by these Terms of Service. If you do not agree to these terms, you may not use our services.</p>

<h3>2. Description of Service</h3>
<p>Deed Finders provides a platform to access and analyze property and lien data, facilitating informed decisions about buying properties with tax liens and tax deeds. Our services may include research tools, property listing features, user support, and more.</p>

<h3>3. User Responsibilities</h3>
<ul>
  <li><strong>Eligibility:</strong> You must be at least 18 years old to use our services.</li>
  <li><strong>Account Security:</strong> You are responsible for maintaining the confidentiality of your account information and password. You agree to notify us immediately of any unauthorized use of your account.</li>
  <li><strong>User Conduct:</strong> You agree not to use our services for any illegal or unauthorized purpose. You must not, in the use of the service, violate any laws in your jurisdiction.</li>
</ul>

<h3>4. Payments and Fees</h3>
<ul>
  <li><strong>Subscription Fees:</strong> Some services may require payment of subscription fees. These fees are subject to change, and we will notify you of any changes in advance.</li>
  <li><strong>Payment Terms:</strong> By subscribing to our services, you agree to pay the applicable fees as described on our website. All payments are due in full at the time of purchase.</li>
</ul>

<h3>5. Intellectual Property</h3>
<p>All content, trademarks, and data on Deed Finders, including but not limited to software, text, graphics, logos, and images, are the property of Deed Finders or its licensors and are protected by applicable intellectual property laws.</p>

<h3>6. Termination</h3>
<p>We reserve the right to terminate or suspend your account and access to our services, without prior notice or liability, if you breach these Terms of Service. You may also terminate your account at any time by contacting us at privacy@deedfinders.com.</p>

<h3>7. Limitation of Liability</h3>
<p>To the fullest extent permitted by law, Deed Finders shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses, resulting from your use of our services.</p>

<h3>8. Governing Law</h3>
<p>These Terms of Service shall be governed by and construed in accordance with the laws of the State of Delaware, without regard to its conflict of law principles.</p>

<h3>9. Changes to Terms</h3>
<p>We reserve the right to update or modify these Terms of Service at any time. Any changes will be effective immediately upon posting on our website. Your continued use of the service following the posting of any changes constitutes acceptance of those changes.</p>

<h3>10. Contact Information</h3>
<p>For any questions about these Terms of Service, please contact us at:</p>
<p>Email: privacy@deedfinders.com</p>
`;
