import React, { createContext, useState, useContext, useEffect } from "react";

const PublicBuyContext = createContext();

export const usePublicBuy = () => useContext(PublicBuyContext);

export const PublicBuyProvider = ({ children }) => {
  const [publicProperties, setPublicProperties] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [searchCount, setSearchCount] = useState(0);
  const [lastSearchTime, setLastSearchTime] = useState(null);
  const [resources, setResources] = useState({});
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    const storedSearchCount = localStorage.getItem("publicSearchCount");
    const storedLastSearchTime = localStorage.getItem("lastPublicSearchTime");

    if (storedSearchCount) setSearchCount(parseInt(storedSearchCount, 10));
    if (storedLastSearchTime) setLastSearchTime(new Date(storedLastSearchTime));
  }, []);

  const fetchPublicProperties = async (filters = {}, page = 1) => {
    setLoading(true);
    setError(null);

    try {
      const now = new Date();
      if (lastSearchTime && now - lastSearchTime < 24 * 60 * 60 * 1000) {
        if (searchCount >= 5) {
          throw new Error("Daily search limit reached. Please try again tomorrow.");
        }
      } else {
        setSearchCount(0);
        setLastSearchTime(now);
      }

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/public-properties?page=${page}`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(filters),
        }
      );

      if (!response.ok) throw new Error("Failed to fetch properties");

      const data = await response.json();
      setPublicProperties(data.properties);
      setTotalCount(data.totalCount);
      setCurrentPage(data.currentPage);
      setTotalPages(data.totalPages);

      setSearchCount((prevCount) => {
        const newCount = prevCount + 1;
        localStorage.setItem("publicSearchCount", newCount);
        localStorage.setItem("lastPublicSearchTime", now.toISOString());
        return newCount;
      });
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const fetchPublicPropertyDetails = async (uid) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/public-properties/${uid}`
      );

      if (!response.ok) throw new Error("Failed to fetch property details");

      const data = await response.json();
      
      // Store resources if they exist
      if (data.resources) {
        setResources(prev => ({
          ...prev,
          [data.overview.countyResourceId]: data.resources.countyResources,
          [data.overview.propertyResourceId]: data.resources.propertyResources
        }));
      }

      return data;
    } catch (err) {
      throw new Error("Failed to fetch property details");
    }
  };

  return (
    <PublicBuyContext.Provider
      value={{
        publicProperties,
        loading,
        error,
        searchCount,
        lastSearchTime,
        resources,
        totalCount,
        currentPage,
        totalPages,
        fetchPublicProperties,
        fetchPublicPropertyDetails
      }}
    >
      {children}
    </PublicBuyContext.Provider>
  );
};
