// OverviewTab.jsx

import React from "react";
import { useTranslation } from "react-i18next";
import { Hash, DollarSign, Tag, Briefcase, Calendar, Home } from "react-feather";
import { formatToUSD } from "../../../utils/helper";
import CopyToClipboard from "../../common/CopyToClipboard";
import { TabDetails, ScrollableContent, DetailsGrid as BaseDetailsGrid, DetailItem as BaseDetailItem, DetailIcon as BaseDetailIcon, DetailContent, DetailLabel as BaseDetailLabel, DetailValue as BaseDetailValue } from "./TabStyles";
import styled from "styled-components";
import { LoadingText } from "../../common/LoadingAnimation";
import Tippy from "@tippyjs/react";

// Styled Components
const DetailsGrid = styled(BaseDetailsGrid)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
`;

const DetailItem = styled(BaseDetailItem)`
  background-color: #1a1a1a; // Updated to darker background
  padding: 10px;
  border-radius: 4px;
  display: flex;
  align-items: center;
`;

const DetailIcon = styled(BaseDetailIcon)`
  color: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px; // Fixed width for alignment
  margin-right: 10px;
`;

const DetailLabel = styled(BaseDetailLabel)`
  color: #a0a0a0;
  font-size: 12px;
`;

const DetailValue = styled(BaseDetailValue)`
  color: #ffffff;
  font-size: 14px;
`;

// Helper Functions
const formatResponse = (response) => {
  const linkRegex = /<a\s+(?:[^>]*?\s+)?href="([^"]*)"[^>]*>(.*?)<\/a>/g;
  return response.replace(linkRegex, (match, url, text) => {
    return `<a href="${url}" target="_blank" rel="noopener noreferrer" class="custom-link">${text}</a>`;
  });
};

const formatDate = (dateString) => {
  if (!dateString) return null;
  const date = new Date(dateString);
  return date.toLocaleDateString();
};

// OverviewTab Component
const OverviewTab = ({ property, isTranslating }) => {
  const { t } = useTranslation();

  // Get overview from property
  const overview = property?.overview;

  if (!overview) {
    return <div>{t("noOverviewAvailable")}</div>;
  }

  const propertyDetailsFormatted = [
    {
      icon: <Hash size={18} />,
      label: t("parcelNumber"),
      value: overview.account_nbr || t("notAvailable"),
      copyable: true,
    },
    {
      icon: <Hash size={18} />,
      label: t("address"),
      value: overview.address_full || t("notAvailable"),
      copyable: true,
    },
    {
      icon: <Hash size={18} />,
      label: t("county"),
      value: overview.county || t("notAvailable"),
      copyable: true,
    },
    {
      icon: <Hash size={18} />,
      label: t("zipCode"),
      value: overview.zipCode || t("notAvailable"),
      copyable: true,
    },
    {
      icon: <Home size={18} />,
      label: t("propertyType"),
      value: overview.prop_type || t("notAvailable"),
      copyable: false,
    },
    {
      icon: <DollarSign size={18} />,
      label: t("minimumBid"),
      value: overview.minimum_bid
        ? formatToUSD(overview.minimum_bid)
        : t("notAvailable"),
      copyable: false,
    },
    {
      icon: <DollarSign size={18} />,
      label: t("adjudgedValue"),
      value: overview.value ? formatToUSD(overview.value) : t("notAvailable"),
      copyable: false,
    },
    {
      icon: <Tag size={18} />,
      label: t("status"),
      value: overview.status || t("notAvailable"),
      copyable: false,
    },
    {
      icon: <Briefcase size={18} />,
      label: t("saleType"),
      value: overview.sale_type || t("notAvailable"),
      copyable: false,
    },
    {
      icon: <Calendar size={18} />,
      label: t("saleDate"),
      value: overview.sale_date_only
        ? formatDate(overview.sale_date_only)
        : t("notAvailable"),
      copyable: false,
    },
  ];

  const renderValue = (value) => {
    if (isTranslating) {
      return <LoadingText>{value}</LoadingText>;
    }
    return (
      <span
        dangerouslySetInnerHTML={{
          __html: formatResponse(value.toString()),
        }}
      />
    );
  };

  return (
    <TabDetails>
      <ScrollableContent>
        <DetailsGrid>
          {propertyDetailsFormatted.map((detail, index) => (
            <DetailItem key={index}>
              <DetailIcon>{detail.icon}</DetailIcon>
              <DetailContent>
                <DetailLabel>{detail.label}</DetailLabel>
                <DetailValue>
                  {renderValue(detail.value)}
                </DetailValue>
              </DetailContent>
              {detail.copyable && (
                <Tippy
                  content={t("copyToClipboard")}
                  animation="shift-away"
                  placement="top"
                  arrow={true}
                  duration={[300, 250]}
                  delay={[400, 0]}
                  theme="modern"
                >
                  <CopyToClipboard text={detail.value} />
                </Tippy>
              )}
            </DetailItem>
          ))}
        </DetailsGrid>
      </ScrollableContent>
    </TabDetails>
  );
};

export default OverviewTab;
