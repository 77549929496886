import React, { useState, useRef, useContext, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Filter, Heart, FileText, Check, MessageCircle } from 'react-feather';
import styled from 'styled-components';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/animations/shift-away.css';
import { useTranslation } from 'react-i18next';
import MiniChat from '../../common/MiniChat';
import { io } from 'socket.io-client';
import { AuthContext } from '../../../context/AuthContext';

// Styled Components with Jony Ive-inspired design
const FloatingButtonsContainer = styled(motion.div)`
  position: fixed;
  bottom: 30px;
  right: 30px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  z-index: 10001;
  pointer-events: none;
  transition: transform 0.4s cubic-bezier(0.16, 1, 0.3, 1);
  transform: ${props => props.$isDetailedOpen ? 'translateX(calc(100% + 30px))' : 'translateX(0)'};

  & > * {
    pointer-events: auto;
  }
`;

const FloatingButton = styled(motion.button)`
  width: 56px;
  height: 56px;
  border-radius: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
  background: linear-gradient(
    165deg,
    rgba(28, 28, 35, 0.95),
    rgba(18, 18, 24, 0.95)
  );
  box-shadow: 
    0 2px 20px rgba(0, 0, 0, 0.3),
    0 0 0 1px rgba(255, 255, 255, 0.1) inset;
  backdrop-filter: blur(20px);
  transition: all 0.4s cubic-bezier(0.16, 1, 0.3, 1);
  position: relative;

  &::before {
    content: '';
    position: absolute;
    inset: 1px;
    background: linear-gradient(
      145deg,
      rgba(255, 255, 255, 0.1),
      transparent
    );
    border-radius: inherit;
    opacity: 0.5;
    transition: opacity 0.4s ease;
  }

  svg {
    color: #8853fb;
    width: 22px;
    height: 22px;
    stroke-width: 2px;
    filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.2));
    transition: all 0.3s cubic-bezier(0.16, 1, 0.3, 1);
  }

  &:hover {
    transform: translateY(-2px) scale(1.05);
    background: linear-gradient(
      165deg,
      rgba(38, 38, 45, 0.95),
      rgba(28, 28, 35, 0.95)
    );
    box-shadow: 
      0 8px 32px rgba(0, 0, 0, 0.4),
      0 0 0 1px rgba(255, 255, 255, 0.15) inset;

    &::before {
      opacity: 1;
    }

    svg {
      color: #9c6eff;
      transform: scale(1.1);
    }
  }

  &:active {
    transform: translateY(1px) scale(0.95);
  }

  &.active {
    background: linear-gradient(
      165deg,
      rgba(136, 83, 251, 0.15),
      rgba(96, 57, 176, 0.15)
    );
    border: 1px solid rgba(136, 83, 251, 0.3);

    svg {
      color: #8853fb;
    }

    &:hover {
      background: linear-gradient(
        165deg,
        rgba(136, 83, 251, 0.2),
        rgba(96, 57, 176, 0.2)
      );
      border-color: rgba(136, 83, 251, 0.4);
    }
  }
`;

const FavoritesDropdown = styled(motion.div)`
  position: absolute;
  right: calc(100% + 16px);
  top: 0;
  background: linear-gradient(
    165deg,
    rgba(28, 28, 35, 0.95),
    rgba(18, 18, 24, 0.95)
  );
  backdrop-filter: blur(40px);
  border-radius: 16px;
  padding: 12px;
  width: 240px;
  border: 1px solid rgba(255, 255, 255, 0.07);
  box-shadow: 
    0 0 0 1px rgba(255, 255, 255, 0.05),
    0 20px 50px -12px rgba(0, 0, 0, 0.5),
    0 0 100px rgba(136, 83, 251, 0.1);
`;

const FolderItem = styled(motion.button)`
  width: 100%;
  padding: 10px 12px;
  background: none;
  border: none;
  color: rgba(255, 255, 255, 0.9);
  font-size: 14px;
  text-align: left;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.16, 1, 0.3, 1);

  &:hover {
    background: rgba(136, 83, 251, 0.1);
    color: #8853fb;
  }

  svg {
    width: 16px;
    height: 16px;
    color: #8853fb;
  }
`;

const UnreadBadge = styled(motion.div)`
  position: absolute;
  top: -6px;
  right: -6px;
  min-width: 18px;
  height: 18px;
  padding: 0 6px;
  background: linear-gradient(165deg, #8853fb, #6039b0);
  color: white;
  font-size: 11px;
  font-weight: 500;
  border-radius: 9px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 6px rgba(136, 83, 251, 0.3);
  z-index: 1;
`;

const FloatingActionButtons = ({ 
  onFilterClick, 
  onFavoritesClick, 
  onNotesClick,
  isFilterOpen,
  isNotesOpen,
  hasFavorites,
  folders = [],
  isPublic = false,
  isDetailedOpen = false
}) => {
  const { t } = useTranslation();
  const [showFavoritesDropdown, setShowFavoritesDropdown] = useState(false);
  const [isMiniChatOpen, setIsMiniChatOpen] = useState(false);
  const [unreadCount, setUnreadCount] = useState(0);
  const socket = useRef(null);
  const { user } = useContext(AuthContext);

  useEffect(() => {
    const fetchUnreadCount = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/chat/unread-count`,
          {
            credentials: "include",
          }
        );
        if (response.ok) {
          const { totalUnread } = await response.json();
          setUnreadCount(totalUnread);
        }
      } catch (error) {
        console.error("Error fetching unread count:", error);
      }
    };

    fetchUnreadCount();

    socket.current = io(process.env.REACT_APP_API_URL, {
      withCredentials: true,
      transports: ["websocket"],
      path: '/socket.io/',
      reconnectionAttempts: 5,
      reconnectionDelay: 1000,
      auth: {
        token: document.cookie
      },
      extraHeaders: {
        "Cookie": document.cookie
      },
      autoConnect: false
    });

    socket.current.on('connect_error', (error) => {
      console.error('Socket connection error details:', error.message);
    });

    socket.current.connect();

    socket.current.on('connect', () => {
      console.log('Socket connected successfully');
      if (user?._id) {
        socket.current.emit('user connected', user._id);
      }
    });

    socket.current.on('message received', () => {
      setUnreadCount(prev => prev + 1);
    });

    socket.current.on('update_unread_count', ({ totalUnread }) => {
      setUnreadCount(totalUnread);
    });

    return () => {
      if (socket.current) {
        socket.current.disconnect();
      }
    };
  }, [user]);

  useEffect(() => {
    const handleSocketError = (error) => {
      console.error('Socket error:', error);
    };

    const handleSocketConnect = () => {
      console.log('Socket connected successfully');
    };

    if (socket.current) {
      socket.current.on('error', handleSocketError);
      socket.current.on('connect', handleSocketConnect);
      
      return () => {
        socket.current.off('error', handleSocketError);
        socket.current.off('connect', handleSocketConnect);
        socket.current.disconnect();
      };
    }
  }, []);

  const handleFavoritesClick = (e) => {
    e.stopPropagation();
    if (isFilterOpen) onFilterClick();
    if (isNotesOpen) onNotesClick();
    setShowFavoritesDropdown(!showFavoritesDropdown);
  };

  const handleFolderSelect = (folder = null) => {
    if (folder) {
      onFavoritesClick([folder]);
    } else {
      onFavoritesClick(folders);
    }
    setShowFavoritesDropdown(false);
  };

  const handleNotesClick = () => {
    if (isFilterOpen) onFilterClick();
    if (showFavoritesDropdown) setShowFavoritesDropdown(false);
    if (isNotesOpen) {
      onNotesClick(false);
    } else {
      onNotesClick(true);
    }
  };

  const handleFilterClick = () => {
    if (isNotesOpen) onNotesClick();
    if (showFavoritesDropdown) setShowFavoritesDropdown(false);
    onFilterClick();
  };

  React.useEffect(() => {
    const handleClickOutside = () => {
      if (showFavoritesDropdown) {
        setShowFavoritesDropdown(false);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, [showFavoritesDropdown]);

  const tippyProps = {
    animation: "shift-away",
    placement: "left",
    arrow: true,
    duration: [300, 250],
    delay: [400, 0],
    theme: "modern"
  };

  if (isPublic) {
    return (
      <FloatingButtonsContainer $isDetailedOpen={isDetailedOpen}>
        <Tippy content={t('filterProperties')} {...tippyProps}>
          <FloatingButton
            onClick={handleFilterClick}
            className={isFilterOpen ? 'active' : ''}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            {isFilterOpen ? <Check size={24} /> : <Filter size={24} />}
          </FloatingButton>
        </Tippy>
      </FloatingButtonsContainer>
    );
  }

  return (
    <FloatingButtonsContainer $isDetailedOpen={isDetailedOpen}>
      <div style={{ position: 'relative' }}>
        <Tippy content={t('chat')} {...tippyProps}>
          <FloatingButton
            onClick={() => {
              setIsMiniChatOpen(!isMiniChatOpen);
              if (!isMiniChatOpen) {
                setUnreadCount(0);
              }
            }}
            className={isMiniChatOpen ? 'active' : ''}
          >
            <MessageCircle size={24} />
            <AnimatePresence>
              {!isMiniChatOpen && unreadCount > 0 && (
                <UnreadBadge
                  initial={{ scale: 0 }}
                  animate={{ scale: 1 }}
                  exit={{ scale: 0 }}
                  transition={{
                    type: "spring",
                    stiffness: 400,
                    damping: 25
                  }}
                >
                  {unreadCount}
                </UnreadBadge>
              )}
            </AnimatePresence>
          </FloatingButton>
        </Tippy>
        <MiniChat 
          isOpen={isMiniChatOpen} 
          onClose={() => setIsMiniChatOpen(false)} 
        />
      </div>

      <div style={{ position: 'relative' }}>
        <Tippy content={t('favorites')} {...tippyProps}>
          <FloatingButton
            onClick={handleFavoritesClick}
            className={hasFavorites ? 'active' : ''}
          >
            <Heart size={24} />
          </FloatingButton>
        </Tippy>

        <AnimatePresence>
          {showFavoritesDropdown && (
            <FavoritesDropdown
              initial={{ opacity: 0, x: 20 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: 20 }}
              onClick={(e) => e.stopPropagation()}
            >
              <FolderItem
                onClick={() => handleFolderSelect()}
                whileHover={{ x: 4 }}
              >
                <Heart size={16} />
                {t('allFavorites')}
              </FolderItem>
              {folders.map(folder => (
                <FolderItem
                  key={folder.id}
                  onClick={() => handleFolderSelect(folder)}
                  whileHover={{ x: 4 }}
                >
                  <Heart size={16} />
                  {folder.name}
                </FolderItem>
              ))}
            </FavoritesDropdown>
          )}
        </AnimatePresence>
      </div>

      <Tippy content={t('notes')} {...tippyProps}>
        <FloatingButton 
          onClick={handleNotesClick}
          className={isNotesOpen ? 'active' : ''}
        >
          <FileText size={24} />
        </FloatingButton>
      </Tippy>

      <Tippy content={t('filterProperties')} {...tippyProps}>
        <FloatingButton
          onClick={handleFilterClick}
          className={isFilterOpen ? 'active' : ''}
        >
          {isFilterOpen ? <Check size={24} /> : <Filter size={24} />}
        </FloatingButton>
      </Tippy>
    </FloatingButtonsContainer>
  );
};

export default FloatingActionButtons; 