// frontend/src/context/AuthContext.js

import React, { createContext, useState, useEffect } from "react";
import { initI18n } from "../i18n"; // Ensure this path is correct

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  // State variables to manage user data, loading status, and errors
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch user information when the component mounts
  useEffect(() => {
    fetchUserInfo();
  }, []);

  /**
   * Fetches the authenticated user's information from the server.
   * Assumes that the server uses HttpOnly cookies for authentication.
   */
  const fetchUserInfo = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/auth/user`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include", // Ensures cookies are sent with the request
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch user info");
      }

      const data = await response.json();
      setUser(data);
      initI18n(data.preferredLanguage || "en"); // Initialize i18n with user's preferred language
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  /**
   * Logs in the user by sending credentials to the server.
   * @param {Object} credentials - Contains email and password.
   * @returns {Object} - An object indicating success or failure.
   */
  const login = async (credentials) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/auth/login`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include", // Important for cookies
          body: JSON.stringify(credentials),
        }
      );

      const data = await response.json();

      if (response.ok) {
        setUser(data.user);
        initI18n(data.user.preferredLanguage || "en");
        return { success: true };
      } else {
        throw new Error(data.msg || "An error occurred during sign-in");
      }
    } catch (error) {
      console.error("Login error:", error);
      return { success: false, error: error.message };
    }
  };

  /**
   * Registers a new user by sending user details to the server.
   * @param {Object} userData - Contains name, email, and password.
   * @returns {Object} - An object indicating success or failure.
   */
  const register = async (userData) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/auth/register`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include", // Ensures cookies are sent with the request
          body: JSON.stringify(userData),
        }
      );

      const data = await response.json();

      if (response.ok) {
        setUser(data.user); // Assuming the server returns the user data upon successful registration
        initI18n(data.user.preferredLanguage || "en");
        return { success: true };
      } else {
        throw new Error(data.msg || "Registration failed");
      }
    } catch (error) {
      console.error("Registration error:", error);
      return { success: false, error: error.message };
    }
  };

  /**
   * Logs out the user by informing the server to invalidate the session/token.
   * @returns {Object} - An object indicating success or failure.
   */
  const logout = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/auth/logout`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include", // Ensures cookies are sent with the request
        }
      );

      if (response.ok) {
        setUser(null);
        return { success: true };
      } else {
        const data = await response.json();
        throw new Error(data.msg || "Failed to logout");
      }
    } catch (error) {
      console.error("Logout error:", error);
      return { success: false, error: error.message };
    }
  };

  /**
   * Updates the authenticated user's data.
   * @param {Object} userData - The user data to update.
   * @returns {Object} - An object indicating success or failure.
   */
  const updateUserData = async (userData) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/auth/update-user-data`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include", // Ensures cookies are sent with the request
          body: JSON.stringify(userData),
        }
      );

      if (response.ok) {
        const updatedUser = await response.json();
        setUser(updatedUser);
        if (updatedUser.preferredLanguage) {
          initI18n(updatedUser.preferredLanguage);
        }
        return { success: true };
      } else {
        const errorData = await response.json();
        throw new Error(errorData.msg || "Failed to update settings");
      }
    } catch (error) {
      console.error("Error updating settings:", error);
      return { success: false, error: error.message };
    }
  };

  /**
   * Allows the authenticated user to change their password.
   * @param {Object} passwordData - Contains currentPassword and newPassword.
   * @returns {Object} - An object indicating success or failure.
   */
  const changePassword = async (passwordData) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/auth/change-password`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            // If implementing CSRF protection, include the CSRF token here
            // "CSRF-Token": csrfToken,
          },
          credentials: "include", // Ensures cookies are sent with the request
          body: JSON.stringify({
            currentPassword: passwordData.currentPassword,
            newPassword: passwordData.newPassword,
          }),
        }
      );

      if (response.ok) {
        // Optionally, you can refetch user info or update state
        // const updatedUser = await response.json();
        // setUser(updatedUser);
        return { success: true };
      } else {
        const errorData = await response.json();
        throw new Error(errorData.msg || "Failed to change password");
      }
    } catch (error) {
      console.error("Error changing password:", error);
      return { success: false, error: error.message };
    }
  };

  /**
   * Sets up Two-Factor Authentication (2FA) for the authenticated user.
   * @returns {Object} - Contains success status and QR code data if successful.
   */
  const setupTwoFactorAuth = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/two-factor/setup`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            // "CSRF-Token": csrfToken, // Include if implementing CSRF protection
          },
          credentials: "include", // Ensures cookies are sent with the request
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.msg || "Failed to set up 2FA");
      }

      const data = await response.json();
      return { success: true, qrCode: data.qrCode };
    } catch (error) {
      console.error("Error setting up 2FA:", error);
      return { success: false, error: error.message };
    }
  };

  /**
   * Verifies the Two-Factor Authentication (2FA) code entered by the user.
   * @param {string} verificationCode - The 2FA code provided by the user.
   * @returns {Object} - An object indicating success or failure.
   */
  const verifyTwoFactorAuth = async (verificationCode) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/two-factor/verify`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            // "CSRF-Token": csrfToken, // Include if implementing CSRF protection
          },
          credentials: "include", // Ensures cookies are sent with the request
          body: JSON.stringify({ code: verificationCode }),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.msg || "2FA verification failed");
      }

      const result = await response.json();
      if (result.success) {
        // Update the user state to reflect 2FA being enabled
        setUser((prevUser) => ({
          ...prevUser,
          twoFactorEnabled: true,
        }));
        return { success: true };
      } else {
        throw new Error("Failed to verify 2FA");
      }
    } catch (error) {
      console.error("Error verifying 2FA:", error);
      return { success: false, error: error.message };
    }
  };

  /**
   * Validates the user's current password.
   * Useful for sensitive operations like changing the password.
   * @param {string} currentPassword - The current password of the user.
   * @returns {Object} - An object indicating success or failure.
   */
  const validateCurrentPassword = async (currentPassword) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/auth/validate-password`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            // "CSRF-Token": csrfToken, // Include if implementing CSRF protection
          },
          credentials: "include", // Ensures cookies are sent with the request
          body: JSON.stringify({ currentPassword }),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.msg || "Failed to validate current password");
      }

      return { success: true };
    } catch (error) {
      console.error("Error validating current password:", error);
      return { success: false, error: error.message };
    }
  };

  // You can add more authentication-related functions here as needed

  return (
    <AuthContext.Provider
      value={{
        user,
        loading,
        error,
        login,
        register,
        logout,
        updateUserData,
        changePassword,
        setupTwoFactorAuth,
        verifyTwoFactorAuth,
        validateCurrentPassword,
        // Include other functions as needed
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
