import React, { useState, useEffect, useContext, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import DataTable from "../../common/DataTable";
import Pagination from "../../common/Pagination";
import DetailedPropertyInfo from "./DetailedPropertyInfo";
import PropertyDetails from "./PropertyDetails";
import "../../../assets/styles/Buy.css";
import fetchFilteredData from "../../../hooks/fetchFilteredData";
import fetchExpandedData from "../../../hooks/fetchExpandedData";
import { FavoritesContext } from "../../../context/FavoritesContext";
import { motion } from "framer-motion";
import LoadingOverlay from "../../common/LoadingOverlay";
import { useTranslation } from "react-i18next";
import Toast from "../../common/Toast";
import NotesModal from "../../modals/NotesModal";
import { NotesContext } from "../../../context/NotesContext";
import ModernFilterSystem from "./ModernFilterSystem";
import FloatingActionButtons from "./FloatingActionButtons";

const Buy = () => {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const { fetchPropertiesByUIDs, folders } = useContext(FavoritesContext);
  const { fetchAllUserNotes } = useContext(NotesContext);

  const [displayData, setDisplayData] = useState([]);
  const [sortConfig, setSortConfig] = useState({
    key: "account_nbr",
    direction: "ascending",
  });
  const [expandedData, setExpandedData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(18);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [selectedCoordinates, setSelectedCoordinates] = useState(null);
  const [activeMap, setActiveMap] = useState("Google");
  const [filters, setFilters] = useState(() => {
    const searchParams = new URLSearchParams(location.search);
    const savedFilters = JSON.parse(searchParams.get("filters") || "{}");

    return {
      state: Array.isArray(savedFilters?.StateOrProvince)
        ? savedFilters.StateOrProvince
        : [],
      county: Array.isArray(savedFilters?.County) ? savedFilters.County : [],
      city: Array.isArray(savedFilters?.City) ? savedFilters.City : [],
      saleType: Array.isArray(savedFilters?.SaleType)
        ? savedFilters.SaleType
        : [],
      propType: Array.isArray(savedFilters?.PropType)
        ? savedFilters.PropType
        : [],
      status: Array.isArray(savedFilters?.Status) ? savedFilters.Status : [],
      adjudgedValueRange: Array.isArray(savedFilters?.AdjudgedValue)
        ? savedFilters.AdjudgedValue
        : [1, 50000000],
      minimumBidRange: Array.isArray(savedFilters?.MinimumBid)
        ? savedFilters.MinimumBid
        : [1, 10000000],
      zipCode: savedFilters?.zipCode || "",
      parcelNumber: savedFilters?.parcelNumber || "",
    };
  });
  const [initialFetchDone, setInitialFetchDone] = useState(false);
  const [totalItems, setTotalItems] = useState(0);

  const [isFavoritesSelected, setIsFavoritesSelected] = useState(false);
  const [favoriteProperties, setFavoriteProperties] = useState([]);

  const [loadingDetails, setLoadingDetails] = useState(false);
  const [toast, setToast] = useState(null);

  const [isNotesModalOpen, setIsNotesModalOpen] = useState(false);

  const [wasNotesModalOpen, setWasNotesModalOpen] = useState(false);

  const [currentFilters, setCurrentFilters] = useState({});

  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const [isFilterOpen, setIsFilterOpen] = useState(true);

  const [isAnyModalOpen, setIsAnyModalOpen] = useState(false);

  const loadingMessageLayers = [
    t("loadingMessages.layer1", { returnObjects: true }),
    t("loadingMessages.layer2", { returnObjects: true }),
    t("loadingMessages.layer3", { returnObjects: true }),
    t("loadingMessages.layer4", { returnObjects: true }),
  ];

  const [searchState, setSearchState] = useState(() => {
    const searchParams = new URLSearchParams(location.search);
    return {
      filters: JSON.parse(searchParams.get("filters") || "{}"),
      page: parseInt(searchParams.get("page") || "1", 10),
      sort: JSON.parse(searchParams.get("sort") || "null"),
    };
  });

  const fetchPageData = useCallback(
    async (page, currentFilters, currentSort) => {
      if (loading) return;

      setLoading(true);
      try {
        const filtersToUse =
          Object.keys(currentFilters).length === 0 ? {} : currentFilters;

        const data = await fetchFilteredData(
          filtersToUse,
          page,
          itemsPerPage,
          currentSort
        );

        setDisplayData(data.properties);
        setTotalItems(data.totalCount);
      } catch (err) {
        setError(t("failedToFetchData"));
        console.error(err);
      } finally {
        setLoading(false);
      }
    },
    [itemsPerPage, t, loading]
  );

  const paginateProperties = (properties, page, itemsPerPage) => {
    const startIndex = (page - 1) * itemsPerPage;
    return properties.slice(startIndex, startIndex + itemsPerPage);
  };

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const searchParams = new URLSearchParams(location.search);
        const savedFilters = JSON.parse(searchParams.get("filters") || "{}");
        const savedPage = parseInt(searchParams.get("page") || "1", 10);
        const savedSort = JSON.parse(searchParams.get("sort") || "null");
        const propertyId = searchParams.get("property");

        setFilters(savedFilters);
        setCurrentPage(savedPage);
        if (savedSort) setSortConfig(savedSort);

        const filtersToUse =
          Object.keys(savedFilters).length === 0 ? {} : savedFilters;
        const initialData = await fetchFilteredData(
          filtersToUse,
          savedPage,
          itemsPerPage,
          savedSort || sortConfig
        );

        setDisplayData(initialData.properties || []);
        setTotalItems(initialData.totalCount || 0);

        if (propertyId) {
          const sharedProperty = initialData.properties.find(
            (item) => item.uid === parseInt(propertyId, 10)
          );
          if (sharedProperty) {
            handlePropertyDetails(sharedProperty);
          } else {
            const expandedData = await fetchExpandedData(propertyId);
            if (expandedData) {
              handlePropertyDetails(expandedData.overview);
            }
          }
        }
      } catch (err) {
        setError(t("failedToFetchInitialData"));
        console.error(err);
      } finally {
        setLoading(false);
        setInitialFetchDone(true);
      }
    };

    fetchInitialData();
  }, [location.search]);

  useEffect(() => {
    if (initialFetchDone && isFavoritesSelected) {
      if (favoriteProperties.length > 0) {
        const paginatedFavorites = paginateProperties(
          favoriteProperties,
          currentPage,
          itemsPerPage
        );
        setDisplayData(paginatedFavorites);
        setTotalItems(favoriteProperties.length);
      } else {
        setDisplayData([]);
        setTotalItems(0);
      }
    }
  }, [
    favoriteProperties,
    currentPage,
    itemsPerPage,
    initialFetchDone,
    isFavoritesSelected,
  ]);

  const updateURL = (newFilters, newPage, newSort) => {
    const searchParams = new URLSearchParams();
    searchParams.set("filters", JSON.stringify(newFilters));
    searchParams.set("page", newPage.toString());
    if (newSort) searchParams.set("sort", JSON.stringify(newSort));

    setSearchState({
      filters: newFilters,
      page: newPage,
      sort: newSort,
    });

    navigate(`?${searchParams.toString()}`, { replace: true });
  };

  const handleApplyFilters = async (newFilters) => {
    if (JSON.stringify(filters) === JSON.stringify(newFilters)) return;

    setLoading(true);
    setError(null);
    setIsFavoritesSelected(false);

    try {
      const data = await fetchFilteredData(
        newFilters,
        1,
        itemsPerPage,
        sortConfig
      );

      setDisplayData(data.properties || []);
      setTotalItems(data.totalCount || 0);
      setCurrentPage(1);
      setFilters(newFilters);
      updateURL(newFilters, 1, sortConfig);
    } catch (err) {
      console.error("Filter error:", err);
      setError(t("failedToFetchData"));
    } finally {
      setLoading(false);
    }
  };

  const handleFavoriteSelection = async (selectedFolders = []) => {
    if (!initialFetchDone) {
      return;
    }

    setError(null);

    try {
      const foldersToUse =
        selectedFolders.length > 0 ? selectedFolders : folders;

      const uidsToFetch = foldersToUse.flatMap(
        (folder) => folder.properties || []
      );

      if (uidsToFetch.length === 0) {
        setIsFavoritesSelected(false);
        setFavoriteProperties([]);
        setCurrentPage(1);
        fetchPageData(1, filters, sortConfig);
        updateURL(filters, 1, sortConfig);
        return;
      }

      const properties = await fetchPropertiesByUIDs(uidsToFetch);
      setFavoriteProperties(properties || []);
      setIsFavoritesSelected(true);
      setCurrentPage(1);
      setTotalItems(properties.length || 0);
      updateURL(filters, 1, sortConfig);
    } catch (err) {
      setError(t("failedToFetchFavoriteProperties"));
      console.error(err);
    }
  };

  const handleSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    const newSortConfig = { key, direction };
    setSortConfig(newSortConfig);
    setCurrentPage(1);
    updateURL(filters, 1, newSortConfig);

    if (isFavoritesSelected) {
      const sortedFavorites = [...favoriteProperties].sort((a, b) => {
        if (a[key] < b[key]) {
          return direction === "ascending" ? -1 : 1;
        }
        if (a[key] > b[key]) {
          return direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
      setFavoriteProperties(sortedFavorites);
    }
  };

  const handlePropertyDetails = async (item) => {
    setLoadingDetails(true);
    try {
      const expandedData = await fetchExpandedData(item.uid);
      setExpandedData(expandedData);
      setSelectedCoordinates({
        lat: expandedData.overview.geometry.coordinates[1],
        lng: expandedData.overview.geometry.coordinates[0],
      });
      setActiveMap("Google");
      setIsModalOpen(true);
    } catch (err) {
      console.error(t("failedToFetchExpandedData"), err);
      setToast({ message: t("failedToFetchDetails"), type: "error" });
    } finally {
      setLoadingDetails(false);
    }
  };

  const handleRowClick = async (relativeIndex) => {
    const item = displayData[relativeIndex];
    if (item) {
      await handlePropertyDetails(item);
    } else {
      console.warn("Clicked item is undefined:", relativeIndex);
      setToast({ message: t("invalidProperty"), type: "error" });
    }
  };

  const closeModal = async () => {
    setIsModalOpen(false);
    setExpandedData(null);
    setSelectedCoordinates(null);

    if (wasNotesModalOpen) {
      await fetchAllUserNotes();
      setIsNotesModalOpen(true);
      setWasNotesModalOpen(false);
    }
  };

  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const handlePageChange = useCallback(
    (newPage) => {
      if (newPage < 1 || newPage > totalPages || loading) return;

      updateURL(filters, newPage, sortConfig);
      setCurrentPage(newPage);

      fetchPageData(newPage, filters, sortConfig);
    },
    [totalPages, loading, filters, sortConfig, fetchPageData]
  );
  const headers = [
    { key: "account_nbr", label: t("propertyIdentifier") },
    { key: "city", label: t("city") },
    { key: "county", label: t("county") },
    { key: "state", label: t("state") },
    { key: "zipCode", label: t("zipCode") },
    { key: "minimum_bid", label: t("minimumBid"), format: "USD" },
    { key: "value", label: t("adjudgedValue"), format: "USD" },
    {
      key: "status",
      label: t("status"),
      format: (value) => t(value.toLowerCase().replace(/\s+/g, "")),
    },
    {
      key: "sale_type",
      label: t("saleType"),
      format: (value) => t(value.toLowerCase().replace(/\s+/g, "")),
    },
  ];

  const handleFetchAllNotes = async () => {
    await fetchAllUserNotes();
    setIsNotesModalOpen(true);
    setWasNotesModalOpen(true);
  };

  const handleViewPropertyFromNotes = async (propertyId, tabIndex = 0) => {
    setWasNotesModalOpen(true);
    setIsNotesModalOpen(false);

    setLoadingDetails(true);
    try {
      const expandedData = await fetchExpandedData(propertyId);
      setExpandedData(expandedData);
      setSelectedCoordinates({
        lat: expandedData.overview.geometry.coordinates[1],
        lng: expandedData.overview.geometry.coordinates[0],
      });
      setActiveMap("Google");
      setIsModalOpen(true);
      setTabIndex(tabIndex);
      setSelectedTabIndex(tabIndex);
    } catch (err) {
      console.error(t("failedToFetchExpandedData"), err);
      setToast({ message: t("failedToFetchDetails"), type: "error" });
    } finally {
      setLoadingDetails(false);
    }
  };

  const hasFavorites = folders?.some((folder) => folder.properties?.length > 0);

  const handleTabChange = (newTabIndex) => {
    setTabIndex(newTabIndex);
  };

  const handleModalOpen = () => setIsAnyModalOpen(true);
  const handleModalClose = () => setIsAnyModalOpen(false);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="buy-content"
    >
      {loading && !initialFetchDone ? (
        <LoadingOverlay messageLayers={loadingMessageLayers} />
      ) : (
        <div className="buy-layout">
          <div className="filter-area">
            <ModernFilterSystem
              onApplyFilters={handleApplyFilters}
              initialFilters={currentFilters}
              onSelectFavorites={handleFavoriteSelection}
              onFetchNotes={handleFetchAllNotes}
              hasFavorites={hasFavorites}
              isOpen={isFilterOpen}
              setIsOpen={setIsFilterOpen}
            />
          </div>

          <div className="results-area">
            <div className="results-container">
              {error && <p className="error-message">{error}</p>}
              {!loading && !error && (
                <>
                  <DataTable
                    headers={headers}
                    items={displayData}
                    sortConfig={sortConfig}
                    onSort={handleSort}
                    onRowClick={handleRowClick}
                    loading={loading}
                    key={`${currentPage}-${sortConfig.key}-${sortConfig.direction}`}
                  />
                  <div className="pagination-container">
                    <Pagination
                      totalPages={totalPages}
                      currentPage={currentPage}
                      onPageChange={handlePageChange}
                    />
                    <motion.div
                      className="page-info"
                      initial={{ opacity: 0, y: 10 }}
                      animate={{ opacity: 1, y: 0 }}
                      key={`${currentPage}-${totalPages}`}
                    >
                      <span className="current-page">{currentPage}</span>
                      <span className="page-divider">/</span>
                      <span className="total-pages">{totalPages}</span>
                    </motion.div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      )}

      <DetailedPropertyInfo
        isOpen={isModalOpen}
        onOpen={handleModalOpen}
        onClose={() => {
          handleModalClose();
          closeModal();
        }}
        mapCenter={selectedCoordinates}
        activeMap={activeMap}
        expandedData={expandedData}
        setExpandedData={setExpandedData}
        setActiveMap={setActiveMap}
        tabIndex={tabIndex}
        setTabIndex={handleTabChange}
      >
        {expandedData && (
          <PropertyDetails
            property={expandedData}
            tabIndex={tabIndex}
            setTabIndex={handleTabChange}
          />
        )}
      </DetailedPropertyInfo>
      <NotesModal
        isOpen={isNotesModalOpen}
        onOpen={handleModalOpen}
        onClose={() => {
          handleModalClose();
          setIsNotesModalOpen(false);
        }}
        onViewProperty={handleViewPropertyFromNotes}
      />
      {!isAnyModalOpen && (
        <FloatingActionButtons
          onFilterClick={() => setIsFilterOpen(!isFilterOpen)}
          onFavoritesClick={handleFavoriteSelection}
          onNotesClick={handleFetchAllNotes}
          onApplyFilters={handleApplyFilters}
          isFilterOpen={isFilterOpen}
          hasFavorites={hasFavorites}
          folders={folders}
          isDetailedOpen={isModalOpen}
        />
      )}
      {(loading || loadingDetails) && (
        <LoadingOverlay messageLayers={loadingMessageLayers} />
      )}
      {toast && (
        <Toast
          message={toast.message}
          type={toast.type}
          duration={3000}
          onClose={() => setToast(null)}
        />
      )}
    </motion.div>
  );
};

export default Buy;
