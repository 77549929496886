import styled from 'styled-components';
import { motion } from 'framer-motion';

export const FilterOrb = styled(motion.div)`
  position: fixed;
  bottom: 30px;
  right: 30px;
  width: 56px;
  height: 56px;
  background: linear-gradient(
    145deg,
    rgba(136, 83, 251, 0.95),
    rgba(96, 57, 176, 0.95)
  );
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 
    0 2px 20px rgba(136, 83, 251, 0.3),
    0 0 0 1px rgba(255, 255, 255, 0.1) inset,
    0 0 0 1px rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(20px);
  transition: all 0.4s cubic-bezier(0.16, 1, 0.3, 1);
  z-index: 9999;
  pointer-events: auto;

  &::before {
    content: '';
    position: absolute;
    inset: 1px;
    background: linear-gradient(
      145deg,
      rgba(255, 255, 255, 0.1),
      transparent
    );
    border-radius: inherit;
    opacity: 0.5;
  }

  &:hover {
    transform: scale(1.05) translateY(-2px);
    box-shadow: 
      0 8px 32px rgba(136, 83, 251, 0.4),
      0 0 0 1px rgba(255, 255, 255, 0.15) inset;
  }

  &.active {
    background: linear-gradient(
      145deg,
      rgba(96, 57, 176, 0.95),
      rgba(136, 83, 251, 0.95)
    );
    transform: scale(1.1);
  }

  svg {
    filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.2));
    color: white;
    transition: transform 0.3s cubic-bezier(0.16, 1, 0.3, 1);
  }

  &:hover svg {
    transform: scale(1.1);
  }
`;

export const ModalOverlay = styled(motion.div)`
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(8px);
  z-index: 9999;
  overflow: hidden;
  touch-action: none;
  pointer-events: all;
  user-select: none;

  & + * {
    overflow: hidden;
  }
`;

export const FilterPanel = styled(motion.div)`
  position: fixed;
  inset: 0;
  margin: auto;
  width: 90%;
  height: 85%;
  max-width: 1200px;
  max-height: 750px;
  background: linear-gradient(
    165deg,
    rgba(28, 28, 35, 0.85),
    rgba(18, 18, 24, 0.95)
  );
  backdrop-filter: blur(40px);
  border-radius: 32px;
  padding: 24px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  z-index: 10000;
  border: 1px solid rgba(255, 255, 255, 0.07);
  box-shadow: 
    0 0 0 1px rgba(255, 255, 255, 0.05),
    0 20px 50px -12px rgba(0, 0, 0, 0.5),
    0 0 100px rgba(136, 83, 251, 0.1);
  pointer-events: auto;

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    background: radial-gradient(
      circle at top right,
      rgba(136, 83, 251, 0.08),
      transparent 70%
    );
    border-radius: inherit;
    pointer-events: none;
  }
`;

export const FilterCardsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  padding: 20px;
  overflow-y: auto;
  flex: 1;
  margin-bottom: 80px;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: rgba(18, 18, 24, 0.3);
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: linear-gradient(
      180deg,
      rgba(136, 83, 251, 0.3),
      rgba(96, 57, 176, 0.3)
    );
    border-radius: 4px;
    border: 2px solid rgba(18, 18, 24, 0.3);
  }
`;

export const FilterCard = styled(motion.div)`
  background: linear-gradient(
    165deg,
    rgba(38, 38, 45, 0.5),
    rgba(28, 28, 35, 0.5)
  );
  border: 1px solid rgba(255, 255, 255, 0.03);
  border-radius: 20px;
  padding: 20px;
  height: auto;
  min-height: 180px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  backdrop-filter: blur(10px);
  transition: all 0.3s cubic-bezier(0.16, 1, 0.3, 1);

  &:hover {
    background: linear-gradient(
      165deg,
      rgba(43, 43, 50, 0.6),
      rgba(33, 33, 40, 0.6)
    );
    transform: translateY(-2px);
    box-shadow: 
      0 20px 40px rgba(0, 0, 0, 0.2),
      0 0 0 1px rgba(136, 83, 251, 0.1);
  }
`;

export const FilterCardHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 15px;

  svg {
    width: 24px;
    height: 24px;
    color: #8853fb;
  }

  h3 {
    margin: 0;
    color: #fff;
    font-size: 16px;
  }
`;

export const FilterInputs = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

export const FilterInputGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  label {
    font-size: 14px;
    color: #f4f4f4;
    font-weight: 500;
  }
`;

export const ModernTextInput = styled.input`
  background: rgba(40, 40, 40, 0.8);
  border: 1px solid rgba(136, 83, 251, 0.2);
  border-radius: 8px;
  padding: 10px 15px;
  color: #fff;
  font-size: 14px;
  transition: all 0.2s ease;

  &:focus {
    outline: none;
    border-color: rgba(136, 83, 251, 0.6);
    background: rgba(45, 45, 45, 0.9);
  }

  &::placeholder {
    color: rgba(255, 255, 255, 0.4);
  }
`;

export const QuickActions = styled.div`
  padding: 24px;
  background: linear-gradient(
    to top,
    rgba(18, 18, 24, 0.95),
    rgba(18, 18, 24, 0)
  );
  display: flex;
  justify-content: space-between;
  margin-top: auto;
  backdrop-filter: blur(10px);

  /* Create two distinct groups */
  .utility-buttons {
    display: flex;
    gap: 15px;
  }

  .primary-action {
    position: relative;
    
    &::before {
      content: '';
      position: absolute;
      left: -30px;
      top: 50%;
      transform: translateY(-50%);
      height: 24px;
      width: 1px;
      background: rgba(136, 83, 251, 0.2);
    }
  }
`;

export const QuickActionButton = styled(motion.button)`
  background: linear-gradient(
    165deg,
    rgba(136, 83, 251, 0.15),
    rgba(96, 57, 176, 0.15)
  );
  border: 1px solid rgba(136, 83, 251, 0.3);
  border-radius: 28px;
  padding: 14px 28px;
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
  min-width: 180px;
  justify-content: center;
  position: relative;
  overflow: hidden;
  font-weight: 500;
  font-size: 15px;
  letter-spacing: 0.3px;
  backdrop-filter: blur(20px);
  transform: translateZ(0);
  will-change: transform;
  transition: all 0.4s cubic-bezier(0.16, 1, 0.3, 1);

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    background: linear-gradient(
      165deg,
      rgba(255, 255, 255, 0.1),
      transparent
    );
    opacity: 0;
    transition: opacity 0.4s ease;
  }

  &:hover {
    transform: translateY(-2px) scale(1.02);
    background: linear-gradient(
      165deg,
      rgba(136, 83, 251, 0.2),
      rgba(96, 57, 176, 0.2)
    );
    border-color: rgba(136, 83, 251, 0.4);
    box-shadow: 
      0 8px 32px rgba(136, 83, 251, 0.2),
      0 0 0 1px rgba(136, 83, 251, 0.2);

    &::before {
      opacity: 1;
    }
  }

  &:active {
    transform: translateY(1px) scale(0.98);
  }

  svg {
    filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.2));
  }
`;

export const ActiveFilterPill = styled(motion.div)`
  background: linear-gradient(
    165deg,
    rgba(136, 83, 251, 0.15),
    rgba(96, 57, 176, 0.15)
  );
  border: 1px solid rgba(136, 83, 251, 0.2);
  border-radius: 20px;
  padding: 8px 16px;
  font-size: 13px;
  color: #fff;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.16, 1, 0.3, 1);
  backdrop-filter: blur(10px);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);

  &:hover {
    background: linear-gradient(
      165deg,
      rgba(136, 83, 251, 0.2),
      rgba(96, 57, 176, 0.2)
    );
    border-color: rgba(136, 83, 251, 0.3);
    transform: translateY(-1px);
    box-shadow: 
      0 4px 12px rgba(136, 83, 251, 0.2),
      0 0 0 1px rgba(136, 83, 251, 0.2);
  }

  svg {
    width: 14px;
    height: 14px;
    opacity: 0.7;
    transition: all 0.2s ease;
    filter: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.2));

    &:hover {
      opacity: 1;
      transform: scale(1.1);
    }
  }
`;

export const ApplyButtonContainer = styled.div`
  position: absolute;
  bottom: 24px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
`;

export const ApplyButton = styled(motion.button)`
  height: 48px;
  padding: 0 32px;
  background: linear-gradient(
    165deg,
    rgba(28, 28, 35, 0.95),
    rgba(18, 18, 24, 0.95)
  );
  border: none;
  border-radius: 24px;
  color: #8853fb;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0.3px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  cursor: pointer;
  box-shadow: 
    0 2px 20px rgba(0, 0, 0, 0.3),
    0 0 0 1px rgba(255, 255, 255, 0.1) inset;
  backdrop-filter: blur(20px);
  transition: all 0.4s cubic-bezier(0.16, 1, 0.3, 1);

  &::before {
    content: '';
    position: absolute;
    inset: 1px;
    background: linear-gradient(
      145deg,
      rgba(255, 255, 255, 0.1),
      transparent
    );
    border-radius: inherit;
    opacity: 0.5;
    transition: opacity 0.4s ease;
  }

  svg {
    width: 18px;
    height: 18px;
    stroke-width: 2.5px;
    color: #8853fb;
    filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.2));
  }

  &:hover {
    transform: translateY(-2px);
    background: linear-gradient(
      165deg,
      rgba(38, 38, 45, 0.95),
      rgba(28, 28, 35, 0.95)
    );
    color: #9c6eff;
    box-shadow: 
      0 8px 32px rgba(0, 0, 0, 0.4),
      0 0 0 1px rgba(255, 255, 255, 0.15) inset;

    &::before {
      opacity: 1;
    }

    svg {
      color: #9c6eff;
    }
  }

  &:active {
    transform: translateY(1px);
  }
`; 