import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import styled from "styled-components";
import SwarmLogo from "./SwarmLogo";
import "../../assets/styles/LoadingOverlay.css";

const StyledSwarmWrapper = styled(motion.div)`
  width: 120px;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  transform-style: preserve-3d;

  animation: swarmFloat 4s ease-in-out infinite;

  @keyframes swarmFloat {
    0%,
    100% {
      transform: translateY(0px) translateZ(0) rotate(0deg);
    }
    50% {
      transform: translateY(-10px) translateZ(20px) rotate(5deg);
    }
  }
`;

const LoadingOverlay = ({ messageLayers = [] }) => {
  const [currentMessages, setCurrentMessages] = useState([]);
  const [currentLayerIndex, setCurrentLayerIndex] = useState(0);

  useEffect(() => {
    if (messageLayers.length === 0) return;

    const selectRandomMessages = () => {
      const newMessages = messageLayers.map(
        (layer) => layer[Math.floor(Math.random() * layer.length)]
      );
      setCurrentMessages(newMessages);
    };

    selectRandomMessages();
    const interval = setInterval(() => {
      setCurrentLayerIndex(
        (prevIndex) => (prevIndex + 1) % messageLayers.length
      );
    }, 2000);

    return () => clearInterval(interval);
  }, [messageLayers]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="loading-overlay"
    >
      <div className="loading-overlay-content">
        <StyledSwarmWrapper>
          <SwarmLogo size="120px" enableHover={false} active={true} />
        </StyledSwarmWrapper>

        <AnimatePresence mode="wait">
          <motion.p
            key={currentLayerIndex}
            initial={{ opacity: 0, y: 10, z: -50 }}
            animate={{ opacity: 1, y: 0, z: 0 }}
            exit={{ opacity: 0, y: -10, z: -50 }}
            transition={{ duration: 0.5 }}
            className="loading-text"
          >
            {currentMessages[currentLayerIndex]}
          </motion.p>
        </AnimatePresence>
      </div>
    </motion.div>
  );
};

export default LoadingOverlay;
