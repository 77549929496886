import React, { useState, useEffect } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { X, MapPin, Home, DollarSign, Check } from "react-feather";
import { useTranslation } from "react-i18next";
import Dropdown from "../../common/Dropdown";
import RangeSlider from "./RangeSlider";
import useLocationData from "../../../hooks/useLocationData";
import {
  FilterPanel,
  FilterCardsContainer,
  FilterCard,
  FilterCardHeader,
  FilterInputs,
  ActiveFilterPill,
  ModalOverlay,
  ApplyButtonContainer,
  ApplyButton,
} from "./ModernFilterSystem.styles";

const ModernFilterSystem = ({
  onApplyFilters,
  initialFilters,
  isOpen,
  setIsOpen,
  isPublic = false
}) => {
  const { t } = useTranslation();
  const [activeFilters, setActiveFilters] = useState([]);
  const [filters, setFilters] = useState(() => ({
    state: [],
    county: [],
    city: [],
    saleType: [],
    propType: [],
    status: [],
    adjudgedValueRange: [1, 50000000],
    minimumBidRange: [1, 10000000],
    zipCode: "",
    parcelNumber: "",
    ...initialFilters,
  }));
  const [fetchCounties, setFetchCounties] = useState(false);
  const [fetchCities, setFetchCities] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(null);

  const { states, loading: statesLoading } = useLocationData("states");

  const { counties, loading: countiesLoading } = useLocationData(
    "counties",
    fetchCounties && filters.state.length > 0
      ? `?states=${filters.state.join(",")}`
      : ""
  );

  const { cities, loading: citiesLoading } = useLocationData(
    "cities",
    fetchCities && filters.county.length > 0
      ? `?counties=${filters.county.join("-")}`
      : ""
  );

  const stateOptions = (states || []).map((state) => ({
    label: state.label,
    value: state.value[0],
  }));

  const countyOptions = (counties || []).map((county) => ({
    label: county.label,
    value: county.value.join(", "),
  }));

  const cityOptions = (cities || []).map((city) => ({
    label: city.label,
    value: city.value.join(", "),
  }));

  const saleTypeOptions = [
    { label: t("futureSale"), value: "FUTURE SALE" },
    { label: t("resale"), value: "RESALE" },
    { label: t("sale"), value: "SALE" },
    { label: t("struckOff"), value: "STRUCK OFF" },
  ];

  const propTypeOptions = [
    {
      label: t("businessPersonalProperty"),
      value: "BUSINESS PERSONAL PROPERTY",
    },
    { label: t("improvementOnly"), value: "IMP ONLY" },
    { label: t("mineral"), value: "MINERAL" },
    { label: t("mobileHome"), value: "MOBILE HOME" },
    { label: t("otherUnknown"), value: "OTHER / UNKNOWN" },
    { label: t("realProperty"), value: "REAL PROPERTY" },
  ];

  const statusOptions = [
    { label: t("availableForFutureSale"), value: "Available for Future Sale" },
    { label: t("cancelled"), value: "Cancelled" },
    { label: t("postponed"), value: "Postponed" },
    { label: t("saleResultsPending"), value: "Sale Results Pending" },
    { label: t("scheduledForAuction"), value: "Scheduled for Auction" },
    { label: t("sold"), value: "Sold" },
    { label: t("stayed"), value: "Stayed" },
  ];

  const filterCategories = [
    {
      id: "location",
      title: t("location"),
      icon: MapPin,
      content: (
        <div className="filter-inputs">
          <div className="filter-input-group">
            <label>{t("state")}</label>
            <Dropdown
              options={stateOptions}
              selectedOption={filters.state}
              canSelectMultiple={true}
              onSelect={(value) =>
                handleFilterChange("location", "state", value)
              }
              placeholder={t("state")}
              loading={statesLoading}
              isOpen={openDropdown === "state"}
              onToggle={() => handleDropdownToggle("state")}
            />
          </div>
          <div className="filter-input-group">
            <label>{t("county")}</label>
            <Dropdown
              options={countyOptions}
              selectedOption={filters.county}
              canSelectMultiple={true}
              onSelect={(value) =>
                handleFilterChange("location", "county", value)
              }
              placeholder={t("county")}
              loading={countiesLoading}
              isOpen={openDropdown === "county"}
              onToggle={() => handleDropdownToggle("county")}
            />
          </div>
          <div className="filter-input-group">
            <label>{t("city")}</label>
            <Dropdown
              options={cityOptions}
              selectedOption={filters.city}
              canSelectMultiple={true}
              onSelect={(value) =>
                handleFilterChange("location", "city", value)
              }
              placeholder={t("city")}
              loading={citiesLoading}
              isOpen={openDropdown === "city"}
              onToggle={() => handleDropdownToggle("city")}
            />
          </div>
          <div className="filter-input-group">
            <label>{t("zipCode")}</label>
            <input
              type="text"
              name="zipCode"
              className="modern-text-input"
              value={filters.zipCode || ""}
              onChange={(e) =>
                handleFilterChange("location", "zipCode", e.target.value)
              }
              placeholder={t("enterZipCode")}
            />
          </div>
          <div className="filter-input-group">
            <label>{t("parcelNumber")}</label>
            <input
              type="text"
              name="parcelNumber"
              className="modern-text-input"
              value={filters.parcelNumber || ""}
              onChange={(e) =>
                handleFilterChange("location", "parcelNumber", e.target.value)
              }
              placeholder={t("enterParcelNumber")}
            />
          </div>
        </div>
      ),
    },
    {
      id: "property",
      title: t("propertyDetails"),
      icon: Home,
      content: (
        <div className="filter-inputs">
          <div className="filter-input-group">
            <label>{t("saleType")}</label>
            <Dropdown
              options={saleTypeOptions}
              selectedOption={filters.saleType}
              canSelectMultiple={true}
              onSelect={(value) =>
                handleFilterChange("property", "saleType", value)
              }
              placeholder={t("saleType")}
              isOpen={openDropdown === "saleType"}
              onToggle={() => handleDropdownToggle("saleType")}
            />
          </div>
          <div className="filter-input-group">
            <label>{t("propertyType")}</label>
            <Dropdown
              options={propTypeOptions}
              selectedOption={filters.propType}
              canSelectMultiple={true}
              onSelect={(value) =>
                handleFilterChange("property", "propType", value)
              }
              placeholder={t("propertyType")}
              isOpen={openDropdown === "propType"}
              onToggle={() => handleDropdownToggle("propType")}
            />
          </div>
          <div className="filter-input-group">
            <RangeSlider
              label={t("adjudgedValueRange")}
              min={1}
              max={50000000}
              step={0.1}
              initialValue={filters.adjudgedValueRange}
              onChange={(value) =>
                handleFilterChange("property", "adjudgedValueRange", value)
              }
            />
          </div>
        </div>
      ),
    },
    {
      id: "financial",
      title: t("legalAndFinancial"),
      icon: DollarSign,
      content: (
        <div className="filter-inputs">
          <div className="filter-input-group">
            <label>{t("status")}</label>
            <Dropdown
              options={statusOptions}
              selectedOption={filters.status}
              canSelectMultiple={true}
              onSelect={(value) =>
                handleFilterChange("financial", "status", value)
              }
              placeholder={t("status")}
              isOpen={openDropdown === "status"}
              onToggle={() => handleDropdownToggle("status")}
            />
          </div>
          <div className="filter-input-group">
            <RangeSlider
              label={t("minimumBid")}
              min={1}
              max={10000000}
              step={0.1}
              initialValue={filters.minimumBidRange}
              onChange={(value) =>
                handleFilterChange("financial", "minimumBidRange", value)
              }
            />
          </div>
        </div>
      ),
    },
  ];

  const handleFilterChange = (category, filterName, value) => {
    let newValue = value;
    const filterKey = `${category}-${filterName}`;

    if (filterName === "zipCode" || filterName === "parcelNumber") {
      newValue = value.trim();

      setFilters((prev) => ({
        ...prev,
        [filterName]: newValue,
      }));

      if (newValue === "") {
        setActiveFilters((prev) => prev.filter((f) => f.key !== filterKey));
      } else {
        const filterDisplay = {
          key: filterKey,
          label: `${category}: ${newValue}`,
        };
        setActiveFilters((prev) => {
          const filtered = prev.filter((f) => f.key !== filterKey);
          return [...filtered, filterDisplay];
        });
      }
      return;
    }

    if (
      ["state", "county", "city", "saleType", "propType", "status"].includes(
        filterName
      )
    ) {
      newValue = Array.isArray(value) ? value : [value];

      if (filterName === "state") {
        setFilters((prev) => ({
          ...prev,
          [filterName]: newValue,
          county: [],
          city: [],
        }));
        return;
      } else if (filterName === "county") {
        setFilters((prev) => ({
          ...prev,
          [filterName]: newValue,
          city: [],
        }));
        return;
      }
    }

    if (filterName.includes("Range")) {
      newValue = Array.isArray(value) ? value : [1, value];
    }

    setFilters((prev) => ({
      ...prev,
      [filterName]: newValue,
    }));

    let displayValue = Array.isArray(newValue) ? newValue.join(", ") : newValue;

    if (filterName.includes("Range")) {
      displayValue = `$${newValue[0].toLocaleString()} - $${newValue[1].toLocaleString()}`;
    }

    const filterDisplay = {
      key: filterKey,
      label: `${category}: ${displayValue}`,
    };

    setActiveFilters((prev) => {
      const filtered = prev.filter((f) => f.key !== filterKey);
      if (newValue && (!Array.isArray(newValue) || newValue.length > 0)) {
        return [...filtered, filterDisplay];
      }
      return filtered;
    });
  };

  useEffect(() => {
    if (filters?.state?.length > 0) {
      setFetchCounties(true);
    } else {
      setFetchCounties(false);
    }

    if (filters?.county?.length > 0) {
      setFetchCities(true);
    } else {
      setFetchCities(false);
    }
  }, [filters?.state, filters?.county]);

  useEffect(() => {
    if (initialFilters) {
      setFilters((prev) => ({
        ...prev,
        state: initialFilters.StateOrProvince || [],
        county: initialFilters.County || [],
        city: initialFilters.City || [],
        saleType: initialFilters.SaleType || [],
        propType: initialFilters.PropType || [],
        status: initialFilters.Status || [],
        adjudgedValueRange: initialFilters.AdjudgedValue || [1, 50000000],
        minimumBidRange: initialFilters.MinimumBid || [1, 10000000],
        zipCode: initialFilters.zipCode || "",
        parcelNumber: initialFilters.parcelNumber || "",
      }));

      const newActiveFilters = [];
      Object.entries(initialFilters).forEach(([key, value]) => {
        if (value && (Array.isArray(value) ? value.length > 0 : value)) {
          let category = "";
          let displayValue = Array.isArray(value) ? value.join(", ") : value;

          switch (key) {
            case "StateOrProvince":
            case "County":
            case "City":
            case "zipCode":
            case "parcelNumber":
              category = "location";
              break;
            case "SaleType":
            case "PropType":
              category = "property";
              break;
            case "Status":
            case "AdjudgedValue":
            case "MinimumBid":
              category = "financial";
              break;
            default:
              category = "";
          }

          if (key === "AdjudgedValue" || key === "MinimumBid") {
            displayValue = `$${value[0].toLocaleString()} - $${value[1].toLocaleString()}`;
          }

          newActiveFilters.push({
            key: `${category}-${key}`,
            label: `${category}: ${displayValue}`,
          });
        }
      });

      setActiveFilters(newActiveFilters);
    }
  }, [initialFilters]);

  const getMappedFilters = (currentFilters) => {
    const mappedFilters = {
      StateOrProvince: currentFilters.state || [],
      County: currentFilters.county || [],
      City: currentFilters.city || [],
      SaleType: currentFilters.saleType || [],
      PropType: currentFilters.propType || [],
      Status: currentFilters.status || [],
      AdjudgedValue: currentFilters.adjudgedValueRange || [1, 50000000],
      MinimumBid: currentFilters.minimumBidRange || [1, 10000000],
      zipCode: currentFilters.zipCode?.trim() || "",
      parcelNumber: currentFilters.parcelNumber?.trim() || "",
    };

    // Filter out empty values
    return Object.fromEntries(
      Object.entries(mappedFilters).filter(([_, value]) => {
        if (Array.isArray(value)) {
          return value.length > 0;
        }
        return value !== "" && value !== null && value !== undefined;
      })
    );
  };

  const applyFilters = () => {
    const cleanedFilters = getMappedFilters(filters);
    
    // If in public mode, limit the number of filters that can be applied
    if (isPublic) {
      const filterCount = Object.keys(cleanedFilters).length;
      if (filterCount > 3) {
        // You could show a toast message here
        return;
      }
    }
    
    onApplyFilters(cleanedFilters);
    setIsOpen(false);
  };

  const handleDropdownToggle = (dropdownName) => {
    setOpenDropdown((prev) => (prev === dropdownName ? null : dropdownName));
  };

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (!e.target.closest(".filter-input-group")) {
        setOpenDropdown(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
      document.body.style.paddingRight = "15px";
    } else {
      document.body.style.overflow = "unset";
      document.body.style.paddingRight = "0px";
    }

    return () => {
      document.body.style.overflow = "unset";
      document.body.style.paddingRight = "0px";
    };
  }, [isOpen]);

  const handleRemoveFilter = (filterKey) => {
    const [category, filterName] = filterKey.split("-");
    const updatedFilters = { ...filters };

    if (filterName === "zipCode" || filterName === "parcelNumber") {
      updatedFilters[filterName] = "";
      const input = document.querySelector(`input[name="${filterName}"]`);
      if (input) {
        input.value = "";
      }
    } else if (filterName.includes("Range")) {
      updatedFilters[filterName] =
        filterName === "adjudgedValueRange" ? [1, 50000000] : [1, 10000000];
    } else {
      updatedFilters[filterName] = [];
    }

    setFilters(updatedFilters);
    setActiveFilters((prev) => prev.filter((f) => f.key !== filterKey));

    const cleanedFilters = getMappedFilters(updatedFilters);
    onApplyFilters(cleanedFilters);
  };

  return (
    <>
      <AnimatePresence>
        {isOpen && (
          <>
            <ModalOverlay
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              onClick={() => setIsOpen(false)}
            />
            <FilterPanel
              initial={{ opacity: 0, scale: 0.9, y: 20 }}
              animate={{ opacity: 1, scale: 1, y: 0 }}
              exit={{ opacity: 0, scale: 0.9, y: 20 }}
              onClick={(e) => e.stopPropagation()}
            >
              {isPublic && (
                <div className="public-mode-message" style={{
                  padding: "10px 20px",
                  color: "rgba(255,255,255,0.7)",
                  textAlign: "center",
                  borderBottom: "1px solid rgba(255,255,255,0.1)"
                }}>
                  {t("publicModeFilterLimit")}
                </div>
              )}
              {activeFilters.length > 0 && (
                <motion.div
                  className="active-filters-container"
                  initial={{ opacity: 0, y: -10 }}
                  animate={{ opacity: 1, y: 0 }}
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "8px",
                    padding: "0 20px 20px 20px",
                    borderBottom: "1px solid rgba(255, 255, 255, 0.1)",
                  }}
                >
                  {activeFilters.map((filter) => (
                    <ActiveFilterPill
                      key={filter.key}
                      initial={{ scale: 0 }}
                      animate={{ scale: 1 }}
                      exit={{ scale: 0 }}
                    >
                      {filter.label}
                      <X
                        size={14}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleRemoveFilter(filter.key);
                        }}
                      />
                    </ActiveFilterPill>
                  ))}
                </motion.div>
              )}
              <FilterCardsContainer>
                {filterCategories.map((category) => (
                  <FilterCard
                    key={category.id}
                    initial={{ y: 20, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                  >
                    <FilterCardHeader>
                      <category.icon />
                      <h3>{category.title}</h3>
                    </FilterCardHeader>
                    <FilterInputs>{category.content}</FilterInputs>
                  </FilterCard>
                ))}
              </FilterCardsContainer>
              <ApplyButtonContainer>
                <ApplyButton
                  onClick={applyFilters}
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                >
                  <Check size={18} />
                  Apply Filters
                </ApplyButton>
              </ApplyButtonContainer>
            </FilterPanel>
          </>
        )}
      </AnimatePresence>
    </>
  );
};

export default ModernFilterSystem;
