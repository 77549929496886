import React, { memo, useState, useRef, useContext, useEffect } from "react";
import { motion } from "framer-motion";
import { 
  Home as DashboardIcon,
  CreditCard as WalletIcon,
  Settings as SettingsIcon,
  MessageCircle as ChatIcon,
  Users as FriendsIcon
} from "react-feather";
import { useNavigate, useLocation } from "react-router-dom";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import "tippy.js/animations/shift-away.css";
import "../../../assets/styles/Sidebar.css";
import styled from "styled-components";
import { io } from "socket.io-client";
import { AuthContext } from "../../../context/AuthContext";

const NotificationBadge = styled(motion.div)`
  position: absolute;
  top: -6px;
  right: -6px;
  background: linear-gradient(165deg, #8853FB, #6039b0);
  color: white;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  font-size: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #1a1a1a;
  font-weight: 500;
  z-index: 100;
  box-shadow: 0 2px 6px rgba(136, 83, 251, 0.3);
`;

const IconContainer = styled(motion.div)`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const Sidebar = memo(() => {
  const [unreadCount, setUnreadCount] = useState(0);
  const socket = useRef();
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();

  const fetchUnreadCount = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/chat/unread-count`,
        {
          credentials: "include",
        }
      );
      if (response.ok) {
        const { totalUnread } = await response.json();
        if (location.pathname !== '/home/chat') {
          setUnreadCount(totalUnread);
        }
      }
    } catch (error) {
      console.error("Error fetching unread count:", error);
    }
  };

  useEffect(() => {
    fetchUnreadCount();
  }, [location.pathname]);

  useEffect(() => {
    socket.current = io(process.env.REACT_APP_API_URL, {
      withCredentials: true,
      transports: ["websocket"],
      reconnectionAttempts: 5,
      reconnectionDelay: 1000,
      path: '/socket.io/',
    });

    socket.current.on('connect_error', (error) => {
      console.error('Socket connection error:', error);
    });

    socket.current.on('connect', () => {
      if (user?._id) {
        socket.current.emit('user connected', user._id);
      }
    });

    socket.current.on('message received', (data) => {
      if (location.pathname !== '/home/chat') {
        setUnreadCount(prev => prev + 1);
      }
    });

    socket.current.on('update_unread_count', ({ totalUnread }) => {
      if (location.pathname !== '/home/chat') {
        setUnreadCount(totalUnread);
      }
    });

    socket.current.on('messages read', ({ chatId, count }) => {
      if (location.pathname !== '/home/chat') {
        setUnreadCount(prev => Math.max(0, prev - count));
      }
    });

    return () => {
      if (socket.current) {
        socket.current.disconnect();
      }
    };
  }, [user, location.pathname]);

  useEffect(() => {
    const handlePathChange = async () => {
      if (location.pathname === '/home/chat') {
        setUnreadCount(0);
      } else {
        try {
          const response = await fetch(
            `${process.env.REACT_APP_API_URL}/api/chat/unread-count`,
            {
              credentials: "include",
            }
          );
          if (response.ok) {
            const { totalUnread } = await response.json();
            setUnreadCount(totalUnread);
          }
        } catch (error) {
          console.error("Error fetching unread count:", error);
        }
      }
    };

    handlePathChange();
  }, [location.pathname]);

  const handleNavClick = async (path) => {
    if (path === '/home/chat') {
      setUnreadCount(0);
    }
    navigate(path);
  };

  const navItems = [
    {
      id: 'dashboard',
      icon: DashboardIcon,
      path: '/home/buy',
      label: 'Dashboard',
      tooltip: 'Dashboard'
    },
    {
      id: 'chat',
      icon: ChatIcon,
      path: '/home/chat',
      label: 'Chat',
      tooltip: 'Messages',
      notifications: unreadCount
    },
    {
      id: 'friends',
      icon: FriendsIcon,
      path: '/home/friends',
      label: 'Friends',
      tooltip: 'Friends'
    },
    {
      id: 'wallet',
      icon: WalletIcon,
      path: '/home/wallet',
      label: 'Wallet',
      tooltip: 'My Wallet'
    },
    {
      id: 'settings',
      icon: SettingsIcon,
      path: '/home/account-settings',
      label: 'Settings',
      tooltip: 'Settings'
    }
  ];

  const isActive = (path) => location.pathname === path;

  return (
    <motion.nav 
      className="modern-sidebar"
      initial={{ x: -60, opacity: 0 }}
      animate={{ x: 0, opacity: 1 }}
      transition={{ duration: 0.3 }}
    >
      <div className="nav-items">
        {navItems.map((item) => (
          <Tippy
            key={item.id}
            content={item.tooltip}
            animation="shift-away"
            placement="right"
            duration={[300, 250]}
            delay={[400, 0]}
            theme="modern"
            arrow={true}
          >
            <motion.button
              className={`nav-item ${isActive(item.path) ? 'active' : ''}`}
              onClick={() => handleNavClick(item.path)}
              whileHover={{ 
                backgroundColor: 'rgba(255, 255, 255, 0.05)',
                scale: 1.02 
              }}
              whileTap={{ scale: 0.98 }}
            >
              <IconContainer>
                <item.icon size={20} />
                {item.id === 'chat' && unreadCount > 0 && !isActive(item.path) && (
                  <NotificationBadge
                    initial={{ scale: 0 }}
                    animate={{ scale: 1 }}
                    exit={{ scale: 0 }}
                  >
                    {unreadCount}
                  </NotificationBadge>
                )}
              </IconContainer>
              {isActive(item.path) && (
                <motion.div 
                  className="active-indicator"
                  layoutId="activeIndicator"
                  transition={{ type: "spring", stiffness: 300, damping: 30 }}
                />
              )}
            </motion.button>
          </Tippy>
        ))}
      </div>
    </motion.nav>
  );
});

export default Sidebar;
