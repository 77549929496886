import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import { Search as SearchIcon, X as CloseIcon } from 'react-feather';
import { motion, AnimatePresence } from 'framer-motion';
import debounce from 'lodash/debounce';
import { searchUsers } from './friendsApi';
import FriendSearchResults from './FriendSearchResults';

const SearchModal = styled(motion.div)`
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(8px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
  padding: 20px;
`;

const SearchContent = styled(motion.div)`
  background: linear-gradient(
    165deg,
    rgba(28, 28, 35, 0.85),
    rgba(18, 18, 24, 0.95)
  );
  backdrop-filter: blur(40px);
  border-radius: 24px;
  padding: 24px;
  width: 90%;
  max-width: 440px;
  min-width: 280px;
  border: 1px solid rgba(255, 255, 255, 0.07);
  box-shadow: 
    0 0 0 1px rgba(255, 255, 255, 0.05),
    0 20px 50px -12px rgba(0, 0, 0, 0.5),
    0 0 100px rgba(136, 83, 251, 0.1);
  position: relative;
  overflow: hidden;
`;

const SearchHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;

  h2 {
    font-size: 1.8rem;
    background: linear-gradient(45deg, #8853fb, #3498db);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    margin: 0;
  }
`;

const CloseButton = styled(motion.button)`
  background: none;
  border: none;
  color: rgba(255, 255, 255, 0.6);
  cursor: pointer;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  transition: all 0.2s ease;

  &:hover {
    background: rgba(255, 255, 255, 0.1);
    color: white;
  }
`;

const SearchInputWrapper = styled.div`
  position: relative;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  width: 100%;

  .input-container {
    position: relative;
    width: 80%;
  }
`;

const SearchInput = styled.input`
  width: 100%;
  height: 48px;
  background: linear-gradient(
    165deg,
    rgba(38, 38, 45, 0.5),
    rgba(28, 28, 35, 0.5)
  );
  border: 1px solid rgba(136, 83, 251, 0.2);
  border-radius: 12px;
  color: #fff;
  font-size: 14px;
  padding: 0 48px;
  transition: all 0.3s cubic-bezier(0.16, 1, 0.3, 1);
  backdrop-filter: blur(10px);
  box-sizing: border-box;

  &:focus {
    outline: none;
    border-color: rgba(136, 83, 251, 0.4);
    background: linear-gradient(
      165deg,
      rgba(43, 43, 50, 0.6),
      rgba(33, 33, 40, 0.6)
    );
    box-shadow: 
      0 4px 20px rgba(0, 0, 0, 0.2),
      0 0 20px rgba(136, 83, 251, 0.1);
  }

  &::placeholder {
    color: rgba(255, 255, 255, 0.4);
    text-overflow: ellipsis;
  }
`;

const SearchIconWrapper = styled.div`
  position: absolute;
  left: 16px;
  top: 50%;
  transform: translateY(-50%);
  color: rgba(136, 83, 251, 0.6);
`;

const ErrorMessage = styled.div`
  color: rgb(255, 59, 48);
  font-size: 14px;
  padding: 8px 12px;
  margin-bottom: 12px;
  background: rgba(255, 59, 48, 0.1);
  border-radius: 8px;
  border: 1px solid rgba(255, 59, 48, 0.2);
`;

const FriendsSearch = ({ show, onClose, onFriendAdd }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [error, setError] = useState(null);

  const debouncedSearch = useCallback(
    debounce(async (term) => {
      if (!term || term.length < 2) {
        setSearchResults([]);
        setIsSearching(false);
        return;
      }

      setIsSearching(true);
      setError(null);

      try {
        const results = await searchUsers(term);

        setSearchResults(results);
      } catch (error) {
        console.error('Search error:', error);
        setError(error.message);
      } finally {
        setIsSearching(false);
      }
    }, 300),
    []
  );

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    if (value.length >= 2) {
      debouncedSearch(value);
    } else {
      setSearchResults([]);
    }
  };

  // Clear search on close
  const handleClose = () => {
    setSearchTerm('');
    setSearchResults([]);
    setError(null);
    onClose();
  };

  if (!show) return null;

  return (
    <AnimatePresence>
      <SearchModal
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        onClick={handleClose}
      >
        <SearchContent
          onClick={(e) => e.stopPropagation()}
          initial={{ scale: 0.95, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          exit={{ scale: 0.95, opacity: 0 }}
          transition={{ type: "spring", damping: 25, stiffness: 500 }}
        >
          <SearchHeader>
            <h2>Add Friend</h2>
            <CloseButton
              onClick={handleClose}
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
            >
              <CloseIcon size={24} />
            </CloseButton>
          </SearchHeader>

          <SearchInputWrapper>
            <div className="input-container">
              <SearchIconWrapper>
                <SearchIcon size={20} />
              </SearchIconWrapper>
              <SearchInput
                type="text"
                placeholder="Search users by email..."
                value={searchTerm}
                onChange={handleSearchChange}
                autoFocus
              />
            </div>
          </SearchInputWrapper>

          {error && (
            <ErrorMessage>
              {error}
            </ErrorMessage>
          )}

          <FriendSearchResults 
            results={searchResults}
            isSearching={isSearching}
            searchTerm={searchTerm}
            onFriendAdd={(friend) => {
              onFriendAdd(friend);
              handleClose();
            }}
          />
        </SearchContent>
      </SearchModal>
    </AnimatePresence>
  );
};

export default FriendsSearch; 