import React from "react";
import { motion, AnimatePresence } from "framer-motion";
import { X } from "react-feather";
import styled from "styled-components";

const PolicyOverlay = styled(motion.div)`
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(8px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
  padding: 20px;
`;

const PolicyContent = styled(motion.div)`
  background: linear-gradient(
    165deg,
    rgba(28, 28, 35, 0.85),
    rgba(18, 18, 24, 0.95)
  );
  backdrop-filter: blur(40px);
  border-radius: 24px;
  width: 95%;
  max-width: 800px;
  max-height: 85vh;
  border: 1px solid rgba(255, 255, 255, 0.07);
  box-shadow: 
    0 0 0 1px rgba(255, 255, 255, 0.05),
    0 20px 50px -12px rgba(0, 0, 0, 0.5),
    0 0 100px rgba(136, 83, 251, 0.1);
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  margin: 20px;

  @media (max-width: 768px) {
    width: 95%;
    margin: 10px;
  }

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    background: radial-gradient(
      circle at top right,
      rgba(136, 83, 251, 0.08),
      transparent 70%
    );
    border-radius: inherit;
    pointer-events: none;
  }
`;

const PolicyHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem 2rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.07);
  background: linear-gradient(
    165deg,
    rgba(38, 38, 45, 0.5),
    rgba(28, 28, 35, 0.5)
  );
  backdrop-filter: blur(10px);
  position: relative;
  z-index: 2;

  @media (max-width: 768px) {
    padding: 1rem 1.5rem;
  }

  @media (max-width: 480px) {
    padding: 1rem;
  }
`;

const PolicyTitle = styled.h2`
  margin: 0;
  font-size: 1.8rem;
  background: linear-gradient(45deg, #8853fb, #3498db);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  text-shadow: 0 2px 10px rgba(136, 83, 251, 0.3);

  @media (max-width: 480px) {
    font-size: 1.4rem;
  }
`;

const PolicyCloseButton = styled.button`
  width: 32px;
  height: 32px;
  background: linear-gradient(
    165deg,
    rgba(38, 38, 45, 0.5),
    rgba(28, 28, 35, 0.5)
  );
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  color: rgba(255, 255, 255, 0.8);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s cubic-bezier(0.16, 1, 0.3, 1);

  &:hover {
    background: linear-gradient(
      165deg,
      rgba(43, 43, 50, 0.6),
      rgba(33, 33, 40, 0.6)
    );
    border-color: rgba(136, 83, 251, 0.2);
    color: white;
    transform: translateY(-1px);
    box-shadow: 
      0 4px 12px rgba(0, 0, 0, 0.2),
      0 0 0 1px rgba(136, 83, 251, 0.1);
  }

  &:active {
    transform: translateY(1px);
  }
`;

const PolicyBody = styled.div`
  padding: 2rem;
  overflow-y: auto;
  flex-grow: 1;
  color: rgba(255, 255, 255, 0.8);

  h3 {
    background: linear-gradient(45deg, #8853fb, #3498db);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    margin-top: 1.5rem;
    margin-bottom: 1rem;
    font-size: 1.4rem;
  }

  p {
    margin-bottom: 1rem;
    line-height: 1.6;
    font-size: 0.95rem;
    color: rgba(255, 255, 255, 0.7);
  }

  ul {
    margin-bottom: 1rem;
    padding-left: 1.5rem;
    
    li {
      margin-bottom: 0.5rem;
      color: rgba(255, 255, 255, 0.7);
      position: relative;

      &::before {
        content: '•';
        color: #8853fb;
        position: absolute;
        left: -1rem;
      }
    }
  }

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: rgba(136, 83, 251, 0.1);
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(136, 83, 251, 0.2);
    border-radius: 4px;
    
    &:hover {
      background: rgba(136, 83, 251, 0.3);
    }
  }

  @media (max-width: 768px) {
    padding: 1.5rem;
    
    h3 {
      font-size: 1.2rem;
    }
    
    p {
      font-size: 0.9rem;
    }
  }

  @media (max-width: 480px) {
    padding: 1rem;
  }
`;

const PolicyModal = ({ open, onClose, title, content }) => {
  if (!open) return null;

  return (
    <AnimatePresence>
      {open && (
        <PolicyOverlay
          onClick={onClose}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <PolicyContent
            onClick={(e) => e.stopPropagation()}
            initial={{ scale: 0.95, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.95, opacity: 0 }}
            transition={{ type: "spring", damping: 25, stiffness: 500 }}
          >
            <PolicyHeader>
              <PolicyTitle>{title}</PolicyTitle>
              <PolicyCloseButton onClick={onClose}>
                <X size={24} />
              </PolicyCloseButton>
            </PolicyHeader>
            <PolicyBody>
              <div dangerouslySetInnerHTML={{ __html: content }} />
            </PolicyBody>
          </PolicyContent>
        </PolicyOverlay>
      )}
    </AnimatePresence>
  );
};

export default PolicyModal;