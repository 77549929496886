import React, { useEffect, useRef, useState } from "react";
import { ReactComponent as USAMap } from "../../assets/images/MapUSA.svg";
import styled from "styled-components";

// Styled components for better mobile responsiveness
const AnimationContainer = styled.div`
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 0;
`;

const StyledUSAMap = styled(USAMap)`
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: auto;
  height: 100%;
  opacity: ${(props) => (props.$isIdle ? "0.3" : "0.25")};
  transition: opacity 0.6s cubic-bezier(0.16, 1, 0.3, 1);
  z-index: 0;
  pointer-events: none;

  path {
    fill: none !important;
    stroke: rgba(136, 83, 251, 0.4);
    stroke-width: 1.5px;
    vector-effect: non-scaling-stroke;
    filter: drop-shadow(0 0 3px rgba(136, 83, 251, 0.3));
  }

  @media (max-width: 768px) {
    height: auto;
    width: 150%;
    opacity: ${(props) => (props.$isIdle ? "0.25" : "0.2")};

    path {
      stroke: rgba(136, 83, 251, 0.35);
      stroke-width: 1.2px;
      filter: drop-shadow(0 0 2px rgba(136, 83, 251, 0.25));
    }
  }

  @media (max-width: 480px) {
    width: 200%;
    opacity: ${(props) => (props.$isIdle ? "0.2" : "0.15")};

    path {
      stroke: rgba(136, 83, 251, 0.3);
      stroke-width: 1px;
      filter: drop-shadow(0 0 2px rgba(136, 83, 251, 0.2));
    }
  }

  path {
    filter: drop-shadow(0 0 2px rgba(136, 83, 251, 0.2));
  }

  path:hover {
    stroke: rgba(136, 83, 251, 0.6);
    filter: drop-shadow(0 0 4px rgba(136, 83, 251, 0.4))
      drop-shadow(0 0 8px rgba(136, 83, 251, 0.2));
    transition: all 0.3s cubic-bezier(0.16, 1, 0.3, 1);
  }
`;

const AnimationCanvas = styled.canvas`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  transition: z-index 0.3s step-end;
  pointer-events: auto;
`;

// Utility function to convert hex color to RGBA
function hexToRgbA(hex, alpha) {
  let c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split("");
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = "0x" + c.join("");
    return `rgba(${[(c >> 16) & 255, (c >> 8) & 255, c & 255].join(
      ","
    )},${alpha})`;
  }
  throw new Error("Bad Hex");
}

const BackgroundAnimation = () => {
  const canvasRef = useRef(null);
  const [isIdle, setIsIdle] = useState(false);
  const idleTimerRef = useRef(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    let animationFrameId;
    let mouseX = 0;
    let mouseY = 0;

    // Handle window resize and device detection
    const handleResize = () => {
      const mobile = window.innerWidth <= 768;
      setIsMobile(mobile);

      // Update canvas dimensions with device pixel ratio
      const pixelRatio = window.devicePixelRatio || 1;
      canvas.width = window.innerWidth * pixelRatio;
      canvas.height = window.innerHeight * pixelRatio;

      // Scale the context to ensure correct drawing
      ctx.scale(pixelRatio, pixelRatio);

      // Set canvas CSS size
      canvas.style.width = `${window.innerWidth}px`;
      canvas.style.height = `${window.innerHeight}px`;
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    // Adjust property parameters for mobile
    const getPropertyParams = () => ({
      maxProperties: isMobile ? 5 : 10,
      tailLength: isMobile ? 50 : 100,
      spawnRate: isMobile ? 0.005 : 0.01,
      particleSize: isMobile ? { min: 1, max: 3 } : { min: 2, max: 5 },
      avoidanceRadius: isMobile ? 50 : 100,
    });

    // Define state coordinates for all US states
    const stateCoordinates = {
      AL: { x: 0.67, y: 0.65 },
      AK: { x: 0.15, y: 0.88 },
      AZ: { x: 0.23, y: 0.6 },
      AR: { x: 0.59, y: 0.59 },
      CA: { x: 0.1, y: 0.45 },
      CO: { x: 0.35, y: 0.45 },
      CT: { x: 0.89, y: 0.3 },
      DE: { x: 0.87, y: 0.4 },
      FL: { x: 0.78, y: 0.78 },
      GA: { x: 0.75, y: 0.65 },
      HI: { x: 0.29, y: 0.95 },
      ID: { x: 0.22, y: 0.25 },
      IL: { x: 0.62, y: 0.42 },
      IN: { x: 0.67, y: 0.4 },
      IA: { x: 0.54, y: 0.35 },
      KS: { x: 0.48, y: 0.5 },
      KY: { x: 0.7, y: 0.5 },
      LA: { x: 0.6, y: 0.73 },
      ME: { x: 0.92, y: 0.15 },
      MD: { x: 0.83, y: 0.4 },
      MA: { x: 0.9, y: 0.27 },
      MI: { x: 0.67, y: 0.3 },
      MN: { x: 0.52, y: 0.22 },
      MS: { x: 0.64, y: 0.68 },
      MO: { x: 0.57, y: 0.5 },
      MT: { x: 0.3, y: 0.18 },
      NE: { x: 0.45, y: 0.38 },
      NV: { x: 0.17, y: 0.4 },
      NH: { x: 0.89, y: 0.22 },
      NJ: { x: 0.87, y: 0.35 },
      NM: { x: 0.31, y: 0.62 },
      NY: { x: 0.82, y: 0.25 },
      NC: { x: 0.8, y: 0.52 },
      ND: { x: 0.45, y: 0.18 },
      OH: { x: 0.73, y: 0.4 },
      OK: { x: 0.48, y: 0.58 },
      OR: { x: 0.12, y: 0.22 },
      PA: { x: 0.8, y: 0.35 },
      RI: { x: 0.91, y: 0.3 },
      SC: { x: 0.78, y: 0.6 },
      SD: { x: 0.45, y: 0.28 },
      TN: { x: 0.68, y: 0.55 },
      TX: { x: 0.45, y: 0.72 },
      UT: { x: 0.25, y: 0.45 },
      VT: { x: 0.87, y: 0.2 },
      VA: { x: 0.8, y: 0.45 },
      WA: { x: 0.13, y: 0.12 },
      WV: { x: 0.77, y: 0.45 },
      WI: { x: 0.6, y: 0.25 },
      WY: { x: 0.32, y: 0.32 },
    };

    // Define an array of colors for properties
    const propertyColors = [
      "#8853FB",
      "#34D399",
      "#FBBF24",
      "#F472B6",
      "#60A5FA",
    ];

    class Property {
      constructor(startState, endState) {
        const params = getPropertyParams();
        this.startState = startState;
        this.endState = endState;
        this.progress = 0;
        this.color =
          propertyColors[Math.floor(Math.random() * propertyColors.length)];
        this.size =
          Math.random() * (params.particleSize.max - params.particleSize.min) +
          params.particleSize.min;
        this.speed = Math.random() * 0.002 + (isMobile ? 0.002 : 0.001);
        this.stream = [];
        this.tailLength = Math.floor(Math.random() * 50 + params.tailLength);
        this.avoidanceRadius = params.avoidanceRadius;

        // Add easing for smoother animation
        this.ease = (t) => (t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t);
      }

      update() {
        this.progress += this.speed;
        return this.progress >= 1;
      }

      draw() {
        const start = stateCoordinates[this.startState];
        const end = stateCoordinates[this.endState];
        const { width, height } = canvas;

        // Apply easing to the progress
        const easedProgress = this.ease(this.progress);

        let x = start.x * width + (end.x - start.x) * width * easedProgress;
        let y = start.y * height + (end.y - start.y) * height * easedProgress;

        // Mouse avoidance with smoother transition
        const dx = x - mouseX;
        const dy = y - mouseY;
        const distance = Math.sqrt(dx * dx + dy * dy);

        if (distance < this.avoidanceRadius) {
          const angle = Math.atan2(dy, dx);
          const repelForce =
            (this.avoidanceRadius - distance) / this.avoidanceRadius;
          const easeRepel = this.ease(repelForce);
          x += Math.cos(angle) * easeRepel * (isMobile ? 10 : 20);
          y += Math.sin(angle) * easeRepel * (isMobile ? 10 : 20);
        }

        // Update stream with new position
        this.stream.unshift({ x, y, size: this.size });
        if (this.stream.length > this.tailLength) this.stream.pop();

        // Optimized tail drawing
        ctx.save();
        this.stream.forEach((point, index) => {
          const alpha = 1 - index / this.tailLength;
          const streamSize = point.size * (1 - index / this.tailLength);

          if (alpha > 0.05) {
            // Only draw visible particles
            ctx.fillStyle = hexToRgbA(this.color, alpha * (isIdle ? 0.3 : 0.1));
            ctx.beginPath();
            ctx.arc(point.x, point.y, streamSize, 0, Math.PI * 2);
            ctx.fill();
          }
        });

        // Draw main particle with optimized glow
        ctx.shadowColor = this.color;
        ctx.shadowBlur = this.size * (isMobile ? 1.5 : 2);
        ctx.fillStyle = hexToRgbA(this.color, 1);
        ctx.beginPath();
        ctx.arc(x, y, this.size, 0, Math.PI * 2);
        ctx.fill();
        ctx.restore();
      }
    }

    class DollarSignIcon {
      constructor(x, y) {
        this.x = x * canvas.width;
        this.y = y * canvas.height;
        this.opacity = 1;
        this.size = 24;
        this.speed = 2;
        this.lifetime = 60;
      }

      update() {
        this.y -= this.speed;
        this.lifetime--;
        this.opacity = this.lifetime / 60;
      }

      draw() {
        ctx.save();
        ctx.globalAlpha = this.opacity;
        ctx.fillStyle = "rgba(255, 215, 0, 0.8)"; // Gold color
        ctx.font = `bold ${this.size}px Arial`;
        ctx.shadowColor = "rgba(255, 215, 0, 0.8)";
        ctx.shadowBlur = 10;
        ctx.fillText("$", this.x, this.y);
        ctx.restore();
      }
    }

    class PropertyIcon {
      constructor(x, y) {
        this.x = x * canvas.width;
        this.y = y * canvas.height;
        this.opacity = 1;
        this.size = 20;
        this.lifetime = 120;
      }

      update() {
        this.lifetime--;
        this.opacity = this.lifetime / 120;
      }

      draw() {
        ctx.save();
        ctx.globalAlpha = this.opacity;
        ctx.fillStyle = "rgba(136, 83, 251, 0.8)";
        ctx.strokeStyle = "rgba(255, 255, 255, 0.4)";
        ctx.lineWidth = 1;

        ctx.shadowColor = this.fillStyle;
        ctx.shadowBlur = 5;

        // Draw a more detailed house shape
        ctx.beginPath();
        ctx.moveTo(this.x, this.y - this.size / 2); // Top point
        ctx.lineTo(this.x - this.size / 2, this.y); // Bottom left
        ctx.lineTo(this.x - this.size / 3, this.y); // Left wall
        ctx.lineTo(this.x - this.size / 3, this.y + this.size / 2); // Left base
        ctx.lineTo(this.x + this.size / 3, this.y + this.size / 2); // Right base
        ctx.lineTo(this.x + this.size / 3, this.y); // Right wall
        ctx.lineTo(this.x + this.size / 2, this.y); // Bottom right
        ctx.closePath(); // Close to top point

        ctx.fill();
        ctx.stroke();

        ctx.shadowBlur = 0;

        ctx.restore();
      }
    }

    const properties = [];
    let dollarSigns = [];
    let propertyIcons = [];

    const animate = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      // Create gradient background with proper alpha for layering
      let gradient = ctx.createRadialGradient(
        canvas.width / 2,
        canvas.height / 2,
        0,
        canvas.width / 2,
        canvas.height / 2,
        canvas.width / 1.5
      );

      gradient.addColorStop(
        0,
        isIdle ? "rgba(0, 0, 0, 0.2)" : "rgba(0, 0, 0, 0.4)"
      );
      gradient.addColorStop(
        1,
        isIdle ? "rgba(0, 0, 0, 0.4)" : "rgba(0, 0, 0, 0.6)"
      );

      // Apply gradient with proper alpha for layering
      ctx.fillStyle = gradient;
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      properties.forEach((property) => {
        if (property.update()) {
          const endState = stateCoordinates[property.endState];
          dollarSigns.push(new DollarSignIcon(endState.x, endState.y));
          propertyIcons.push(new PropertyIcon(endState.x, endState.y));

          const states = Object.keys(stateCoordinates);
          property.startState = property.endState;
          do {
            property.endState =
              states[Math.floor(Math.random() * states.length)];
          } while (property.endState === property.startState);
          property.progress = 0;
          property.stream = [];
        }
        property.draw();
      });

      dollarSigns = dollarSigns.filter((dollar) => dollar.lifetime > 0);
      dollarSigns.forEach((dollar) => {
        dollar.update();
        dollar.draw();
      });

      propertyIcons = propertyIcons.filter((icon) => icon.lifetime > 0);
      propertyIcons.forEach((icon) => {
        icon.update();
        icon.draw();
      });

      // Adjusted probabilities and max properties
      if (Math.random() < 0.01 && properties.length < 10) {
        const states = Object.keys(stateCoordinates);
        const startState = states[Math.floor(Math.random() * states.length)];
        let endState;
        do {
          endState = states[Math.floor(Math.random() * states.length)];
        } while (endState === startState);
        properties.push(new Property(startState, endState));
      }

      animationFrameId = requestAnimationFrame(animate);
    };

    animate();

    const handleMouseMove = (event) => {
      mouseX = event.clientX;
      mouseY = event.clientY;
      resetIdleTimer();
    };

    const handleClick = () => {
      if (isIdle) {
        setIsIdle(false);
        resetIdleTimer();
      }
    };

    const resetIdleTimer = () => {
      if (idleTimerRef.current) clearTimeout(idleTimerRef.current);
      idleTimerRef.current = setTimeout(() => setIsIdle(true), 60000); // 1 minute
    };

    canvas.addEventListener("mousemove", handleMouseMove);
    canvas.addEventListener("click", handleClick);
    resetIdleTimer();

    return () => {
      cancelAnimationFrame(animationFrameId);
      window.removeEventListener("resize", handleResize);
      canvas.removeEventListener("mousemove", handleMouseMove);
      canvas.removeEventListener("click", handleClick);
      if (idleTimerRef.current) clearTimeout(idleTimerRef.current);
    };
  }, [isIdle, isMobile]);

  return (
    <AnimationContainer>
      <StyledUSAMap $isIdle={isIdle} />
      <AnimationCanvas ref={canvasRef} $isIdle={isIdle} />
    </AnimationContainer>
  );
};


export default BackgroundAnimation;