import styled from "styled-components";

export const TabDetails = styled.div`
  padding: clamp(10px, 2vw, 20px);
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

export const ScrollableContent = styled.div`
  flex: 1;
  overflow-y: auto;
  padding-right: 10px;
  height: calc(100% - 60px);
  position: relative;

  /* Styling for the scrollbar */
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #1a1a1a;
  }

  &::-webkit-scrollbar-thumb {
    background: #4a4a4a;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #5a5a5a;
  }
`;

export const DetailsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(300px, 100%), 1fr));
  gap: clamp(8px, 1.5vw, 15px);
  width: 100%;
  margin-bottom: 20px;
`;

export const DetailItem = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: clamp(8px, 1.5vw, 15px);
  min-width: 0; // Prevent overflow
`;

export const DetailIcon = styled.div`
  margin-right: 10px;
  color: #8853fb;
  flex-shrink: 0;
`;

export const DetailContent = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 0; // Prevent overflow
  flex: 1;
`;

export const DetailLabel = styled.span`
  font-weight: bold;
  margin-bottom: 4px;
  font-size: clamp(12px, 1.2vw, 14px);
`;

export const DetailValue = styled.span`
  color: #ccc;
  word-wrap: break-word;
  font-size: clamp(13px, 1.3vw, 15px);
  line-height: 1.4;

  a.custom-link {
    color: #00bfff;
    text-decoration: underline;

    &:hover {
      color: #1e90ff;
    }
  }
`;
