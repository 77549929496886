import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import { Users, UserPlus, UserCheck, UserX, Clock, X, Shield } from 'react-feather';
import FriendsList from './FriendsList';
import FriendsSearch from './FriendsSearch';
import { getFriendsList, getPendingRequests, respondToRequest, sendFriendRequest, cancelFriendRequest } from './friendsApi';
import { useToast } from '../../context/ToastContext';
import LoadingSpinner from '../common/LoadingSpinner';
import ConfirmationModal from '../common/ConfirmationModal';

const FriendsContainer = styled(motion.div)`
  display: grid;
  grid-template-columns: 280px 1fr;
  gap: 24px;
  height: calc(100vh - 100px);
  padding: 24px;
  background: linear-gradient(
    165deg,
    rgba(28, 28, 35, 0.85),
    rgba(18, 18, 24, 0.95)
  );
  backdrop-filter: blur(40px);
  border-radius: 24px;
  border: 1px solid rgba(255, 255, 255, 0.07);
  box-shadow: 
    0 0 0 1px rgba(255, 255, 255, 0.05),
    0 20px 50px -12px rgba(0, 0, 0, 0.5),
    0 0 100px rgba(136, 83, 251, 0.1);
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    background: radial-gradient(
      circle at top right,
      rgba(136, 83, 251, 0.08),
      transparent 70%
    );
    border-radius: inherit;
    pointer-events: none;
  }
`;

const Sidebar = styled.div`
  background: linear-gradient(
    165deg,
    rgba(38, 38, 45, 0.5),
    rgba(28, 28, 35, 0.5)
  );
  border-right: 1px solid rgba(255, 255, 255, 0.07);
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 280px;
  min-width: 280px;
  border-radius: 12px;
`;

const MainContent = styled.div`
  padding: 24px;
  overflow-y: auto;
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.02);
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(136, 83, 251, 0.2);
    border-radius: 4px;

    &:hover {
      background: rgba(136, 83, 251, 0.3);
    }
  }
`;

const MainHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
`;

const MainTitle = styled.h2`
  font-size: 1.8rem;
  background: linear-gradient(45deg, #8853fb, #3498db);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  margin: 0;
`;

const SearchButton = styled(motion.button)`
  padding: 12px 24px;
  background: linear-gradient(
    165deg,
    rgba(136, 83, 251, 0.15),
    rgba(96, 57, 176, 0.15)
  );
  border: 1px solid rgba(136, 83, 251, 0.3);
  border-radius: 12px;
  color: white;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.16, 1, 0.3, 1);

  &:hover {
    transform: translateY(-1px);
    background: linear-gradient(
      165deg,
      rgba(136, 83, 251, 0.2),
      rgba(96, 57, 176, 0.2)
    );
    border-color: rgba(136, 83, 251, 0.4);
    box-shadow: 0 8px 32px rgba(136, 83, 251, 0.2);
  }
`;

const TabsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`;

const Tab = styled(motion.button)`
  width: 100%;
  padding: 12px 16px;
  background: ${props => props.$active 
    ? 'linear-gradient(165deg, rgba(136, 83, 251, 0.15), rgba(96, 57, 176, 0.15))'
    : 'transparent'};
  border: 1px solid ${props => props.$active 
    ? 'rgba(136, 83, 251, 0.3)'
    : 'rgba(255, 255, 255, 0.07)'};
  border-radius: 12px;
  color: ${props => props.$active 
    ? 'white' 
    : 'rgba(255, 255, 255, 0.6)'};
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.16, 1, 0.3, 1);
  backdrop-filter: blur(10px);

  .tab-content {
    display: flex;
    align-items: center;
    gap: 12px;
  }

  svg {
    width: 16px;
    height: 16px;
  }

  &:hover {
    background: linear-gradient(165deg, rgba(136, 83, 251, 0.1), rgba(96, 57, 176, 0.1));
    border-color: rgba(136, 83, 251, 0.2);
    transform: translateY(-1px);
  }

  span {
    background: ${props => props.$active 
      ? 'linear-gradient(45deg, #8853fb, #3498db)'
      : 'rgba(255, 255, 255, 0.1)'};
    padding: 2px 8px;
    border-radius: 10px;
    font-size: 12px;
    font-weight: 500;
    min-width: 24px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const AddFriendButton = styled(motion.button)`
  width: 100%;
  padding: 12px 16px;
  background: linear-gradient(
    165deg,
    rgba(136, 83, 251, 0.15),
    rgba(96, 57, 176, 0.15)
  );
  border: 1px solid rgba(136, 83, 251, 0.3);
  border-radius: 12px;
  color: white;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.16, 1, 0.3, 1);
  backdrop-filter: blur(10px);

  &:hover {
    transform: translateY(-1px);
    background: linear-gradient(
      165deg,
      rgba(136, 83, 251, 0.2),
      rgba(96, 57, 176, 0.2)
    );
    border-color: rgba(136, 83, 251, 0.4);
    box-shadow: 
      0 8px 32px rgba(136, 83, 251, 0.2),
      0 0 0 1px rgba(136, 83, 251, 0.2);
  }

  svg {
    width: 18px;
    height: 18px;
  }
`;

const RequestsContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 20px 0;
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
`;

const RequestCard = styled(motion.div)`
  display: flex;
  flex-direction: column;
  gap: 8px;
  background: linear-gradient(
    165deg,
    rgba(38, 38, 45, 0.5),
    rgba(28, 28, 35, 0.5)
  );
  backdrop-filter: blur(40px);
  border: 1px solid rgba(136, 83, 251, 0.2);
  border-radius: 12px;
  padding: 16px;
  transition: all 0.3s cubic-bezier(0.16, 1, 0.3, 1);
  position: relative;

  &:hover {
    border-color: rgba(136, 83, 251, 0.3);
    transform: translateY(-1px);
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 12px;
  }

  .user-info {
    display: flex;
    flex-direction: column;
    gap: 2px;

    h3 {
      font-size: 14px;
      font-weight: 500;
      margin: 0;
      color: rgba(255, 255, 255, 0.9);
    }

    p {
      color: rgba(255, 255, 255, 0.5);
      font-size: 12px;
      margin: 0;
    }
  }

  .status-actions {
    display: flex;
    align-items: center;
    gap: 12px;
  }

  .message {
    font-size: 13px;
    color: rgba(255, 255, 255, 0.7);
    padding: 8px 12px;
    background: rgba(136, 83, 251, 0.05);
    border-radius: 8px;
    border: 1px solid rgba(136, 83, 251, 0.1);
    margin-top: 4px;
    font-style: italic;
  }
`;

const StatusBadge = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 4px 8px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 6px;
  color: rgba(255, 255, 255, 0.6);
  font-size: 11px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  white-space: nowrap;

  svg {
    width: 12px;
    height: 12px;
    stroke: rgba(136, 83, 251, 0.6);
  }
`;

const CancelButton = styled(motion.button)`
  padding: 4px 8px;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 6px;
  color: rgba(255, 255, 255, 0.8);
  font-size: 11px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background: rgba(255, 255, 255, 0.08);
    border-color: rgba(255, 255, 255, 0.15);
  }

  svg {
    width: 12px;
    height: 12px;
  }
`;

const EmptyState = styled.div`
  text-align: center;
  padding: 48px;
  color: rgba(255, 255, 255, 0.5);
  
  svg {
    width: 48px;
    height: 48px;
    margin-bottom: 16px;
    stroke: rgba(136, 83, 251, 0.4);
  }

  h3 {
    font-size: 18px;
    color: white;
    margin: 0 0 8px 0;
  }

  p {
    font-size: 14px;
    margin: 0;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 24px;
`;

const Title = styled.h1`
  font-size: 2rem;
  background: linear-gradient(45deg, #8853fb, #3498db);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  margin: 0;
  display: flex;
  align-items: center;
  gap: 12px;

  svg {
    width: 32px;
    height: 32px;
    stroke: #8853fb;
  }
`;

const RequestButton = styled(motion.button)`
  padding: 8px 16px;
  background: ${props => {
    if (props.$accept) return 'rgba(80, 200, 120, 0.1)';
    if (props.$cancel) return 'rgba(255, 255, 255, 0.05)';
    return 'rgba(255, 59, 48, 0.1)';
  }};
  border: 1px solid ${props => {
    if (props.$accept) return 'rgba(80, 200, 120, 0.3)';
    if (props.$cancel) return 'rgba(255, 255, 255, 0.1)';
    return 'rgba(255, 59, 48, 0.3)';
  }};
  border-radius: 8px;
  color: ${props => {
    if (props.$accept) return 'rgb(80, 200, 120)';
    if (props.$cancel) return 'rgba(255, 255, 255, 0.8)';
    return 'rgb(255, 59, 48)';
  }};
  font-size: 12px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 6px;
  cursor: pointer;
  transition: all 0.2s ease;

  svg {
    width: 14px;
    height: 14px;
  }

  &:hover {
    transform: translateY(-1px);
    background: ${props => {
      if (props.$accept) return 'rgba(80, 200, 120, 0.15)';
      if (props.$cancel) return 'rgba(255, 255, 255, 0.08)';
      return 'rgba(255, 59, 48, 0.15)';
    }};
    border-color: ${props => {
      if (props.$accept) return 'rgba(80, 200, 120, 0.4)';
      if (props.$cancel) return 'rgba(255, 255, 255, 0.15)';
      return 'rgba(255, 59, 48, 0.4)';
    }};
  }
`;

const Friends = () => {
  const [activeTab, setActiveTab] = useState('friends');
  const [friends, setFriends] = useState([]);
  const [receivedRequests, setReceivedRequests] = useState([]);
  const [sentRequests, setSentRequests] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showSearch, setShowSearch] = useState(false);
  const { showToast } = useToast();
  const [showCancelConfirmation, setShowCancelConfirmation] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState(null);

  useEffect(() => {
    const loadData = async () => {
      try {
        const [friendsData, requestsData] = await Promise.all([
          getFriendsList(),
          getPendingRequests()
        ]);
        
        setFriends(friendsData);
        setReceivedRequests(requestsData.received || []);
        setSentRequests(requestsData.sent || []);
      } catch (error) {
        console.error('Error loading friends data:', error);
        showToast('Failed to load friends data', 'error');
      } finally {
        setIsLoading(false);
      }
    };

    loadData();
  }, [showToast]);

  const handleAcceptRequest = async (requestId) => {
    try {
      await respondToRequest(requestId, 'accepted');
      setReceivedRequests(prev => prev.filter(r => r._id !== requestId));
      showToast('Friend request accepted', 'success');
    } catch (error) {
      showToast('Failed to accept request', 'error');
    }
  };

  const handleRejectRequest = async (requestId) => {
    try {
      await respondToRequest(requestId, 'rejected');
      setReceivedRequests(prev => prev.filter(r => r._id !== requestId));
      showToast('Friend request rejected', 'success');
    } catch (error) {
      showToast('Failed to reject request', 'error');
    }
  };

  const handleCancelRequest = async (request) => {
    setSelectedRequest(request);
    setShowCancelConfirmation(true);
  };

  const handleConfirmCancel = async () => {
    try {
      await cancelFriendRequest(selectedRequest._id);
      setSentRequests(prev => prev.filter(r => r._id !== selectedRequest._id));
      showToast('Friend request canceled', 'success');
    } catch (error) {
      showToast('Failed to cancel request', 'error');
    } finally {
      setShowCancelConfirmation(false);
      setSelectedRequest(null);
    }
  };

  const renderContent = () => {
    if (isLoading) return <LoadingSpinner />;

    switch (activeTab) {
      case 'friends':
        return friends.filter(f => f.status !== 'blocked').length > 0 ? (
          <FriendsList 
            friends={friends.filter(f => f.status !== 'blocked')} 
            onFriendRemove={(friendId) => 
              setFriends(friends.filter(f => f._id !== friendId))
            }
          />
        ) : (
          <EmptyState>
            <Users />
            <h3>No friends yet</h3>
            <p>Start adding friends to connect with them!</p>
          </EmptyState>
        );

      case 'received':
        return receivedRequests.length > 0 ? (
          <RequestsContainer>
            {receivedRequests.map(request => (
              <RequestCard
                key={request._id}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
              >
                <div className="header">
                  <div className="user-info">
                    <h3>{request.from.name}</h3>
                    <p>{request.from.email}</p>
                  </div>
                  <div className="status-actions">
                    <RequestButton
                      $accept
                      onClick={() => handleAcceptRequest(request._id)}
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                    >
                      <UserCheck size={12} />
                      Accept
                    </RequestButton>
                    <RequestButton
                      onClick={() => handleRejectRequest(request._id)}
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                    >
                      <UserX size={12} />
                      Reject
                    </RequestButton>
                  </div>
                </div>
                {request.message && (
                  <div className="message">
                    {request.message}
                  </div>
                )}
              </RequestCard>
            ))}
          </RequestsContainer>
        ) : (
          <EmptyState>
            <UserCheck />
            <h3>No pending requests</h3>
            <p>You don't have any friend requests to review</p>
          </EmptyState>
        );

      case 'sent':
        return sentRequests.length > 0 ? (
          <RequestsContainer>
            {sentRequests.map(request => (
              <RequestCard
                key={request._id}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
              >
                <div className="header">
                  <div className="user-info">
                    <h3>{request.to.name}</h3>
                    <p>{request.to.email}</p>
                  </div>
                  <div className="status-actions">
                    <StatusBadge>
                      <Clock size={12} />
                      Pending
                    </StatusBadge>
                    <CancelButton
                      onClick={() => handleCancelRequest(request)}
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                    >
                      <X size={12} />
                      Cancel
                    </CancelButton>
                  </div>
                </div>
                {request.message && (
                  <div className="message">
                    {request.message}
                  </div>
                )}
              </RequestCard>
            ))}
          </RequestsContainer>
        ) : (
          <EmptyState>
            <Clock />
            <h3>No pending requests</h3>
            <p>You haven't sent any friend requests yet</p>
          </EmptyState>
        );

      case 'blocked':
        const blockedFriends = friends.filter(f => f.status === 'blocked');
        return blockedFriends.length > 0 ? (
          <FriendsList 
            friends={blockedFriends}
            onFriendRemove={(friendId) => 
              setFriends(friends.filter(f => f._id !== friendId))
            }
          />
        ) : (
          <EmptyState>
            <Shield />
            <h3>No blocked users</h3>
            <p>You haven't blocked any users yet</p>
          </EmptyState>
        );

      default:
        return null;
    }
  };

  return (
    <FriendsContainer>
      <Sidebar>
        <Header>
          <Title>
            <Users />
            Friends
          </Title>
        </Header>

        <TabsContainer>
          <Tab
            $active={activeTab === 'friends'}
            onClick={() => setActiveTab('friends')}
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
          >
            <div className="tab-content">
              <Users size={16} />
              Friends
            </div>
            <span>{friends.filter(f => f.status !== 'blocked').length}</span>
          </Tab>
          <Tab
            $active={activeTab === 'received'}
            onClick={() => setActiveTab('received')}
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
          >
            <div className="tab-content">
              <UserCheck size={16} />
              Received
            </div>
            <span>{receivedRequests.length}</span>
          </Tab>
          <Tab
            $active={activeTab === 'sent'}
            onClick={() => setActiveTab('sent')}
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
          >
            <div className="tab-content">
              <Clock size={16} />
              Sent
            </div>
            <span>{sentRequests.length}</span>
          </Tab>
          <Tab
            $active={activeTab === 'blocked'}
            onClick={() => setActiveTab('blocked')}
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
          >
            <div className="tab-content">
              <Shield size={16} />
              Blocked
            </div>
            <span>{friends.filter(f => f.status === 'blocked').length}</span>
          </Tab>
        </TabsContainer>

        <AddFriendButton
          onClick={() => setShowSearch(true)}
          whileHover={{ scale: 1.02 }}
          whileTap={{ scale: 0.98 }}
        >
          <UserPlus />
          Add Friend
        </AddFriendButton>
      </Sidebar>

      <MainContent>
        <AnimatePresence mode="wait">
          {renderContent()}
        </AnimatePresence>
      </MainContent>

      <FriendsSearch 
        show={showSearch}
        onClose={() => setShowSearch(false)}
        onFriendAdd={(friend) => setSentRequests([...sentRequests, friend])}
      />

      <ConfirmationModal
        isOpen={showCancelConfirmation}
        onClose={() => {
          setShowCancelConfirmation(false);
          setSelectedRequest(null);
        }}
        onConfirm={handleConfirmCancel}
        title="Cancel Friend Request"
        message={`Are you sure you want to cancel your friend request to ${selectedRequest?.to?.name}?`}
      />
    </FriendsContainer>
  );
};

export default Friends; 