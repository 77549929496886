// SalesTab.jsx

import React from "react";
import { useTranslation } from "react-i18next";
import { DollarSign, Calendar, Tag, TrendingUp, Info } from "react-feather";
import { formatToUSD } from "../../../utils/helper";
import {
  TabDetails,
  ScrollableContent,
  DetailsGrid,
  DetailItem,
  DetailIcon,
  DetailContent,
  DetailLabel,
  DetailValue,
} from "./TabStyles";
import { LoadingText } from "../../common/LoadingAnimation";
import styled from "styled-components";

// Styled Components

const StyledDetailsGrid = styled(DetailsGrid)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
`;

const StyledDetailItem = styled(DetailItem)`
  background-color: #1a1a1a; // Updated to darker background
  padding: 10px;
  border-radius: 4px;
  display: flex;
  align-items: center;
`;

const StyledDetailIcon = styled(DetailIcon)`
  color: inherit; // This will keep the original icon colors
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px; // Fixed width for alignment
  margin-right: 10px;
`;

const StyledDetailLabel = styled(DetailLabel)`
  color: #a0a0a0;
  font-size: 12px;
`;

const StyledDetailValue = styled(DetailValue)`
  color: #ffffff;
  font-size: 14px;
`;

// Helper Functions
const formatResponse = (response) => {
  const linkRegex = /<a\s+(?:[^>]*?\s+)?href="([^"]*)"[^>]*>(.*?)<\/a>/g;
  return response.replace(linkRegex, (match, url, text) => {
    return `<a href="${url}" target="_blank" rel="noopener noreferrer" class="custom-link">${text}</a>`;
  });
};

const formatDate = (dateString) => {
  if (!dateString) return null;
  const date = new Date(dateString);
  return date.toLocaleDateString();
};

// SalesTab Component
const SalesTab = ({ sales, isTranslating }) => {
  const { t } = useTranslation();

  const getSaleDetails = (sale) => [
    {
      icon: <DollarSign size={18} />,
      label: t("minimumBid"),
      value: formatToUSD(sale.minimum_bid),
    },
    {
      icon: <Tag size={18} />,
      label: t("saleType"),
      value: sale.sale_type,
    },
    {
      icon: <TrendingUp size={18} />,
      label: t("struckOffAmount"),
      value: formatToUSD(sale.struck_off_amt || 0),
    },
    {
      icon: <Calendar size={18} />,
      label: t("struckOffDate"),
      value: formatDate(sale.struck_off_date) || t("notAvailable"),
    },
    {
      icon: <Calendar size={18} />,
      label: t("saleDate"),
      value: formatDate(sale.sale_date) || t("notAvailable"),
    },
    {
      icon: <Info size={18} />,
      label: t("additionalInfo"),
      value: sale.additional_info || t("notAvailable"),
    },
  ];

  return (
    <TabDetails>
      <ScrollableContent>
        <StyledDetailsGrid>
          {sales.flatMap((sale, saleIndex) =>
            getSaleDetails(sale).map((detail, detailIndex) => (
              <StyledDetailItem key={`${saleIndex}-${detailIndex}`}>
                <StyledDetailIcon>{detail.icon}</StyledDetailIcon>
                <DetailContent>
                  <StyledDetailLabel>{detail.label}</StyledDetailLabel>
                  <StyledDetailValue>
                    {isTranslating ? (
                      <LoadingText>{detail.value}</LoadingText>
                    ) : (
                      <span
                        dangerouslySetInnerHTML={{
                          __html: formatResponse(detail.value.toString()),
                        }}
                      />
                    )}
                  </StyledDetailValue>
                </DetailContent>
              </StyledDetailItem>
            ))
          )}
        </StyledDetailsGrid>
      </ScrollableContent>
    </TabDetails>
  );
};

export default SalesTab;
