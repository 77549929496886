import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  :root {
    --primary-color: #8853fb;
    --secondary-color: #6039b0;
    --background-gradient-start: #16161a;
    --background-gradient-end: #121216;
    --surface-color: rgba(28, 28, 35, 0.95);
    --text-primary: rgba(255, 255, 255, 0.95);
    --text-secondary: rgba(255, 255, 255, 0.7);
    --text-tertiary: rgba(255, 255, 255, 0.5);
  }

  html, body {
    background: linear-gradient(
      180deg,
      var(--background-gradient-start) 0%,
      var(--background-gradient-end) 100%
    );
    color: var(--text-primary);
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
    margin: 0;
    padding: 0;
    min-height: 100vh;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  #root {
    background: transparent;
    min-height: 100vh;
  }

  ::selection {
    background: rgba(136, 83, 251, 0.2);
    color: var(--text-primary);
  }

  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  ::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.1);
  }

  ::-webkit-scrollbar-thumb {
    background: rgba(136, 83, 251, 0.2);
    border-radius: 4px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: rgba(136, 83, 251, 0.3);
  }
`;

export default GlobalStyle; 