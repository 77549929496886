import React, { createContext, useState, useEffect } from "react";

export const FavoritesContext = createContext();

export const FavoritesProvider = ({ children }) => {
  const [folders, setFolders] = useState([]);
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [selectedProperties, setSelectedProperties] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isFavorited, setIsFavorited] = useState(false);

  useEffect(() => {
    const fetchFolders = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/user-data`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
            credentials: "include",
          }
        );

        if (response.ok) {
          const data = await response.json();
          setFolders(data.favorites || []); // Ensure this is always an array
        } else {
          const error = `HTTP error! status: ${response.status}`;
          console.error(error);
          setError(error);
        }
      } catch (error) {
        console.error("Error fetching folders:", error.message);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchFolders();
  }, []);
  const addFavorite = async (uid, folderId) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/user-data/add-favorite`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
          body: JSON.stringify({ uid, folderId }),
        }
      );
      if (response.ok) {
        setFolders((prevFolders) => {
          // First, remove the uid from any folder that contains it
          const updatedFolders = prevFolders.map((folder) => ({
            ...folder,
            properties: folder.properties.filter(
              (propertyId) => propertyId !== uid
            ),
          }));

          // Then, add the uid to the new folder
          return updatedFolders.map((folder) =>
            folder.id === folderId
              ? { ...folder, properties: [...folder.properties, uid] }
              : folder
          );
        });
        setIsFavorited(true);
      } else {
        console.error(
          "Failed to save favorite to the server, status:",
          response.status
        );
      }
    } catch (err) {
      console.error("Error saving favorite to the server:", err);
    }
  };

  const removeFavorite = async (uid, folderId) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/user-data/remove-favorite`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
          body: JSON.stringify({ uid, folderId }),
        }
      );
      if (response.ok) {
        setFolders((prevFolders) =>
          prevFolders.map((folder) =>
            folder.id === folderId
              ? {
                  ...folder,
                  properties: folder.properties.filter((id) => id !== uid),
                }
              : folder
          )
        );
        setIsFavorited(false);
      } else {
        console.error(
          "Failed to remove favorite from the server, status:",
          response.status
        );
      }
    } catch (err) {
      console.error("Error removing favorite from the server:", err);
    }
  };

  const moveFavorite = async (uid, fromFolderId, toFolderId) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/user-data/move-favorite`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
          body: JSON.stringify({ uid, fromFolderId, toFolderId }),
        }
      );
      if (response.ok) {
        setFolders((prevFolders) =>
          prevFolders.map((folder) => {
            if (folder.id === fromFolderId) {
              return {
                ...folder,
                properties: folder.properties.filter((id) => id !== uid),
              };
            } else if (folder.id === toFolderId) {
              return {
                ...folder,
                properties: [...folder.properties, uid],
              };
            }
            return folder;
          })
        );
      } else {
        console.error(
          "Failed to move favorite on the server, status:",
          response.status
        );
      }
    } catch (err) {
      console.error("Error moving favorite on the server:", err);
    }
  };

  const createFolder = async (folderName) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/user-data/create-folder`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
          body: JSON.stringify({ folderName }),
        }
      );
      if (response.ok) {
        const newFolder = await response.json();
        setFolders((prevFolders) => [
          ...prevFolders,
          { id: newFolder.id, name: newFolder.name, properties: [] },
        ]);
      } else {
        console.error(
          "Failed to create folder on the server, status:",
          response.status
        );
      }
    } catch (err) {
      console.error("Error creating folder on the server:", err);
    }
  };

  const deleteFolder = async (folderId) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/user-data/delete-folder`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
          body: JSON.stringify({ folderId }),
        }
      );
      if (response.ok) {
        setFolders((prevFolders) =>
          prevFolders.filter((folder) => folder.id !== folderId)
        );
        if (selectedFolder === folderId) {
          setSelectedFolder(null);
          setSelectedProperties([]);
          setIsFavorited(false);
        }
      } else {
        console.error(
          "Failed to delete folder on the server, status:",
          response.status
        );
      }
    } catch (err) {
      console.error("Error deleting folder on the server:", err);
    }
  };

  const fetchPropertiesByUIDs = async (uids) => {
    setLoading(true);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/properties/favorited-properties`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
          body: JSON.stringify({ uids }), // Ensure this is wrapped in an object
        }
      );

      if (response.ok) {
        const properties = await response.json();
        setSelectedProperties(properties);
        return properties;
      } else {
        const error = `Failed to fetch properties, status: ${response.status}`;
        console.error(error);
        setError(error);
      }
    } catch (error) {
      console.error("Error fetching properties:", error.message);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <FavoritesContext.Provider
      value={{
        folders,
        selectedFolder,
        selectedProperties,
        loading,
        error,
        isFavorited,
        addFavorite,
        removeFavorite,
        moveFavorite,
        createFolder,
        deleteFolder,
        fetchPropertiesByUIDs,
        setSelectedFolder,
      }}
    >
      {children}
    </FavoritesContext.Provider>
  );
};
