import React, { useState } from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import { UserPlus, Loader, MessageCircle, Send, X as CloseIcon } from 'react-feather';
import { sendFriendRequest } from './friendsApi';
import { useToast } from '../../context/ToastContext';

const ResultsContainer = styled(motion.div)`
  margin-top: 8px;
  background: linear-gradient(
    165deg,
    rgba(28, 28, 35, 0.95),
    rgba(18, 18, 24, 0.95)
  );
  border: 1px solid rgba(136, 83, 251, 0.2);
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 
    0 4px 20px rgba(0, 0, 0, 0.2),
    0 0 0 1px rgba(136, 83, 251, 0.1);
`;

const UserItem = styled(motion.div)`
  padding: 12px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.16, 1, 0.3, 1);

  &:last-child {
    border-bottom: none;
  }

  &:hover {
    background: linear-gradient(
      165deg,
      rgba(136, 83, 251, 0.1),
      rgba(96, 57, 176, 0.1)
    );
  }

  .user-info {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  .name {
    color: rgba(255, 255, 255, 0.9);
    font-size: 14px;
    font-weight: 500;
  }

  .email {
    color: rgba(255, 255, 255, 0.5);
    font-size: 12px;
  }

  .status-text {
    font-size: 12px;
    color: ${props => {
      switch (props.$status) {
        case 'friend': return 'rgb(80, 200, 120)';
        case 'request_sent': return 'rgba(136, 83, 251, 0.8)';
        case 'request_received': return '#3498db';
        default: return 'inherit';
      }
    }};
  }
`;

const AddButton = styled(motion.button)`
  background: none;
  border: none;
  color: rgba(136, 83, 251, 0.9);
  padding: 8px;
  cursor: pointer;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;

  &:hover {
    background: rgba(136, 83, 251, 0.1);
    transform: scale(1.1);
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
    background: rgba(255, 255, 255, 0.1);
    color: rgba(255, 255, 255, 0.4);
    transform: none;
  }

  svg {
    width: 16px;
    height: 16px;
  }
`;

const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  color: rgba(255, 255, 255, 0.5);

  svg {
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
  }
`;

const EmptyState = styled.div`
  text-align: center;
  padding: 32px;
  color: rgba(255, 255, 255, 0.5);
  font-size: 14px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;

  svg {
    color: rgba(136, 83, 251, 0.4);
    width: 24px;
    height: 24px;
    margin-bottom: 4px;
  }
`;

const HighlightedText = styled.span`
  color: ${props => props.$highlight ? '#8853fb' : 'inherit'};
  background: ${props => props.$highlight ? 'rgba(136, 83, 251, 0.1)' : 'transparent'};
  padding: ${props => props.$highlight ? '0 4px' : '0'};
  border-radius: 4px;
`;

const MessageOverlay = styled(motion.div)`
  position: absolute;
  inset: 0;
  background: linear-gradient(
    165deg,
    rgba(28, 28, 35, 0.98),
    rgba(18, 18, 24, 0.98)
  );
  backdrop-filter: blur(40px);
  border-radius: 12px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  z-index: 10;
`;

const UserCard = styled(motion.div)`
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 12px 16px;
  background: linear-gradient(
    165deg,
    rgba(136, 83, 251, 0.1),
    rgba(96, 57, 176, 0.1)
  );
  border: 1px solid rgba(136, 83, 251, 0.2);
  border-radius: 10px;

  h3 {
    color: white;
    font-size: 14px;
    margin: 0;
    font-weight: 500;
  }

  p {
    color: rgba(255, 255, 255, 0.5);
    font-size: 12px;
    margin: 0;
  }
`;

const MessageInput = styled.textarea`
  height: 80px;
  background: linear-gradient(
    165deg,
    rgba(38, 38, 45, 0.5),
    rgba(28, 28, 35, 0.5)
  );
  border: 1px solid rgba(136, 83, 251, 0.2);
  border-radius: 10px;
  color: white;
  font-size: 13px;
  padding: 12px;
  resize: none;
  transition: all 0.3s cubic-bezier(0.16, 1, 0.3, 1);
  line-height: 1.5;

  &:focus {
    outline: none;
    border-color: rgba(136, 83, 251, 0.4);
    background: linear-gradient(
      165deg,
      rgba(43, 43, 50, 0.6),
      rgba(33, 33, 40, 0.6)
    );
    box-shadow: 0 0 20px rgba(136, 83, 251, 0.1);
  }

  &::placeholder {
    color: rgba(255, 255, 255, 0.4);
  }
`;

const SendButton = styled(motion.button)`
  padding: 10px;
  background: linear-gradient(
    165deg,
    rgba(136, 83, 251, 0.15),
    rgba(96, 57, 176, 0.15)
  );
  border: 1px solid rgba(136, 83, 251, 0.3);
  border-radius: 10px;
  color: white;
  font-size: 13px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.16, 1, 0.3, 1);

  &:hover {
    transform: translateY(-1px);
    background: linear-gradient(
      165deg,
      rgba(136, 83, 251, 0.2),
      rgba(96, 57, 176, 0.2)
    );
    border-color: rgba(136, 83, 251, 0.4);
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
    transform: none;
  }

  svg {
    width: 16px;
    height: 16px;
  }
`;

const CloseButton = styled(motion.button)`
  position: absolute;
  top: 12px;
  right: 12px;
  background: none;
  border: none;
  color: rgba(255, 255, 255, 0.6);
  padding: 6px;
  cursor: pointer;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;

  &:hover {
    color: white;
    background: rgba(255, 255, 255, 0.1);
  }

  svg {
    width: 16px;
    height: 16px;
  }
`;

const FriendSearchResults = ({ results, isSearching, onFriendAdd, searchTerm = '' }) => {
  const [selectedUser, setSelectedUser] = useState(null);
  const [message, setMessage] = useState('');
  const { showToast } = useToast();

  const highlightMatch = (text, term) => {
    if (!term) return text;
    
    const regex = new RegExp(`(${term})`, 'gi');
    const parts = text.split(regex);
    
    return parts.map((part, i) => 
      regex.test(part) ? (
        <HighlightedText key={i} $highlight>
          {part}
        </HighlightedText>
      ) : (
        <span key={i}>{part}</span>
      )
    );
  };

  const handleSendRequest = async () => {
    if (!message.trim()) {
      showToast('Please add a message to your friend request', 'error');
      return;
    }

    try {
      const response = await sendFriendRequest(selectedUser._id, message);
      onFriendAdd(response);
      showToast('Friend request sent successfully', 'success');
      setSelectedUser(null);
      setMessage('');
    } catch (error) {
      showToast(error.message || 'Failed to send friend request', 'error');
    }
  };

  if (isSearching) {
    return (
      <ResultsContainer>
        <LoadingContainer>
          <Loader size={20} />
        </LoadingContainer>
      </ResultsContainer>
    );
  }

  return (
    <ResultsContainer>
      {!results.length && searchTerm.length >= 2 ? (
        <EmptyState>
          <UserPlus />
          <p>No users found matching "{searchTerm}"</p>
        </EmptyState>
      ) : (
        <>
          <AnimatePresence>
            {selectedUser && (
              <MessageOverlay
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 10 }}
                transition={{ duration: 0.2 }}
              >
                <CloseButton
                  onClick={() => setSelectedUser(null)}
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                >
                  <CloseIcon size={16} />
                </CloseButton>

                <UserCard>
                  <div>
                    <h3>{selectedUser.name}</h3>
                    <p>{selectedUser.email}</p>
                  </div>
                </UserCard>

                <MessageInput
                  placeholder="Write a message to introduce yourself..."
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  autoFocus
                />

                <SendButton
                  onClick={handleSendRequest}
                  disabled={!message.trim()}
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                >
                  <Send size={16} />
                  Send Friend Request
                </SendButton>
              </MessageOverlay>
            )}
          </AnimatePresence>

          {results.map((user) => (
            <UserItem
              key={user._id}
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              $status={user.status}
            >
              <div className="user-info">
                <div className="name">
                  {highlightMatch(user.name, searchTerm)}
                </div>
                <div className="email">
                  {highlightMatch(user.email, searchTerm)}
                </div>
                {user.status !== 'none' && (
                  <div className="status-text">
                    {user.status === 'friend' && '✓ Already friends'}
                    {user.status === 'request_sent' && '• Request sent'}
                    {user.status === 'request_received' && '• Request received'}
                  </div>
                )}
              </div>
              <AddButton
                onClick={() => setSelectedUser(user)}
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                disabled={user.status !== 'none'}
              >
                <UserPlus />
              </AddButton>
            </UserItem>
          ))}
        </>
      )}
    </ResultsContainer>
  );
};

export default FriendSearchResults; 