const buildQueryParams = (filters) => {
  console.log('Building query params with filters:', filters);

  const query = Object.keys(filters)
    .filter(
      (key) =>
        filters[key] !== "" &&
        filters[key] !== undefined &&
        filters[key] !== null &&
        (Array.isArray(filters[key]) ? filters[key].length > 0 : true)
    )
    .map((key) => {

      if (Array.isArray(filters[key])) {
        if (filters[key].length === 2 && typeof filters[key][0] === "number") {
          return `${encodeURIComponent(key)}=${encodeURIComponent(
            filters[key][0]
          )}-${encodeURIComponent(filters[key][1])}`;
        }

        return `${encodeURIComponent(key)}=${encodeURIComponent(
          filters[key].join("-")
        )}`;
      }
      
      return `${encodeURIComponent(key)}=${encodeURIComponent(filters[key])}`;
    })
    .filter(Boolean);

  const queryString = query.join("&");
  return queryString;
};

const fetchFilteredData = async (filters, page, itemsPerPage, sortConfig) => {
  const queryString = buildQueryParams(filters);
  const sortParam = `&sortKey=${sortConfig.key}&sortDirection=${sortConfig.direction}`;
  const paginationParam = `&page=${page}&itemsPerPage=${itemsPerPage}`;

  const fullUrl = `${process.env.REACT_APP_API_URL}/api/properties?${queryString}${sortParam}${paginationParam}`;

  try {
    const response = await fetch(fullUrl, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });

    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      console.error(`HTTP error! status: ${response.status}`);
      return { properties: [], totalCount: 0 };
    }
  } catch (error) {
    console.error("Failed to fetch filtered data:", error);
    return { properties: [], totalCount: 0 };
  }
};

export default fetchFilteredData;
