import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { useLinkReport } from '../../context/LinkReportContext';
import { useTranslation } from 'react-i18next';
import '../../assets/styles/modals/ReportLinkModal.css';
import { CheckCircle } from 'react-feather';

const ReportLinkModal = ({ 
  isOpen, 
  onClose, 
  propertyId, 
  reportedUrl,
  resourceType,
  resourceTitle
}) => {
  const { t } = useTranslation();
  const [reason, setReason] = useState('');
  const { submitReport, loading: isSubmitting, error } = useLinkReport();
  const [submitted, setSubmitted] = useState(false);
  const [submitError, setSubmitError] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitError(null);

    if (!propertyId) {
      setSubmitError(t('reportLinkMissingId'));
      return;
    }

    try {
      await submitReport({
        uid: propertyId,
        url: reportedUrl,
        reason,
        resourceType,
        resourceTitle
      });

      setSubmitted(true);
    } catch (error) {
      console.error('Error submitting report:', error);
      setSubmitError(error.message || t('reportLinkError'));
    }
  };

  const handleClose = () => {
    onClose();
    // Reset state after animation
    setTimeout(() => {
      setSubmitted(false);
      setReason('');
    }, 300);
  };

  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <div className="report-modal-overlay" onClick={(e) => {
      if (e.target.className === 'report-modal-overlay') {
        handleClose();
      }
    }}>
      <div className="report-modal">
        <div className={`report-card ${submitted ? 'flipped' : ''}`}>
          <div className="report-card-front">
            <button className="close-button" onClick={handleClose}>&times;</button>
            <h2>{t('reportLinkTitle')}</h2>
            <div className="report-details">
              <p><strong>{t('reportLinkResourceType')}:</strong> {resourceType}</p>
              <p><strong>{t('reportLinkResourceTitle')}:</strong> {resourceTitle}</p>
              <p><strong>{t('reportLinkUrl')}:</strong> {reportedUrl}</p>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label>{t('reportLinkDescription')}</label>
                <textarea
                  value={reason}
                  onChange={(e) => setReason(e.target.value)}
                  required
                  rows={4}
                  placeholder={t('reportLinkPlaceholder')}
                />
              </div>
              <button 
                type="submit" 
                disabled={isSubmitting}
                className={`submit-button ${isSubmitting ? 'loading' : ''}`}
              >
                {isSubmitting ? t('reportLinkSubmitting') : t('reportLinkSubmit')}
              </button>
            </form>
          </div>
          
          <div className="report-card-back">
            <div className="success-content">
              <div className="success-icon">
                <CheckCircle size={60} />
              </div>
              <h2>{t('reportLinkSuccess')}</h2>
              <p>{t('reportLinkSuccessMessage')}</p>
              <button onClick={handleClose} className="close-success-button">
                {t('reportLinkClose')}
              </button>
            </div>
          </div>
        </div>

        {submitError && (
          <div className="error-message">
            {submitError}
          </div>
        )}
      </div>
    </div>,
    document.getElementById('modal-root')
  );
};

export default ReportLinkModal; 