import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import { Check, ChevronDown } from 'react-feather';

const DropdownContainer = styled.div`
  position: relative;
  width: 100%;
`;

const DropdownTrigger = styled.div`
  background: rgba(28, 28, 35, 0.5);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  padding: 12px 16px;
  color: #fff;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  backdrop-filter: blur(10px);
  transition: all 0.2s ease;

  &:hover {
    background: rgba(33, 33, 40, 0.6);
    border-color: rgba(136, 83, 251, 0.2);
  }

  svg {
    transition: transform 0.2s ease;
    color: rgba(255, 255, 255, 0.5);
  }

  &.active {
    border-color: rgba(136, 83, 251, 0.3);
    background: rgba(33, 33, 40, 0.8);
    
    svg {
      transform: rotate(180deg);
    }
  }
`;

const DropdownContent = styled(motion.div)`
  position: absolute;
  top: calc(100% + 8px);
  left: 0;
  right: 0;
  background: rgba(28, 28, 35, 0.95);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  backdrop-filter: blur(20px);
  box-shadow: 
    0 4px 20px rgba(0, 0, 0, 0.3),
    0 0 0 1px rgba(255, 255, 255, 0.05);
  z-index: 1000;
  max-height: 300px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: rgba(18, 18, 24, 0.3);
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: linear-gradient(
      180deg,
      rgba(136, 83, 251, 0.3),
      rgba(96, 57, 176, 0.3)
    );
    border-radius: 4px;
    border: 2px solid rgba(18, 18, 24, 0.3);
  }
`;

const Option = styled.div`
  padding: 12px 16px;
  color: #fff;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: all 0.2s ease;

  &:hover {
    background: rgba(136, 83, 251, 0.1);
  }

  .checkbox {
    width: 20px;
    height: 20px;
    border: 2px solid rgba(136, 83, 251, ${props => props.selected ? '1' : '0.3'});
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease;
    background: ${props => props.selected ? 'linear-gradient(135deg, #8853fb, #6039b0)' : 'transparent'};
    
    svg {
      color: white;
      width: 14px;
      height: 14px;
      opacity: ${props => props.selected ? 1 : 0};
      transform: scale(${props => props.selected ? 1 : 0.5});
      transition: all 0.2s ease;
    }
  }
`;

const SelectedDisplay = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  
  .placeholder {
    color: rgba(255, 255, 255, 0.5);
  }
  
  .selected-count {
    background: rgba(136, 83, 251, 0.2);
    padding: 2px 8px;
    border-radius: 12px;
    font-size: 0.85em;
  }
`;

const Dropdown = ({ 
  options, 
  selectedOption, 
  onSelect, 
  placeholder, 
  canSelectMultiple = false,
  loading = false 
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const handleSelect = (value) => {
    if (canSelectMultiple) {
      const newSelection = selectedOption.includes(value)
        ? selectedOption.filter(item => item !== value)
        : [...selectedOption, value];
      onSelect(newSelection);
    } else {
      onSelect(value);
      setIsOpen(false);
    }
  };

  const getDisplayText = () => {
    if (!selectedOption || (Array.isArray(selectedOption) && selectedOption.length === 0)) {
      return <span className="placeholder">{placeholder}</span>;
    }

    if (canSelectMultiple) {
      return (
        <SelectedDisplay>
          <span>{placeholder}</span>
          <span className="selected-count">{selectedOption.length}</span>
        </SelectedDisplay>
      );
    }

    return options.find(opt => opt.value === selectedOption)?.label || placeholder;
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <DropdownContainer ref={dropdownRef}>
      <DropdownTrigger 
        onClick={() => setIsOpen(!isOpen)}
        className={isOpen ? 'active' : ''}
      >
        {getDisplayText()}
        <ChevronDown size={20} />
      </DropdownTrigger>

      <AnimatePresence>
        {isOpen && (
          <DropdownContent
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            transition={{ duration: 0.2 }}
          >
            {options.map((option) => (
              <Option
                key={option.value}
                onClick={() => handleSelect(option.value)}
                selected={canSelectMultiple 
                  ? selectedOption.includes(option.value)
                  : selectedOption === option.value
                }
              >
                <span>{option.label}</span>
                <div className="checkbox">
                  <Check />
                </div>
              </Option>
            ))}
          </DropdownContent>
        )}
      </AnimatePresence>
    </DropdownContainer>
  );
};

export default Dropdown;
