import styled from "styled-components";
import { motion } from "framer-motion";
import { Search } from "react-feather";

export const ChatContainer = styled.div`
  display: grid;
  grid-template-columns: 300px 1fr;
  height: 98%;
  width: 98%;
  background: linear-gradient(
    165deg,
    rgba(28, 28, 35, 0.85),
    rgba(18, 18, 24, 0.95)
  );
  backdrop-filter: blur(40px);
  border-radius: 24px;
  overflow: hidden;
  border: 1px solid rgba(255, 255, 255, 0.07);
  box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.05),
    0 20px 50px -12px rgba(0, 0, 0, 0.5), 0 0 100px rgba(136, 83, 251, 0.1);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  &::before {
    content: "";
    position: absolute;
    inset: 0;
    background: radial-gradient(
      circle at top right,
      rgba(136, 83, 251, 0.08),
      transparent 70%
    );
    border-radius: inherit;
    pointer-events: none;
  }
`;

export const Sidebar = styled.div`
  background: linear-gradient(
    165deg,
    rgba(38, 38, 45, 0.5),
    rgba(28, 28, 35, 0.5)
  );
  border-right: 1px solid rgba(255, 255, 255, 0.07);
  padding: 24px;
  backdrop-filter: blur(10px);
`;

export const ChatList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 16px;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: calc(100vh - 180px);
  padding-right: 8px;
  padding-left: 8px;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.03);
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(136, 83, 251, 0.2);
    border-radius: 10px;

    &:hover {
      background: rgba(136, 83, 251, 0.3);
    }
  }
`;

export const ChatItem = styled(motion.div)`
  padding: 12px;
  border-radius: 12px;
  cursor: pointer;
  background: ${(props) =>
    props.$active
      ? "linear-gradient(165deg, rgba(136, 83, 251, 0.15), rgba(96, 57, 176, 0.15))"
      : "transparent"};
  border: 1px solid
    ${(props) => (props.$active ? "rgba(136, 83, 251, 0.3)" : "transparent")};
  transition: all 0.3s cubic-bezier(0.16, 1, 0.3, 1);

  h4 {
    font-size: 14px;
    font-weight: 500;
    color: rgba(255, 255, 255, ${(props) => (props.$active ? "0.95" : "0.8")});
    margin: 0 0 4px 0;
    transition: color 0.3s ease;
  }

  p {
    font-size: 12px;
    color: rgba(255, 255, 255, ${(props) => (props.$active ? "0.7" : "0.5")});
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px; // Add this to control the width
  }

  &:hover {
    background: linear-gradient(
      165deg,
      rgba(136, 83, 251, 0.1),
      rgba(96, 57, 176, 0.1)
    );
    transform: translateY(-1px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1), 0 0 0 1px rgba(136, 83, 251, 0.1);

    h4 {
      color: rgba(255, 255, 255, 0.95);
    }

    p {
      color: rgba(255, 255, 255, 0.7);
    }
  }

  .editable-title {
    transition: all 0.2s ease;
    padding: 4px 8px;
    margin: -4px -8px;
    border-radius: 6px;
    position: relative;

    &:hover {
      background: rgba(136, 83, 251, 0.1);

      .edit-indicator {
        opacity: 1;
      }

      &::after {
        opacity: 1;
      }
    }

    &::after {
      content: "";
      position: absolute;
      inset: 0;
      background: linear-gradient(
        135deg,
        rgba(136, 83, 251, 0.1),
        transparent 70%
      );
      border-radius: inherit;
      opacity: 0;
      transition: opacity 0.3s ease;
      pointer-events: none;
    }

    input {
      &:focus {
        outline: none;
        border-color: rgba(136, 83, 251, 0.4);
        background: linear-gradient(
          165deg,
          rgba(43, 43, 50, 0.6),
          rgba(33, 33, 40, 0.6)
        );
        box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2),
          0 0 20px rgba(136, 83, 251, 0.1), 0 0 0 1px rgba(136, 83, 251, 0.2);
      }
    }
  }
`;

export const MainChat = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  min-height: 0;
`;

export const ChatHeader = styled.div`
  padding: 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.07);
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  gap: 20px;
  background: linear-gradient(
    165deg,
    rgba(28, 28, 35, 0.5),
    rgba(18, 18, 24, 0.5)
  );
  backdrop-filter: blur(20px);
`;

export const ChatHeaderInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;

  h3 {
    color: rgba(255, 255, 255, 0.9);
    font-size: 16px;
    font-weight: 500;
    margin: 0;
  }

  span {
    color: rgba(255, 255, 255, 0.5);
    font-size: 13px;
  }
`;

export const ChatHeaderActions = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  justify-self: end;
`;

export const MessageContainer = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 16px;
  background: linear-gradient(
    165deg,
    rgba(28, 28, 35, 0.3),
    rgba(18, 18, 24, 0.3)
  );
  border-radius: 16px;
  border: 1px solid rgba(136, 83, 251, 0.1);
  backdrop-filter: blur(10px);
  position: relative;
  scroll-behavior: smooth;
  height: calc(100vh - 250px);
  min-height: 0;

  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.02);
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(136, 83, 251, 0.2);
    border-radius: 4px;

    &:hover {
      background: rgba(136, 83, 251, 0.3);
    }
  }
`;

export const InputArea = styled.div`
  padding: 16px;
  border-top: 1px solid rgba(255, 255, 255, 0.07);
  display: flex;
  align-items: center;
  gap: 12px;
  background: linear-gradient(
    165deg,
    rgba(28, 28, 35, 0.5),
    rgba(18, 18, 24, 0.5)
  );
  backdrop-filter: blur(20px);
`;

export const Input = styled.input`
  flex: 1;
  height: 44px;
  padding: 0 16px;
  border-radius: 12px;
  border: 1px solid rgba(136, 83, 251, 0.2);
  background: linear-gradient(
    165deg,
    rgba(38, 38, 45, 0.5),
    rgba(28, 28, 35, 0.5)
  );
  color: rgba(255, 255, 255, 0.9);
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.2px;
  backdrop-filter: blur(10px);
  transition: all 0.3s cubic-bezier(0.16, 1, 0.3, 1);
  width: calc(100% - 56px);

  &::placeholder {
    color: rgba(255, 255, 255, 0.4);
  }

  &:focus {
    outline: none;
    border-color: rgba(136, 83, 251, 0.4);
    background: linear-gradient(
      165deg,
      rgba(43, 43, 50, 0.6),
      rgba(33, 33, 40, 0.6)
    );
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2), 0 0 20px rgba(136, 83, 251, 0.1),
      0 0 0 1px rgba(136, 83, 251, 0.2);
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
    background: linear-gradient(
      165deg,
      rgba(38, 38, 45, 0.3),
      rgba(28, 28, 35, 0.3)
    );
  }
`;

export const IconButton = styled(motion.button)`
  width: 44px;
  height: 44px;
  flex-shrink: 0;
  background: linear-gradient(
    165deg,
    rgba(136, 83, 251, 0.15),
    rgba(96, 57, 176, 0.15)
  );
  border: 1px solid rgba(136, 83, 251, 0.3);
  border-radius: 12px;
  color: rgba(255, 255, 255, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  backdrop-filter: blur(20px);
  transition: all 0.3s cubic-bezier(0.16, 1, 0.3, 1);

  svg {
    width: 18px;
    height: 18px;
    transition: transform 0.3s ease;
  }

  &:hover {
    transform: translateY(-1px);
    background: linear-gradient(
      165deg,
      rgba(136, 83, 251, 0.2),
      rgba(96, 57, 176, 0.2)
    );
    border-color: rgba(136, 83, 251, 0.4);
    box-shadow: 0 8px 32px rgba(136, 83, 251, 0.2),
      0 0 0 1px rgba(136, 83, 251, 0.2);

    svg {
      transform: scale(1.1);
    }
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
    transform: none;
    box-shadow: none;

    svg {
      transform: none;
    }
  }
`;

export const SearchContainer = styled.div`
  position: relative;
  margin-bottom: 16px;
  width: 100%;
`;

export const SearchInput = styled(Input)`
  height: 40px;
  padding: 0 36px;
  font-size: 13px;
  background: linear-gradient(
    165deg,
    rgba(38, 38, 45, 0.4),
    rgba(28, 28, 35, 0.4)
  );
  border: 1px solid rgba(136, 83, 251, 0.15);

  &:focus {
    background: linear-gradient(
      165deg,
      rgba(43, 43, 50, 0.5),
      rgba(33, 33, 40, 0.5)
    );
    border-color: rgba(136, 83, 251, 0.3);
  }

  &::placeholder {
    color: rgba(255, 255, 255, 0.4);
  }
`;

export const SearchIcon = styled(Search)`
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  color: rgba(136, 83, 251, 0.6);
  width: 16px;
  height: 16px;
`;

export const NewChatButton = styled(IconButton)`
  width: 100%;
  height: 40px;
  margin-bottom: 16px;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.3px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  color: rgba(255, 255, 255, 0.9);

  svg {
    width: 16px;
    height: 16px;
  }
`;

export const UserSearchModal = styled(motion.div)`
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(8px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
  padding: 20px;
`;

export const UserSearchContent = styled(motion.div)`
  background: linear-gradient(
    165deg,
    rgba(28, 28, 35, 0.85),
    rgba(18, 18, 24, 0.95)
  );
  backdrop-filter: blur(40px);
  border-radius: 24px;
  padding: 24px;
  width: 90%;
  max-width: 440px;
  border: 1px solid rgba(255, 255, 255, 0.07);
  box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.05),
    0 20px 50px -12px rgba(0, 0, 0, 0.5), 0 0 100px rgba(136, 83, 251, 0.1);

  h3 {
    color: rgba(255, 255, 255, 0.9);
    font-size: 18px;
    margin-bottom: 16px;
    text-align: center;
  }

  .search-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }

  .close-button {
    background: none;
    border: none;
    color: rgba(255, 255, 255, 0.6);
    cursor: pointer;
    padding: 4px;

    &:hover {
      color: rgba(255, 255, 255, 0.9);
    }
  }
`;

export const UserList = styled.div`
  margin-top: 16px;
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 0 8px;
`;

export const UserItem = styled(motion.div)`
  padding: 12px 16px;
  border-radius: 12px;
  cursor: pointer;
  background: linear-gradient(
    165deg,
    rgba(38, 38, 45, 0.5),
    rgba(28, 28, 35, 0.5)
  );
  border: 1px solid rgba(136, 83, 251, 0.2);
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  transition: all 0.3s cubic-bezier(0.16, 1, 0.3, 1);

  .user-info {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  .name {
    color: rgba(255, 255, 255, 0.9);
    font-size: 14px;
    font-weight: 500;
  }

  .email {
    color: rgba(255, 255, 255, 0.5);
    font-size: 12px;
  }

  svg {
    width: 16px;
    height: 16px;
    color: rgba(136, 83, 251, 0.9);
    transition: all 0.3s ease;
  }

  &:hover {
    background: linear-gradient(
      165deg,
      rgba(136, 83, 251, 0.1),
      rgba(96, 57, 176, 0.1)
    );
    border-color: rgba(136, 83, 251, 0.3);
    transform: translateY(-1px);
    box-shadow: 0 4px 12px rgba(136, 83, 251, 0.1),
      0 0 0 1px rgba(136, 83, 251, 0.2);

    svg {
      color: rgba(136, 83, 251, 1);
      transform: scale(1.1);
    }

    .name {
      color: rgba(255, 255, 255, 1);
    }

    .email {
      color: rgba(255, 255, 255, 0.7);
    }
  }

  &:active {
    transform: translateY(0px);
  }
`;

export const ChatOptionsMenu = styled(motion.div)`
  position: absolute;
  top: 60px;
  right: 20px;
  background: linear-gradient(
    165deg,
    rgba(28, 28, 35, 0.95),
    rgba(18, 18, 24, 0.95)
  );
  backdrop-filter: blur(20px);
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.07);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2), 0 0 0 1px rgba(136, 83, 251, 0.1);
  overflow: hidden;
  z-index: 100;
`;

export const ChatOptionItem = styled(motion.button)`
  width: 100%;
  padding: 12px 16px;
  background: none;
  border: none;
  color: ${(props) =>
    props.$danger ? "rgba(255, 59, 48, 0.9)" : "rgba(255, 255, 255, 0.9)"};
  font-size: 14px;
  text-align: left;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: all 0.2s ease;

  &:hover {
    background: ${(props) =>
      props.$danger ? "rgba(255, 59, 48, 0.1)" : "rgba(136, 83, 251, 0.1)"};
  }
`;

export const UnreadBadge = styled.div`
  background: #8853fb;
  color: white;
  border-radius: 12px;
  padding: 2px 8px;
  font-size: 12px;
  font-weight: 500;
  margin-left: auto;
`;

export const ModalDeleteButton = styled(motion.button)`
  padding: 12px 24px;
  border-radius: 12px;
  font-size: 14px;
  font-weight: 500;
  border: 1px solid;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.16, 1, 0.3, 1);
  backdrop-filter: blur(10px);

  ${(props) =>
    props.$variant === "cancel"
      ? `
    background: linear-gradient(
      165deg,
      rgba(255, 255, 255, 0.1),
      rgba(255, 255, 255, 0.05)
    );
    border-color: rgba(255, 255, 255, 0.1);
    color: rgba(255, 255, 255, 0.9);

    &:hover {
      background: linear-gradient(
        165deg,
        rgba(255, 255, 255, 0.15),
        rgba(255, 255, 255, 0.1)
      );
      border-color: rgba(255, 255, 255, 0.2);
      transform: translateY(-1px);
    }
  `
      : `
    background: linear-gradient(
      165deg,
      rgba(255, 59, 48, 0.15),
      rgba(255, 59, 48, 0.1)
    );
    border-color: rgba(255, 59, 48, 0.3);
    color: rgba(255, 59, 48, 0.9);

    &:hover {
      background: linear-gradient(
        165deg,
        rgba(255, 59, 48, 0.2),
        rgba(255, 59, 48, 0.15)
      );
      border-color: rgba(255, 59, 48, 0.4);
      transform: translateY(-1px);
      box-shadow: 0 8px 32px rgba(255, 59, 48, 0.2);
    }
  `}

  &:active {
    transform: translateY(1px);
  }
`;

export const DeleteConfirmationModal = styled(motion.div)`
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(20px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
  padding: 20px;
  perspective: 1000px;
`;

export const DeleteConfirmationContent = styled(motion.div)`
  background: linear-gradient(
    165deg,
    rgba(28, 28, 35, 0.85),
    rgba(18, 18, 24, 0.95)
  );
  backdrop-filter: blur(40px);
  border-radius: 24px;
  padding: 32px;
  width: 90%;
  max-width: 400px;
  border: 1px solid rgba(255, 255, 255, 0.07);
  box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.05),
    0 20px 50px -12px rgba(0, 0, 0, 0.5), 0 0 100px rgba(136, 83, 251, 0.1);
  position: relative;
  overflow: hidden;
  transform-style: preserve-3d;

  &::before {
    content: "";
    position: absolute;
    inset: 0;
    background: radial-gradient(
      circle at top right,
      rgba(136, 83, 251, 0.08),
      transparent 70%
    );
    border-radius: inherit;
    pointer-events: none;
  }
`;

export const DeleteTitle = styled.h3`
  color: rgba(255, 255, 255, 0.9);
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 16px;
  text-align: center;
  transform: translateZ(10px);
`;

export const DeleteDescription = styled.p`
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
  line-height: 1.6;
  text-align: center;
  margin-bottom: 24px;
  transform: translateZ(5px);
`;

export const DeleteActions = styled.div`
  display: flex;
  gap: 12px;
  justify-content: center;
  transform: translateZ(15px);
`;

// Add this new styled component for the empty state
export const EmptyStateContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: rgba(255, 255, 255, 0.5);
  gap: 16px;
  padding: 32px;
  text-align: center;
  background: linear-gradient(
    165deg,
    rgba(28, 28, 35, 0.3),
    rgba(18, 18, 24, 0.3)
  );
  border-radius: 24px;
  margin: 16px;
  border: 1px solid rgba(136, 83, 251, 0.1);
  backdrop-filter: blur(10px);
  svg {
    width: 48px;
    height: 48px;
    color: rgba(136, 83, 251, 0.4);
    margin-bottom: 16px;
  }

  h3 {
    font-size: 20px;
    font-weight: 500;
    color: rgba(255, 255, 255, 0.9);
    margin: 0;
    background: linear-gradient(45deg, #8853fb, #3498db);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  p {
    font-size: 15px;
    line-height: 1.6;
    color: rgba(255, 255, 255, 0.5);
    max-width: 320px;
    margin: 0;
  }
`;

export const ChatSearchContainer = styled.div`
  position: relative;
  width: 100%;
  max-width: 400px;
  justify-self: center;

  @media (max-width: 768px) {
    max-width: 300px;
  }
`;

export const ChatSearchInput = styled(Input)`
  height: 36px;
  background: linear-gradient(
    165deg,
    rgba(38, 38, 45, 0.4),
    rgba(28, 28, 35, 0.4)
  );
  border: 1px solid rgba(136, 83, 251, 0.15);
  padding-left: 36px;
  font-size: 13px;

  &:focus {
    background: linear-gradient(
      165deg,
      rgba(43, 43, 50, 0.5),
      rgba(33, 33, 40, 0.5)
    );
    border-color: rgba(136, 83, 251, 0.3);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1), 0 0 0 1px rgba(136, 83, 251, 0.2);
  }

  &::placeholder {
    color: rgba(255, 255, 255, 0.4);
  }
`;

export const ChatSearchIcon = styled(Search)`
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  color: rgba(136, 83, 251, 0.6);
  width: 16px;
  height: 16px;
  pointer-events: none;
  transition: color 0.2s ease;

  ${ChatSearchContainer}:hover & {
    color: rgba(136, 83, 251, 0.8);
  }

  ${ChatSearchInput}:focus + & {
    color: rgba(136, 83, 251, 1);
  }
`;

export const LoadingWaveContainer = styled.div`
  position: relative;
  width: 100%;
  height: 40px;
  overflow: hidden;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const WaveEffect = styled.div`
  position: absolute;
  width: 200%;
  height: 100%;
  background: linear-gradient(
    90deg,
    transparent,
    rgba(136, 83, 251, 0.1),
    rgba(136, 83, 251, 0.2),
    rgba(136, 83, 251, 0.1),
    transparent
  );
  animation: waveAnimation 2s linear infinite;
  transform-origin: center;
  opacity: 0.5;

  @keyframes waveAnimation {
    0% {
      transform: translateX(-50%) perspective(500px) rotateX(60deg) scaleY(0.5);
    }
    100% {
      transform: translateX(0%) perspective(500px) rotateX(60deg) scaleY(0.5);
    }
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: inherit;
    filter: blur(8px);
  }
`;

export const LoadingText = styled.div`
  color: rgba(255, 255, 255, 0.7);
  font-size: 13px;
  z-index: 1;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
`;

export const ParticipantsModal = styled(motion.div)`
  position: absolute;
  top: 70px;
  left: 420px;
  width: 300px;
  max-height: 400px;
  background: linear-gradient(
    165deg,
    rgba(28, 28, 35, 0.95),
    rgba(18, 18, 24, 0.95)
  );
  backdrop-filter: blur(40px);
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.07);
  box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.05),
    0 20px 50px -12px rgba(0, 0, 0, 0.5), 0 0 100px rgba(136, 83, 251, 0.1);
  z-index: 10;
  overflow: hidden;
  transform-origin: top left;

  &::before {
    content: "";
    position: absolute;
    inset: 0;
    background: radial-gradient(
      circle at top left,
      rgba(136, 83, 251, 0.08),
      transparent 70%
    );
    border-radius: inherit;
    pointer-events: none;
  }
`;

export const ParticipantList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 12px;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: calc(100% - 60px);

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.02);
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(136, 83, 251, 0.2);
    border-radius: 3px;
  }
`;

export const ParticipantItem = styled.div`
  padding: 10px;
  background: linear-gradient(
    165deg,
    rgba(38, 38, 45, 0.5),
    rgba(28, 28, 35, 0.5)
  );
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.07);

  .name {
    color: rgba(255, 255, 255, 0.9);
    font-size: 13px;
    font-weight: 500;
  }

  .email {
    color: rgba(255, 255, 255, 0.5);
    font-size: 11px;
    margin-top: 2px;
  }
`;

export const ParticipantsButton = styled(motion.button)`
  background: linear-gradient(
    165deg,
    rgba(136, 83, 251, 0.15),
    rgba(96, 57, 176, 0.15)
  );
  border: 1px solid rgba(136, 83, 251, 0.3);
  border-radius: 8px;
  padding: 4px 12px;
  color: rgba(255, 255, 255, 0.9);
  font-size: 13px;
  display: flex;
  align-items: center;
  gap: 6px;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.16, 1, 0.3, 1);
  backdrop-filter: blur(10px);

  &:hover {
    background: linear-gradient(
      165deg,
      rgba(136, 83, 251, 0.2),
      rgba(96, 57, 176, 0.2)
    );
    border-color: rgba(136, 83, 251, 0.4);
    transform: translateY(-1px);
    box-shadow: 0 4px 12px rgba(136, 83, 251, 0.1),
      0 0 0 1px rgba(136, 83, 251, 0.2);
  }

  &:active {
    transform: translateY(1px);
  }

  svg {
    width: 14px;
    height: 14px;
    color: rgba(136, 83, 251, 0.9);
  }
`;

export const ModalHeader = styled.div`
  padding: 12px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.07);
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: linear-gradient(
    165deg,
    rgba(38, 38, 45, 0.5),
    rgba(28, 28, 35, 0.5)
  );

  h3 {
    color: rgba(255, 255, 255, 0.9);
    font-size: 14px;
    font-weight: 500;
    margin: 0;
  }
`;

export const CloseButton = styled(motion.button)`
  background: none;
  border: none;
  color: rgba(255, 255, 255, 0.6);
  cursor: pointer;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  transition: all 0.2s ease;

  &:hover {
    color: rgba(255, 255, 255, 0.9);
    background: rgba(255, 255, 255, 0.1);
  }

  svg {
    width: 16px;
    height: 16px;
  }
`;

export const TabsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  margin-bottom: 16px;
`;

export const Tab = styled(motion.button)`
  width: 100%;
  padding: 12px 16px;
  background: ${(props) =>
    props.$active
      ? "linear-gradient(165deg, rgba(136, 83, 251, 0.15), rgba(96, 57, 176, 0.15))"
      : "transparent"};
  border: 1px solid
    ${(props) =>
      props.$active ? "rgba(136, 83, 251, 0.3)" : "rgba(255, 255, 255, 0.07)"};
  border-radius: 12px;
  color: ${(props) => (props.$active ? "white" : "rgba(255, 255, 255, 0.6)")};
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.16, 1, 0.3, 1);
  backdrop-filter: blur(10px);

  .tab-content {
    display: flex;
    align-items: center;
    gap: 12px;
  }

  svg {
    width: 16px;
    height: 16px;
  }

  &:hover {
    background: linear-gradient(
      165deg,
      rgba(136, 83, 251, 0.1),
      rgba(96, 57, 176, 0.1)
    );
    border-color: rgba(136, 83, 251, 0.2);
    transform: translateY(-1px);
  }

  span {
    background: ${(props) =>
      props.$active
        ? "linear-gradient(45deg, #8853fb, #3498db)"
        : "rgba(255, 255, 255, 0.1)"};
    padding: 2px 8px;
    border-radius: 10px;
    font-size: 12px;
    font-weight: 500;
    min-width: 24px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const ChatHistoryModal = styled(motion.div)`
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(8px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
  padding: 20px;
  perspective: 1000px;
`;

export const ChatHistoryContent = styled(motion.div)`
  background: linear-gradient(
    165deg,
    rgba(28, 28, 35, 0.85),
    rgba(18, 18, 24, 0.95)
  );
  backdrop-filter: blur(40px);
  border-radius: 24px;
  padding: 32px;
  width: 90%;
  max-width: 600px;
  border: 1px solid rgba(255, 255, 255, 0.07);
  box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.05),
    0 20px 50px -12px rgba(0, 0, 0, 0.5), 
    0 0 100px rgba(136, 83, 251, 0.1);
  position: relative;
  overflow: hidden;
  transform-style: preserve-3d;

  &::before {
    content: "";
    position: absolute;
    inset: 0;
    background: radial-gradient(
      circle at top right,
      rgba(136, 83, 251, 0.08),
      transparent 70%
    );
    border-radius: inherit;
    pointer-events: none;
  }
`;

export const ChatHistoryList = styled.div`
  max-height: 70vh;
  overflow-y: auto;
  margin: 20px 0;
  padding: 0 16px;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.02);
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(136, 83, 251, 0.2);
    border-radius: 3px;
  }
`;

export const ChatHistoryItem = styled(motion.div)`
  padding: 16px;
  margin: 8px 0;
  background: linear-gradient(
    165deg,
    rgba(38, 38, 45, 0.5),
    rgba(28, 28, 35, 0.5)
  );
  border: 1px solid rgba(136, 83, 251, 0.1);
  border-radius: 12px;
  color: rgba(255, 255, 255, 0.9);
  
  .timestamp {
    font-size: 12px;
    color: rgba(255, 255, 255, 0.5);
    margin-bottom: 8px;
  }

  .content {
    font-size: 14px;
    line-height: 1.6;
  }

  &:hover {
    background: linear-gradient(
      165deg,
      rgba(136, 83, 251, 0.1),
      rgba(96, 57, 176, 0.1)
    );
    border-color: rgba(136, 83, 251, 0.2);
    transform: translateY(-1px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }
`;
