import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import { UserX, Shield, Search, Users, MoreVertical, MessageCircle, Trash2 } from 'react-feather';
import { removeFriend, blockUser, unblockUser, deleteUser } from './friendsApi';
import { useToast } from '../../context/ToastContext';
import ConfirmationModal from '../common/ConfirmationModal';
import { createOrGetChat } from '../chat/chatApi';

const ListContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 12px;
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
  justify-content: center;
`;

const FriendCard = styled(motion.div)`
  background: linear-gradient(
    165deg,
    rgba(38, 38, 45, 0.5),
    rgba(28, 28, 35, 0.5)
  );
  border: 1px solid rgba(136, 83, 251, 0.2);
  border-radius: 12px;
  padding: 16px;
  width: 350px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  backdrop-filter: blur(10px);
  transition: all 0.3s cubic-bezier(0.16, 1, 0.3, 1);

  &:hover {
    border-color: rgba(136, 83, 251, 0.3);
    transform: translateY(-1px);
    box-shadow: 
      0 4px 20px rgba(0, 0, 0, 0.2),
      0 0 20px rgba(136, 83, 251, 0.1);
  }
`;

const FriendInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  h3 {
    color: rgba(255, 255, 255, 0.9);
    font-size: 16px;
    margin: 0;
  }

  p {
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
    margin: 0;
  }
`;

const ActionButtons = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

const IconButton = styled(motion.button)`
  background: none;
  border: none;
  color: ${props => props.$danger ? 'rgba(255, 59, 48, 0.9)' : props.$message ? 'rgba(136, 83, 251, 0.9)' : 'rgba(255, 255, 255, 0.6)'};
  padding: 8px;
  cursor: pointer;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;

  &:hover {
    background: ${props => props.$danger ? 'rgba(255, 59, 48, 0.1)' : props.$message ? 'rgba(136, 83, 251, 0.1)' : 'rgba(255, 255, 255, 0.1)'};
    transform: scale(1.1);
  }
`;

const DropdownMenu = styled(motion.div)`
  position: absolute;
  top: 100%;
  right: 0;
  background: linear-gradient(
    165deg,
    rgba(28, 28, 35, 0.95),
    rgba(18, 18, 24, 0.95)
  );
  backdrop-filter: blur(40px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  padding: 8px;
  min-width: 180px;
  box-shadow: 
    0 4px 20px rgba(0, 0, 0, 0.3),
    0 0 0 1px rgba(255, 255, 255, 0.05);
  z-index: 100;
`;

const DropdownItem = styled(motion.button)`
  width: 100%;
  padding: 10px 12px;
  background: none;
  border: none;
  color: ${props => props.$danger ? 'rgba(255, 59, 48, 0.9)' : 'rgba(255, 255, 255, 0.8)'};
  font-size: 13px;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  border-radius: 8px;
  transition: all 0.2s ease;

  &:hover {
    background: ${props => props.$danger ? 'rgba(255, 59, 48, 0.1)' : 'rgba(255, 255, 255, 0.05)'};
  }

  svg {
    width: 16px;
    height: 16px;
  }
`;

const DropdownContainer = styled.div`
  position: relative;
`;

const EmptyState = styled.div`
  text-align: center;
  padding: 32px;
  color: rgba(255, 255, 255, 0.5);
`;

const SearchContainer = styled.div`
  margin-bottom: 20px;
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;

  .input-container {
    position: relative;
    width: 80%;
  }
`;

const SearchInput = styled.input`
  width: 100%;
  height: 40px;
  background: linear-gradient(
    165deg,
    rgba(38, 38, 45, 0.5),
    rgba(28, 28, 35, 0.5)
  );
  border: 1px solid rgba(136, 83, 251, 0.2);
  border-radius: 10px;
  color: white;
  font-size: 13px;
  padding: 0 40px;
  transition: all 0.3s cubic-bezier(0.16, 1, 0.3, 1);
  backdrop-filter: blur(10px);

  &:focus {
    outline: none;
    border-color: rgba(136, 83, 251, 0.4);
    background: linear-gradient(
      165deg,
      rgba(43, 43, 50, 0.6),
      rgba(33, 33, 40, 0.6)
    );
    box-shadow: 
      0 4px 20px rgba(0, 0, 0, 0.2),
      0 0 20px rgba(136, 83, 251, 0.1);
  }

  &::placeholder {
    color: rgba(255, 255, 255, 0.4);
  }
`;

const SearchIcon = styled.div`
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  color: rgba(136, 83, 251, 0.6);
`;

const BlockedBadge = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 4px 8px;
  background: rgba(255, 59, 48, 0.1);
  border: 1px solid rgba(255, 59, 48, 0.2);
  border-radius: 6px;
  color: rgb(255, 59, 48);
  font-size: 11px;
  font-weight: 500;
`;

const UnblockButton = styled(motion.button)`
  padding: 6px 12px;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  color: rgba(255, 255, 255, 0.8);
  font-size: 12px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 6px;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background: rgba(255, 255, 255, 0.08);
    border-color: rgba(255, 255, 255, 0.15);
    transform: translateY(-1px);
  }

  svg {
    width: 14px;
    height: 14px;
  }
`;

const FriendsList = ({ friends, onFriendRemove }) => {
  const navigate = useNavigate();
  const { showToast } = useToast();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showBlockModal, setShowBlockModal] = useState(false);
  const [selectedFriend, setSelectedFriend] = useState(null);
  const [blockReason, setBlockReason] = useState('');
  const [openDropdownId, setOpenDropdownId] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const handleRemoveClick = (friend) => {
    setSelectedFriend(friend);
    setShowConfirmation(true);
    setOpenDropdownId(null);
  };

  const handleConfirmRemove = async () => {
    try {
      await removeFriend(selectedFriend.friendshipId);
      onFriendRemove(selectedFriend._id);
      showToast('Friend removed successfully', 'success');
    } catch (error) {
      showToast('Failed to remove friend', 'error');
    } finally {
      setShowConfirmation(false);
      setSelectedFriend(null);
    }
  };

  const handleBlock = (friend) => {
    setSelectedFriend(friend);
    setShowBlockModal(true);
    setOpenDropdownId(null);
  };

  const handleConfirmBlock = async () => {
    try {
      await blockUser(selectedFriend._id, blockReason);
      onFriendRemove(selectedFriend._id);
      showToast('User blocked successfully', 'success');
    } catch (error) {
      showToast('Failed to block user', 'error');
    } finally {
      setShowBlockModal(false);
      setSelectedFriend(null);
      setBlockReason('');
    }
  };

  const handleChatClick = async (friendId) => {
    try {
      const chatData = await createOrGetChat(friendId);
      navigate(`/home/chat?chatId=${chatData._id}`);
    } catch (error) {
      showToast('Failed to open chat', 'error');
      console.error('Error opening chat:', error);
    }
  };

  const handleUnblock = async (friend) => {
    try {
      await unblockUser(friend._id);
      onFriendRemove(friend._id);
      showToast('User unblocked successfully', 'success');
    } catch (error) {
      showToast('Failed to unblock user', 'error');
    } finally {
      setOpenDropdownId(null);
    }
  };

  const handleDeleteUser = (friend) => {
    setSelectedFriend(friend);
    setShowDeleteConfirmation(true);
    setOpenDropdownId(null);
  };

  const handleConfirmDelete = async () => {
    try {
      await deleteUser(selectedFriend._id);
      onFriendRemove(selectedFriend._id);
      showToast('User deleted successfully', 'success');
    } catch (error) {
      showToast('Failed to delete user', 'error');
    } finally {
      setShowDeleteConfirmation(false);
      setSelectedFriend(null);
    }
  };

  const filteredFriends = friends.filter(friend => 
    friend.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    friend.email.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <>
      <SearchContainer>
        <div className="input-container">
          <SearchIcon>
            <Search size={16} />
          </SearchIcon>
          <SearchInput
            type="text"
            placeholder="Search friends..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
      </SearchContainer>

      {!filteredFriends.length ? (
        <EmptyState>
          {searchTerm ? (
            <>
              <p>No friends found matching "{searchTerm}"</p>
            </>
          ) : (
            <>
              <Users />
              <h3>No friends yet</h3>
              <p>Start adding friends to connect with them!</p>
            </>
          )}
        </EmptyState>
      ) : (
        <ListContainer>
          {filteredFriends.map((friend) => (
            <FriendCard
              key={friend._id}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
            >
              <FriendInfo>
                <h3>{friend.name}</h3>
                <p>{friend.email}</p>
                {friend.status === 'blocked' && (
                  <BlockedBadge>
                    <Shield size={12} />
                    Blocked
                    {friend.blockReason && (
                      <span style={{ 
                        marginLeft: '4px', 
                        color: 'rgba(255, 59, 48, 0.7)',
                        fontSize: '10px' 
                      }}>
                        • {friend.blockReason}
                      </span>
                    )}
                  </BlockedBadge>
                )}
              </FriendInfo>
              <ActionButtons>
                {friend.status === 'blocked' ? (
                  <>
                    <UnblockButton
                      onClick={() => handleUnblock(friend)}
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                    >
                      <Shield size={14} />
                      Unblock
                    </UnblockButton>
                    <DropdownContainer>
                      <IconButton
                        onClick={() => setOpenDropdownId(openDropdownId === friend._id ? null : friend._id)}
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.9 }}
                      >
                        <MoreVertical size={20} />
                      </IconButton>
                      <AnimatePresence>
                        {openDropdownId === friend._id && (
                          <DropdownMenu
                            initial={{ opacity: 0, y: -10 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0, y: -10 }}
                            transition={{ duration: 0.2 }}
                          >
                            <DropdownItem
                              onClick={() => handleDeleteUser(friend)}
                              $danger
                            >
                              <Trash2 size={16} />
                              Delete User
                            </DropdownItem>
                          </DropdownMenu>
                        )}
                      </AnimatePresence>
                    </DropdownContainer>
                  </>
                ) : (
                  <>
                    <IconButton
                      $message
                      onClick={() => handleChatClick(friend._id)}
                      whileHover={{ scale: 1.1 }}
                      whileTap={{ scale: 0.9 }}
                    >
                      <MessageCircle size={20} />
                    </IconButton>
                    <DropdownContainer>
                      <IconButton
                        onClick={() => setOpenDropdownId(openDropdownId === friend._id ? null : friend._id)}
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.9 }}
                      >
                        <MoreVertical size={20} />
                      </IconButton>
                      <AnimatePresence>
                        {openDropdownId === friend._id && (
                          <DropdownMenu
                            initial={{ opacity: 0, y: -10 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0, y: -10 }}
                            transition={{ duration: 0.2 }}
                          >
                            <DropdownItem
                              onClick={() => handleRemoveClick(friend)}
                            >
                              <UserX size={16} />
                              Remove Friend
                            </DropdownItem>
                            <DropdownItem
                              onClick={() => handleBlock(friend)}
                              $danger
                            >
                              <Shield size={16} />
                              Block User
                            </DropdownItem>
                          </DropdownMenu>
                        )}
                      </AnimatePresence>
                    </DropdownContainer>
                  </>
                )}
              </ActionButtons>
            </FriendCard>
          ))}
        </ListContainer>
      )}

      <ConfirmationModal
        isOpen={showConfirmation}
        onClose={() => {
          setShowConfirmation(false);
          setSelectedFriend(null);
        }}
        onConfirm={handleConfirmRemove}
        title="Remove Friend"
        message={`Are you sure you want to remove ${selectedFriend?.name} from your friends list?`}
        confirmText="Remove Friend"
      />

      <ConfirmationModal
        isOpen={showBlockModal}
        onClose={() => {
          setShowBlockModal(false);
          setSelectedFriend(null);
          setBlockReason('');
        }}
        onConfirm={handleConfirmBlock}
        title="Block User"
        message={
          <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
            <p>Are you sure you want to block {selectedFriend?.name}?</p>
            <p style={{ fontSize: '12px', color: 'rgba(255, 255, 255, 0.6)' }}>
              Blocking will prevent them from sending you messages or friend requests.
            </p>
            <textarea
              placeholder="Reason for blocking (optional)"
              value={blockReason}
              onChange={(e) => setBlockReason(e.target.value)}
              style={{
                width: '95%',
                height: '80px',
                padding: '12px',
                borderRadius: '10px',
                background: 'linear-gradient(165deg, rgba(38, 38, 45, 0.5), rgba(28, 28, 35, 0.5))',
                border: '1px solid rgba(136, 83, 251, 0.2)',
                color: 'white',
                fontSize: '13px',
                resize: 'none',
                marginTop: '8px',
                marginLeft: 'auto',
                marginRight: 'auto',
                display: 'block'
              }}
            />
          </div>
        }
        confirmText="Block User"
      />

      <ConfirmationModal
        isOpen={showDeleteConfirmation}
        onClose={() => {
          setShowDeleteConfirmation(false);
          setSelectedFriend(null);
        }}
        onConfirm={handleConfirmDelete}
        title="Delete User"
        message={
          <div>
            <p>Are you sure you want to permanently delete {selectedFriend?.name}?</p>
            <p style={{ fontSize: '12px', color: 'rgba(255, 255, 255, 0.6)', marginTop: '8px' }}>
              This action cannot be undone. All data associated with this user will be permanently removed.
            </p>
          </div>
        }
        confirmText="Delete User"
      />
    </>
  );
};

export default FriendsList;