import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { motion, AnimatePresence } from 'framer-motion';
import { ArrowLeft, Eye, EyeOff, Lock, AlertTriangle } from 'react-feather';
import styled from 'styled-components';

const ResetPasswordPage = styled(motion.div)`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background: linear-gradient(
    165deg,
    rgba(28, 28, 35, 0.85),
    rgba(18, 18, 24, 0.95)
  );
  color: var(--text-color);
`;

const Header = styled.header`
  background: linear-gradient(
    165deg,
    rgba(38, 38, 45, 0.5),
    rgba(28, 28, 35, 0.5)
  );
  backdrop-filter: blur(20px);
  padding: 1rem 2rem;
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.07);
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.1);
`;

const BackButton = styled.button`
  background: linear-gradient(
    165deg,
    rgba(38, 38, 45, 0.5),
    rgba(28, 28, 35, 0.5)
  );
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  color: rgba(255, 255, 255, 0.8);
  cursor: pointer;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;
  transition: all 0.3s cubic-bezier(0.16, 1, 0.3, 1);

  &:hover {
    background: linear-gradient(
      165deg,
      rgba(43, 43, 50, 0.6),
      rgba(33, 33, 40, 0.6)
    );
    border-color: rgba(136, 83, 251, 0.2);
    transform: translateY(-1px);
    box-shadow: 
      0 4px 12px rgba(0, 0, 0, 0.2),
      0 0 0 1px rgba(136, 83, 251, 0.1);
  }

  &:active {
    transform: translateY(1px);
  }
`;

const PageTitle = styled.h1`
  font-size: 1.5rem;
  margin: 0;
  background: linear-gradient(45deg, #fff, rgba(255, 255, 255, 0.7));
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  text-shadow: 0 2px 10px rgba(255, 255, 255, 0.1);
`;

const Container = styled(motion.div)`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 4rem 2rem;
  max-width: 440px;
  margin: 0 auto;
  width: 100%;
  box-sizing: border-box;
`;

const Form = styled.form`
  width: 100%;
  max-width: 380px;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

const FormGroup = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const PasswordWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
`;

const Input = styled.input`
  width: 100%;
  height: 48px;
  background: linear-gradient(
    165deg,
    rgba(38, 38, 45, 0.5),
    rgba(28, 28, 35, 0.5)
  );
  border: 1px solid rgba(136, 83, 251, 0.2);
  border-radius: 12px;
  color: #fff;
  font-size: 14px;
  padding: 0 48px 0 16px;
  transition: all 0.3s cubic-bezier(0.16, 1, 0.3, 1);
  backdrop-filter: blur(10px);

  &:focus {
    outline: none;
    border-color: rgba(136, 83, 251, 0.4);
    background: linear-gradient(
      165deg,
      rgba(43, 43, 50, 0.6),
      rgba(33, 33, 40, 0.6)
    );
    box-shadow: 
      0 4px 20px rgba(0, 0, 0, 0.2),
      0 0 20px rgba(136, 83, 251, 0.1),
      0 0 0 1px rgba(136, 83, 251, 0.2);
  }

  &::placeholder {
    color: rgba(255, 255, 255, 0.4);
  }
`;

const TogglePassword = styled.button`
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  color: rgba(136, 83, 251, 0.6);
  cursor: pointer;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
  border-radius: 8px;

  &:hover {
    color: rgba(136, 83, 251, 0.9);
    background: rgba(136, 83, 251, 0.1);
  }
`;

const SubmitButton = styled(motion.button)`
  width: 100%;
  height: 48px;
  background: linear-gradient(
    165deg,
    rgba(136, 83, 251, 0.15),
    rgba(96, 57, 176, 0.15)
  );
  border: 1px solid rgba(136, 83, 251, 0.3);
  border-radius: 12px;
  color: white;
  cursor: pointer;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0.3px;
  backdrop-filter: blur(20px);
  transition: all 0.4s cubic-bezier(0.16, 1, 0.3, 1);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  &:hover {
    transform: translateY(-2px) scale(1.02);
    background: linear-gradient(
      165deg,
      rgba(136, 83, 251, 0.2),
      rgba(96, 57, 176, 0.2)
    );
    border-color: rgba(136, 83, 251, 0.4);
    box-shadow: 
      0 8px 32px rgba(136, 83, 251, 0.2),
      0 0 0 1px rgba(136, 83, 251, 0.2);
  }

  &:active {
    transform: translateY(1px) scale(0.98);
  }
`;

const Message = styled(motion.div)`
  width: 100%;
  max-width: 380px;
  background: ${props => props.$isError 
    ? 'linear-gradient(165deg, rgba(255, 59, 48, 0.1), rgba(255, 59, 48, 0.05))'
    : 'linear-gradient(165deg, rgba(52, 199, 89, 0.1), rgba(48, 209, 88, 0.05))'
  };
  backdrop-filter: blur(8px);
  border: 1px solid ${props => props.$isError 
    ? 'rgba(255, 59, 48, 0.2)'
    : 'rgba(52, 199, 89, 0.2)'
  };
  border-radius: 12px;
  padding: 16px;
  color: ${props => props.$isError ? '#ff3b30' : '#34c759'};
  margin-top: 20px;
  text-align: center;
  font-size: 14px;
  line-height: 1.6;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  box-shadow: ${props => props.$isError
    ? '0 4px 12px rgba(255, 59, 48, 0.1), 0 0 0 1px rgba(255, 59, 48, 0.1)'
    : '0 4px 12px rgba(52, 199, 89, 0.1), 0 0 0 1px rgba(52, 199, 89, 0.1)'
  };
`;

const ResetPassword = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const { token } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage('');
    setError('');

    if (password !== confirmPassword) {
      setError(t('passwordsDoNotMatch'));
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/auth/reset-password/${token}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ password }),
      });

      const data = await response.json();

      if (response.ok) {
        setMessage(data.msg);
        setTimeout(() => navigate('/'), 3000);
      } else {
        setError(data.msg);
      }
    } catch (err) {
      setError(t('unexpectedError'));
    }
  };

  const handleBackClick = () => {
    navigate('/');
  };

  return (
    <ResetPasswordPage
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      <Header>
        <BackButton onClick={handleBackClick}>
          <ArrowLeft size={20} />
        </BackButton>
        <PageTitle>{t('resetPassword')}</PageTitle>
      </Header>

      <Container
        initial={{ y: 20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{ y: -20, opacity: 0 }}
        transition={{ duration: 0.5 }}
      >
        <Form onSubmit={handleSubmit}>
          <FormGroup>
            <PasswordWrapper>
              <Input
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder={t('newPassword')}
                required
              />
              <TogglePassword
                type="button"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? <EyeOff size={20} /> : <Eye size={20} />}
              </TogglePassword>
            </PasswordWrapper>
          </FormGroup>

          <FormGroup>
            <PasswordWrapper>
              <Input
                type={showConfirmPassword ? "text" : "password"}
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                placeholder={t('confirmPassword')}
                required
              />
              <TogglePassword
                type="button"
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              >
                {showConfirmPassword ? <EyeOff size={20} /> : <Eye size={20} />}
              </TogglePassword>
            </PasswordWrapper>
          </FormGroup>

          <SubmitButton
            type="submit"
            whileTap={{ scale: 0.98 }}
          >
            <Lock size={16} />
            {t('resetPassword')}
          </SubmitButton>
        </Form>

        <AnimatePresence mode="wait">
          {(message || error) && (
            <Message
              $isError={!!error}
              initial={{ opacity: 0, y: 20, scale: 0.95 }}
              animate={{ opacity: 1, y: 0, scale: 1 }}
              exit={{ opacity: 0, y: -20, scale: 0.95 }}
              transition={{ duration: 0.2 }}
            >
              {error ? <AlertTriangle size={16} /> : null}
              {message || error}
            </Message>
          )}
        </AnimatePresence>
      </Container>
    </ResetPasswordPage>
  );
};

export default ResetPassword;