// TODO: Move this to context api later
import { useReducer, useEffect } from "react";

const initialLocationState = {
  states: [],
  counties: [],
  cities: [],
  loading: false,
  error: null,
};

const locationReducer = (state, action) => {
  switch (action.type) {
    case "SET_DATA":
      return {
        ...state,
        [action.category]: action.payload,
        loading: false,
      };
    case "LOADING":
      return {
        ...state,
        loading: true,
      };
    case "ERROR":
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

const useLocationData = (category, query = "") => {
  const [state, dispatch] = useReducer(locationReducer, initialLocationState);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/location/${category}${query}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
            credentials: "include", // Include cookies in the request
          }
        );

        if (response.ok) {
          const result = await response.json();
          dispatch({
            type: "SET_DATA",
            category,
            payload: result || [],
          });
        } else {
          dispatch({
            type: "ERROR",
            payload: `HTTP error! status: ${response.status}`,
          });
        }
      } catch (error) {
        dispatch({ type: "ERROR", payload: error.message });
      }
    };

    dispatch({ type: "LOADING" });
    fetchData();
  }, [category, query]);

  return state;
};

export default useLocationData;
