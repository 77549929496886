import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { usePublicBuy } from "../../context/PublicBuyContext";
import { usePropertyResources } from "../../context/PropertyResourcesContext";
import PublicFilterSidebar from "../layout/buy/PublicFilterSidebar";
import DataTable from "./DataTable";
import { useTranslation } from "react-i18next";
import DetailedPropertyInfo from "../layout/buy/DetailedPropertyInfo";
import { motion, AnimatePresence } from "framer-motion";
import Toast from "./Toast";
import LoadingOverlay from "./LoadingOverlay";
import PropertyDetails from "../layout/buy/PropertyDetails";
import ModernFilterSystem from '../layout/buy/ModernFilterSystem';
import Pagination from './Pagination';
import { UserPlus, LogIn, ArrowLeft, Filter } from 'react-feather';
import { FilterOrb } from '../layout/buy/ModernFilterSystem.styles';

const PublicBuyContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const ResultsContainer = styled.div`
  flex: 1;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
`;

const ErrorMessage = styled.p`
  color: var(--error-color);
  text-align: center;
`;

const LoadingMessage = styled.p`
  text-align: center;
`;

const BlurredOverlay = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  margin-top: 20px;
`;

const ActionButton = styled.button`
  background-color: var(--primary-color);
  color: var(--text-color);
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  margin: 10px 5px 0;

  &:hover {
    background-color: var(--primary-color-dark);
  }
`;

const SearchesRemaining = styled.p`
  font-size: 14px;
  color: var(--text-secondary-color);
  margin-top: 20px;
  text-align: center;
`;

const LimitReachedMessage = styled.div`
  text-align: center;
  margin-top: 20px;
  padding: 20px;
  background-color: var(--surface-color);
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const LimitReachedTitle = styled.h3`
  color: var(--primary-color);
  margin-bottom: 10px;
`;

const LimitReachedText = styled.p`
  margin-bottom: 20px;
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
`;

const GlossyOverlay = styled(motion.div)`
  position: fixed;
  inset: 0;
  background: linear-gradient(
    145deg,
    rgba(18, 18, 24, 0.95),
    rgba(28, 28, 35, 0.85)
  );
  backdrop-filter: blur(20px);
  z-index: 10000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  text-align: center;
  color: white;
`;

const GlossyContent = styled.div`
  background: linear-gradient(
    145deg,
    rgba(28, 28, 35, 0.5),
    rgba(18, 18, 24, 0.5)
  );
  padding: 3rem;
  border-radius: 24px;
  backdrop-filter: blur(10px);
  max-width: 600px;
  width: 90%;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 
    0 0 0 1px rgba(255, 255, 255, 0.05),
    0 4px 50px rgba(0, 0, 0, 0.4);

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    background: linear-gradient(
      135deg,
      rgba(136, 83, 251, 0.1),
      transparent
    );
    border-radius: 24px;
    pointer-events: none;
  }
`;

const GlossyTitle = styled.h2`
  font-size: 2rem;
  margin-bottom: 1.5rem;
  background: linear-gradient(135deg, #8853fb, #6039b0);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  font-weight: 700;
`;

const GlossyText = styled.p`
  margin-bottom: 2rem;
  line-height: 1.8;
  font-size: 1.1rem;
  color: rgba(255, 255, 255, 0.9);
  max-width: 480px;
  margin-left: auto;
  margin-right: auto;
`;

const GlossyButtons = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: center;
  flex-wrap: wrap;
`;

const GlossyButton = styled(motion.button)`
  background: ${props => props.$primary ? 
    'linear-gradient(135deg, #8853fb, #6039b0)' : 
    'rgba(255, 255, 255, 0.1)'
  };
  border: 1px solid ${props => props.$primary ? 
    'rgba(136, 83, 251, 0.4)' : 
    'rgba(255, 255, 255, 0.2)'
  };
  padding: 12px 24px;
  border-radius: 25px;
  color: white;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  min-width: 160px;
  justify-content: center;
  backdrop-filter: blur(10px);
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-2px);
    background: ${props => props.$primary ? 
      'linear-gradient(135deg, #9163fb, #704bc0)' : 
      'rgba(255, 255, 255, 0.15)'
    };
    border-color: ${props => props.$primary ? 
      'rgba(136, 83, 251, 0.6)' : 
      'rgba(255, 255, 255, 0.3)'
    };
  }

  &:active {
    transform: translateY(1px);
  }
`;

const PublicBuyContent = ({ planType, onSignUp, onSignIn }) => {

  const { t } = useTranslation();
  const {
    publicProperties,
    loading,
    error,
    fetchPublicProperties,
    searchCount,
    totalCount: propertyCount,
    currentPage: serverPage,
    totalPages: serverTotalPages,
  } = usePublicBuy();
  const [sortConfig, setSortConfig] = useState({
    key: "account_nbr",
    direction: "ascending",
  });
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeMap, setActiveMap] = useState("Google");
  const [tabIndex, setTabIndex] = useState(0);
  const [favorites, setFavorites] = useState([]);
  const [notes, setNotes] = useState({});
  const [toast, setToast] = useState(null);
  const [detailedPropertyData, setDetailedPropertyData] = useState(null);
  const [loadingDetails, setLoadingDetails] = useState(false);
  const [expandedData, setExpandedData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [currentFilters, setCurrentFilters] = useState({});
  const [showGlossyOverlay, setShowGlossyOverlay] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [itemsPerPage] = useState(18);
  const [isDetailedOpen, setIsDetailedOpen] = useState(false);
  const [isAnyModalOpen, setIsAnyModalOpen] = useState(false);

  const loadingMessageLayers = [
    t("loadingMessages.layer1", { returnObjects: true }),
    t("loadingMessages.layer2", { returnObjects: true }),
    t("loadingMessages.layer3", { returnObjects: true }),
    t("loadingMessages.layer4", { returnObjects: true }),
  ];

  const handleApplyFilters = (filters) => {
    const processedFilters = Object.entries(filters).reduce(
      (acc, [key, value]) => {
        if (Array.isArray(value)) {
          acc[key] = value.join("-");
        } else {
          acc[key] = value;
        }
        return acc;
      },
      {}
    );

    setCurrentFilters(processedFilters); // Store the current filters
    setCurrentPage(1); // Reset to first page when filters change
    fetchPublicProperties(processedFilters, 1);
  };

  const handleSort = (key) => {
    setSortConfig((prevConfig) => ({
      key,
      direction:
        prevConfig.key === key && prevConfig.direction === "ascending"
          ? "descending"
          : "ascending",
    }));
  };

  const headers = [
    { key: "account_nbr", label: t("propertyIdentifier") },
    { key: "city", label: t("city") },
    { key: "county", label: t("county") },
    { key: "state", label: t("state") },
    { key: "zipCode", label: t("zipCode") },
    { key: "minimum_bid", label: t("minimumBid"), format: "USD" },
    { key: "value", label: t("adjudgedValue"), format: "USD" },
    {
      key: "status",
      label: t("status"),
      format: (value) => t(value.toLowerCase().replace(/\s+/g, "")),
    },
    {
      key: "sale_type",
      label: t("saleType"),
      format: (value) => t(value.toLowerCase().replace(/\s+/g, "")),
    },
  ];

  const getVisibleProperties = () => {
    return publicProperties.slice(0, itemsPerPage);
  };

  const fetchPropertyDetails = async (uid) => {
    if (!uid) {
      console.error("Invalid UID");
      setToast({ message: t("invalidPropertyId"), type: "error" });
      return;
    }
    setLoadingDetails(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/public-properties/${uid}`
      );

      if (!response.ok) {
        throw new Error("Failed to fetch property details");
      }
      const data = await response.json();

      setDetailedPropertyData(data);
      setExpandedData(data);
      setIsModalOpen(true); // Open the modal after fetching details
      setIsDetailedOpen(true); // Set this when opening details
    } catch (error) {
      console.error("Error fetching property details:", error);
      setToast({ message: t("failedToFetchDetails"), type: "error" });
    } finally {
      setLoadingDetails(false);
    }
  };

  const handleRowClick = async (index) => {
    const property = getVisibleProperties()[index];
    if (property && property.uid) {
      setSelectedProperty(property);
      await fetchPropertyDetails(property.uid);
    } else {
      console.error("Invalid property or missing UID");
      setToast({ message: t("invalidProperty"), type: "error" });
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setIsDetailedOpen(false); // Reset this when closing
    setSelectedProperty(null);
    setDetailedPropertyData(null);
  };

  const handleAddToFavorites = (propertyId) => {
    if (favorites.includes(propertyId)) {
      setFavorites(favorites.filter((id) => id !== propertyId));
      setToast({ message: t("removedFromFavorites"), type: "info" });
    } else {
      setFavorites([...favorites, propertyId]);
      setToast({ message: t("addedToFavorites"), type: "success" });
    }
  };

  const handleSaveNote = (propertyId, noteText) => {
    setNotes({ ...notes, [propertyId]: noteText });
    setToast({ message: t("noteSaved"), type: "success" });
  };

  const handlePageChange = (newPage) => {
    if (newPage > 1) {
      setShowGlossyOverlay(true);
      return;
    }

    setCurrentPage(newPage);
    fetchPublicProperties(currentFilters, newPage);
  };

  const handleCloseGlossyOverlay = () => {
    setShowGlossyOverlay(false);
    setCurrentPage(1);
  };

  const renderContent = () => {
    if (loading) {
      return <LoadingMessage>{t("loading")}</LoadingMessage>;
    }

    if (error === "Daily search limit reached. Please try again tomorrow.") {
      return (
        <LimitReachedMessage>
          <LimitReachedTitle>{t("timeIsUp")}</LimitReachedTitle>
          <LimitReachedText>{t("thankYouForTesting")}</LimitReachedText>
          <ButtonGroup>
            <ActionButton onClick={onSignUp}>{t("readyToSignUp")}</ActionButton>
            <ActionButton onClick={onSignIn}>
              {t("alreadyHaveAccount")}
            </ActionButton>
          </ButtonGroup>
        </LimitReachedMessage>
      );
    }

    if (error) {
      return <ErrorMessage>{error}</ErrorMessage>;
    }

    if (publicProperties.length === 0) {
      return <p>{t("noItemsToDisplay")}</p>;
    }

    const visibleProperties = getVisibleProperties();

    return (
      <>
        <DataTable
          headers={headers}
          items={visibleProperties}
          sortConfig={sortConfig}
          onSort={handleSort}
          onRowClick={handleRowClick}
          isPublic={true}
        />
        
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
          totalCount={totalCount}
          pageSize={itemsPerPage}
          showingFrom={(currentPage - 1) * itemsPerPage + 1}
          showingTo={Math.min(currentPage * itemsPerPage, totalCount)}
        />

        {currentPage === 1 && totalPages > 1 && (
          <BlurredOverlay>
            <p>{t("createAccountToSeeMore")}</p>
            <ActionButton onClick={onSignUp}>{t("signup")}</ActionButton>
          </BlurredOverlay>
        )}
        
        <SearchesRemaining>
          {t("searchesRemaining", { count: Math.max(0, 5 - searchCount) })}
        </SearchesRemaining>
      </>
    );
  };

  const handleSelectFavorites = () => {
    setToast({ 
      message: t("signUpToAccessFavorites"), 
      type: "info" 
    });
  };

  const handleFetchNotes = () => {
    setToast({ 
      message: t("signUpToAccessNotes"), 
      type: "info" 
    });
  };

  const handleModalOpen = () => setIsAnyModalOpen(true);
  const handleModalClose = () => setIsAnyModalOpen(false);

  useEffect(() => {
    if (propertyCount) {
      setTotalCount(propertyCount);
      setTotalPages(serverTotalPages);
      setCurrentPage(serverPage);
    }
  }, [propertyCount, serverTotalPages, serverPage]);

  return (
    <PublicBuyContentWrapper>
      <ModernFilterSystem
        onApplyFilters={handleApplyFilters}
        initialFilters={currentFilters}
        isOpen={isFilterOpen}
        setIsOpen={setIsFilterOpen}
        isPublic={true}
      />

      <ResultsContainer>
        {renderContent()}
      </ResultsContainer>

      {!isFilterOpen && !isAnyModalOpen && (
        <FilterOrb
          onClick={() => setIsFilterOpen(true)}
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          exit={{ scale: 0 }}
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
        >
          <Filter size={24} />
        </FilterOrb>
      )}

      <AnimatePresence>
        {showGlossyOverlay && (
          <GlossyOverlay
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <GlossyContent>
              <GlossyTitle>Unlock Premium Access</GlossyTitle>
              <GlossyText>
                You've discovered our premium property listings! Create an account to unlock unlimited access to our complete database, advanced search features, and exclusive market insights.
              </GlossyText>
              <GlossyButtons>
                <GlossyButton
                  $primary
                  onClick={onSignUp}
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  <UserPlus size={18} />
                  Create Account
                </GlossyButton>
                <GlossyButton
                  onClick={onSignIn}
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  <LogIn size={18} />
                  Sign In
                </GlossyButton>
                <GlossyButton
                  onClick={handleCloseGlossyOverlay}
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  <ArrowLeft size={18} />
                  Continue Browsing
                </GlossyButton>
              </GlossyButtons>
            </GlossyContent>
          </GlossyOverlay>
        )}
      </AnimatePresence>

      {selectedProperty && detailedPropertyData && (
        <DetailedPropertyInfo
          isOpen={isModalOpen}
          onOpen={handleModalOpen}
          onClose={() => {
            handleModalClose();
            closeModal();
          }}
          mapCenter={{
            lat: detailedPropertyData.overview.geometry?.coordinates[1] || 0,
            lng: detailedPropertyData.overview.geometry?.coordinates[0] || 0,
          }}
          activeMap={activeMap}
          setActiveMap={setActiveMap}
          expandedData={expandedData}
          setExpandedData={setExpandedData}
          tabIndex={tabIndex}
          setTabIndex={setTabIndex}
          isLoading={loadingDetails}
          isPublic={true}
          toast={toast}
          setToast={setToast}
        >
          <PublicPropertyDetails
            property={selectedProperty}
            detailedData={detailedPropertyData}
            isFavorite={favorites.includes(selectedProperty.uid)}
            onAddToFavorites={() => handleAddToFavorites(selectedProperty.uid)}
            note={notes[selectedProperty.uid] || ""}
            onSaveNote={(noteText) =>
              handleSaveNote(selectedProperty.uid, noteText)
            }
            isLoading={loadingDetails}
          />
        </DetailedPropertyInfo>
      )}
      {toast && (
        <Toast
          message={toast.message}
          type={toast.type}
          duration={3000}
          onClose={() => setToast(null)}
        />
      )}
      {loadingDetails && (
        <LoadingOverlay messageLayers={loadingMessageLayers} />
      )}
    </PublicBuyContentWrapper>
  );
};

const PublicPropertyDetails = ({
  property,
  detailedData,
  isFavorite,
  onAddToFavorites,
  note,
  onSaveNote,
  isLoading,
}) => {
  const { t } = useTranslation();
  const [localNote, setLocalNote] = useState(note);
  const { resources } = usePropertyResources();  // Add this

  useEffect(() => {
    setLocalNote(note);
  }, [note]);

  if (isLoading) {
    return null;
  }

  if (!detailedData) {
    return <div>{t("noDetailsAvailable")}</div>;
  }

  // Get resources for this property
  const countyResources = detailedData.resources;

  return (
    <PropertyDetails
      property={detailedData}
      tabIndex={0}
      setTabIndex={() => {}}
      isTranslating={false}
      isPublic={true}
      resources={countyResources}  // Pass resources to PropertyDetails
    />
  );
};

export default PublicBuyContent;
