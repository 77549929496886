import React, { useState, useEffect, useCallback, useRef } from "react";
import { motion } from "framer-motion";
import {
  Lock,
  PlusCircle,
  Award,
  RefreshCw,
  Zap,
  ArrowUpCircle,
} from "react-feather";
import Button from "../common/Button";
import SubscriptionModal from "../modals/SubscriptionModal";
import LoadingOverlay from "../common/LoadingOverlay";
import "../../assets/styles/WalletPage.css";
import { useWallet } from "../../context/WalletContext";
import { loadStripe } from "@stripe/stripe-js";
import { useTranslation } from "react-i18next";
import Confetti from "react-confetti";

const WalletContent = () => {
  const { t, i18n } = useTranslation();
  const [isSubscriptionModalOpen, setIsSubscriptionModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const {
    walletData,
    setWalletData,
    fetchWalletData,
    subscriptionPlans,
    createCheckoutSession,
    updateSubscription,
    reactivateSubscription,
  } = useWallet();
  const [stripePromise, setStripePromise] = useState(null);
  const [showConfetti, setShowConfetti] = useState(false);
  const [confettiComplete, setConfettiComplete] = useState(false);
  const confettiRef = useRef(null);
  const [selectedPlan, setSelectedPlan] = useState(null);

  useEffect(() => {
    setStripePromise(loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY));
  }, []);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const sessionId = urlParams.get("session_id");
    if (sessionId) {
      fetchWalletData();
    }
  }, [fetchWalletData]);

  useEffect(() => {
    fetchWalletData();
  }, [fetchWalletData, i18n.language]);

  const handleUpdateSubscription = () => {
    const currentPlanKey = currentSubscription.plan.toUpperCase();
    const planOrder = ["FREE", "PRO", "PREMIUM"];
    const currentIndex = planOrder.indexOf(currentPlanKey);
    const nextPlanKey = planOrder[currentIndex + 1];

    if (nextPlanKey) {
      setSelectedPlan(nextPlanKey);
    }
    setIsSubscriptionModalOpen(true);
  };

  const handleCloseSubscriptionModal = () => {
    setIsSubscriptionModalOpen(false);
  };

  const handleSubscriptionChange = useCallback(
    async (plan, interval, isReactivation = false) => {
      try {
        setIsLoading(true);
        let updatedWalletData;

        if (isReactivation) {
          updatedWalletData = await reactivateSubscription();
        } else {
          updatedWalletData = await updateSubscription(plan, interval);
        }

        if (updatedWalletData) {
          setWalletData(updatedWalletData);
          setIsSubscriptionModalOpen(false);
        } else {
          console.error("Failed to update subscription");
        }
      } catch (error) {
        console.error("Error updating subscription:", error);
      } finally {
        setIsLoading(false);
      }
    },
    [setWalletData, updateSubscription, reactivateSubscription]
  );

  const handleCheckout = useCallback(
    async (plan, interval) => {
      try {
        setIsLoading(true);
        const sessionId = await createCheckoutSession(plan, interval);

        if (sessionId && stripePromise) {
          const stripe = await stripePromise;
          const { error } = await stripe.redirectToCheckout({ sessionId });
          if (error) {
            console.error("Error redirecting to Stripe Checkout:", error);
          }
        } else {
          console.error(
            "Failed to create checkout session or Stripe not initialized"
          );
        }
      } catch (error) {
        console.error("Error during checkout process:", error);
      } finally {
        setIsLoading(false);
      }
    },
    [createCheckoutSession, stripePromise]
  );

  const handleSubscriptionAction = () => {
    setSelectedPlan(currentSubscription.plan.toUpperCase());
    setIsSubscriptionModalOpen(true);
  };

  const getCurrentSubscription = () => {
    if (walletData?.subscription) {
      return walletData.subscription;
    }
    return {
      plan: "FREE",
      price: 0,
      interval: "month",
      nextBillingDate: "N/A",
      status: "active",
    };
  };

  const currentSubscription = getCurrentSubscription();
  const currentPlanFeatures = walletData?.currentPlanFeatures || [];
  const nextPlanFeatures = walletData?.nextPlanFeatures || [];

  const isTopPlan = currentSubscription.plan === "PREMIUM";
  const hasUpcomingPlanChange = Boolean(walletData?.subscription?.upcomingPlan);
  const upcomingPlan = walletData?.subscription?.upcomingPlan;
  const upcomingInterval = walletData?.subscription?.upcomingInterval;

  const getNextPlanInfo = () => {
    const currentPlanKey = currentSubscription.plan.toUpperCase();
    const planOrder = ["FREE", "PRO", "PREMIUM"];
    const currentIndex = planOrder.indexOf(currentPlanKey);
    const nextPlanKey = planOrder[currentIndex + 1];

    if (nextPlanKey && subscriptionPlans[nextPlanKey]) {
      const nextPlanBenefits = subscriptionPlans[nextPlanKey].benefits || [];
      const nextPlanFeatures = subscriptionPlans[nextPlanKey].features || [];

      const nextPlanInfo = {
        name: t(nextPlanKey.toLowerCase()),
        benefits: nextPlanBenefits,
        features: nextPlanFeatures,
        unlockInfo: subscriptionPlans[nextPlanKey].unlockInfo || t('upgradeToUnlock', { 
          plan: t(nextPlanKey.toLowerCase())
        }),
        key: nextPlanKey
      };

      return nextPlanInfo;
    }
    return null;
  };

  const nextPlanInfo = getNextPlanInfo();

  useEffect(() => {
    const shouldShowConfetti =
      isTopPlan &&
      !hasUpcomingPlanChange &&
      currentSubscription.status !== "canceling";

    if (shouldShowConfetti) {
      setShowConfetti(true);
      setConfettiComplete(false);
    } else {
      setShowConfetti(false);
    }
  }, [isTopPlan, hasUpcomingPlanChange, currentSubscription.status]);

  const handleConfettiComplete = () => {
    setConfettiComplete(true);
  };

  useEffect(() => {
    if (confettiComplete) {
      const timer = setTimeout(() => setShowConfetti(false), 1000);
      return () => clearTimeout(timer);
    }
  }, [confettiComplete]);

  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.95 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0, scale: 0.95 }}
      transition={{ duration: 0.5, ease: [0.16, 1, 0.3, 1] }}
      className="wallet-page"
    >
      {showConfetti && (
        <Confetti
          ref={confettiRef}
          width={window.innerWidth}
          height={window.innerHeight}
          recycle={false}
          numberOfPieces={500}
          onConfettiComplete={handleConfettiComplete}
          colors={["#8853fb", "#6039b0", "#ffd700"]}
        />
      )}
      {isLoading && <LoadingOverlay />}

      <div className="wallet-header">
        <motion.h1
          className="page-title"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2, duration: 0.5 }}
        >
          {t("myWallet")}
        </motion.h1>
        <motion.div
          className="security-info"
          initial={{ opacity: 0, x: 20 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ delay: 0.3, duration: 0.5 }}
        >
          <Lock size={20} />
          <span>{t("securityInfo")}</span>
        </motion.div>
      </div>

      <div className="wallet-content">
        <motion.div
          className="wallet-grid"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.3, duration: 0.5 }}
        >
          <motion.div
            className="wallet-card current-plan-info"
            transition={{ duration: 0.3 }}
          >
            <div className="subscription-details">
              <h2>{t("currentSubscription")}</h2>
              <div className="subscription-header">
                <span className="subscription-plan">
                  {t(currentSubscription.plan.toLowerCase())}
                </span>
                <span
                  className={`subscription-status ${currentSubscription.status}`}
                >
                  {t(currentSubscription.status)}
                </span>
              </div>
              <div className="subscription-price">
                ${currentSubscription.price}
                <span className="subscription-interval">
                  /{t(currentSubscription.interval)}
                </span>
              </div>
              {currentSubscription.status === "active" &&
                currentSubscription.plan !== "FREE" && (
                  <div className="subscription-billing">
                    {t("nextBilling")}:{" "}
                    {new Date(
                      currentSubscription.nextBillingDate
                    ).toLocaleDateString()}
                  </div>
                )}
              {currentSubscription.status === "canceling" && (
                <div className="subscription-cancellation-info">
                  <p>{t("subscriptionCanceledInfo")}</p>
                  <p>
                    {t("accessUntil")}:{" "}
                    {new Date(
                      currentSubscription.nextBillingDate
                    ).toLocaleDateString()}
                  </p>
                </div>
              )}
            </div>
            <div className="current-plan-features">
              <h3>{t("currentPlanFeatures")}</h3>
              {Array.isArray(currentPlanFeatures) &&
              currentPlanFeatures.length > 0 ? (
                <ul>
                  {currentPlanFeatures.map((feature, index) => (
                    <li key={index}>
                      <span>{t(feature)}</span>
                    </li>
                  ))}
                </ul>
              ) : (
                <p>{t("noFeaturesAvailable")}</p>
              )}
            </div>
            <Button
              onClick={handleSubscriptionAction}
              className={`subscription-action-button ${
                currentSubscription.status === "canceling"
                  ? "reactivate-button"
                  : currentSubscription.plan === "FREE"
                  ? "upgrade-button"
                  : ""
              }`}
            >
              <motion.span
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -10 }}
                transition={{ duration: 0.2 }}
              >
                {(() => {
                  if (currentSubscription.status === "canceling") {
                    return (
                      <>
                        <RefreshCw size={16} />
                        {t("reactivate")}
                      </>
                    );
                  }
                  if (currentSubscription.plan === "FREE") {
                    return (
                      <>
                        <Zap size={16} />
                        {t("subscribe")}
                      </>
                    );
                  }
                  return (
                    <>
                      <ArrowUpCircle size={16} />
                      {t("changePlan")}
                    </>
                  );
                })()}
              </motion.span>
            </Button>
          </motion.div>

          <motion.div
            className="wallet-card upgrade-info"
            transition={{ duration: 0.3 }}
          >
            <div className="upgrade-info-header">
              <h2>
                {isTopPlan && !hasUpcomingPlanChange
                  ? t("youreOnTopPlan")
                  : hasUpcomingPlanChange
                  ? t("upcomingPlanChange")
                  : t("upgradeYourPlan")}
              </h2>
            </div>
            <div className="upgrade-info-content">
              {isTopPlan && !hasUpcomingPlanChange ? (
                <motion.div
                  className="premium-thank-you"
                  initial={{ opacity: 0, scale: 0.8 }}
                  animate={{ opacity: 1, scale: 1 }}
                  transition={{
                    duration: 0.8,
                    ease: [0.16, 1, 0.3, 1],
                    delay: 0.5,
                  }}
                >
                  <motion.div
                    className="trophy-container"
                    animate={{
                      y: [0, -10, 0],
                      scale: [1, 1.05, 1],
                    }}
                    transition={{
                      duration: 3,
                      repeat: Infinity,
                      ease: "easeInOut",
                    }}
                  >
                    <Award size={48} className="trophy-icon" />
                  </motion.div>
                  <motion.h3
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.8 }}
                  >
                    {t("premiumMember")}
                  </motion.h3>
                  <motion.p
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 1 }}
                    className="premium-description"
                  >
                    {t("enjoyingAllFeatures")}
                  </motion.p>
                </motion.div>
              ) : hasUpcomingPlanChange ? (
                <>
                  <div className="subscription-details">
                    <div className="upcoming-change-message">
                      <h3>{t("upcomingChangeTitle")}</h3>
                      <p>
                        {t("upcomingChangeMessage", {
                          currentPlan: t(currentSubscription.plan.toLowerCase()),
                          currentInterval: t(currentSubscription.interval.toLowerCase()),
                          newPlan: t(upcomingPlan.toLowerCase()),
                          newInterval: t(upcomingInterval.toLowerCase()),
                          date: new Date(currentSubscription.nextBillingDate).toLocaleDateString(),
                        })}
                      </p>
                    </div>
                  </div>
                  <div className="current-plan-features">
                    <h3>{t("newPlanFeatures")}</h3>
                    <ul className="plan-features-list">
                      {nextPlanFeatures.map((feature, index) => (
                        <li key={index}>
                          <span>{t(feature)}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                </>
              ) : nextPlanInfo ? (
                <>
                  <div className="subscription-details">
                    <p className="unlock-info">{nextPlanInfo.unlockInfo}</p>
                    <div className="subscription-header">
                      <span className="subscription-plan">
                        {nextPlanInfo.name}
                      </span>
                    </div>
                  </div>
                  <div className="current-plan-features">
                    <h3>{t("nextPlanBenefits", { plan: nextPlanInfo.name })}</h3>
                    {nextPlanInfo.benefits && nextPlanInfo.benefits.length > 0 ? (
                      <ul>
                        {nextPlanInfo.benefits.map((benefit, index) => (
                          <li key={index}>
                            <span>{benefit}</span>
                          </li>
                        ))}
                      </ul>
                    ) : nextPlanInfo.features && nextPlanInfo.features.length > 0 ? (
                      <ul>
                        {nextPlanInfo.features.map((feature, index) => (
                          <li key={index}>
                            <span>{feature}</span>
                          </li>
                        ))}
                      </ul>
                    ) : (
                      <p>{t("noBenefitsAvailable")}</p>
                    )}
                  </div>
                  <Button
                    onClick={handleUpdateSubscription}
                    icon={<PlusCircle size={16} />}
                    className="subscription-action-button upgrade-button"
                  >
                    {t("upgradeNowTo", { plan: nextPlanInfo.name })}
                  </Button>
                </>
              ) : null}
            </div>
          </motion.div>
        </motion.div>
      </div>

      <SubscriptionModal
        isOpen={isSubscriptionModalOpen}
        onClose={handleCloseSubscriptionModal}
        plans={subscriptionPlans}
        onSubscriptionChange={handleSubscriptionChange}
        onCheckout={handleCheckout}
        currentSubscription={currentSubscription}
        selectedPlan={selectedPlan}
        setSelectedPlan={setSelectedPlan}
      />
    </motion.div>
  );
};

export default WalletContent;
