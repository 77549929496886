import React, { useContext, useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import Sidebar from "../layout/navigation/Sidebar";
import MainContent from "../layout/home/MainContent";
import Buy from "../layout/buy/Buy";
import SellContent from "../layout/sell/SellContent";
import HireContent from "../layout/hire/HireContent";
import WalletContent from "./WalletPage";
import AccountSettings from "./AccountSettings";
import LoadingOverlay from "../common/LoadingOverlay";
import "../../assets/styles/HomePage.css";
import ChatPage from './ChatPage';
import FriendsPage from './FriendsPage';

const HomePage = () => {
  const { loading, error } = useContext(AuthContext);
  const [isCollapsed, setIsCollapsed] = useState(true);

  const handleCollapseSidebar = () => {
    setIsCollapsed(true);
  };

  if (loading) return <LoadingOverlay />;
  if (error) return <p>Error: {error}</p>;

  return (
    <div className="homepage">
      <div className="content-wrapper">
        <Sidebar
          isCollapsed={isCollapsed}
          onCollapseSidebar={handleCollapseSidebar}
        />
        <div className="right-side-content">
          <div className="right-side-below-sidebar">
            <Routes>
              <Route path="/" element={<Navigate to="overview" replace />} />
              <Route path="overview" element={<MainContent />} />
              <Route path="buy" element={<Buy />} />
              <Route path="sell" element={<SellContent />} />
              <Route path="hire" element={<HireContent />} />
              <Route path="wallet" element={<WalletContent />} />
              <Route path="account-settings" element={<AccountSettings />} />
              <Route path="chat" element={<ChatPage />} />
              <Route path="friends" element={<FriendsPage />} />
            </Routes>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
