import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Check, Globe, UserPlus, LogIn, LogOut } from "react-feather";
import BackgroundAnimation from "../common/BackgroundAnimation";
import SignUpModal from "../modals/SignUpModal";
import SignInModal from "../modals/SignInModal";
import PolicyModal from "../modals/PolicyModal";
import LoadingOverlay from "../common/LoadingOverlay";
import {
  privacyPolicyContent,
  termsOfServiceContent,
} from "../../utils/policyContent";
import "../../assets/styles/PricingPage.css";

import styled from "styled-components";

import SwarmLogo, {
  SwarmContainer,
  BubbleSkin,
  Highlight,
  EnergyRing,
} from "../common/SwarmLogo";
import GlobalStyle from "../common/GlobalStyle";

const GetStartedButton = styled(motion.button)`
  width: 100%;
  height: 48px;
  background: linear-gradient(
    165deg,
    rgba(136, 83, 251, 0.15),
    rgba(96, 57, 176, 0.15)
  );
  border: 1px solid rgba(136, 83, 251, 0.3);
  border-radius: 12px;
  color: white;
  cursor: pointer;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0.3px;
  backdrop-filter: blur(20px);
  transition: all 0.3s cubic-bezier(0.34, 1.56, 0.64, 1);
  margin-top: auto;
  position: relative;
  overflow: hidden;
  z-index: 10;

  &::before {
    content: "";
    position: absolute;
    inset: 0;
    background: linear-gradient(165deg, rgba(255, 255, 255, 0.07), transparent);
    opacity: 0;
    transition: opacity 0.3s cubic-bezier(0.34, 1.56, 0.64, 1);
  }

  &:hover {
    transform: translateY(-2px);
    background: linear-gradient(
      165deg,
      rgba(136, 83, 251, 0.18),
      rgba(96, 57, 176, 0.18)
    );
    border-color: rgba(136, 83, 251, 0.4);
    box-shadow: 0 8px 24px rgba(136, 83, 251, 0.15),
      0 0 0 1px rgba(136, 83, 251, 0.2);

    &::before {
      opacity: 1;
    }
  }

  &:active {
    transform: translateY(0);
  }
`;

const PricingCard = styled(motion.div)`
  background: linear-gradient(
    165deg,
    rgba(38, 38, 45, 0.5),
    rgba(28, 28, 35, 0.5)
  );
  border: 1px solid rgba(136, 83, 251, 0.2);
  border-radius: 24px;
  padding: 28px;
  backdrop-filter: blur(20px);
  box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.05),
    0 20px 50px -12px rgba(0, 0, 0, 0.5);
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 600px;
  transform: translateY(0);
  will-change: transform;
  z-index: 1;
  transition: transform 0.25s cubic-bezier(0.16, 1, 0.3, 1),
    box-shadow 0.25s cubic-bezier(0.16, 1, 0.3, 1);

  &::before {
    content: "";
    position: absolute;
    inset: 0;
    background: linear-gradient(165deg, rgba(255, 255, 255, 0.03), transparent);
    border-radius: inherit;
    opacity: 0.5;
    transition: opacity 0.4s cubic-bezier(0.34, 1.56, 0.64, 1);
  }

  &::after {
    content: "";
    position: absolute;
    inset: -1px;
    background: linear-gradient(
      165deg,
      rgba(136, 83, 251, 0.1),
      transparent 50%
    );
    border-radius: inherit;
    opacity: 0;
    transition: opacity 0.4s cubic-bezier(0.34, 1.56, 0.64, 1);
  }

  &:hover {
    transform: translateY(-4px);
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.4),
      0 0 60px rgba(136, 83, 251, 0.07);
    z-index: 2;

    &::before {
      opacity: 0.7;
    }

    &::after {
      opacity: 0.4;
    }

    ${GetStartedButton} {
      transform: translateY(-1px);
      border-color: rgba(136, 83, 251, 0.4);
      background: linear-gradient(
        165deg,
        rgba(136, 83, 251, 0.18),
        rgba(96, 57, 176, 0.18)
      );
    }
  }
`;

const PricingContainer = styled(motion.div)`
  background: linear-gradient(
    165deg,
    rgba(28, 28, 35, 0.85),
    rgba(18, 18, 24, 0.95)
  );
  backdrop-filter: blur(40px);
  min-height: 100vh;
  height: 100vh;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

const PricingMain = styled.main`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 100px 2rem 20px;
  position: relative;
  z-index: 2;
  overflow: hidden;
  max-height: calc(100vh - 80px);
`;

const PricingTitle = styled.h1`
  font-size: clamp(2rem, 3vw, 2.5rem);
  text-align: center;
  margin-bottom: 2rem;
  background: linear-gradient(45deg, #8853fb, #3498db);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  text-shadow: 0 2px 10px rgba(136, 83, 251, 0.3);
  font-weight: 700;
  letter-spacing: -0.02em;
  position: relative;
  margin-top: 0;

  &::after {
    content: "";
    position: absolute;
    inset: -20px -40px;
    background: radial-gradient(
      circle at center,
      rgba(136, 83, 251, 0.1),
      transparent 70%
    );
    z-index: -1;
    pointer-events: none;
  }
`;

const PricingGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: clamp(1.5rem, 3vw, 2.5rem);
  width: 100%;
  max-width: 1200px;
  margin: -20px auto 0;
  padding: 0 2rem;
  height: auto;
  max-height: calc(100vh - 350px);
  perspective: 2000px;
  transform-style: preserve-3d;
`;

const PriceDisplay = styled.div`
  font-size: 2.5rem;
  font-weight: 600;
  background: linear-gradient(45deg, #8853fb, #3498db);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
`;

const FeaturesList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex: 1;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: rgba(136, 83, 251, 0.1);
    border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(136, 83, 251, 0.2);
    border-radius: 3px;

    &:hover {
      background: rgba(136, 83, 251, 0.3);
    }
  }
`;

const FeatureItem = styled.li`
  display: flex;
  align-items: center;
  gap: 10px;
  color: rgba(255, 255, 255, 0.8);
  font-size: 13px;
  line-height: 1.4;

  svg {
    color: #8853fb;
    filter: drop-shadow(0 2px 4px rgba(136, 83, 251, 0.3));
    flex-shrink: 0;
    width: 14px;
    height: 14px;
  }
`;

const PlanTitle = styled.h2`
  font-size: 1.8rem;
  margin-bottom: 16px;
  background: linear-gradient(45deg, #8853fb, #3498db);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  letter-spacing: 0.5px;
  text-shadow: 0 2px 10px rgba(136, 83, 251, 0.2);
`;

const PriceInterval = styled.span`
  font-size: 1rem;
  opacity: 0.7;
  margin-left: 4px;
`;

const Navbar = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  background: linear-gradient(
    165deg,
    rgba(28, 28, 35, 0.85),
    rgba(18, 18, 24, 0.95)
  );
  backdrop-filter: blur(40px);
  border-bottom: 1px solid rgba(255, 255, 255, 0.07);
  box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.05), 0 4px 20px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
`;

const Nav = styled.nav`
  display: flex;
  gap: 1rem;
  align-items: center;

  @media (max-width: 768px) {
    width: 100%;
    flex-direction: column;
    gap: 0.5rem;
  }
`;

const IconButton = styled.button`
  width: 140px;
  height: 44px;
  background: linear-gradient(
    165deg,
    rgba(136, 83, 251, 0.12),
    rgba(96, 57, 176, 0.12)
  );
  border: 1px solid rgba(136, 83, 251, 0.2);
  border-radius: 12px;
  color: rgba(255, 255, 255, 0.9);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.3px;
  backdrop-filter: blur(10px);
  transition: all 0.4s cubic-bezier(0.16, 1, 0.3, 1);
  position: relative;
  overflow: hidden;

  &::before {
    content: "";
    position: absolute;
    inset: 0;
    background: linear-gradient(
      165deg,
      rgba(136, 83, 251, 0.1),
      rgba(96, 57, 176, 0.05)
    );
    opacity: 0;
    transition: opacity 0.4s ease;
  }

  &:hover {
    transform: translateY(-2px);
    background: linear-gradient(
      165deg,
      rgba(136, 83, 251, 0.15),
      rgba(96, 57, 176, 0.15)
    );
    border-color: rgba(136, 83, 251, 0.3);
    box-shadow: 0 8px 32px rgba(136, 83, 251, 0.15),
      0 0 0 1px rgba(136, 83, 251, 0.2);

    &::before {
      opacity: 1;
    }
  }

  &:active {
    transform: translateY(1px);
  }

  svg {
    width: 16px;
    height: 16px;
    filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.2));
  }

  @media (max-width: 768px) {
    width: 100%;
    max-width: 280px;
    margin: 4px 0;
  }
`;

const LandingFooter = styled.footer`
  flex-shrink: 0;
  background: linear-gradient(
    165deg,
    rgba(28, 28, 35, 0.95),
    rgba(18, 18, 24, 0.98)
  );
  backdrop-filter: blur(40px);
  border-top: 1px solid rgba(255, 255, 255, 0.07);
  padding: 1rem;
  text-align: center;
  position: relative;
  z-index: 2;
  box-shadow: 0 -4px 20px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 1rem 0.5rem;
    gap: 0.5rem;
  }
`;

const FooterLinks = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 24px;
  padding: 0 1rem;

  a {
    color: rgba(136, 83, 251, 0.8);
    text-decoration: none;
    font-size: 14px;
    transition: all 0.2s ease;

    &:hover {
      color: #8853fb;
    }
  }
`;

const LogoText = styled.span`
  position: relative;
  background: linear-gradient(45deg, #3498db, #8853fb, #3498db, #8853fb);
  background-size: 300% 300%;
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  animation: waveAnimation 8s ease-in-out infinite;

  @keyframes waveAnimation {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
`;

const Logo = styled(Link)`
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  font-weight: bold;
  text-decoration: none;
  cursor: pointer;

  ${SwarmContainer} {
    width: 48px;
    height: 48px;
    transform: scale(1);
    transition: transform 0.3s cubic-bezier(0.16, 1, 0.3, 1);
  }

  &:hover {
    ${SwarmContainer} {
      transform: scale(1.05);
    }

    ${BubbleSkin} {
      scale: 1.02;
      opacity: 0.9;
    }

    ${Highlight} {
      opacity: 0.5;
      scale: 1.1;
    }

    ${EnergyRing} {
      scale: 1.1;
      opacity: 1;
    }
  }
`;

const IntervalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.75rem;
  margin-bottom: 2rem;
  position: relative;
  z-index: 3;

  &::before {
    content: "";
    position: absolute;
    inset: -20px -40px;
    background: radial-gradient(
      circle at center,
      rgba(136, 83, 251, 0.05),
      transparent 70%
    );
    z-index: -1;
    pointer-events: none;
  }
`;

const LanguageButtons = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

const LanguageButton = styled.button`
  background: none;
  border: none;
  color: rgba(255, 255, 255, 0.5);
  font-size: 13px;
  padding: 4px 8px;
  cursor: pointer;
  transition: all 0.2s ease;
  border-radius: 6px;
  display: flex;
  align-items: center;
  gap: 4px;

  &:hover,
  &.active {
    color: rgba(136, 83, 251, 0.8);
    background: rgba(136, 83, 251, 0.1);
  }

  svg {
    width: 12px;
    height: 12px;
  }
`;

const PricingPage = () => {
  const { t, i18n } = useTranslation();
  const [subscriptionPlans, setSubscriptionPlans] = useState({});
  const [selectedInterval, setSelectedInterval] = useState("monthly");
  const [isSignUpModalOpen, setIsSignUpModalOpen] = useState(false);
  const [isSignInModalOpen, setIsSignInModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [activeModal, setActiveModal] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchSubscriptionPlans = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/wallet/subscription-plans`
        );
        if (response.ok) {
          const data = await response.json();
          setSubscriptionPlans(data);
        } else {
          console.error("Failed to fetch subscription plans");
        }
      } catch (error) {
        console.error("Error fetching subscription plans:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchSubscriptionPlans();
  }, []);

  useEffect(() => {
    const hash = location.hash.slice(1);
    if (hash === "privacy-policy") {
      setActiveModal("privacyPolicy");
    } else if (hash === "terms-of-service") {
      setActiveModal("termsOfService");
    } else {
      setActiveModal(null);
    }
  }, [location]);

  const handleIntervalChange = (interval) => {
    setSelectedInterval(interval);
  };

  const handleGetStarted = () => {
    setIsSignUpModalOpen(true);
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const handleOpenModal = (modalType) => {
    if (modalType === "privacyPolicy" || modalType === "termsOfService") {
      const hash = modalType.replace(/([A-Z])/g, "-$1").toLowerCase();
      navigate(`#${hash}`);
    }
    setActiveModal(modalType);
  };

  const handleCloseModal = () => {
    navigate("");
    setActiveModal(null);
  };

  const handlePrivacyPolicyClick = (e) => {
    e.preventDefault();
    handleOpenModal("privacyPolicy");
  };

  const handleTermsOfServiceClick = (e) => {
    e.preventDefault();
    handleOpenModal("termsOfService");
  };

  if (isLoading) {
    return <LoadingOverlay />;
  }

  return (
    <>
      <GlobalStyle />
      <PricingContainer
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <BackgroundAnimation />
        <Navbar>
          <Logo to="/">
            <SwarmLogo size="48px" />
            <LogoText>{t("companyName")}</LogoText>
          </Logo>
          <Nav>
            <IconButton onClick={() => setIsSignInModalOpen(true)}>
              <LogIn size={18} /> {t("signin")}
            </IconButton>
            <IconButton onClick={() => setIsSignUpModalOpen(true)}>
              <UserPlus size={18} /> {t("signup")}
            </IconButton>
          </Nav>
        </Navbar>

        <PricingMain>
          <PricingTitle>{t("choosePlan")}</PricingTitle>
          <IntervalContainer>
            <div className="interval-selection">
              <button
                className={`interval-button ${
                  selectedInterval === "monthly" ? "active" : ""
                }`}
                onClick={() => handleIntervalChange("monthly")}
              >
                {t("monthly")}
              </button>
              <button
                className={`interval-button ${
                  selectedInterval === "annual" ? "active" : ""
                }`}
                onClick={() => handleIntervalChange("annual")}
              >
                {t("annual")}
              </button>
              <div
                className={`interval-slider ${
                  selectedInterval === "annual" ? "annual" : ""
                }`}
              ></div>
            </div>
          </IntervalContainer>

          <PricingGrid>
            {Object.entries(subscriptionPlans).map(([key, plan], index) => (
              <PricingCard
                key={key}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.1 }}
              >
                <PlanTitle>{t(plan.name.toLowerCase())}</PlanTitle>
                <PriceDisplay>
                  {plan.prices && plan.prices[selectedInterval] ? (
                    <>
                      ${plan.prices[selectedInterval].amount}
                      <PriceInterval>
                        /{selectedInterval === "monthly" ? t("mo") : t("yr")}
                      </PriceInterval>
                    </>
                  ) : (
                    t("free")
                  )}
                </PriceDisplay>
                <FeaturesList>
                  {plan.features.map((feature, index) => (
                    <FeatureItem key={index}>
                      <Check size={16} />
                      <span>{t(feature)}</span>
                    </FeatureItem>
                  ))}
                </FeaturesList>
                <GetStartedButton
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  onClick={handleGetStarted}
                >
                  {t("getStarted")}
                </GetStartedButton>
              </PricingCard>
            ))}
          </PricingGrid>
        </PricingMain>

        <LandingFooter>
          <p>&copy; {new Date().getFullYear()} DeedFinders</p>

          <FooterLinks>
            <Link to="/pricing">Pricing</Link>
            <a href="#" onClick={handlePrivacyPolicyClick}>
              Privacy Policy
            </a>
            <a href="#" onClick={handleTermsOfServiceClick}>
              Terms of Service
            </a>
          </FooterLinks>

          <LanguageButtons>
            <LanguageButton
              onClick={() => changeLanguage("en")}
              className={i18n.language === "en" ? "active" : ""}
            >
              <Globe size={12} />
              EN
            </LanguageButton>
            <LanguageButton
              onClick={() => changeLanguage("ptbr")}
              className={i18n.language === "ptbr" ? "active" : ""}
            >
              <Globe size={12} />
              PT
            </LanguageButton>
          </LanguageButtons>
        </LandingFooter>

        <SignUpModal
          open={isSignUpModalOpen}
          onClose={() => setIsSignUpModalOpen(false)}
          onOpenPrivacyPolicy={() => handleOpenModal("privacyPolicy")}
          onOpenTermsOfService={() => handleOpenModal("termsOfService")}
        />
        <SignInModal
          open={isSignInModalOpen}
          onClose={() => setIsSignInModalOpen(false)}
          onSuccess={() => {}}
          onSwitchToSignUp={() => {
            setIsSignInModalOpen(false);
            setIsSignUpModalOpen(true);
          }}
          onOpenPrivacyPolicy={() => handleOpenModal("privacyPolicy")}
          onOpenTermsOfService={() => handleOpenModal("termsOfService")}
        />
        <PolicyModal
          open={
            activeModal === "privacyPolicy" || activeModal === "termsOfService"
          }
          onClose={handleCloseModal}
          title={
            activeModal === "privacyPolicy"
              ? "Privacy Policy"
              : "Terms of Service"
          }
          content={
            activeModal === "privacyPolicy"
              ? privacyPolicyContent
              : termsOfServiceContent
          }
        />
      </PricingContainer>
    </>
  );
};

export default PricingPage;
