// FAQTab.jsx

import React, { useState } from "react";
import { ChevronDown, ChevronUp } from "react-feather";
import { TabDetails, ScrollableContent } from "./TabStyles";
import styled from "styled-components";
import { LoadingText } from "../../common/LoadingAnimation";
import { useTranslation } from "react-i18next";

const FAQItemContainer = styled.div`
  background-color: #1a1a1a;
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 10px;
`;

const FAQTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  cursor: pointer;
  background-color: #1a1a1a;
  color: #f4f4f4;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #282828;
  }
`;

const FAQContent = styled.div`
  padding: 0 15px;
  background-color: #1a1a1a;
  color: #ccc;
  max-height: ${(props) => (props.$isActive ? "1000px" : "0")};
  overflow: hidden;
  transition: max-height 0.3s ease-in-out, padding 0.3s ease-in-out;

  & > div {
    padding: ${(props) => (props.$isActive ? "15px 0" : "0")};
    transition: padding 0.3s ease-in-out;
  }

  a.custom-link {
    color: #00bfff;
    text-decoration: underline;

    &:hover {
      color: #1e90ff;
    }
  }
`;

// Updated Helper Function
const formatResponse = (response) => {
  // Handle Markdown-style links [text](url)
  const markdownLinkRegex = /\[([^\]]+)\]\(([^)]+)\)/g;
  const formattedResponse = response.replace(
    markdownLinkRegex,
    (match, text, url) => {
      return `<a href="${url}" target="_blank" rel="noopener noreferrer" class="custom-link">${text}</a>`;
    }
  );

  // Handle any remaining HTML links
  const htmlLinkRegex = /<a\s+(?:[^>]*?\s+)?href="([^"]*)"[^>]*>(.*?)<\/a>/g;
  return formattedResponse.replace(htmlLinkRegex, (match, url, text) => {
    return `<a href="${url}" target="_blank" rel="noopener noreferrer" class="custom-link">${text}</a>`;
  });
};

// FAQItem Component
const FAQItem = ({ prompt, response, isActive, onClick, isTranslating }) => {
  return (
    <FAQItemContainer className={`faq-item ${isActive ? "active" : ""}`}>
      <FAQTitle className="faq-title" onClick={onClick}>
        <span>
          {isTranslating ? <LoadingText>{prompt}</LoadingText> : prompt}
        </span>
        {isActive ? <ChevronUp size={18} /> : <ChevronDown size={18} />}
      </FAQTitle>
      <FAQContent className="faq-content" $isActive={isActive}>
        <div>
          {isTranslating ? (
            <LoadingText>{response}</LoadingText>
          ) : (
            <div
              dangerouslySetInnerHTML={{ __html: formatResponse(response) }}
            />
          )}
        </div>
      </FAQContent>
    </FAQItemContainer>
  );
};

// FAQTab Component
const FAQTab = ({ faqItems, isTranslating }) => {
  const { t } = useTranslation();
  const [activeIndex, setActiveIndex] = useState(null);

  const handleClick = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  // Ensure faqItems is always an array
  const items = Array.isArray(faqItems) ? faqItems : [];

  return (
    <TabDetails>
      <ScrollableContent>
        {items.length === 0 ? (
          <p>{t("noFAQAvailable")}</p>
        ) : (
          items.map((faqItem, index) => (
            <FAQItem
              key={index}
              prompt={faqItem.prompt}
              response={faqItem.response}
              isActive={activeIndex === index}
              onClick={() => handleClick(index)}
              isTranslating={isTranslating}
            />
          ))
        )}
      </ScrollableContent>
    </TabDetails>
  );
};

export default FAQTab;
