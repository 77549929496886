import React from "react";
import {
  ChevronLeft,
  ChevronRight,
  ChevronsLeft,
  ChevronsRight,
} from "react-feather";
import { motion, AnimatePresence } from "framer-motion";

const Pagination = ({ totalPages, currentPage, onPageChange }) => {
  const maxItems = 15; // Increased to 10 pages
  const halfItems = Math.floor(maxItems / 2);

  let startPage = Math.max(currentPage - halfItems, 1);
  let endPage = startPage + maxItems - 1;

  if (endPage > totalPages) {
    endPage = totalPages;
    startPage = Math.max(endPage - maxItems + 1, 1);
  }

  const pages = Array.from(
    { length: endPage - startPage + 1 },
    (_, index) => startPage + index
  );

  const formatPageNumber = (page) => {
    // More sophisticated page number formatting
    if (page < 10) return `0${page}`;
    if (page < 100) return `${page}`;
    if (page < 1000) return `${page}`;
    return `${page}`;
  };

  return (
    <motion.div
      className="pagination-container"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3 }}
    >
      <div className="pagination-controls">
        <motion.button
          className="pagination-button edge-button"
          onClick={() => onPageChange(1)}
          disabled={currentPage === 1}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          title="First Page"
        >
          <ChevronsLeft size={16} />
        </motion.button>
        <motion.button
          className="pagination-button nav-button"
          onClick={() => onPageChange(currentPage - 1)}
          disabled={currentPage === 1}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          title="Previous Page"
        >
          <ChevronLeft size={16} />
        </motion.button>

        <div className="pagination-numbers">
          <AnimatePresence mode="sync">
            {pages.map((page) => (
              <motion.button
                key={page}
                className={`pagination-number ${
                  currentPage === page ? "active" : ""
                }`}
                onClick={() => onPageChange(page)}
                initial={{ opacity: 0, y: 10 }}
                animate={{
                  opacity: 1,
                  y: 0,
                  transition: {
                    type: "spring",
                    stiffness: 300,
                    damping: 30,
                  },
                }}
                exit={{ opacity: 0, y: -10 }}
                whileHover={{
                  scale: 1.1,
                  backgroundColor: "rgba(255, 255, 255, 0.1)",
                }}
                whileTap={{ scale: 0.95 }}
              >
                <span className="page-number">{formatPageNumber(page)}</span>
              </motion.button>
            ))}
          </AnimatePresence>
        </div>

        <motion.button
          className="pagination-button nav-button"
          onClick={() => onPageChange(currentPage + 1)}
          disabled={currentPage === totalPages || totalPages === 0}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          title="Next Page"
        >
          <ChevronRight size={16} />
        </motion.button>
        <motion.button
          className="pagination-button edge-button"
          onClick={() => onPageChange(totalPages)}
          disabled={currentPage === totalPages || totalPages === 0}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          title="Last Page"
        >
          <ChevronsRight size={16} />
        </motion.button>
      </div>
    </motion.div>
  );
};

export default Pagination;
