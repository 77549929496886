import React, { useEffect, useRef, useState, useMemo, useCallback } from "react";
import Tippy from "@tippyjs/react";
import { useTranslation } from "react-i18next";
import "tippy.js/dist/tippy.css";
import "tippy.js/animations/shift-away.css";
import "../../assets/styles/Tabs.css";
import "../../assets/styles/shared/Tooltip.css";

export const Tabs = ({ children, activeTab, onTabClick }) => {
  const tabsRef = useRef(null);
  const [isScrolling, setIsScrolling] = useState(false);

  useEffect(() => {
    const activeElement = tabsRef.current?.children[activeTab];
    if (activeElement && !isScrolling) {
      setIsScrolling(true);
      activeElement.scrollIntoView({ 
        behavior: 'smooth', 
        block: 'nearest', 
        inline: 'center' 
      });
      
      const scrollTimeout = setTimeout(() => {
        setIsScrolling(false);
      }, 300);

      return () => clearTimeout(scrollTimeout);
    }
  }, [activeTab]);

  const handleTabClick = useCallback((index) => {
    if (!isScrolling) {
      onTabClick(index);
    }
  }, [isScrolling, onTabClick]);

  if (!children || !Array.isArray(React.Children.toArray(children))) {
    console.error('Tabs component requires child Tab components');
    return null;
  }

  return (
    <div className="tabs" ref={tabsRef}>
      {React.Children.map(children, (child, index) => {
        if (!React.isValidElement(child)) {
          console.error(`Invalid tab child at index ${index}`);
          return null;
        }
        
        return React.cloneElement(child, {
          isActive: activeTab === index,
          onClick: () => handleTabClick(index),
        });
      })}
    </div>
  );
};

export const Tab = ({ label, tooltip, isActive, onClick, disabled }) => {
  const { t } = useTranslation();
  
  const translatedLabel = useMemo(() => t(label), [t, label]);
  const translatedTooltip = useMemo(() => tooltip ? t(tooltip) : null, [t, tooltip]);

  const handleClick = useCallback(() => {
    if (!disabled) {
      onClick();
    }
  }, [disabled, onClick]);

  return (
    <Tippy 
      content={translatedTooltip} 
      animation="shift-away" 
      placement="top"
      delay={[400, 0]}
      duration={[300, 250]}
      theme="modern"
      arrow={true}
      disabled={!translatedTooltip || disabled}
    >
      <button 
        className={`tab ${isActive ? "active" : ""} ${disabled ? "disabled" : ""}`} 
        onClick={handleClick}
        disabled={disabled}
        role="tab"
        aria-selected={isActive}
        aria-disabled={disabled}
        type="button"
      >
        <span className="tab-label">{translatedLabel}</span>
      </button>
    </Tippy>
  );
};

export const TabContent = ({ children, isActive }) => {
  const [mounted, setMounted] = useState(false);
  
  useEffect(() => {
    if (isActive && !mounted) {
      setMounted(true);
    }
  }, [isActive]);

  if (!mounted) return null;

  return (
    <div 
      role="tabpanel"
      aria-hidden={!isActive}
      style={{ 
        display: isActive ? 'block' : 'none',
        height: '100%'
      }}
    >
      {children}
    </div>
  );
};
