// CopyToClipboard.jsx

import React, { useState } from "react";
import { Copy, Check } from "react-feather";
import styled from "styled-components";

// Styled Components

const CopyButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  color: #8853fb;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: color 0.3s ease;

  &:hover {
    color: #5936a3;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px var(--accent-color);
  }
`;

const CopiedIcon = styled(Check)`
  color: green;
`;

// Create the component as a function first
const CopyToClipboardComponent = ({ text, ...props }, ref) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(text);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (err) {
      console.error('Failed to copy text:', err);
    }
  };

  return (
    <CopyButton
      ref={ref}
      className="copy-button"
      onClick={handleCopy}
      {...props}
    >
      {copied ? <CopiedIcon size={16} /> : <Copy size={16} />}
    </CopyButton>
  );
};

// Then wrap it with forwardRef
const CopyToClipboard = React.forwardRef(CopyToClipboardComponent);

// Add display name
CopyToClipboard.displayName = 'CopyToClipboard';

export default CopyToClipboard;
