import React from "react";

const SellContent = () => {
  return (
    <div>
      <h2>Sell Section</h2>
      {/* Your Sell content here */}
    </div>
  );
};

export default SellContent;
