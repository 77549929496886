import styled, { keyframes } from 'styled-components';

const shimmer = keyframes`
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
`;

export const LoadingText = styled.span`
  background: linear-gradient(
    90deg,
    #333 25%,
    #444 50%,
    #333 75%
  );
  background-size: 200% 100%;
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  animation: ${shimmer} 1.5s infinite;
  display: inline-block;
`;

export const LoadingWrapper = styled.div`
  position: relative;
`;

export const LoadingOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.1) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  animation: ${shimmer} 1.5s infinite;
  pointer-events: none;
`;