import React, { useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ArrowLeft, AlertTriangle, Send } from 'react-feather';
import { motion, AnimatePresence } from 'framer-motion';
import styled from 'styled-components';
import debounce from 'lodash/debounce';

const ForgotPasswordPage = styled(motion.div)`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background: linear-gradient(
    165deg,
    rgba(28, 28, 35, 0.85),
    rgba(18, 18, 24, 0.95)
  );
  color: var(--text-color);
`;

const ForgotPasswordHeader = styled.header`
  background: linear-gradient(
    165deg,
    rgba(38, 38, 45, 0.5),
    rgba(28, 28, 35, 0.5)
  );
  backdrop-filter: blur(20px);
  padding: 1rem 2rem;
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.07);
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.1);
`;

const BackButton = styled.button`
  background: linear-gradient(
    165deg,
    rgba(38, 38, 45, 0.5),
    rgba(28, 28, 35, 0.5)
  );
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  color: rgba(255, 255, 255, 0.8);
  cursor: pointer;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;
  transition: all 0.3s cubic-bezier(0.16, 1, 0.3, 1);

  &:hover {
    background: linear-gradient(
      165deg,
      rgba(43, 43, 50, 0.6),
      rgba(33, 33, 40, 0.6)
    );
    border-color: rgba(136, 83, 251, 0.2);
    transform: translateY(-1px);
    box-shadow: 
      0 4px 12px rgba(0, 0, 0, 0.2),
      0 0 0 1px rgba(136, 83, 251, 0.1);
  }

  &:active {
    transform: translateY(1px);
  }
`;

const PageTitle = styled.h1`
  font-size: 1.5rem;
  margin: 0;
  background: linear-gradient(45deg, #fff, rgba(255, 255, 255, 0.7));
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  text-shadow: 0 2px 10px rgba(255, 255, 255, 0.1);
`;

const Container = styled(motion.div)`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 4rem 2rem;
  max-width: 440px;
  margin: 0 auto;
  width: 100%;
  box-sizing: border-box;
`;

const Description = styled.p`
  text-align: center;
  margin-bottom: 2rem;
  color: rgba(255, 255, 255, 0.7);
  font-size: 1rem;
  line-height: 1.6;
  max-width: 380px;
  width: 100%;
`;

const Form = styled.form`
  width: 100%;
  max-width: 380px;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

const FormGroup = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Input = styled.input`
  width: 100%;
  height: 48px;
  background: linear-gradient(
    165deg,
    rgba(38, 38, 45, 0.5),
    rgba(28, 28, 35, 0.5)
  );
  border: 1px solid ${props => 
    props.$hasError 
      ? 'rgba(255, 59, 48, 0.4)'
      : 'rgba(136, 83, 251, 0.2)'
  };
  border-radius: 12px;
  color: #fff;
  font-size: 14px;
  padding: 0 16px;
  transition: all 0.3s cubic-bezier(0.16, 1, 0.3, 1);
  backdrop-filter: blur(10px);

  &:focus {
    outline: none;
    border-color: ${props => 
      props.$hasError 
        ? 'rgba(255, 59, 48, 0.6)'
        : 'rgba(136, 83, 251, 0.4)'
    };
    background: linear-gradient(
      165deg,
      rgba(43, 43, 50, 0.6),
      rgba(33, 33, 40, 0.6)
    );
    box-shadow: ${props => 
      props.$hasError
        ? '0 4px 20px rgba(255, 59, 48, 0.1), 0 0 0 1px rgba(255, 59, 48, 0.2)'
        : '0 4px 20px rgba(0, 0, 0, 0.2), 0 0 20px rgba(136, 83, 251, 0.1), 0 0 0 1px rgba(136, 83, 251, 0.2)'
    };
  }

  &::placeholder {
    color: rgba(255, 255, 255, 0.4);
  }
`;

const SubmitButton = styled(motion.button)`
  width: 100%;
  height: 48px;
  background: linear-gradient(
    165deg,
    rgba(136, 83, 251, 0.15),
    rgba(96, 57, 176, 0.15)
  );
  border: 1px solid rgba(136, 83, 251, 0.3);
  border-radius: 12px;
  color: white;
  cursor: pointer;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0.3px;
  backdrop-filter: blur(20px);
  transition: all 0.4s cubic-bezier(0.16, 1, 0.3, 1);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  &:hover {
    transform: translateY(-2px) scale(1.02);
    background: linear-gradient(
      165deg,
      rgba(136, 83, 251, 0.2),
      rgba(96, 57, 176, 0.2)
    );
    border-color: rgba(136, 83, 251, 0.4);
    box-shadow: 
      0 8px 32px rgba(136, 83, 251, 0.2),
      0 0 0 1px rgba(136, 83, 251, 0.2);
  }

  &:active {
    transform: translateY(1px) scale(0.98);
  }
`;

const ErrorMessage = styled(motion.div)`
  position: absolute;
  left: 0;
  bottom: -20px;
  font-size: 12px;
  color: rgba(255, 59, 48, 0.9);
  display: flex;
  align-items: center;
  gap: 6px;

  svg {
    width: 12px;
    height: 12px;
  }
`;

const SuccessMessage = styled(motion.div)`
  background: linear-gradient(
    165deg,
    rgba(52, 199, 89, 0.1),
    rgba(48, 209, 88, 0.05)
  );
  backdrop-filter: blur(8px);
  border: 1px solid rgba(52, 199, 89, 0.2);
  border-radius: 12px;
  padding: 16px;
  color: #34c759;
  margin-top: 20px;
  text-align: center;
  font-size: 14px;
  line-height: 1.6;
  box-shadow: 
    0 4px 12px rgba(52, 199, 89, 0.1),
    0 0 0 1px rgba(52, 199, 89, 0.1);
`;

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [showEmailError, setShowEmailError] = useState(false);
  const [isEmailTouched, setIsEmailTouched] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const debouncedValidateEmail = useCallback(
    debounce((value) => {
      if (isEmailTouched && value && !validateEmail(value)) {
        setShowEmailError(true);
      } else {
        setShowEmailError(false);
      }
    }, 800),
    [isEmailTouched]
  );

  useEffect(() => {
    return () => {
      debouncedValidateEmail.cancel();
    };
  }, [debouncedValidateEmail]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage('');
    setError('');

    if (!validateEmail(email)) {
      setError(t('pleaseEnterValidEmail'));
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/auth/forgot-password`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      const data = await response.json();

      if (response.ok) {
        setMessage(data.msg);
      } else {
        setError(data.msg);
      }
    } catch (err) {
      setError(t('unexpectedError'));
    }
  };

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);
    debouncedValidateEmail(value);
  };

  const handleEmailBlur = () => {
    setIsEmailTouched(true);
    if (email) {
      debouncedValidateEmail(email);
    }
  };

  return (
    <ForgotPasswordPage
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      <ForgotPasswordHeader>
        <BackButton onClick={() => navigate(-1)}>
          <ArrowLeft size={20} />
        </BackButton>
        <PageTitle>{t('forgotPassword')}</PageTitle>
      </ForgotPasswordHeader>

      <Container
        initial={{ y: 20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{ y: -20, opacity: 0 }}
        transition={{ duration: 0.5 }}
      >
        <Description>{t('forgotPasswordDescription')}</Description>

        <Form onSubmit={handleSubmit}>
          <FormGroup>
            <Input
              type="email"
              value={email}
              onChange={handleEmailChange}
              onBlur={handleEmailBlur}
              placeholder={t('email')}
              required
              $hasError={showEmailError}
            />
            <AnimatePresence>
              {showEmailError && (
                <ErrorMessage
                  initial={{ opacity: 0, y: -10 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -10 }}
                  transition={{ duration: 0.2 }}
                >
                  <AlertTriangle />
                  {t('pleaseEnterValidEmail')}
                </ErrorMessage>
              )}
            </AnimatePresence>
          </FormGroup>

          <SubmitButton 
            type="submit"
            whileTap={{ scale: 0.98 }}
          >
            <Send size={16} />
            {t('resetPassword')}
          </SubmitButton>
        </Form>

        <AnimatePresence>
          {message && (
            <SuccessMessage
              initial={{ opacity: 0, y: 20, scale: 0.95 }}
              animate={{ opacity: 1, y: 0, scale: 1 }}
              exit={{ opacity: 0, y: -20, scale: 0.95 }}
              transition={{ duration: 0.2 }}
            >
              {message}
            </SuccessMessage>
          )}
        </AnimatePresence>
      </Container>
    </ForgotPasswordPage>
  );
};

export default ForgotPassword;