import React from 'react';
import styled, { keyframes } from 'styled-components';

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const shimmer = keyframes`
  0% { background-position: -200% center; }
  100% { background-position: 200% center; }
`;

const SpinnerWrapper = styled.div`
  position: relative;
  width: ${props => props.$size}px;
  height: ${props => props.$size}px;
`;

const SpinnerOuter = styled.div`
  position: absolute;
  inset: 0;
  border-radius: 50%;
  padding: 2px;
  background: linear-gradient(
    165deg,
    rgba(136, 83, 251, 0.8),
    rgba(96, 57, 176, 0.2)
  );
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  animation: ${spin} 1.5s linear infinite;

  &::after {
    content: '';
    position: absolute;
    inset: -2px;
    background: linear-gradient(
      90deg,
      rgba(136, 83, 251, 0.2) 0%,
      rgba(136, 83, 251, 0.8) 50%,
      rgba(136, 83, 251, 0.2) 100%
    );
    background-size: 200% auto;
    animation: ${shimmer} 2s linear infinite;
    border-radius: inherit;
    opacity: 0.5;
  }
`;

const SpinnerInner = styled.div`
  position: absolute;
  inset: 4px;
  background: linear-gradient(
    165deg,
    rgba(38, 38, 45, 0.95),
    rgba(28, 28, 35, 0.95)
  );
  border-radius: 50%;
  backdrop-filter: blur(8px);
`;

const LoadingSpinner = ({ size = 40 }) => {
  return (
    <SpinnerWrapper $size={size}>
      <SpinnerOuter />
      <SpinnerInner />
    </SpinnerWrapper>
  );
};

export default LoadingSpinner; 