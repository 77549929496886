// WalletProvider.js
import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useCallback,
} from "react";
import { useTranslation } from "react-i18next";

const WalletContext = createContext();

export const useWallet = () => useContext(WalletContext);

export const WalletProvider = ({ children }) => {
  const [walletData, setWalletData] = useState(null);
  const [subscriptionPlans, setSubscriptionPlans] = useState({});
  const { i18n } = useTranslation();

  /**
   * Fetches wallet data from the server.
   * Memoized with useCallback to prevent unnecessary re-renders.
   */
  const fetchWalletData = useCallback(async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/wallet`,
        {
          credentials: "include",
          headers: {
            "Accept-Language": i18n.language,
          },
        }
      );
      if (response.ok) {
        const data = await response.json();
        setWalletData(data);
      } else {
        console.error("Failed to fetch wallet data");
      }
    } catch (error) {
      console.error("Error fetching wallet data:", error);
    }
  }, [i18n.language]);

  /**
   * Fetches subscription plans from the server.
   * Memoized with useCallback to prevent unnecessary re-renders.
   */
  const fetchSubscriptionPlans = useCallback(async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/wallet/subscription-plans`,
        {
          credentials: "include",
          headers: {
            "Accept-Language": i18n.language,
          },
        }
      );
      if (response.ok) {
        const data = await response.json();
        setSubscriptionPlans(data);
      } else {
        console.error("Failed to fetch subscription plans");
      }
    } catch (error) {
      console.error("Error fetching subscription plans:", error);
    }
  }, [i18n.language]);

  useEffect(() => {
    fetchWalletData();
    fetchSubscriptionPlans();
  }, [fetchWalletData, fetchSubscriptionPlans]);

  /**
   * Updates the user's subscription.
   * Memoized with useCallback.
   */
  const updateSubscription = useCallback(async (plan, interval) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/wallet/update-subscription`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
          body: JSON.stringify({ plan, interval }),
        }
      );
      if (response.ok) {
        const updatedWalletData = await response.json();
        setWalletData(updatedWalletData);
        return updatedWalletData;
      } else {
        const errorData = await response.json();
        console.error("Failed to update subscription:", errorData);
        return null;
      }
    } catch (error) {
      console.error("Error updating subscription:", error);
      return null;
    }
  }, []);

  /**
   * Creates a Stripe checkout session.
   * Memoized with useCallback.
   */
  const createCheckoutSession = useCallback(async (plan, interval) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/wallet/create-checkout-session`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
          body: JSON.stringify({ plan, interval }),
        }
      );
      if (response.ok) {
        const { sessionId } = await response.json();
        return sessionId;
      } else {
        console.error("Failed to create checkout session");
        return null;
      }
    } catch (error) {
      console.error("Error creating checkout session:", error);
      return null;
    }
  }, []);

  /**
   * Reactivates a canceled subscription.
   * Memoized with useCallback.
   */
  const reactivateSubscription = useCallback(async (plan, interval) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/wallet/reactivate-subscription`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
          body: JSON.stringify({ plan, interval }),
        }
      );
      if (response.ok) {
        const updatedWalletData = await response.json();
        setWalletData(updatedWalletData);
        return updatedWalletData;
      } else {
        console.error("Failed to reactivate subscription");
        return null;
      }
    } catch (error) {
      console.error("Error reactivating subscription:", error);
      return null;
    }
  }, []);

  return (
    <WalletContext.Provider
      value={{
        walletData,
        setWalletData,
        fetchWalletData,
        subscriptionPlans,
        updateSubscription,
        createCheckoutSession,
        reactivateSubscription,
      }}
    >
      {children}
    </WalletContext.Provider>
  );
};
