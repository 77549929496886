import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import SignInModal from "./modals/SignInModal";
import "../assets/styles/EmailVerification.css";

const EmailVerification = () => {
  const [verificationStatus, setVerificationStatus] = useState("verifying");
  const [countdown, setCountdown] = useState(5); // Initialize countdown state
  const [showSignInModal, setShowSignInModal] = useState(false); // Initialize modal visibility state
  const { token } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    let isMounted = true;
    let errorTimer;

    const verifyEmail = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/auth/verify-email/${token}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        const data = await response.json();

        if (isMounted) {
          if (response.ok) {
            if (data.alreadyVerified) {
              // If email is already verified, redirect to login and show the modal
              setVerificationStatus("already-verified");
              startCountdown();
            } else {
              // Successfully verified email, start countdown
              setVerificationStatus("success");
              startCountdown();
            }
          } else {
            // Handle error responses only if email is not already verified
            errorTimer = setTimeout(() => {
              if (isMounted) {
                setVerificationStatus("error");
              }
            }, 3000); // Set error state after 3 seconds
          }
        }
      } catch (error) {
        console.error("Verification error:", error); // Log the error for debugging
        if (isMounted) {
          setVerificationStatus("error");
        }
      }
    };

    // Trigger verification process only once when the component mounts
    verifyEmail();

    return () => {
      isMounted = false;
      if (errorTimer) {
        clearTimeout(errorTimer);
      }
    };
  }, [token]); // Only run this effect once, when `token` changes

  // Define startCountdown function
  const startCountdown = () => {
    const timer = setInterval(() => {
      setCountdown((prevCount) => {
        if (prevCount <= 1) {
          clearInterval(timer);
          setShowSignInModal(true); // Show sign-in modal after countdown
          return 0;
        }
        return prevCount - 1;
      });
    }, 1000); // Countdown updates every second
  };

  const handleCloseSignInModal = () => {
    setShowSignInModal(false);
    navigate("/home/buy");
  };

  const handleSignInSuccess = () => {
    handleCloseSignInModal();
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="email-verification-container"
    >
      <h1>Email Verification</h1>
      {verificationStatus === "verifying" && (
        <p className="verification-status verifying verifying-animation">
          Verifying your email...
        </p>
      )}
      {verificationStatus === "success" && (
        <>
          <p className="verification-status success">
            Your email has been successfully verified!
          </p>
          {countdown > 0 && (
            <p className="countdown">
              Redirecting to login in {countdown} seconds...
            </p>
          )}
        </>
      )}
      {verificationStatus === "already-verified" && (
        <>
          <p className="verification-status success">
            Your email has already been verified.
          </p>
          {countdown > 0 && (
            <p className="countdown">
              Redirecting to login in {countdown} seconds...
            </p>
          )}
        </>
      )}
      {verificationStatus === "error" && (
        <>
          <p className="verification-status error">
            There was an error verifying your email.
          </p>
          <button
            className="retry-button"
            onClick={() => window.location.reload()}
          >
            Try Again
          </button>
        </>
      )}

      <SignInModal
        open={showSignInModal}
        onClose={handleCloseSignInModal}
        onSuccess={handleSignInSuccess}
      />
    </motion.div>
  );
};

export default EmailVerification;
