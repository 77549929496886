import React, { useState, useContext, useCallback, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import {
  Eye,
  EyeOff,
  Check,
  X,
  LogOut as ExitToAppIcon,
  Trash2,
  UserMinus,
  Globe,
  ChevronDown,
  ChevronUp,
  Settings as SettingsIcon,
  Link,
  AlertTriangle,
} from "react-feather";
import { AuthContext } from "../../context/AuthContext";
import "../../assets/styles/AccountSettings.css";
import { debounce } from "lodash";
import Toast from "../common/Toast";
import ConfirmationModal from "../common/ConfirmationModal";
import LoadingOverlay from "../common/LoadingOverlay"; // Change this import
import { useTranslation } from "react-i18next";
import PolicyModal from "../modals/PolicyModal"; // Add this import
import {
  privacyPolicyContent,
  termsOfServiceContent,
} from "../../utils/policyContent"; // Add this import
import styled from 'styled-components';

const ValidationMessage = styled(motion.div)`
  position: absolute;
  left: 0;
  bottom: -28px;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 4px 12px;
  color: rgba(255, 59, 48, 0.8);
  pointer-events: none;
  font-size: 13px;
  font-weight: 500;
  transform-origin: left center;

  svg {
    width: 14px;
    height: 14px;
    flex-shrink: 0;
    filter: drop-shadow(0 2px 4px rgba(255, 59, 48, 0.2));
  }
`;

const ActionButton = styled(motion.button)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 12px 24px;
  border-radius: 12px;
  font-size: 15px;
  font-weight: 500;
  transition: all 0.4s cubic-bezier(0.16, 1, 0.3, 1);
  backdrop-filter: blur(10px);
  cursor: pointer;
  svg {
    width: 18px;
    height: 18px;
  }
`;

const SaveButton = styled(ActionButton)`
  background: linear-gradient(
    165deg,
    rgba(136, 83, 251, 0.15),
    rgba(96, 57, 176, 0.15)
  );
  border: 1px solid rgba(136, 83, 251, 0.3);
  color: white;

  &:hover {
    transform: translateY(-2px);
    background: linear-gradient(
      165deg,
      rgba(136, 83, 251, 0.2),
      rgba(96, 57, 176, 0.2)
    );
    cursor: pointer;
    border-color: rgba(136, 83, 251, 0.4);
    box-shadow: 
      0 8px 32px rgba(136, 83, 251, 0.2),
      0 0 0 1px rgba(136, 83, 251, 0.2);
  }

  &:active {
    transform: translateY(1px);
  }
`;

const LogoutButton = styled(ActionButton)`
  background: linear-gradient(
    165deg,
    rgba(255, 59, 48, 0.1),
    rgba(255, 59, 48, 0.05)
  );
  border: 1px solid rgba(255, 59, 48, 0.2);
  color: rgba(255, 59, 48, 0.9);

  &:hover {
    transform: translateY(-2px);
    background: linear-gradient(
      165deg,
      rgba(255, 59, 48, 0.15),
      rgba(255, 59, 48, 0.1)
    );
    border-color: rgba(255, 59, 48, 0.3);
    box-shadow: 
      0 8px 32px rgba(255, 59, 48, 0.15),
      0 0 0 1px rgba(255, 59, 48, 0.2);
  }

  &:active {
    transform: translateY(1px);
  }
`;

const BottomActionsContainer = styled(motion.div)`
  width: 100%;
  max-width: 1120px;
  padding: 20px 40px;
  margin: 24px auto 0;
  background: linear-gradient(
    to top,
    rgba(18, 18, 24, 0.95),
    rgba(18, 18, 24, 0.8)
  );
  backdrop-filter: blur(20px);
  border: 1px solid rgba(255, 255, 255, 0.05);
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 24px;

  @media (max-width: 1200px) {
    padding: 20px;
    margin: 24px 20px;
    width: calc(100% - 40px);
  }

  @media (max-width: 768px) {
    padding: 16px;
    margin: 20px 16px;
    width: calc(100% - 32px);
    border-radius: 16px;
  }
`;

const LegalLinksContainer = styled.div`
  display: flex;
  gap: 16px;
  margin: 0 20px;

  @media (max-width: 768px) {
    display: none;
  }
`;

const LegalLink = styled.button`
  background: none;
  border: none;
  color: rgba(255, 255, 255, 0.5);
  font-size: 13px;
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 8px;
  border-radius: 8px;
  transition: all 0.3s ease;
  cursor: pointer;

  svg {
    width: 14px;
    height: 14px;
  }

  &:hover {
    color: rgba(255, 255, 255, 0.8);
    background: rgba(255, 255, 255, 0.05);
  }
`;

const AccountSettings = () => {
  const {
    user,
    loading,
    error,
    updateUserData,
    changePassword,
    validateCurrentPassword,
  } = useContext(AuthContext);
  const [userData, setUserData] = useState(user || {});
  const [passwordData, setPasswordData] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [passwordError, setPasswordError] = useState("");
  const [showPasswordRequirements, setShowPasswordRequirements] =
    useState(false);
  const [passwordRequirements, setPasswordRequirements] = useState({
    length: false,
    uppercase: false,
    lowercase: false,
    number: false,
    special: false,
  });
  const [passwordsMatch, setPasswordsMatch] = useState(false);
  const [showPasswordForm, setShowPasswordForm] = useState(false);
  const [isCurrentPasswordValid, setIsCurrentPasswordValid] = useState(false);
  const [toast, setToast] = useState(null);
  const [showCancelButton, setShowCancelButton] = useState(false);
  const [activeModal, setActiveModal] = useState(null);
  const [newPasswordError, setNewPasswordError] = useState("");
  const { t, i18n } = useTranslation();
  const [availableLanguages] = useState(["en", "ptbr"]);
  const [showAdvancedActions, setShowAdvancedActions] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);

  useEffect(() => {
    let timer;
    if (activeDropdown) {
      timer = setTimeout(() => {
        setActiveDropdown(null);
      }, 10000); // 10 seconds
    }
    return () => clearTimeout(timer);
  }, [activeDropdown]);

  const debouncedValidatePassword = useCallback(
    debounce(async (value) => {
      const result = await validateCurrentPassword(value);
      setIsCurrentPasswordValid(result.success);
      if (!result.success) {
        setPasswordError(result.error);
      } else {
        setPasswordError("");
      }
    }, 1000),
    [validateCurrentPassword]
  );

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (name === "email") return; // Prevent email updates
    setUserData((prevState) => {
      const newState = { ...prevState };
      if (name.includes(".")) {
        const [category, setting] = name.split(".");
        newState[category] = {
          ...newState[category],
          [setting]: type === "checkbox" ? checked : value,
        };
      } else {
        newState[name] = type === "checkbox" ? checked : value;
      }
      return newState;
    });
  };

  const handlePasswordInputChange = (e) => {
    const { name, value } = e.target;
    setPasswordData((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    if (name === "currentPassword") {
      if (value === "") {
        // Reset the password form state when current password is emptied
        setShowPasswordForm(false);
        setPasswordData({
          currentPassword: "",
          newPassword: "",
          confirmPassword: "",
        });
        setIsCurrentPasswordValid(false);
        setShowPasswordRequirements(false);
        setShowCancelButton(false);
        setPasswordError("");
      } else {
        debouncedValidatePassword(value);
      }
    }

    if (name === "newPassword") {
      setShowCancelButton(value.length > 0);
      // Check if new password is the same as current password
      if (value === passwordData.currentPassword) {
        setNewPasswordError("Avoid reusing your last password.");
      } else {
        setNewPasswordError("");
      }
    }

    if (name === "newPassword" || name === "confirmPassword") {
      setShowPasswordRequirements(true);
      checkPasswordRequirements(
        name === "newPassword" ? value : passwordData.newPassword
      );
      checkPasswordsMatch(
        name === "newPassword" ? value : passwordData.newPassword,
        name === "confirmPassword" ? value : passwordData.confirmPassword
      );
    }
  };

  const checkPasswordRequirements = (password) => {
    setPasswordRequirements({
      length: password.length >= 8,
      uppercase: /[A-Z]/.test(password),
      lowercase: /[a-z]/.test(password),
      number: /[0-9]/.test(password),
      special: /[!@#$%^&*]/.test(password),
    });
  };

  const checkPasswordsMatch = (newPassword, confirmPassword) => {
    setPasswordsMatch(newPassword === confirmPassword);
  };

  const isPasswordValid = () => {
    return (
      passwordRequirements.length &&
      passwordRequirements.uppercase &&
      passwordRequirements.lowercase &&
      passwordRequirements.number &&
      passwordRequirements.special &&
      passwordsMatch &&
      isCurrentPasswordValid
    );
  };

  const handleSaveChanges = async () => {
    const result = await updateUserData(userData);
    if (result.success) {
      setToast({ message: "Settings updated successfully!", type: "success" });
    } else {
      setToast({
        message: `Failed to update settings: ${result.error}`,
        type: "error",
      });
    }
  };

  const handlePasswordChange = async () => {
    if (isPasswordValid()) {
      const result = await changePassword(passwordData);
      if (result.success) {
        setToast({
          message: "Password changed successfully!",
          type: "success",
        });
        setPasswordData({
          currentPassword: "",
          newPassword: "",
          confirmPassword: "",
        });
        setShowPasswordForm(false);
        setIsCurrentPasswordValid(false);
        setShowPasswordRequirements(false);
        setShowCancelButton(false);

        // Add a delay before validating the new password
        setTimeout(async () => {
          await validateCurrentPassword(passwordData.newPassword);
        }, 2000);
      } else {
        setToast({ message: result.error, type: "error" });
      }
    } else {
      setToast({
        message: "Please ensure all password requirements are met.",
        type: "error",
      });
    }
  };

  const handleOpenModal = (modalType) => {
    setActiveModal(modalType);
  };

  const handleCloseModal = () => {
    setActiveModal(null);
  };

  const handleLogout = () => {
    setActiveModal("logout");
  };

  const handleDeactivate = () => {
    setActiveModal("deactivate");
  };

  const handleDelete = () => {
    setActiveModal("delete");
  };

  const confirmLogout = () => {
    fetch(`${process.env.REACT_APP_API_URL}/api/auth/logout`, {
      method: "POST",
      credentials: "include",
    })
      .then((response) => {
        if (response.ok) {
          window.location.reload();
        } else {
          console.error("Logout failed");
        }
      })
      .catch((err) => console.error("Sign out error:", err));
  };

  const confirmDeactivate = async () => {
    setActiveModal(null);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/auth/deactivate`,
        {
          method: "POST",
          credentials: "include",
        }
      );
      if (response.ok) {
        setToast({
          message: "Account deactivated. You will be logged out.",
          type: "success",
        });
        // Wait for the toast to be displayed before logging out
        setTimeout(() => {
          window.location.href = "/";
        }, 3000);
      } else {
        const errorData = await response.json();
        throw new Error(errorData.msg || "Failed to deactivate account");
      }
    } catch (error) {
      setToast({
        message: `Error deactivating account: ${error.message}`,
        type: "error",
      });
    }
  };

  const confirmDelete = async () => {
    setActiveModal(null);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/auth/delete`,
        {
          method: "POST",
          credentials: "include",
        }
      );
      if (response.ok) {
        setToast({
          message:
            "Account deletion process initiated. Your account will be deleted within 30 days. You can reactivate by logging back in during this period.",
          type: "success",
        });
        // Wait for the toast to be displayed before logging out
        setTimeout(() => {
          window.location.href = "/";
        }, 5000);
      } else {
        const errorData = await response.json();
        throw new Error(errorData.msg || "Failed to initiate account deletion");
      }
    } catch (error) {
      setToast({
        message: `Error initiating account deletion: ${error.message}`,
        type: "error",
      });
    }
  };

  const handleLanguageChange = async (language) => {
    try {
      const updatedUserData = { ...userData, preferredLanguage: language };
      const result = await updateUserData(updatedUserData);
      if (result.success) {
        setUserData(updatedUserData);
        i18n.changeLanguage(language);
        setToast({ message: t("languageUpdateSuccess"), type: "success" });
      } else {
        setToast({ message: t("languageUpdateError"), type: "error" });
      }
    } catch (error) {
      console.error("Error updating language preference:", error);
      setToast({ message: t("languageUpdateError"), type: "error" });
    }
  };

  const handleDropdownToggle = (dropdownName) => {
    setActiveDropdown(activeDropdown === dropdownName ? null : dropdownName);
  };

  if (loading) return <LoadingOverlay />;
  if (error) return <div>Error: {error}</div>;

  // Add these variants for animations
  const pageVariants = {
    initial: { opacity: 0, y: 20 },
    animate: { 
      opacity: 1, 
      y: 0,
      transition: {
        duration: 0.6,
        ease: [0.16, 1, 0.3, 1],
        staggerChildren: 0.1
      }
    },
    exit: { 
      opacity: 0, 
      y: 20,
      transition: {
        duration: 0.4,
        ease: [0.16, 1, 0.3, 1]
      }
    }
  };

  const sectionVariants = {
    initial: { opacity: 0, y: 20 },
    animate: { 
      opacity: 1, 
      y: 0,
      transition: {
        duration: 0.5,
        ease: [0.16, 1, 0.3, 1]
      }
    }
  };

  return (
    <motion.div 
      className="account-settings-container" 
      {...pageVariants}
    >
      <motion.div className="account-settings">
        <motion.h1
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2, duration: 0.5 }}
        >
          {t("accountSettings")}
        </motion.h1>
        
        <motion.div 
          className="settings-grid"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.3, duration: 0.5 }}
        >
          {/* Personal Information Section */}
          <motion.section 
            className="settings-section"
            variants={sectionVariants}
          >
            <h2>{t("personalInformation")}</h2>
            <div className="form-group">
              <div className="input-label-group">
                <label htmlFor="name">{t("name")}</label>
              </div>
              <input
                type="text"
                id="name"
                name="name"
                value={userData.name || ""}
                onChange={handleInputChange}
              />
            </div>
            <div className="form-group">
              <div className="input-label-group">
                <label htmlFor="email">{t("email")}</label>
              </div>
              <input
                type="email"
                id="email"
                name="email"
                value={userData.email || ""}
                onChange={handleInputChange}
                disabled
              />
            </div>
            <div className="form-group">
              <div className="input-label-group">
                <label htmlFor="location">{t("location")}</label>
              </div>
              <input
                type="text"
                id="location"
                name="location"
                value={userData.location || ""}
                onChange={handleInputChange}
              />
            </div>
            <div className="form-group">
              <div className="input-label-group">
                <label htmlFor="bio">{t("bio")}</label>
              </div>
              <textarea
                id="bio"
                name="bio"
                value={userData.bio || ""}
                onChange={handleInputChange}
              />
            </div>
          </motion.section>

          {/* Security Section */}
          <motion.section 
            className="settings-section"
            variants={sectionVariants}
          >
            <h2>{t("security")}</h2>
            {!showPasswordForm ? (
              <button
                onClick={() => setShowPasswordForm(true)}
                className="secondary-button"
              >
                {t("changePassword")}
              </button>
            ) : (
              <div>
                <div className="form-group">
                  <div className="password-label-group">
                    <label htmlFor="currentPassword">
                      {t("currentPassword")}
                    </label>
                  </div>
                  <div className="password-input">
                    <input
                      type={showCurrentPassword ? "text" : "password"}
                      id="currentPassword"
                      name="currentPassword"
                      value={passwordData.currentPassword}
                      onChange={handlePasswordInputChange}
                      required
                      $hasError={!!passwordError}
                      className={passwordError ? 'error' : ''}
                    />
                    <button
                      type="button"
                      className="toggle-password"
                      onClick={() =>
                        setShowCurrentPassword(!showCurrentPassword)
                      }
                    >
                      {showCurrentPassword ? (
                        <EyeOff size={20} />
                      ) : (
                        <Eye size={20} />
                      )}
                    </button>
                    <AnimatePresence>
                      {passwordError && (
                        <ValidationMessage
                          initial={{ opacity: 0, y: -10 }}
                          animate={{ opacity: 1, y: 0 }}
                          exit={{ opacity: 0, y: -10 }}
                          transition={{ duration: 0.2 }}
                        >
                          <AlertTriangle size={14} />
                          {passwordError}
                        </ValidationMessage>
                      )}
                    </AnimatePresence>
                  </div>
                </div>
                {isCurrentPasswordValid && (
                  <>
                    <div className="form-group">
                      <div className="password-label-group">
                        <label htmlFor="newPassword">{t("newPassword")}</label>
                        {newPasswordError && (
                          <span className="password-error">
                            {newPasswordError}
                          </span>
                        )}
                      </div>
                      <div className="password-input">
                        <input
                          type={showNewPassword ? "text" : "password"}
                          id="newPassword"
                          name="newPassword"
                          value={passwordData.newPassword}
                          onChange={handlePasswordInputChange}
                          required
                          $hasError={!!newPasswordError}
                          className={newPasswordError ? 'error' : ''}
                        />
                        <button
                          type="button"
                          className="toggle-password"
                          onClick={() => setShowNewPassword(!showNewPassword)}
                        >
                          {showNewPassword ? (
                            <EyeOff size={20} />
                          ) : (
                            <Eye size={20} />
                          )}
                        </button>
                        <AnimatePresence>
                          {newPasswordError && (
                            <ValidationMessage
                              initial={{ opacity: 0, y: -10 }}
                              animate={{ opacity: 1, y: 0 }}
                              exit={{ opacity: 0, y: -10 }}
                              transition={{ duration: 0.2 }}
                            >
                              <AlertTriangle size={14} />
                              {newPasswordError}
                            </ValidationMessage>
                          )}
                        </AnimatePresence>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="password-label-group">
                        <label htmlFor="confirmPassword">
                          {t("confirmNewPassword")}
                        </label>
                      </div>
                      <div className="password-input">
                        <input
                          type={showConfirmPassword ? "text" : "password"}
                          id="confirmPassword"
                          name="confirmPassword"
                          value={passwordData.confirmPassword}
                          onChange={handlePasswordInputChange}
                          required
                        />
                        <button
                          type="button"
                          className="toggle-password"
                          onClick={() =>
                            setShowConfirmPassword(!showConfirmPassword)
                          }
                        >
                          {showConfirmPassword ? (
                            <EyeOff size={20} />
                          ) : (
                            <Eye size={20} />
                          )}
                        </button>
                      </div>
                    </div>
                  </>
                )}
                {showPasswordRequirements && (
                  <div className="password-requirements">
                    <p>{t("passwordMust")}:</p>
                    <ul>
                      <li className={passwordRequirements.length ? "met" : ""}>
                        {passwordRequirements.length ? (
                          <Check size={14} />
                        ) : (
                          <X size={14} />
                        )}
                        {t("beAtLeast8CharactersLong")}
                      </li>
                      <li
                        className={passwordRequirements.uppercase ? "met" : ""}
                      >
                        {passwordRequirements.uppercase ? (
                          <Check size={14} />
                        ) : (
                          <X size={14} />
                        )}
                        {t("containAtLeastOneUppercaseLetter")}
                      </li>
                      <li
                        className={passwordRequirements.lowercase ? "met" : ""}
                      >
                        {passwordRequirements.lowercase ? (
                          <Check size={14} />
                        ) : (
                          <X size={14} />
                        )}
                        {t("containAtLeastOneLowercaseLetter")}
                      </li>
                      <li className={passwordRequirements.number ? "met" : ""}>
                        {passwordRequirements.number ? (
                          <Check size={14} />
                        ) : (
                          <X size={14} />
                        )}
                        {t("containAtLeastOneNumber")}
                      </li>
                      <li className={passwordRequirements.special ? "met" : ""}>
                        {passwordRequirements.special ? (
                          <Check size={14} />
                        ) : (
                          <X size={14} />
                        )}
                        {t("containAtLeastOneSpecialCharacter")}
                      </li>
                      <li className={passwordsMatch ? "met" : ""}>
                        {passwordsMatch ? <Check size={14} /> : <X size={14} />}
                        {t("passwordsMustMatch")}
                      </li>
                    </ul>
                  </div>
                )}
                <div className="button-container">
                  {showCancelButton && (
                    <button
                      type="button"
                      onClick={() => {
                        setShowPasswordForm(false);
                        setPasswordData({
                          currentPassword: "",
                          newPassword: "",
                          confirmPassword: "",
                        });
                        setShowCancelButton(false);
                      }}
                      className="cancel-button"
                    >
                      {t("cancel")}
                    </button>
                  )}
                  {isPasswordValid() && (
                    <button
                      type="button"
                      onClick={handlePasswordChange}
                      className="save-button"
                    >
                      {t("changePassword")}
                    </button>
                  )}
                </div>
              </div>
            )}
          </motion.section>

          {/* Account Actions Section */}
          <motion.section 
            className="settings-section"
            variants={sectionVariants}
          >
            <h2>{t("accountActions")}</h2>
            <div className="account-actions">
              {/* Language Preferences */}
              <div className="language-preferences">
                <div
                  className="language-toggle"
                  onClick={() => handleDropdownToggle('language')}
                >
                  <Globe size={20} />
                  <span>{t("languagePreferences")}</span>
                  {activeDropdown === 'language' ? (
                    <ChevronUp size={20} />
                  ) : (
                    <ChevronDown size={20} />
                  )}
                </div>
                <AnimatePresence>
                  {activeDropdown === 'language' && (
                    <motion.div
                      className="language-dropdown"
                      initial={{ opacity: 0, height: 0 }}
                      animate={{ opacity: 1, height: 'auto' }}
                      exit={{ opacity: 0, height: 0 }}
                      transition={{ duration: 0.2, ease: [0.16, 1, 0.3, 1] }}
                    >
                      {availableLanguages.map((lang) => (
                        <button
                          key={lang}
                          className={`language-option ${
                            userData.preferredLanguage === lang ? "active" : ""
                          }`}
                          onClick={() => handleLanguageChange(lang)}
                        >
                          {userData.preferredLanguage === lang && <Check size={16} />}
                          {t(`language.${lang}`)}
                        </button>
                      ))}
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>

              {/* Advanced Actions */}
              <div className="account-action-item advanced-actions">
                <div
                  className="text-button advanced-actions-toggle"
                  onClick={() => handleDropdownToggle('advanced')}
                >
                  <SettingsIcon size={20} />
                  <span>{t("advancedActions")}</span>
                  {activeDropdown === 'advanced' ? (
                    <ChevronUp size={20} />
                  ) : (
                    <ChevronDown size={20} />
                  )}
                </div>
                <AnimatePresence>
                  {activeDropdown === 'advanced' && (
                    <motion.div
                      className="advanced-actions-dropdown"
                      initial={{ opacity: 0, height: 0 }}
                      animate={{ opacity: 1, height: 'auto' }}
                      exit={{ opacity: 0, height: 0 }}
                      transition={{ duration: 0.2, ease: [0.16, 1, 0.3, 1] }}
                    >
                      {/* Deactivate Account */}
                      <div
                        className="account-action-item"
                        onClick={handleDeactivate}
                      >
                        <div className="text-button deactivate-button">
                          <UserMinus size={20} />
                          <span>{t("deactivateAccount")}</span>
                        </div>
                        <p className="action-description">
                          {t("deactivateAccountDescription")}
                        </p>
                      </div>
                      {/* Delete Account */}
                      <div className="account-action-item" onClick={handleDelete}>
                        <div className="text-button delete-button">
                          <Trash2 size={20} />
                          <span>{t("deleteAccount")}</span>
                        </div>
                        <p className="action-description">
                          {t("deleteAccountDescription")}
                        </p>
                      </div>
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>
            </div>
          </motion.section>
        </motion.div>

        <BottomActionsContainer
          initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ 
            duration: 0.6, 
            delay: 0.3,
            ease: [0.16, 1, 0.3, 1]
          }}
        >
          <LogoutButton onClick={handleLogout}>
            <ExitToAppIcon size={20} />
            {t("logout")}
          </LogoutButton>

          <LegalLinksContainer>
            <LegalLink onClick={() => handleOpenModal("privacyPolicy")}>
              <Link size={14} />
              {t("privacyPolicy")}
            </LegalLink>
            <LegalLink onClick={() => handleOpenModal("termsOfService")}>
              <Link size={14} />
              {t("termsOfService")}
            </LegalLink>
          </LegalLinksContainer>

          <SaveButton 
            onClick={handleSaveChanges}
            whileTap={{ scale: 0.98 }}
          >
            <Check size={20} />
            {t("saveChanges")}
          </SaveButton>
        </BottomActionsContainer>
      </motion.div>
      <ConfirmationModal
        isOpen={activeModal === "logout"}
        message={t("areYouSureYouWantToLogOut")}
        onConfirm={confirmLogout}
        onClose={handleCloseModal}
        confirmLabel={t("logout")}
      />
      <ConfirmationModal
        isOpen={activeModal === "deactivate"}
        message={t("areYouSureYouWantToDeactivateYourAccount")}
        onConfirm={confirmDeactivate}
        onClose={handleCloseModal}
        confirmLabel={t("deactivate")}
      />
      <ConfirmationModal
        isOpen={activeModal === "delete"}
        message={t("areYouSureYouWantToDeleteYourAccount")}
        onConfirm={confirmDelete}
        onClose={handleCloseModal}
      />
      <PolicyModal
        open={
          activeModal === "privacyPolicy" || activeModal === "termsOfService"
        }
        onClose={handleCloseModal}
        title={
          activeModal === "privacyPolicy"
            ? t("privacyPolicy")
            : t("termsOfService")
        }
        content={
          activeModal === "privacyPolicy"
            ? privacyPolicyContent
            : termsOfServiceContent
        }
      />
      {toast && (
        <Toast
          message={toast.message}
          type={toast.type}
          onClose={() => setToast(null)}
        />
      )}
    </motion.div>
  );
};

export default AccountSettings;
