import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { Check, X, RefreshCw, Zap, ArrowUpCircle } from 'react-feather';
import {
  SubscriptionOverlay,
  SubscriptionContent,
  SubscriptionTitle,
  SubscriptionGrid,
  PlanCard,
  PlanName,
  PlanPrice,
  FeaturesList,
  ConfirmButton,
  CloseButton,
  IntervalSelection,
  IntervalButton,
  IntervalSlider,
  ConfirmationContent,
  ConfirmationTitle,
  PlanChangeDetails,
  CurrentPlanInfo,
  NewPlanInfo,
  PlanValue,
  ChangeImpact,
  ConfirmationActions,
  BackButton
} from './SubscriptionModal.styles';

const PLAN_ORDER = ["FREE", "PRO", "PREMIUM"];

const SubscriptionModal = ({
  isOpen,
  onClose,
  plans,
  onSubscriptionChange,
  onCheckout,
  currentSubscription,
  selectedPlan,
  setSelectedPlan,
  walletData
}) => {
  const { t } = useTranslation();
  const [selectedInterval, setSelectedInterval] = useState('monthly');
  const [confirmationStep, setConfirmationStep] = useState(false);

  const handlePlanSelect = (plan) => {
    if (currentSubscription.status === "canceling") {
      if (canReactivate(plan, selectedInterval)) {
        setSelectedPlan(plan);
        setConfirmationStep(false);
      }
    } else if (
      !isCurrentActivePlan(plan, selectedInterval) &&
      !isUpcomingPlan(plan, selectedInterval)
    ) {
      setSelectedPlan(plan);
      setConfirmationStep(false);
    }
  };

  const handleIntervalChange = (interval) => {
    setSelectedInterval(interval);
    setConfirmationStep(false);
  };

  const handleConfirm = async () => {
    if (selectedPlan) {
      if (currentSubscription.plan === "FREE" && !walletData?.stripeCustomerId) {
        await onCheckout(selectedPlan, selectedInterval);
        onClose();
      } else if (
        currentSubscription.status === "active" ||
        currentSubscription.status === "canceling"
      ) {
        setConfirmationStep(true);
      } else {
        await onCheckout(selectedPlan, selectedInterval);
        onClose();
      }
    }
  };

  const handleFinalConfirmation = async () => {
    try {
      if (currentSubscription.status === "canceling") {
        await onSubscriptionChange(selectedPlan, selectedInterval, true); // true for reactivation
      } else {
        await onSubscriptionChange(selectedPlan, selectedInterval);
      }
      onClose();
    } catch (error) {
      console.error('Error updating subscription:', error);
    }
  };

  const isCurrentActivePlan = (planKey, interval) => {
    return (
      currentSubscription.status === "active" &&
      currentSubscription.plan.toUpperCase() === planKey &&
      currentSubscription.interval.toLowerCase() === interval.toLowerCase()
    );
  };

  const isUpcomingPlan = (planKey, interval) => {
    return (
      currentSubscription.upcomingPlan === planKey &&
      currentSubscription.upcomingInterval &&
      currentSubscription.upcomingInterval.toLowerCase() === interval.toLowerCase()
    );
  };

  const canReactivate = (planKey, interval) => {
    return (
      currentSubscription.status === "canceling" &&
      currentSubscription.plan.toUpperCase() === planKey &&
      currentSubscription.interval.toLowerCase() === interval.toLowerCase()
    );
  };

  const getImpactMessage = (isReactivation, isUpgrade, isDowngrade, isDowngradeToFree, newPlan) => {
    if (isReactivation) {
      return t("reactivationImpact", {
        plan: t(currentSubscription.plan.toLowerCase()),
        interval: t(currentSubscription.interval)
      });
    }

    if (isUpgrade) {
      return t("upgradeImpact", {
        plan: t(selectedPlan.toLowerCase()),
        amount: newPlan.prices[selectedInterval].amount,
        interval: t(selectedInterval)
      });
    }

    if (isDowngradeToFree) {
      return t("downgradeToFreeImpact");
    }

    if (isDowngrade) {
      return t("downgradeImpact", {
        plan: t(selectedPlan.toLowerCase()),
        newAmount: newPlan.prices[selectedInterval].amount,
        newInterval: t(selectedInterval)
      });
    }

    return t("noChangeImpact");
  };

  const renderConfirmationStep = () => {
    const newPlan = plans[selectedPlan];
    const currentPlanKey = currentSubscription.plan.toUpperCase();
    const newPlanKey = selectedPlan.toUpperCase();

    const isUpgrade = PLAN_ORDER.indexOf(newPlanKey) > PLAN_ORDER.indexOf(currentPlanKey);
    const isDowngrade = PLAN_ORDER.indexOf(newPlanKey) < PLAN_ORDER.indexOf(currentPlanKey);
    const isReactivation = currentSubscription.status === "canceling" && currentPlanKey === newPlanKey;
    const isDowngradeToFree = isDowngrade && newPlanKey === "FREE";

    return (
      <ConfirmationContent>
        <ConfirmationTitle>
          {isReactivation ? t("confirmReactivation") : t("confirmPlanChange")}
        </ConfirmationTitle>
        
        <PlanChangeDetails>
          <CurrentPlanInfo>
            <span>{t("currentPlan")}:</span>
            <PlanValue>
              {t(currentSubscription.plan.toLowerCase())}
              {currentSubscription.plan !== "FREE" &&
                ` ($${currentSubscription.price}/${t(currentSubscription.interval)})`}
            </PlanValue>
          </CurrentPlanInfo>

          {!isReactivation && (
            <NewPlanInfo>
              <span>{t("newPlan")}:</span>
              <PlanValue>
                {t(selectedPlan.toLowerCase())}
                {selectedPlan !== "FREE" &&
                  ` ($${newPlan.prices[selectedInterval].amount}/${t(selectedInterval)})`}
              </PlanValue>
            </NewPlanInfo>
          )}

          <ChangeImpact
            $type={
              isUpgrade
                ? "upgrade"
                : isDowngrade
                ? "downgrade"
                : isReactivation
                ? "reactivation"
                : "no-change"
            }
          >
            {getImpactMessage(isReactivation, isUpgrade, isDowngrade, isDowngradeToFree, newPlan)}
          </ChangeImpact>
        </PlanChangeDetails>

        <ConfirmationActions>
          <BackButton onClick={() => setConfirmationStep(false)}>
            {t("back")}
          </BackButton>
          <ConfirmButton onClick={handleFinalConfirmation}>
            {isReactivation ? t("confirmReactivation") : t("confirmChange")}
          </ConfirmButton>
        </ConfirmationActions>
      </ConfirmationContent>
    );
  };

  const renderPriceDisplay = (plan) => {
    if (!plan.prices || !plan.prices[selectedInterval]) {
      return t('free');
    }
    return (
      <>
        ${plan.prices[selectedInterval].amount}
        <span>/{selectedInterval === 'monthly' ? t('mo') : t('yr')}</span>
      </>
    );
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <SubscriptionOverlay
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          onClick={onClose}
        >
          <SubscriptionContent
            onClick={(e) => e.stopPropagation()}
            initial={{ scale: 0.95, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.95, opacity: 0 }}
            transition={{ type: "spring", damping: 25, stiffness: 500 }}
          >
            <CloseButton onClick={onClose}>
              <X size={24} />
            </CloseButton>
            
            {confirmationStep ? (
              renderConfirmationStep()
            ) : (
              <>
                <SubscriptionTitle>
                  {t('chooseYourPlan')}
                </SubscriptionTitle>

                <IntervalSelection>
                  <IntervalButton
                    type="button"
                    $active={selectedInterval === 'monthly'}
                    onClick={() => handleIntervalChange('monthly')}
                  >
                    {t('monthly')}
                  </IntervalButton>
                  <IntervalButton
                    type="button"
                    $active={selectedInterval === 'annual'}
                    onClick={() => handleIntervalChange('annual')}
                  >
                    {t('annual')}
                  </IntervalButton>
                  <IntervalSlider $position={selectedInterval === 'annual' ? 'right' : 'left'} />
                </IntervalSelection>

                <SubscriptionGrid>
                  {Object.entries(plans).map(([key, plan]) => (
                    <PlanCard
                      key={key}
                      $selected={selectedPlan === key}
                      onClick={() => handlePlanSelect(key)}
                      whileHover={{ scale: 1.02 }}
                      whileTap={{ scale: 0.98 }}
                    >
                      <PlanName>{t(plan.name.toLowerCase())}</PlanName>
                      <PlanPrice>
                        {renderPriceDisplay(plan)}
                      </PlanPrice>
                      <FeaturesList>
                        {plan.features.map((feature, index) => (
                          <li key={index}>
                            <Check size={16} />
                            <span>{t(feature)}</span>
                          </li>
                        ))}
                      </FeaturesList>
                    </PlanCard>
                  ))}
                </SubscriptionGrid>

                <ConfirmButton
                  onClick={handleConfirm}
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  disabled={
                    !selectedPlan || 
                    (currentSubscription.status === "canceling" && !canReactivate(selectedPlan, selectedInterval)) ||
                    isCurrentActivePlan(selectedPlan, selectedInterval)
                  }
                  className={(() => {
                    if (currentSubscription.status === "canceling") {
                      return "reactivate-button";
                    }
                    if (currentSubscription.plan === "FREE") {
                      return "subscribe-button";
                    }
                    if (
                      currentSubscription.status === "active" && 
                      PLAN_ORDER.indexOf(selectedPlan) > PLAN_ORDER.indexOf(currentSubscription.plan)
                    ) {
                      return "upgrade-button";
                    }
                    return "";
                  })()}
                >
                  <motion.span
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -10 }}
                    transition={{ duration: 0.2 }}
                  >
                    {(() => {
                      if (currentSubscription.status === "canceling") {
                        return (
                          <>
                            <RefreshCw size={16} style={{ marginRight: '8px' }} />
                            {t("reactivate")}
                          </>
                        );
                      }
                      if (currentSubscription.plan === "FREE") {
                        return (
                          <>
                            <Zap size={16} style={{ marginRight: '8px' }} />
                            {t("subscribe")}
                          </>
                        );
                      }
                      if (currentSubscription.status === "active") {
                        return (
                          <>
                            <ArrowUpCircle size={16} style={{ marginRight: '8px' }} />
                            {t("changePlan")}
                          </>
                        );
                      }
                      return t("subscribe");
                    })()}
                  </motion.span>
                </ConfirmButton>
              </>
            )}
          </SubscriptionContent>
        </SubscriptionOverlay>
      )}
    </AnimatePresence>
  );
};

export default SubscriptionModal;
