import { useReducer, useEffect } from "react";

const initialState = {
  favorites: [],
  purchased: [],
  processing: [],
  discarded: [],
  sold: [],
  loading: false,
  error: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_DATA":
      return {
        ...state,
        [action.category]: action.payload,
        loading: false,
      };
    case "LOADING":
      return {
        ...state,
        loading: true,
      };
    case "ERROR":
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

const useUserData = (category) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/user-data`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
            credentials: "include", // Include cookies in the request
          }
        );

        if (response.ok) {
          const result = await response.json();
          dispatch({
            type: "SET_DATA",
            category,
            payload: result[category] || [],
          });
        } else {
          dispatch({
            type: "ERROR",
            payload: `HTTP error! status: ${response.status}`,
          });
        }
      } catch (error) {
        console.error("Failed to fetch user data:", error);
        dispatch({ type: "ERROR", payload: error.message });
      }
    };

    dispatch({ type: "LOADING" });
    fetchData();
  }, [category]);

  return state;
};

export default useUserData;
