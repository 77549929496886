import React, { createContext, useState, useCallback } from "react";

export const NotesContext = createContext();

export const NotesProvider = ({ children }) => {
  const [notes, setNotes] = useState([]);
  const [propertyNotes, setPropertyNotes] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchNotes = useCallback(async (propertyId) => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/notes/property/${propertyId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
        }
      );
      if (!response.ok) {
        throw new Error("Failed to fetch notes");
      }
      const data = await response.json();
      setPropertyNotes(data);
      setError(null);
    } catch (err) {
      setError("Failed to fetch notes");
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const fetchAllUserNotes = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/notes/user`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
        }
      );
      if (!response.ok) {
        throw new Error("Failed to fetch notes");
      }
      const data = await response.json();
      setNotes(data);
      setError(null);
    } catch (err) {
      setError("Failed to fetch notes");
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const saveNote = useCallback(async (note) => {
    setIsLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/notes`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(note),
      });

      if (!response.ok) {
        throw new Error("Failed to save note");
      }

      const savedNote = await response.json();
      
      setNotes(prevNotes => [...prevNotes, savedNote]);
      setPropertyNotes(prevNotes => 
        note.propertyId === savedNote.propertyId 
          ? [...prevNotes, savedNote]
          : prevNotes
      );
      
      setError(null);
      return savedNote;
    } catch (err) {
      setError("Failed to save note");
      console.error(err);
      throw err;
    } finally {
      setIsLoading(false);
    }
  }, []);

  const deleteNote = async (noteId) => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/notes/${noteId}`,
        {
          method: "DELETE",
          credentials: "include",
        }
      );
      if (!response.ok) {
        const errorText = await response.text();
        console.error("Server response:", errorText);
        throw new Error(errorText || "Failed to delete note");
      }
      
      setNotes((prevNotes) => prevNotes.filter((note) => note._id !== noteId));
      setPropertyNotes((prevNotes) => prevNotes.filter((note) => note._id !== noteId));
      
      setError(null);
      return true;
    } catch (err) {
      setError(`Failed to delete note: ${err.message}`);
      console.error("Delete note error:", err);
      throw err;
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <NotesContext.Provider
      value={{ 
        notes, 
        propertyNotes, 
        isLoading, 
        error, 
        fetchNotes, 
        fetchAllUserNotes, 
        saveNote, 
        deleteNote 
      }}
    >
      {children}
    </NotesContext.Provider>
  );
};
